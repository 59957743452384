import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import { Page } from "../components/Page";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCancelledBill, getCurrentUser } from "../api-config/config";
import DateRangeComponent from "../components/DateRangePicker";
// import Button from "../theme/overrides/Button";
import { CSVLink } from "react-csv";
import FileDownload from "@mui/icons-material/FileDownload";
import { getGuestInHouse } from "../api-config/bill.api";

function GuestInHouseListGrid() {
  const [noShowlist, setNoShowList] = useState([] as any);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const navigate = useNavigate();
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchCheckinOrder(
      new Date(range.startDate).toISOString().slice(0, 10),
      new Date(range.endDate).toISOString().slice(0, 10)
    );
  }
  function fetchCheckinOrder(startDateString: any, endDateString: any) {
    getGuestInHouse(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setNoShowList(response.data);
      getRows(response.data);
      console.log(response.data);
    });
  }

  useEffect(() => {
    const getObj = getDateRange(new Date(), new Date());
    const start = new Date(getObj.start);
    const end = new Date(getObj.end);
    const startDateString = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endDateString = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    fetchCheckinOrder(
      new Date(start).toISOString().slice(0, 10),
      new Date(end).toISOString().slice(0, 10)
    );
  }, []);
  const loggedUser = getCurrentUser();
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth - 1),
      end: eDate.setMonth(currentMonth + 1),
    };
  }

  function onPaymentFolioClick(row: any) {
    navigate("/main/foliodetails", {
      state: {
        booking_id: row?.resId,
      },
    });
  }
  
  function getRows(data: any) {
    const allRows = [] as any;
    let id = 1;
    data.forEach((listData: any, key: any) => {
      // if (listData.booking_status === 'cancelled') {
      const date1 = new Date(listData.from_date);
      const month1 = date1.toLocaleString("default", { month: "short" });
      const date2 = new Date(listData.to_date);
      const month2 = date2.toLocaleString("default", { month: "short" });
      const diffDays = date2.getDate() - date1.getDate();
      const obj = {
        id: id++,
        resId: listData.booking_id,
        guestName: listData.guest_first_name + listData.last_name,
        guestEmail: listData.email,
        guestMobile: listData.mobile_number,
        stayDuration: `${date1.getDate()} ${month1} - ${date2.getDate()} ${month2}(${diffDays})`,
        roomType: listData.room_booking_info.room_title,
        roomNo:listData.room_booking_info.pms_room_no,
        ratePlan:listData?.room_booking_info?.pms_rate_plan_name ? listData?.room_booking_info?.pms_rate_plan_name : 'EP',
        pax: `${listData?.no_of_adults}(A)0(C)`,
        status: listData.booking_status,
        amount: listData.gross_amount,
        guest_id: listData?.guest_id,
      };
      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestName",
      headerName: "Guest Name",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    
    // {
    //   field: "resId",
    //   headerName: "Booking ID",
    //   headerClassName: "super-app-theme--header",
    //   width: 100,
    //   editable: true,
    // },
    {
      field: "stayDuration",
      headerName: "Stay Duration",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "roomType",
      headerName: "Room Type",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "roomNo",
      headerName: "Room No",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "pax",
    //   headerName: "Pax",
    //   width: 120,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "ratePlan",
      headerName: "Rate Plan",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "charges",
    //   headerName: "Charges",
    //   width: 150,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "preferences",
    //   headerName: "Preferences/Notes",
    //   width: 150,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "Payment Folio",
      width: 150,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={(event) => {
              onPaymentFolioClick(cellValues.row)
            }}
          >
            <Button
              type="button"
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              View
            </Button>
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <Box
      sx={{
        height: 423,
        width: "100%",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <p className="m-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink data={dynamicRows} filename={"guest-checkin-list.csv"}>
            <Tooltip title="Download Excel">
              <FileDownload></FileDownload>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <DataGrid
        rows={dynamicRows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function GuestInHouseList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Occupancy Report
          </Typography>
          <GuestInHouseListGrid />
        </Container>
      </Page>
    </>
  );
}
