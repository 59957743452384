import axios from "axios";
import { baseUrl } from "../api-config/config";
import api from "../api-config/axiosInstance";

export const billView = (rowData: any) => {
  api
    .get(
      `${baseUrl}/invoice_pms?booking_id=${rowData?.resId}&customer_id=${rowData?.guest_id}`
    )
    .then((res) => {
      window.open(res.data.file);
    });
};

export const voucherView = (rowData: any) => {
  window.open(
    `${baseUrl}/booking_pdf_pms?booking_id=${rowData?.resId}&customer_id=${rowData?.guest_id}`
  );
};

export const calculateNights = (startdate: any, enddate: any) => {
  const startStr = startdate;
  const endStr = enddate;

  const startDate = new Date(startStr);
  const endDate = new Date(endStr);
  const data: { date: string }[] = [];

  function addDays(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  let currentDate = startDate;
  while (currentDate < endDate) {
    data.push({ date: currentDate.toISOString().split("T")[0] });
    currentDate = addDays(currentDate, 1);
  }

  return data;
};

export const toCalculateTax = (
  ind_room_tariff: any,
  no_of_adults: any,
  rate_plan_amount: any,
  tax_info: any
) => {
  const taxableAmount = ind_room_tariff + rate_plan_amount * no_of_adults;

  let price = 0;
  tax_info?.forEach((brackets: any) => {
    if (
      taxableAmount <= brackets?.max_amount &&
      taxableAmount >= brackets?.min_amount
    ) {
      price = taxableAmount + (taxableAmount * brackets.tax_value) / 100;

      return;
    }
  });
  return {
    finalPrice: price,
    taxableAmount: taxableAmount,
    indTariff: ind_room_tariff,
  };
};

export const findTaxRate = (taxInfo: any, price: any, nights: any) => {
  let current_price = 0;
  taxInfo?.forEach((item: any) => {
    if (
      price / nights <= item?.max_amount &&
      price / nights >= item?.min_amount
    ) {
      current_price = price + (price * item.tax_value) / 100;
      return;
    }
  });

  return current_price;
};
