import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import {
  getCurrentUser,
  getTaxList,
  insertRoomRatePlan,
  insertRoomType,
  updateRoomRatePlan,
} from "../../api-config/config";
import Snackbar from "../CustomSnackBar/AlertSnack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItems = ["Light", "AC", "TV", "Fan"];
const RoomRatePlanType = (props: any) => {
  const {
    handleClickOpen,
    handleClose,
    onRoomTypeAdded,
    roomTypeData,
    roomTypes, 
    setSnackbarMessage,
    open1,
    setOpen1
  } = props;



  const loggedUser = getCurrentUser();

  const [toggleBtn, setToggleBtn] = useState(false);

  const isObjectEmpty = (objectName: any) => {
    return Object.keys(objectName).length === 0;
  };
  useEffect(() => {
    setToggleBtn(isObjectEmpty(roomTypeData));
  }, [roomTypeData]);

  const defaultValues = {
    room_type: roomTypeData?.roomtype,
    planName: roomTypeData?.planname,
    included: roomTypeData?.included,
    rate_amount: roomTypeData?.amount,
    description: roomTypeData?.description,
    room_type_id: roomTypeData?.room_type_id,
    id: roomTypeData?.id,

  };


  const formValidation = Yup.object().shape({
    room_type: Yup.string().required("Please Select room type"),

    // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // image: Yup.string().required("Please enter City"),
    planName: Yup.string().required("Please Select Plan Name"),
    included: Yup.string().required("Please select higher occupancy"),
    // description: Yup.string().required("Please enter extra bed number"),
    rate_amount: Yup.string().required(
      "Please enter the amount for extra meal"
    ),
    // service_charge: Yup.number().max(99, "Value must be less than 100"),
  });

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser);
    const { room_type, planName, included, rate_amount, description, id } =
      formData;
    const { hotel_code } = loggedUser;
    const created_by = 0;
    const getRoomtypeIdFor = (name: any) => {
      const room_id = roomTypes.find(
        (obj: any) =>
          obj.room_type_name.split(" ").join("").toLowerCase() ===
          name.split(" ").join("").toLowerCase()
      );
      return room_id.id;
    };
    const room_type_id = getRoomtypeIdFor(room_type);
    if (toggleBtn) {
      insertRoomRatePlan(
        planName,
        included,
        description,
        Number(rate_amount),
        room_type_id,
        hotel_code
      ).then((response) => {
        setOpen1(true)
        setSnackbarMessage(response);
        // setMessageType(response?.status_message);
        // setSnackbarMessage(response?.message)
        // setMsgDescription(response?.message)
        onRoomTypeAdded();
        handleClose();
      });
    } else {
      updateRoomRatePlan(
        planName,
        included,
        description,
        Number(rate_amount),
        room_type_id,
        hotel_code,
        id
      ).then((response) => {
        setOpen1(true)
        setSnackbarMessage(response);
        // setMessageType(response?.status_message);
        onRoomTypeAdded();
        handleClose();
        // setOpenSnack(false)
      });
    }
  };

  return (
    <>
      {/* <Snackbar message={snackbarMessage} /> */}
      <Formik
        initialValues={defaultValues}
        validationSchema={formValidation}
        onSubmit={(event) => handleSubmit(event)}
      >
        <Form className="grid gap-4 lg:grid-cols-1 p-8">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Room Type
            </label>
            <Field
              as="select"
              type="text"
              name="room_type"
              placeholder="Enter room type*"
              id="room_type"
            >
              <option value="">Select</option>
              {roomTypes?.map((room: any, i: any) => (
                <option value={room?.room_type_name} key={i + 1}>
                  {room?.room_type_name}
                </option>
              ))}
            </Field>
            {/* <Field
            name="room_type"
            className="con-field"
            placeholder="Enter room type*"
            id="room_type"
            type="text"
          /> */}
            <p className="text-red-600">
              <ErrorMessage name="room_type" />
            </p>
          </FormGroup>
          {/* <FormGroup>
          <label htmlFor="" className="pb-2">
            Upload Image
          </label>
          <Field name="image" className="con-field" id="image" type="file" />
          <p className="text-red-600">
            <ErrorMessage name="image" />
          </p>
        </FormGroup> */}
          <div className="grid gap-4 lg:grid-cols-2">
            <FormGroup>
              <label htmlFor="" className="pb-2">
                Plan Name
              </label>
              <Field as="select" type="text" name="planName">
                <option value="">Select</option>
                <option value={"EP"}>EP</option>
                <option value={"CP"}>CP</option>
                <option value={"MAP"}>MAP</option>
                <option value={"AP"}>AP</option>
              </Field>
              <p className="text-red-600">
                <ErrorMessage name="planName" />
              </p>
            </FormGroup>
            <FormGroup>
              <label htmlFor="" className="pb-2">
                Included
              </label>
              <Field as="select" name="included">
                <option value="">Select</option>
                <option value="Breakfast">Breakfast</option>
                <option value="Breakfast + 1 meal">Breakfast + 1 meal</option>
                <option value="Breakfast + 2 meal">Breakfast + 2 meal</option>
                <option value="Room Only">Room Only</option>
              </Field>
              <p className="text-red-600">
                <ErrorMessage name="included" />
              </p>
            </FormGroup>
          </div>
          <div className="grid gap-4 lg:grid-cols-2">
            <FormGroup>
              <div className="flex gap-2 lg:grid-cols-2">
                <label htmlFor="" className="pb-2">
                  Extra Meal Price
                </label>
                {/* <label>
                                <Field as={CustomInputComponent} name="toggle" />
                                {`Yes`}
                            </label> */}
              </div>
              <Field
                name="rate_amount"
                className="con-field"
                placeholder="Enter the amount for extra meal "
                id="rate_amount"
                type="number"
              />
              <p className="text-red-600">
                <ErrorMessage name="rate_amount" />
              </p>
            </FormGroup>
            <FormGroup>
              <label htmlFor="" className="pb-2">
                Description
              </label>
              <Field
                name="description"
                className="con-field"
                placeholder="Enter the description"
                id="description"
                type="text"
              />
              <p className="text-red-600">
                <ErrorMessage name="description" />
              </p>
            </FormGroup>
          </div>
          {/* <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Select Tax
            </label>
            <Field name="taxList" as={CustomeMultipleSelectCheckmarks2} />
            <p className="text-red-600">
              <ErrorMessage name="taxList" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Aminities
            </label>
            <Field name="aminities" as={CustomeMultipleSelectCheckmarks} />
            <p className="text-red-600">
              <ErrorMessage name="aminities" />
            </p>
          </FormGroup>
        </div> */}
          {/* <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Base Price
            </label>
            <Field
              name="basePrice"
              className="con-field"
              placeholder="Enter Base Price*"
              id="basePrice"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="basePrice" />
            </p>
          </FormGroup>
          {/* <FormGroup>
            <label htmlFor="" className="pb-2">
              Extra Bed Price
            </label>
            <Field
              name="extraBedPrice"
              className="con-field"
              placeholder="Enter Extra Bed Price"
              id="extraBedPrice"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="extraBedPrice" />
            </p>
          </FormGroup> */}
          {/* </div> */}
          {/* <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Service Charge(%)
            </label>
            <Field
              name="service_charge"
              className="con-field"
              placeholder="Enter room service charge"
              id="service_charge"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="service_charge" />
            </p>
          </FormGroup>
        </div> */}
          <div className="grid gap-4 lg:grid-cols-2">
            <FormGroup>
              <Button
                type="button"
                onClick={() => handleClose()}
                className="bg-sky-300 hover:bg-sky-400"
              >
                Cancel
              </Button>
            </FormGroup>
            {toggleBtn ? (
              <FormGroup>
                <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
                  Save
                </Button>
              </FormGroup>
            ) : (
              <FormGroup>
                <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
                  Update
                </Button>
              </FormGroup>
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default RoomRatePlanType;
