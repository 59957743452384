import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import { getCurrentUser, getRoomType } from "../../api-config/config";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Iconify from "../../components/Iconify";
import EditIcon from "@mui/icons-material/Edit";
import AddRoomForm from "../../components/Rooms/AddRoomForm";

const label = { inputProps: { "aria-label": "Checkbox" } };

function RoomListGrid(data: any) {
  const [open, setOpen] = React.useState(false);
  const [roomData, setRoomData] = React.useState({} as any);
  function onEditClick(rowData: any) {
    setRoomData(rowData);
    console.log(rowData, "comming from row data");

    setOpen(true);
  }
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "room",
      headerName: "Room",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: true,
    },
    {
      field: "roomtype",
      headerName: "Room Type",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "floor",
      headerName: "Floor",
      width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "block",
      headerName: "Block",
      width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 100,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={(event) => {
              onEditClick(cellValues.row);
            }}
          >
            <EditIcon />
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRoomData({});
  };
  const [base, setBase] = React.useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = React.useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = React.useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const { row, roomAdded } = data;
  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        room: item.room_name,
        roomtype: item.room_type_name,
        floor_id: item.floor_id,
        floor: item.floor_name,
        block: item.block_name,
        block_id: item.block_id,
        room_type_id: item.room_type_id,
        description: item.description,
        hotel_code: item.hotel_code,
      });
    });
  }

  function onRoomAdded() {
    roomAdded();
  }

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add Room
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Room Type(S)
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Save Short Order
          </Button>

          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Room Type List</h3>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
        getRowHeight={() => 60}
        rowsPerPageOptions={[]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add Room</DialogTitle>
        <DialogContent sx={{ minWidth: "580px" }}>
          <h4 style={{ marginTop: -2 }}>
            Please Enter the Room Details below.
          </h4>
          <AddRoomForm
            handleClose={handleClose}
            onRoomAdded={onRoomAdded}
            roomData={roomData}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default function RoomList() {
  const [roomList, setRoomList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const getRoomList = (hotel_code: number, floor_id?: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          const rooms = [] as any;
          res.data?.forEach((roomType: any) => {
            roomType.rooms?.forEach((room: any) => {
              room.room_type_name = roomType.room_type_name;
            });
            rooms.push(...roomType.rooms);
          });
          setRoomList([...rooms]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getRoomList(loggedUser?.hotel_code);
  }, []);
  function onRoomAdded() {
    getRoomList(loggedUser?.hotel_code);
  }
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Room List
          </Typography>
          <RoomListGrid row={roomList} roomAdded={onRoomAdded} />
        </Container>
      </Page>
    </>
  );
}
