import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import React, { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { AccountCircle } from "@mui/icons-material";
interface Guest {
  address: string;
  city: string;
  country: string;
  dob: string;
  email: string;
  first_name: string;
  gender: string;
  hotel_code: number;
  id: number;
  id_proof_number: number;
  photo_list: [];
  id_proof_type: string;
  last_name: string;
  mobile_number: number;
  nationality: string;
  state: string;
  zipcode: number;
}
interface FolioGuestProps {
  guest: Guest;
  setEditReservationDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedGuest: Dispatch<SetStateAction<object>>;
  setAddReservationDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const FolioGuest: React.FC<FolioGuestProps> = ({
  guest,
  setEditReservationDialogOpen,
  setSelectedGuest,
  setAddReservationDialogOpen,
}) => {
  const [isOpenIdProofDialog, setIsOpenIdProofDialog] = useState(false);

  const handleCloseDialog = () => {
    setIsOpenIdProofDialog(false);
  };
  return (
    <>
      <div className="relative ">
        <div className="grid grid-cols-1 gap-2 p-1">
          <div className="bg-gray-50 p-2 shadow-md">
            <div>
              <span className="font-semibold text-base xl:text-lg text-blue-600">
                {guest.first_name + " " + guest.last_name + "   "}
              </span>
              <span className="text-red-600 text-sm">({guest.id})</span>
            </div>
            <hr className="border-t border-black border-1 my-2" />

            <div className="flex flex-row ">
              <div className="font-bold basis-1/5 text-sm">Mobile</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">
                {guest.mobile_number}
              </div>
              <div className="font-bold basis-1/5 text-sm">Email</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.email}</div>
            </div>

            <div className="flex flex-row ">
              <div className="font-bold basis-1/5 text-sm">Gender</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.gender}</div>
              <div className="font-bold basis-1/5 text-sm">Date of Birth</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.dob}</div>
            </div>

            <div className="flex flex-row ">
              <div className="font-bold basis-1/5 text-sm">ID</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">
                {guest.id_proof_type} ({guest.id_proof_number})
              </div>
            </div>

            <div className="font-bold text-sm pt-2">Guest Address</div>
            <hr className="border-t border-dotted border-black my-1" />

            <div className="flex flex-row ">
              <div className="font-bold basis-1/5 text-sm">Address</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.address}</div>
              <div className="font-bold basis-1/5 text-sm">City</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.city}</div>
            </div>

            <div className="flex flex-row ">
              <div className="font-bold basis-1/5 text-sm">State</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.state}</div>
              <div className="font-bold basis-1/5 text-sm">PIN</div>
              <div className="basis-1/10 text-sm">:</div>
              <div className="basis-1/3 ml-1 text-sm">{guest.zipcode}</div>
            </div>

            <div className="absolute bottom-2 right-2 flex justify-end">
              <Tooltip title="Edit Guest Details">
                <IconButton
                  className=""
                  aria-label="edit"
                  onClick={() => {
                    setSelectedGuest(guest);
                    setEditReservationDialogOpen(true);
                  }}
                >
                  <ModeEditRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="absolute top-2 right-2 flex justify-end">
              <Tooltip title="View Guest ID">
                <IconButton
                  className=""
                  aria-label="view-id"
                  onClick={() => {
                    if (guest?.photo_list?.length > 0) {
                      setIsOpenIdProofDialog(true);
                    } else {
                      alert("ID not uploaded");
                    }
                  }}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isOpenIdProofDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "590px", padding: "2rem" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p>Customer Name: {`${guest?.first_name} ${guest?.last_name}`}</p>
            <p>ID Proof Type: {guest?.id_proof_type}</p>
          </div>
          <div>
            {guest?.photo_list?.map((photo: any, i: any) => (
              <img
                src={photo}
                alt="ID proof"
                style={{ padding: "2rem" }}
                key={i}
              />
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FolioGuest;
