import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import Iconify from "../components/Iconify";
import AccountPopover from "./dashboard/AccountPopover";
import NotificationsPopover from "./dashboard/NotificationsPopover";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SchoolIcon from "@mui/icons-material/School";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, getLastNightAuditDate } from "../api-config/config";
import { useDetails } from "../contexts/DetailsContext";
import ReportIcon from "@mui/icons-material/Report";
import moment from "moment";

const DRAWER_WIDTH = 200;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

type DashboardNavbarProps = {
  onOpenSidebar: () => void;
};

export default function DashboardNavbar(props: DashboardNavbarProps) {
  const [frontdesk, setFrontdesk] = React.useState("");
  const [nightAuditDate, setNightAuditDate] = React.useState("");
  const [openNightPopup, setOpenNightPopup] = React.useState(false); //this is the main state for opening night audit
  const [dummyNightAuditState, setDummyNightAuditState] = React.useState(false);
  const loggedUser = getCurrentUser();
  const navigate = useNavigate();
  const { hotelDetails } = useDetails();
  const frontdeskChange = (event: SelectChangeEvent) => {
    setFrontdesk(event.target.value);
  };
  const [selecctedPos, setSelectedPos] = React.useState("");
  const [selecctedMaterial, setSelectedMaterial] = React.useState("");

  const onPOSTSelected = (selectedPos: string) => {
    setSelectedPos(selectedPos);
    window.open("https://billing.foodie.net.in/login", "_blank");
  };
  const onMaterialManagementSelected = (selectedValue: string) => {
    setSelectedMaterial(selectedValue);
    window.open("https://billing.foodie.net.in", "_blank");
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const midNightQuery = (date: Date) => {
    // Get the current time
    const currentTime = new Date();

    // Set the target time to midnight
    console.log(date);
    const targetTime = date;
    // targetTime.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    targetTime.setHours(23, 59, 59, 0); // Set hours, minutes, seconds, and milliseconds to zero
    console.log(currentTime);
    console.log(targetTime);

    // Compare the current time with the target time
    if (currentTime > targetTime) {
      // Enable the button
      console.log("working");
      // setButtonEnable(true);
      return true;
    } else {
      console.log("not-working");
      return false;
    }
  };
  console.log("comming fom get night audit");

  // React.useEffect(() => {
  // }, [openNightPopup])
  setTimeout(() => {
    console.log("entered");
    if (!openNightPopup) {
      getLastNightAuditDate(loggedUser?.hotel_code).then((res) => {
        setNightAuditDate(res.data.last_night_audit_last_date);
        if (
          !midNightQuery(
            new Date(
              new Date(res.data.last_night_audit_last_date).getTime() + 86400000
            )
          )
        ) {
          setOpenNightPopup(true);
        }
      });
    }
  }, 1440000);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={props.onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" sx={{ width: 20, height: 20 }} />
        </IconButton>
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="left"
          sx={{ display: "contents" }}
        >
          {/* <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel>Frontdesk</InputLabel>
              <Select
                value={frontdesk}
                label="Frontdesk"
                onChange={frontdeskChange}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem>
                  {" "}
                  <Link href="/main/guestlookup" underline="none">
                    {"Guest Look-up"}
                  </Link>
                </MenuItem>

                <MenuItem>
                  Room Operations
                  <Select
                    sx={{
                      float: "right",
                      padding: "0px 0px 0px 4px",
                      marginLeft: 4,
                    }}
                  >
                    <MenuItem>Check in List </MenuItem>
                    <MenuItem>Check Out List</MenuItem>
                    <MenuItem>Reservation List</MenuItem>
                    <MenuItem>Temp Room List</MenuItem>
                    <MenuItem>No Show List</MenuItem>
                    <MenuItem>Cancelled Reservation List</MenuItem>
                    <MenuItem>Check Out Pending List</MenuItem>
                    <MenuItem>Pending Folio(s)</MenuItem>
                    <MenuItem>Booking Deposits Tracker</MenuItem>
                    <MenuItem>Guest In-House List</MenuItem>
                  </Select>
                </MenuItem>

                <MenuItem>
                  Fund Operations
                  <Select
                    sx={{
                      float: "right",
                      padding: "0px 0px 0px 4px",
                      marginLeft: 5,
                    }}
                  >
                    <MenuItem>Check in List </MenuItem>
                    <MenuItem>Check Out List</MenuItem>
                    <MenuItem>Reservation List</MenuItem>
                    <MenuItem>Temp Room List</MenuItem>
                    <MenuItem>No Show List</MenuItem>
                    <MenuItem>Cancelled Reservation List</MenuItem>
                    <MenuItem>Check Out Pending List</MenuItem>
                    <MenuItem>Pending Folio(s)</MenuItem>
                    <MenuItem>Booking Deposits Tracker</MenuItem>
                    <MenuItem>Guest In-House List</MenuItem>
                  </Select>
                </MenuItem>

                <MenuItem>Perform Night Audit</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item>
            <Stack direction="row" spacing={2} sx={{ m: 1 }}>
              <Button
                type="button"
                onClick={() => navigate("/main/home")}
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                style={{height:'40px'}}
              >
                FrontDesk
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Button
                type="button"
                onClick={() => navigate("/main/houseKeeping")}
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                style={{height:'40px'}}
              >
                Housekeeping
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <InputLabel sx={{ color: "white" }}>POS</InputLabel>
              <Select
                sx={{ backgroundColor: "#0284c7" }}
                value={selecctedPos}
                label="POS"
                onChange={(e: any) => onPOSTSelected(e.target.value)}
              >
                <MenuItem value={""}></MenuItem>
                <MenuItem value={"Restaurant"}>Restaurant</MenuItem>
                <MenuItem value={"Room Service"}>Room Service</MenuItem>
                <MenuItem value={"BAR"}>BAR</MenuItem>
                <MenuItem value={"Banquets"}>Banquets</MenuItem>
                <MenuItem value={"Laundry"}>Laundry</MenuItem>
                <MenuItem value={"Travel Desk"}>Travel Desk</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50 mr-2"
                style={{height:'40px'}}
                onClick={() => navigate("/reports")}
              >
                Reports
              </Button>
            </Stack>
          </Grid>
          {/* <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                onClick={() => navigate("/main/cashcounter")}
                className="bg-sky-600 hover:bg-sky-400 text-stone-50 mr-2"
              >
                Cashier
              </Button>
            </Stack>
          </Grid> */}
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel sx={{ color: "white" }}>Cashier</InputLabel>
              <Select
                sx={{ backgroundColor: "#0284c7" }}
                value={selecctedPos}
                label="POS"
                onChange={(e: any) => {
                  console.log(e.target.value.split("_")[1]);
                  navigate(e.target.value.split("_")[0], {
                    state: {
                      action: e.target.value.split("_")[1],
                    },
                  });
                }}
              >
                {/* <MenuItem value={""}></MenuItem> */}
                <MenuItem value={"/main/cashcounter"}>Cashier</MenuItem>
                <MenuItem value={"/main/fundsoperations_add"}>
                  Funds Add
                </MenuItem>
                <MenuItem value={"/main/fundsoperations_withdraw"}>
                  Funds Withdrawal
                </MenuItem>
                <MenuItem value={"/main/accountstatement"}>
                  Cashier Report
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                onClick={() => navigate("/main/nightaudit")}
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                style={{height:'40px'}}
              >
                NightAudit/DayEnd
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel sx={{ color: "white" }}>
                Material Management &nbsp;
              </InputLabel>
              <Select
                sx={{ backgroundColor: "#0284c7" }}
                value={selecctedMaterial}
                label="Material Management"
                onChange={(e: any) =>
                  onMaterialManagementSelected(e.target.value)
                }
              >
                {/* <MenuItem value=""></MenuItem> */}
                <MenuItem value={"Purchasing"}>Purchasing</MenuItem>
                <MenuItem value={"Store Management"}>Store Management</MenuItem>
                <MenuItem value={"Inventory Management"}>
                  Inventory Management
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="end"
          sx={{ flexWrap: "nowrap" }}
          gap={1}
        >
          <Grid item sx={{ ml: 4 }}>
            <Box>
              <Link
                href="#"
                onClick={() => navigate("/admin/hoteldetails")}
                underline="none"
              >
                <span className="whitespace-nowrap">
                  <b>{hotelDetails?.hotel_name}</b>
                </span>
                <p>
                  <b>({hotelDetails.hotel_code})</b>
                </p>
              </Link>
            </Box>
          </Grid>

          {/* <Grid item sx={{ ml: 1 }}>
        <NotificationsPopover />
      </Grid> */}
          <Grid item sx={{ ml: 1 }} className="flex-shrink-0 flex-grow-0">
            <AccountPopover />
          </Grid>
        </Grid>
      </ToolbarStyle>
      <Dialog
        open={dummyNightAuditState}
        onClose={() => setOpenNightPopup(false)}
        sx={{
          width: "90vw",
          margin: "auto",
        }}
      >
        {/* <DialogTitle>Guest Info</DialogTitle> */}
        {/* <DialogContent sx={{ width: "100%", textAlign: "center" }}> */}
        <div style={{ width: "800px", margin: "auto" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // border: "1px solid blue",
            }}
          >
            <div
              style={{
                // border: "1px solid green",
                width: "-webkit-fill-available",
                padding: "10px",
                fontWeight: "600",
                background: "#3A3B3C",
                color: "white",
              }}
            >
              <p>
                Perform Night Audit
                <ReportIcon
                  sx={{
                    color: "#E1AD01",
                    bgcolor: "#3A3B3C",
                    marginLeft: "5px",
                  }}
                />
              </p>
            </div>
            <div
              style={{
                padding: "6px",
                width: "-webkit-fill-available",
                margin: "14px",
              }}
            >
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                  borderRadius: "6px",
                  padding: "10px 0 15px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      margin: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    <p>
                      The Night Audit is done till{" "}
                      <span style={{ color: "red" }}>{nightAuditDate}</span>.
                      Current Date is{" "}
                      <span className="text-green-500">
                        {moment().format("YYYY-MM-DD")}
                      </span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "-webkit-fill-available",
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <button
                        className="bg-white px-6 py-2 text-blue-500 font-semibold rounded-md border border-gray-300 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white hover:border-none"
                        onClick={() => {
                          setOpenNightPopup(false);
                          navigate("/main/nightaudit");
                        }}
                      >
                        PERFORM NIGHT AUDIT
                      </button>
                      <p
                        style={{
                          marginTop: "10px",
                          fontFamily: "cursive",
                          fontWeight: "600",
                        }}
                      >
                        (Recommended)
                      </p>
                    </div>
                    <div>
                      <button
                        className="bg-white px-6 py-2 text-blue-500 font-semibold rounded-md border border-gray-300 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white hover:border-none"
                        onClick={() => {
                          setOpenNightPopup(false);
                          navigate("/main/nightaudit");
                        }}
                      >
                        PERFORM AUTO NIGHT AUDIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                // border: "1px solid green",
                width: "-webkit-fill-available",
                padding: "20px",
                fontWeight: "600",
                background: "#3A3B3C",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // margin: "auto",
              }}
            >
              <div
              // style={{
              //   width: "50%",
              //   fontSize: "12px",
              //   margin: "auto",
              //   textAlign: "center",
              // }}
              >
                <button
                  className="bg-white px-3 py-1 text-black font-semibold rounded-md border border-gray-300 transition duration-300 ease-in-out hover:bg-red-500 hover:text-white hover:border-none"
                  onClick={() => setOpenNightPopup(false)}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* You haven't performed Night-Audit for{" "}
          {new Date().toISOString().slice(0, 10)}
          <Button
            variant="contained"
            // color="info"
            className="bg-[#01579b]"
            onClick={() => {
              setOpenNightPopup(false);
              navigate("/main/nightaudit");
            }}
          >
            Perform Night Audit:
          </Button> */}
        {/* <FolioOverviewOtherCharges
          allowance={allowance}
          fetch={fetch}
          folioDetails={folioDetails}
          setOpenOtherCharges={setOpenOtherCharges}
        /> */}
        {/* </DialogContent> */}
      </Dialog>
    </RootStyle>
  );
}
