import { Box, Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import GuestInfo from "../GuestInfo";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUser,
  getFolioInfoData,
  insertFolioInfoData,
} from "../../api-config/config";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getFolioDetails } from "../../api-config/folio.api";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import FolioGuest from "../FolioGuest/FolioGuest";
import AddGuestInfo from "../GuestInfo/AddGuestInfo";
import { logoutUser } from "../../api-config/user.api";
import Corporate from "../CorporateInfo/AddCorporate";
import CorporateInfo from "../CorporateInfo/CorporateInfo";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "date",
  //   headerName: "Date",
  //   width: 150,
  //   editable: true,
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "phone_number",
    headerName: "Phone Number",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

function FeaturesListGrid(data: any) {
  const { row, featureAdded } = data;
  const rows: any[] = [];
  // console.log("==> from inside the grid",data.row.payment_details);
  if (row.other_members !== undefined) {
    row.other_members.forEach((item: any, index: any) => {
      rows.push({
        id: 1,
        name: `${item?.first_name} ${item?.last_name}`,
        // date: item?.date,
        phone_number: item?.phone_number,
        email: item?.email,
      });
      // });
    });
  }

  return (
    <Box
      sx={{
        height: 200,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

const FolioDetailsLeft = (props: any) => {
  const {
    folioDetails,
    setFolioDetails,
    setOpenSnack,
    setSnackbarMessage,
    fetch,
  } = props;
  const navigate = useNavigate();
  const loggedUser = getCurrentUser();
  const [isOpenEditReservationDialog, setEditReservationDialogOpen] =
    useState(false);
  const [isOpenAddReservationDialog, setAddReservationDialogOpen] =
    useState(false);
  const [isOpenAddCorporateDialog, setAddCorporateDialogOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState({});
  const [infoDetails, setInfoDetails] = useState({
    message: "",
    notes: "",
    preferences: "",
    tasks: "",
    guest_preferences: "",
  });
  const [openPreferences, setOpenPreferences] = useState(false);
  const [openTasks, setOpenTasks] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openGuestPreference, setOpenGuestPreference] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    mode: "",
    // type: "",
    amount: null,
    checque_no: null,
    receipt_no: null,
    description: "",
    online: 0,
    upi: 0,
    card: 0,
    cash: 0,
  } as any);

  const handleSubmit = (formData: any) => {
    console.log(formData, folioDetails);
    const payload: any = {
      booking_id: folioDetails?.booking_id,
      customer_id: folioDetails?.customer_id,
      hotel_code: loggedUser?.hotel_code,

      ...formData,
    };
    insertFolioInfoData(payload).then((response: any) => {
      console.log(response);
      if (response.status_message === "success") {
        setOpenPreferences(false);
        setOpenTasks(false);
        setOpenNotes(false);
        setOpenGuestPreference(false);
      }
      // )onFeatureAdded();
      // handleClose();
    });
  };

  const getInfoDetails = async () => {
    const response = await getFolioInfoData({
      booking_id: folioDetails?.booking_id,
      customer_id: folioDetails?.customer_id,
      hotel_code: loggedUser?.hotel_code,
    });
    console.log(response);
    setInfoDetails((prev: any) => ({
      ...prev,
      message: response.data.message,
      notes: response.data.notes,
      preferences: response.data.preferences,
      tasks: response.data.tasks,
      guest_preferences: response.data.guest_preferences,
    }));
  };

  function handleCloseDialog() {
    // setReservationDialogOpen(false);
    setEditReservationDialogOpen(false);
    setAddReservationDialogOpen(false);
    setAddCorporateDialogOpen(false);
  }
  function onGuestUpdated() {
    handleCloseDialog();
    fetch();
  }

  useEffect(() => {
    if (folioDetails?.booking_id) {
      getInfoDetails();
    }
  }, [folioDetails]);
  return (
    <div className=" text-sm flex-wrap pr-2">
      {folioDetails?.guest_detail_new?.map((guest: any, i: any) => (
        <FolioGuest
          guest={guest}
          setEditReservationDialogOpen={setEditReservationDialogOpen}
          setSelectedGuest={setSelectedGuest}
          key={i + 1}
          setAddReservationDialogOpen={setAddReservationDialogOpen}
        />
      ))}
      {/* <div className="flex justify-between text-sm flex-wrap border-r-4 pr-2 my-4">
        <div className="grid gap-2 items-center">
          {folioDetails?.corporate?.corporate_name ? (
            <FormGroup>
              <Button
                type="submit"
                name="editCorporate"
                className="bg-sky-300 hover:bg-green-300"
                onClick={() => setAddCorporateDialogOpen(true)}
              >
                Edit Corporate
              </Button>
            </FormGroup>
          ) : (
            <FormGroup>
              <Button
                type="submit"
                name="addCorporate"
                className="bg-sky-300 hover:bg-green-300"
                onClick={() => setAddCorporateDialogOpen(true)}
              >
                Add Corporate
              </Button>
            </FormGroup>
          )}
        </div>
        <div className="grid gap-2 items-center">
          <FormGroup>
            <Button
              type="submit"
              name="addGuest"
              className="bg-sky-300 hover:bg-green-300"
              onClick={() => setAddReservationDialogOpen(true)}
            >
              Add Guest
            </Button>
          </FormGroup>
        </div>
      </div> */}

      <hr className="w-[100%] border-dotted border-2 my-4" />
      <CorporateInfo
        folioDetails={folioDetails}
        fetch={fetch}
        setAddCorporateDialogOpen={setAddCorporateDialogOpen}
      />

      {/* <div className="w-[100%] flex">
        <FeaturesListGrid row={folioDetailsObj} />
      </div> */}

      {/* <div className="w-[100%] mt-8 flex flex-wrap justify-between">
        <p className="text-[blue] cursor-pointer py-2">
          Send Confirmation Email
        </p>
        <div className="flex w-[100%] py-2 flex-wrap items-center">
          <span className="mr-2">
            <b>Preference: </b>{" "}
          </span>
          <span
            className="  text-right text-[blue] cursor-pointer"
            onClick={() => setOpenPreferences(true)}
          >
            {" "}
            Edit
          </span>
          <p className="border-2 border-black p-2 ml-2">
            {infoDetails?.preferences}
          </p>
        </div>
        <div className="flex w-[100%] py-2 flex-wrap items-center">
          <span className="mr-2">
            <b>Tasks: </b>{" "}
          </span>
          <span
            className="  text-right text-[blue] cursor-pointer"
            onClick={() => setOpenTasks(true)}
          >
            {" "}
            Add / Edit
          </span>
          <p className="border-2 border-black p-2 ml-2">{infoDetails?.tasks}</p>
        </div>
        <div className="flex w-[100%] py-2 flex-wrap items-center">
          <span className="mr-2">
            <b>Notes: </b>{" "}
          </span>
          <span
            className="  text-right text-[blue] cursor-pointer"
            onClick={() => setOpenNotes(true)}
          >
            {" "}
            Add
          </span>
          <p className="border-2 border-black p-2 ml-2">{infoDetails?.notes}</p>
        </div>
        <div className="flex w-[100%] py-2 flex-wrap items-center">
          <span className="mr-2">
            <b>Guest Preferences: </b>{" "}
          </span>
          <span
            className="  text-right text-[blue] cursor-pointer"
            onClick={() => setOpenGuestPreference(true)}
          >
            {" "}
            View
          </span>
          <p className="border-2 border-black p-2 ml-2">
            {infoDetails?.guest_preferences}
          </p>
        </div>
        <br />
      </div> */}
      <Dialog
        open={isOpenEditReservationDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "590px" }}>
          <GuestInfo
            bookingInfo={folioDetails}
            selectedGuest={selectedGuest}
            handleCloseDialog={handleCloseDialog}
            onGuestUpdated={onGuestUpdated}
            setSelectedGuest={setSelectedGuest}
            folioDetails={folioDetails}
            fetch={fetch}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenAddReservationDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "590px" }}>
          <AddGuestInfo
            bookingInfo={folioDetails}
            handleCloseDialog={handleCloseDialog}
            onGuestUpdated={onGuestUpdated}
            selectedGuest={selectedGuest}
            setSelectedGuest={setSelectedGuest}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenAddCorporateDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "590px" }}>
          <Corporate
            bookingInfo={folioDetails}
            handleCloseDialog={handleCloseDialog}
            setOpenSnack={setOpenSnack}
            setSnackbarMessage={setSnackbarMessage}
            fetch={fetch}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMessage}
        onClose={() => setOpenMessage(false)}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "590px", textAlign: "center" }}>
          Enter Message:
          <OutlinedInput
            className="ml-5 w-[80%]"
            type="text"
            value={infoDetails.message}
            onChange={(e) =>
              setInfoDetails((prev: any) => ({
                ...prev,
                message: e.target.value,
              }))
            }
          />
          <Button onClick={() => handleSubmit(infoDetails)}>Save</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FolioDetailsLeft;
