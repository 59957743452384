import axios from "axios";
import { baseUrl } from "./config";
import api from "./axiosInstance";

export const cancelBillApi = async (
  booking_id: number,
  invoice_number: string,
  reason: string,
  func: any
) => {
  return api
    .post(baseUrl + "/cancel_bill", {
      booking_id,
      invoice_number,
      reason,
    })
    .then((response: any) => {
      func(response.data);
      return response.data;
    });
};

export const settleBillApi = async (
  booking_id: number,
  date: string,
  payment_mode: string,
  description: string,
  corporate_id: string,
  travel_agent_id: string,
  func?: any
) => {
  return api
    .post(baseUrl + "/settle_bill", {
      booking_id,
      payment_mode,
      date,
      description,
      corporate_id,
      travel_agent_id,
    })
    .then((response: any) => {
      if (func) {
        func(response.data);
      }
      return response.data;
    });
};

export const getSegmentBills = async (
  hotel_code: number,
  start_date: string,
  end_date: string,
  bill_report_for: string
) => {
  return api
    .post(baseUrl + "/segment_bill_report", {
      hotel_code,
      start_date,
      end_date,
      bill_report_for,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getSettleBills = async (
  hotel_code: number,
  start_date: string,
  end_date: string
) => {
  return api
    .post(baseUrl + "/settlement_bill_report", {
      hotel_code,
      start_date,
      end_date,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getNoShowBills = async (
  start_date: string,
  end_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/get_no_show_orders", {
      hotel_code,
      start_date,
      end_date,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getGuestInHouse = async (
  start_date: string,
  end_date: string,
  hotel_code: number
) => {
  return api
    .post(baseUrl + "/guest_in_house_report", {
      hotel_code,
      start_date,
      end_date,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const splitBill = async (
  booking_id: number,
  customer_id: number,
  payment_info: any[]
) => {
  return api
    .post(baseUrl + "/split_bill", {
      booking_id,
      customer_id,
      payment_info,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const refundBill = async (
  // booking_id: number,
  cash: any,
  upi: number,
  online: number,
  total_amount: any,
  available_balance: number,
  reason: string,
  hotel_code: number,
  booking_id: number,
  charge_to: string,
  corporate_id: number,
  group_booking_id: number,
  guest_id: number,
  id: number,
  room_id: number,
  travel_agent_id: number,
  type: string
) => {
  return api
    .post(baseUrl + "/refund_amount", {
      booking_id,
      cash,
      upi,
      online,
      total_amount,
      available_balance,
      reason,
      hotel_code,
      charge_to,
      corporate_id,
      group_booking_id,
      guest_id,
      id,
      room_id,
      travel_agent_id,
      type,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const getUnsettledBills = async (hotel_code: number) => {
  return api
    .post(baseUrl + "/un_settled_bill_report", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};
