import React, { useEffect, useState } from "react";
import { getAccountStatement } from "../../../api-config/payment.api";
import { getCurrentUser } from "../../../api-config/config";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";

import html2canvas from "html2canvas";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import { CSVLink } from "react-csv";
import LoaderDotted from "../../../components/LoaderDotted/LoaderDotted";

const AccountStatement = () => {
  // Sample data for the account statement
  const [statementData1, setStatementData1] = useState([]);

  const [selectedDate, setSelectedDate] = useState(
    moment().utcOffset("+05:30").toDate()
  );
  const [printType, setPrintType] = useState("Pdf");
  const [loader, setLoader] = useState(false);

  // Pagination
  const itemsPerPage = 30; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = statementData1.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(statementData1.length / itemsPerPage);
  const handleChangePage = (newPage: any) => {
    setCurrentPage(newPage);
  };

  // Function to handle date selection
  const handleDateChange = (date: any) => {
    // Allow only current and past dates
    const currentDate = new Date();
    if (date <= currentDate) {
      setSelectedDate(date);
    }
  };
  const loggedUser = getCurrentUser();

  const extractStatus = (text: any) => {
    if (text === null) {
      return "";
    }
    const pattern = /status\s*([\w\s]+)/i;

    const match = text.match(pattern);

    if (match) {
      const status = match[1].trim();
      // console.log("Booking status:", status);
      return status;
    } else {
      // console.log("Booking status not found.");
      return "";
    }
  };
  const sortTransactionsDescending = (transactions: any) => {
    return transactions.slice().sort((a: any, b: any) => {
      // Compare dates first
      const dateComparison =
        (new Date(b.date + "T" + b.time) as any) -
        (new Date(a.date + "T" + a.time) as any);

      // If dates are equal, compare times
      return dateComparison === 0
        ? b.time.localeCompare(a.time)
        : dateComparison;
    });
  };
  const formatStatementData = (data: any) => {
    return data?.map((ele: any) => {
      const timeDate = ele.date_and_time.split(" ");
      const status = extractStatus(ele?.description);
      return {
        time: timeDate[1],
        date: timeDate[0],
        depositedWithdrawlBy: ele.depositedBy,
        mode: ele.mode,
        withdrawl: ele.withdrawl,
        deposit: ele.deposit,
        current_balance: ele.current_balance,
        remarks: ele.remarks,
        description: ele.description,
        status: status,
      };
    });
  };

  useEffect(() => {
    setLoader(true);
    getAccountStatement({
      hotel_code: loggedUser?.hotel_code,
      from_date: moment().format("YYYY-MM-DD"),
    }).then((res: any) => {
      setStatementData1(
        sortTransactionsDescending(formatStatementData(res.data))
      );
      setLoader(false);
    });
  }, []);

  const handleSearch = () => {
    // const date = new Date(selectedDate).toLocaleDateString().split("/");
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const formatdate = `${date[2]}-${date[0]}-${date[1]}`;
    // console.log(formatdate, "formatred date-------------------");

    setLoader(true);
    getAccountStatement({
      hotel_code: loggedUser?.hotel_code,
      from_date: date,
    }).then((res: any) => {
      setStatementData1(
        sortTransactionsDescending(formatStatementData(res.data))
      );
      setLoader(false);
    });
  };
  const onPrintTypeSelected = (value: any) => {
    setPrintType(value);
  };
  const generatePDF = () => {
    const pdf = new jsPDF();
    const table: any = document.getElementById("myTable");

    pdf.setFontSize(10);

    // First line
    pdf.text(
      `Account Statement for ${new Date(selectedDate).toLocaleDateString()}`,
      10,
      10
    );

    // Second line (increase y-coordinate to create a new line)
    pdf.text(loggedUser.hotel_name, 10, 15);

    // Third line (increase y-coordinate again)
    pdf.text(loggedUser.address, 10, 20);

    html2canvas(table).then((canvas: any) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 30, 190, 0);
      pdf.save("statement.pdf");
    });
  };
  if (loader) {
    return <LoaderDotted />;
  } else {
    return (
      <div className="max-w-8xl p-3 mx-auto mt-8">
        <h2 className="text-2xl font-bold mb-4 text-blue-800">Account Statement</h2>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
            margin: "10px 0 20px 0",
            padding: "18px 12px",
          }}
        >
          <div
            style={{
              // border: "1px solid black",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DatePicker
              showIcon
              withPortal
              selected={selectedDate}
              onChange={handleDateChange}
              maxDate={moment().utcOffset("+05:30").toDate()} // Restrict future dates
              className="my-calendar"
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          <div style={{}}>
            {/* <InputLabel sx={{ color: "black" }}>Select Download Type</InputLabel> */}
            <Select
              sx={{ backgroundColor: "#fff", color: "black" }}
              value={printType}
              // label={printType}
              onChange={(e: any) => onPrintTypeSelected(e.target.value)}
            >
              {/* <MenuItem value={"Select"}>Select</MenuItem> */}
              <MenuItem value={"Pdf"}>PDF</MenuItem>
              <MenuItem value={"Excel"}>EXCEL</MenuItem>
            </Select>
            {printType === "Pdf" ? (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
                onClick={generatePDF}
              >
                Download
              </button>
            ) : (
              <CSVLink data={statementData1} filename={"Account-statement.csv"}>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
                  // onClick={generatePDF}
                >
                  Download
                </button>
              </CSVLink>
            )}
            {/* <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
              onClick={generatePDF}
            >
              PDF
            </button> */}
          </div>
        </div>

        <div className="overflow-x-auto max-h-98 overflow-y-auto  shadow-md rounded">
          <table
            id="myTable"
            className="min-w-full bg-white border border-gray-300 "
          >
            <thead className="sticky top-0 bg-gray-100 z-10 ">
              <tr className="bg-blue-400 text-white">
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "6rem" }}
                >
                  Time
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "8rem" }}
                >
                  Date
                </th>

                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "14rem" }}
                >
                  Deposit/Withdrawl By
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "4rem" }}
                >
                  Mode
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "8rem" }}
                >
                  Withdrawal
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "8rem" }}
                >
                  Deposit/Payment
                </th>
                {/* <th className="py-2 px-4 text-left" style={{ minWidth: "8rem" }}>
                  Opening Balance
                </th> */}
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "10rem" }}
                >
                  Current Balance
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "8rem" }}
                >
                  Discrepancy
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "20rem" }}
                >
                  Description
                </th>
                <th
                  className="py-4 px-4 text-left"
                  style={{ minWidth: "10rem" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Map through the data and render rows */}
              {statementData1?.length === 0 && (
                <tr>
                  <td colSpan={10} className="py-4 px-4 text-center">
                    No transaction performed.
                  </td>
                </tr>
              )}
              {currentItems?.map((row: any, index: any) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : ""
                  } hover:bg-blue-100 hover:text-black transition-all duration-300 cursor-pointer`}
                >
                  <td className="py-2 px-4">{row.time}</td>
                  <td className="py-2 px-4">{row.date}</td>
                  <td className="py-2 px-4">{row.depositedWithdrawlBy}</td>

                  <td className="py-2 px-4">{row.mode}</td>
                  <td
                    className={`py-2 px-4 ${
                      row.withdrawl >= 0 ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {row.withdrawl}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      row.deposit >= 0 ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {row.deposit}
                  </td>
                  {/* <td className="py-2 px-4">{row.openingBalance}</td> */}
                  <td className="py-2 px-4">{row.current_balance}</td>

                  <td className="py-2 px-4">{row.remarks}</td>
                  <td className="py-2 px-4" style={{ fontSize: "12px" }}>
                    {row.description}
                  </td>
                  <td
                    className={`py-2 px-4 ${
                      row.status === "Check in"
                        ? "text-green-500"
                        : "text-orange-400"
                    }`}
                  >
                    {row.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between items-center px-2 py-2 ">
            {/* Display total number of transactions on the left */}
            <span className="text-md font-bold">
              Total Transactions: {statementData1.length}
            </span>

            {/* Display pagination on the right */}
            <div className="flex space-x-2 items-center">
              <button
                className={`w-8 h-8 flex items-center justify-center bg-white border border-gray-300 rounded-full transition-colors duration-300 hover:bg-gray-200 ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => handleChangePage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <KeyboardArrowLeftIcon />
              </button>
              <span className="text-md font-bold">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`w-8 h-8 flex items-center justify-center bg-white border border-gray-300 rounded-full transition-colors duration-300 hover:bg-gray-200 ${
                  currentPage === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={() => handleChangePage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <KeyboardArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AccountStatement;
