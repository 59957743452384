import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface Row {
  id: number;
  date: string;
  roomNights: string;
  revenue: number;
  occupancy: string;
}

interface Room {
  available_rooms: number;
  occupancy_percentage: number;
  total_rooms_revenue: number;
  total_rooms: number;
  total_rooms_booked: number;
}

interface IndObjStructure {
  data: Room[];
  date: string;
}

interface OccupancyChartProps {
  bobData: IndObjStructure[];
}

const DataGridDemo: React.FC<OccupancyChartProps> = ({ bobData }) => {
  const rows: Row[] = bobData.map((ele, index) => {
    const totalRoomNights = ele.data.reduce(
      (acc, room) => acc + room.total_rooms_booked,
      0
    );
    const totalRevenue = ele.data.reduce(
      (acc, room) => acc + room.total_rooms_revenue,
      0
    );
    // const averageOccupancy =
    //   totalRoomNights !== 0
    //     ? ((totalRoomNights - ele.data[0].available_rooms) / totalRoomNights) *
    //       100
    //     : 0;
    const averageOccupancy = ele?.data[0]?.occupancy_percentage;

    return {
      id: index + 1,
      date: ele.date,
      roomNights: totalRoomNights.toString(),
      revenue: totalRevenue,
      occupancy: `${averageOccupancy.toFixed(2)}%`,
    };
  });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "date", headerName: "Date", width: 150, editable: true },
    {
      field: "roomNights",
      headerName: "Room Nights",
      width: 150,
      editable: true,
    },
    { field: "revenue", headerName: "Revenue", width: 110, editable: true },
    { field: "occupancy", headerName: "Occupancy", width: 110, editable: true },
  ];

  return (
    <div style={{ border: "1px solid #ccc" }}>
      <h2 className="occupancy-heading">BoB </h2>
      <Box sx={{ height: 350, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          // disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default DataGridDemo;
