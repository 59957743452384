import { set, sub } from "date-fns";
import { noCase } from "change-case";
import { faker } from "@faker-js/faker";
import { useState, useRef } from "react";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { fToNow } from "../../utils/formatTime";
import Iconify from "../../components/Iconify";
import { Scrollbar } from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";

type Notification = {
  title: string;
  description: string;
  type: string;
  avatar: string;
  createdAt: { date: Date };
  id: string;
  isUnRead: boolean;
};
const NOTIFICATIONS: Notification[] = [
  {
    id: faker.datatype.uuid(),
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: "/static/images/avatars/avatar_2.jpg",
    type: "order_placed",
    createdAt: { date: set(new Date(), { hours: 10, minutes: 30 }) },
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new message",
    description: "5 unread messages",
    avatar: "/static/images/avatars/avatar_2.jpg",
    type: "chat_message",
    createdAt: { date: sub(new Date(), { days: 1, hours: 3, minutes: 30 }) },
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: "/static/images/avatars/avatar_2.jpg",
    type: "mail",
    createdAt: { date: sub(new Date(), { days: 2, hours: 3, minutes: 30 }) },
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "Delivery processing",
    description: "Your order is being shipped",
    avatar: "/static/images/avatars/avatar_2.jpg",
    type: "order_shipped",
    createdAt: { date: sub(new Date(), { days: 3, hours: 3, minutes: 30 }) },
    isUnRead: false,
  },
];

export default function NotificationsPopover() {
  const anchorRef = useRef(null);

  const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "default" : "primary"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" sx={{ width: 20, height: 20 }} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 8,
          ml: -4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify
                  icon="eva:done-all-fill"
                  sx={{ width: 20, height: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notify) => (
              <NotificationItem key={notify.id} notification={notify} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notify) => (
              <NotificationItem key={notify.id} notification={notify} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

type NotificationProps = {
  notification: {
    id: string;
    title: string;
    description: string;
    avatar: string;
    type: string;
    createdAt: { date: Date };
    isUnRead: boolean;
  };
};

function NotificationItem(props: NotificationProps) {
  const { avatar, title } = renderContent(props.notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(props.notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(props.notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function renderContent(props: {
  title: string;
  description: string;
  type: string;
  avatar: string;
  createdAt?: { date: Date };
  id?: string;
  isUnRead?: boolean;
}) {
  const title = (
    <Typography variant="subtitle2">
      {props.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(props.description)}
      </Typography>
    </Typography>
  );

  if (props.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={props.title}
          src="/static/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (props.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={props.title}
          src="/static/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (props.type === "mail") {
    return {
      avatar: (
        <img alt={props.title} src="/static/icons/ic_notification_mail.svg" />
      ),
      title,
    };
  }
  if (props.type === "chat_message") {
    return {
      avatar: (
        <img alt={props.title} src="/static/icons/ic_notification_chat.svg" />
      ),
      title,
    };
  }
  return {
    avatar: props.avatar ? <img alt={props.title} src={props.avatar} /> : null,
    title,
  };
}
