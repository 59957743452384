import { createRef, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // import FullCalendar component

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import ReservationFrom from "../../components/ReservationForm";
import {
  changeCheckingInfo,
  getBookingInfo,
  getCheckInOrders,
  getCurrentUser,
  getHotelDetails,
  getHouseKeepingList,
  getLastNightAuditDate,
  getPmsBlockRoom,
} from "../../api-config/config";
import moment from "moment";
import {
  isBefore,
  isBetween,
  isEqual,
  isEqualOrAfter,
} from "../../utils/formatTime";

import { useLocation, useNavigate } from "react-router-dom";
import DateClicking from "@bwobbones/fullcalendar5-rightclick";
import BasicMenu from "../ContextMenu";

import { useDetails } from "../../contexts/DetailsContext";
import dayjs from "dayjs";
import { calNoOfNights } from "../../utils/formatNumber";
import { Popover, Toolbar, Typography } from "@mui/material";

import GroupReservationForm from "../GroupReservation";
import EventHover from "./EventHover";
import EditRoomsBlock from "../RoomsBlock/EditRoomsBlock";
// import ReservationFrom from "../ReservationForm/mainWindow";
import EditReservationFrom from "../EditReservationNew/mainWindow";
import DemoEdit from "../EditResevation/index1";
// import ReservationFrom from "../ReservationForm";/=

// Import the styles for FullCalendar
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/bootstrap/main.css";

const myInteractionPlugin = new Proxy(interactionPlugin, {
  get(target, prop, receiver) {
    if (prop === "componentInteractions") {
      target.componentInteractions[0] = DateClicking;
    }
    return Reflect.get(target, prop, receiver);
  },
});

function Calendar(props: any) {
  const { roomTypeList } = props;
  const location = useLocation();
  console.log(location);
  const [bookedRooms, setBookedRooms] = useState([] as any);
  const [blockedRooms, setBlockedRooms] = useState([] as any);
  const groupBookingColorMap: Record<string, string> = {};
  const [bookingData, setBookingData] = useState([] as any);
  const [events, setEvent] = useState([] as any);
  const [calendarInfo, setCalendarInfo] = useState({} as any);
  const [isGroupReservationOpen, setIsGroupReservationOpen] = useState(false);
  const [isEditGroupReservationOpen, setEditIsGroupReservationOpen] =
    useState(false);
  const [isOpenReservationDialog, setReservationDialogOpen] = useState(false);
  const [isOpenEditReservationDialog, setEditReservationDialogOpen] =
    useState(false);
  const [dynamicResource, setDynamicResource] = useState([]);
  const [resourceMap, setResourceMap] = useState({} as any);
  const [selectedRoomInfo, setSelectedRoomInfo] = useState({} as any);
  const [isRefresh, setRefresh] = useState(false);
  const [selectedEventInfo, setSelectedEventInfo] = useState({} as any);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [targetElement, setTargetElement] = useState({} as any);
  const [lastNightAuditDate, setLastNightAuditDate] = useState("" as any);
  const [tempReserveStatus, setTempReserveStatus] = useState(false);
  const [someCondition, setSomeCondition] = useState(true);
  const [houseKeepingData, setHouseKeepingData] = useState([]);
  const [eventMouseHover, setEventMouseHover] = useState(false);
  const [eventHoverX, setEventHoverX] = useState(0);
  const [eventHoverY, setEventHoverY] = useState(0);
  const [groupIndBtn, setGroupIndBtn] = useState({
    status: true,
    btnType: "ind",
  });
  const [manageEditRoomsDialog, setManageEditRoomsDialog] = useState(false);
  const { calendarValue, formatAndSetCalendarValue, setHotelDetailsObj } =
    useDetails();
  const calendarComponentRef = createRef() as any;

  function onHandleClose() {
    setTargetElement(null);
    setIsOpenContextMenu(false);
  }
  function handleCloseDialogEditBlock() {
    setManageEditRoomsDialog(false);
  }
  const [houseKeepingList, setHouseKeepingList] = useState([]);
  const loggedUser = getCurrentUser();

  const fetchHouseKeepingList = async (hotel_code: number) => {
    const res = await getHouseKeepingList(hotel_code);
    if (res) {
      setHouseKeepingList(res.data);
    }
    return res.data;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({} as any);

  function cancleReservation(checkInOrder: any) {
    getBookingInfo(checkInOrder.booking_id).then((bookingInfo: any) => {
      // console.log("bookingInfo:", bookingInfo);
      bookingInfo.room_type_id = checkInOrder.room_booking_info?.room_type_id;
      bookingInfo.booking_status = "cancelled";
      changeCheckingInfo(bookingInfo).then((response: any) => {
        console.log(response);
      });
    });
  }
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth - 1),
      end: eDate.setMonth(currentMonth),
    };
  }
  function isRoomClear(houseKeepingData: any, checkinDetails: any) {
    const room_id = checkinDetails.room_booking_info?.room_id;
    let isRoomClean = false;
    houseKeepingData.forEach((houseKeepingObj: any) => {
      if (houseKeepingObj.room_id === room_id) {
        if (houseKeepingObj.status.toLowerCase() === "clean") {
          isRoomClean = true;
        }
      }
    });
    // console.log("houseAndCheckin", houseKeepingData, checkinDetails);
    return isRoomClean;
  }
  const dynamicEvents = [] as any;
  async function createEventsForCalendar(data: any) {
    setBookingData(data);

    const houseKeepingData = await fetchHouseKeepingList(
      loggedUser?.hotel_code
    );
    const eventsWithSameDate = {} as any;
    let lowestDate = "";
    let highestDate = "";

    data.forEach((checkInOrder: any, key: any) => {
      const eventObj = {} as any;
      eventObj.title = `${checkInOrder.booking_id}_${checkInOrder.guest_first_name}`;
      eventObj.start = checkInOrder.from_date;
      eventObj.end = checkInOrder.to_date;
      eventObj.group_booking = checkInOrder.group_booking;
      eventObj.group_booking_id = checkInOrder.group_booking_id;
      eventObj.eventType = "booking";
      console.log(checkInOrder, "check in 2order22222222222222222222222222");

      eventObj.guestBasicInfo = {
        booking_id: checkInOrder.booking_id,
        fName: checkInOrder.guest_first_name,
        lName: checkInOrder.last_name,
        email: checkInOrder.email,
        mobile: checkInOrder.mobile_number,
        no_of_adults: checkInOrder.no_of_adults,
        no_of_children: checkInOrder.no_of_children,
        room_type_id: checkInOrder.room_booking_info?.room_type_id,
        room_id: checkInOrder.room_booking_info?.room_id,
        paid_amount: checkInOrder.paid_amount,
        gross_amount: checkInOrder.gross_amount,
        discount_type: checkInOrder.discount_type,
        discount_value: checkInOrder.discount_value,
        total_sale_amount: checkInOrder.total_sale_amount,
        total_without_tax: checkInOrder.total_without_tax,
        total_service_amount: checkInOrder.total_services_amount,
        tax_amount: checkInOrder.tax_amount,
        booking_status: checkInOrder.booking_status,
        otherGuestInfo: checkInOrder.other_members,
        rate_plan_id: checkInOrder.room_booking_info.rate_plan_id,
        checkin_date_time: `${
          checkInOrder?.check_in_time
            ? moment(checkInOrder.check_in_time).format("DD-MM-YYYY HH:mm")
            : moment(checkInOrder.from_date).format("DD-MM-YYYY") + " 11:00"
        }`,
        checkout_date_time: `${
          checkInOrder?.check_out_time
            ? moment(checkInOrder.check_out_time).format("DD-MM-YYYY HH:mm")
            : moment(checkInOrder.to_date).format("DD-MM-YYYY") + " 11:00"
        }`,
        room_name: checkInOrder?.room_booking_info?.room_name,
        booking_from: checkInOrder?.booking_from,
      };
      if (isBefore(moment(checkInOrder.to_date), moment(lastNightAuditDate))) {
        if (checkInOrder.booking_status == "check_out") {
          eventObj.color = "#FF8B67";
        } else {
          eventObj.color = "red";
        }

        const roomId = checkInOrder.room_booking_info.room_id;
        const startDate = checkInOrder.from_date;
        const endDate = checkInOrder.to_date;
        if (lowestDate == "") lowestDate = startDate;
        if (highestDate == "") highestDate = endDate;
        if (eventsWithSameDate[roomId]) {
          if (
            startDate >= eventsWithSameDate[roomId].lowestDate &&
            startDate <= eventsWithSameDate[roomId].highestDate
          ) {
            return;
          }
          if (startDate < eventsWithSameDate[roomId].lowestDate)
            eventsWithSameDate[roomId].lowestDate = startDate;
          if (endDate > eventsWithSameDate[roomId].highestDate)
            eventsWithSameDate[roomId].highestDate = endDate;
        } else {
          eventsWithSameDate[roomId] = {
            lowestDate,
            highestDate,
          };
          lowestDate = "";
          highestDate = "";
        }
      } else if (checkInOrder.booking_status == "check_in") {
        if (checkInOrder?.complementary) {
          eventObj.color = "rgb(212, 116, 134)";
        } else if (checkInOrder?.to_date === moment().format("YYYY-MM-DD")) {
          eventObj.color = "#ffa630";
        } else {
          eventObj.color = "rgb(255, 30, 30, 0.9)";
        }
      } else if (checkInOrder.booking_status == "check_out") {
        eventObj.color = "#d47486";
        if (isRoomClear(houseKeepingData, checkInOrder)) {
          return;
        }
      } else if (
        checkInOrder.booking_status !== "cancelled" &&
        checkInOrder.group_booking
      ) {
        const groupBookingId = checkInOrder.group_booking_id;
        if (!groupBookingColorMap[groupBookingId]) {
          groupBookingColorMap[groupBookingId] = "#3788d8";
        }
        eventObj.color = groupBookingColorMap[groupBookingId];
      } else if (checkInOrder.booking_status == "pending") {
        if (checkInOrder?.complementary) {
          eventObj.color = "rgb(212, 116, 134)";
        } else {
          eventObj.color = "rgb(12, 151, 251, 0.8)";
        }
      } else if (checkInOrder.booking_status == "cancelled") {
        return;
      } else if (checkInOrder.booking_status == "no_show") {
        return;
      } else {
        eventObj.color = "red";
      }
      eventObj.resourceIds = [checkInOrder.room_booking_info?.room_id];
      if (checkInOrder.booking_status != "check_out") {
        dynamicEvents.push(eventObj);
      }
    });
    setEvent([...dynamicEvents]);
  }

  async function createBlockEventsForCalendar(data: any) {
    const houseKeepingData = await fetchHouseKeepingList(
      loggedUser?.hotel_code
    );

    data.forEach((blockedRooms: any, key: any) => {
      const eventObj = {} as any;
      eventObj.title = blockedRooms.description;
      eventObj.start = moment(blockedRooms.from_date).format("YYYY-MM-DD");
      eventObj.end = moment(blockedRooms.to_date).format("YYYY-MM-DD");
      eventObj.eventType = blockedRooms.block_type;
      eventObj.resourceIds = [blockedRooms?.room_id];
      if (blockedRooms.block_type === "out_of_order") {
        eventObj.color = "rgba(168, 119, 17, 1)";
      } else if (blockedRooms.block_type === "management_block") {
        eventObj.color = "rgba(149, 109, 149, 1)";
      }
      eventObj.blockData = blockedRooms;

      dynamicEvents.push(eventObj);
    });
    setEvent([...dynamicEvents]);
  }

  useEffect(() => {
    if (loggedUser) {
      // const startDate = moment().subtract(4, "month").format("YYYY-MM-DD");
      const startDate = moment()
        .startOf("month")
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      // Get the last day of the current month
      const endDate = moment().add(4, "month").format("YYYY-MM-DD");
      getCheckInOrders(startDate, endDate, loggedUser?.hotel_code).then(
        (response: any) => {
          setBookedRooms([]);
          response.data.forEach((room: any) => {
            if (isBetween(room?.from_date, room?.to_date, new Date())) {
              setBookedRooms((prev: any) => [
                ...prev,
                room?.room_booking_info?.room_id,
              ]);
            }
          });

          createEventsForCalendar(response.data);
        }
      );
      getPmsBlockRoom(startDate, endDate, loggedUser?.hotel_code).then(
        (response: any) => {
          setBlockedRooms([]);
          response.data.forEach((room: any) => {
            if (isBetween(room?.from_date, room?.to_date, new Date())) {
              setBlockedRooms((prev: any) => [
                ...prev,
                room?.room_booking_info?.room_id,
              ]);
            }
          });

          createBlockEventsForCalendar(response.data);
        }
      );
    }
  }, [isRefresh]);

  const navigate = useNavigate();

  function formatRoomTypeList(roomTypeList: any) {
    const tempResourceMap = {} as any;
    if (roomTypeList.length) {
      const resources = [] as any;
      roomTypeList.forEach((element: any, key: any) => {
        element.rooms.forEach((room: any) => {
          const childObj = {
            id: room.id,
            title: room?.room_name,
            venue: element?.room_type_name,
            color: "blue",
            room_no: room?.room_number,
          };
          tempResourceMap[`${childObj.id}`] = room;
          tempResourceMap[`${childObj.id}`].room_type_name =
            element.room_type_name;
          tempResourceMap[`${childObj.id}`].hotel_code = element.hotel_code;
          tempResourceMap[`${childObj.id}`].higher_occupancy =
            element.higher_occupancy;
          tempResourceMap[`${childObj.id}`].price = element.price;
          tempResourceMap[`${childObj.id}`].extra_bed_price =
            element.extra_bed_price;
          resources.push(childObj);
        });
        // console.log(resources,"my resources");

        setDynamicResource([...resources] as any);
        setResourceMap(tempResourceMap);
      });
      console.log("resources this is resources", resources);
    }
  }
  // const getRoomtypeId = (id:any) => {
  //   console.log(id,"getting rooid from edit form");

  // }
  useEffect(() => {
    formatRoomTypeList(roomTypeList);
  }, [roomTypeList]);

  function handleCloseDialog() {
    setReservationDialogOpen(false);
    setEditReservationDialogOpen(false);
    setGroupIndBtn({ ...groupIndBtn, status: true, btnType: "ind" });
  }
  function handleCloseGroupDialog() {
    setIsGroupReservationOpen(false);
    setEditIsGroupReservationOpen(false);
    setGroupIndBtn({ ...groupIndBtn, status: true, btnType: "ind" });
  }
  const handleGroupIndBtnClick = (type: any) => {
    setGroupIndBtn({ ...groupIndBtn, status: true, btnType: type });
    if (type === "group") {
      setReservationDialogOpen(false);
      setIsGroupReservationOpen(true);
    }
  };
  function OnDateSelected(info: any) {
    // console.log(bookedRooms);
    const roomId: string = info?.resource?.id;
    console.log(events, "this is dynamic events");
    console.log(info, "thi sis");

    // console.log(bookingData);
    info.noOfNights = calNoOfNights(info.startStr, info.endStr);
    const booking: any = bookingData.filter(
      (book: any) =>
        book?.room_booking_info?.room_id === parseInt(roomId) &&
        isBetween(book?.from_date, book?.to_date, info?.startStr) &&
        book.booking_status !== "check_out" &&
        book.booking_status !== "cancelled" &&
        book.booking_status !== "no_show"
    );
    // const booking: any = bookingData.find((book: any) => book.room_booking_info.room_id === parseInt(roomId) && book.booking_status !== "check_out" && book.booking_status !== "cancelled" && isBetween(book?.from_date, book?.to_date, info?.startStr));

    // const show: any = isBetween(
    //   booking[0]?.from_date,
    //   booking[0]?.to_date,
    //   info?.startStr
    // );
    // console.log(show);
    const roomInfo = resourceMap[`${roomId}`];
    setSelectedRoomInfo(roomInfo);
    setCalendarInfo(info);
    // const blockedRoomsCheck = events?.some((ele: any) => {
    //   console.log(
    //     isBetween(ele?.start, ele?.end, info?.startStr),
    //     "this is check between"
    //   );
    //   return (
    //     ele?.eventType === "out_of_order" &&
    //     +ele?.blockData?.room_id === +info?.resource?._resource.id
    //   );
    // });

    // console.log(blockedRoomsCheck, "this is blocked rooms check");

    // if (blockedRoomsCheck) {
    //   return;
    // }
    if (booking.length < 1) {
      console.log("this is open reservation form true", info);

      setReservationDialogOpen(true);
      // setTempReserveStatus(true);
      // if (groupIndBtn.btnType === "group") {
      //   setIsGroupReservationOpen(true);
      // } else {
      //   setReservationDialogOpen(true);
      // }
    }
  }
  function onEventClick(info: any) {
    const date = dayjs(new Date(lastNightAuditDate));
    const newDate = date.add(1, "day");

    // const roomInfo =
    //   resourceMap[
    //     `${info.event._def.extendedProps.guestBasicInfo.room_type_id}`
    //   ];
    console.log(info, "comming from else if", selectedRoomInfo);
    if (
      newDate.isAfter(dayjs(info.event.end)) &&
      info?.event._def.extendedProps.guestBasicInfo?.booking_status ===
        "check_in"
    ) {
      // console.log(info,"comming from else if",selectedRoomInfo);
      setSelectedEventInfo(info);
      setEditReservationDialogOpen(true);
    } else if (
      newDate.isAfter(dayjs(info.event.end)) &&
      info?.event._def.extendedProps.guestBasicInfo?.booking_status ===
        "pending"
    ) {
      // console.log(info,"comming from else if",selectedRoomInfo);
      setSelectedEventInfo(info);
      setEditReservationDialogOpen(true);
    } else if (
      info?.event?._def?.extendedProps?.eventType === "out_of_order" ||
      info?.event?._def?.extendedProps?.eventType === "management_block"
    ) {
      // console.log(info,"comming from else if",selectedRoomInfo);
      // return;
      setSelectedEventInfo(info);
      setManageEditRoomsDialog(true);

      return;

      // setEditReservationDialogOpen(true);
    } else if (isEqualOrAfter(newDate, dayjs(info.event.end))) {
      return;
    }

    setSelectedEventInfo(info);
    setEditReservationDialogOpen(true);
  }

  const onMouseEnter = (event: any, content: any) => {
    if (event?.event?._def?.extendedProps?.eventType === "booking") {
      setAnchorEl(event?.el);
      setSelectedEvent(event);
      setEventHoverX(event?.jsEvent?.offsetX - 10);
      setEventHoverY(event?.jsEvent?.offsetY - 10);
      setTimeout(() => {
        setEventMouseHover(true);
      }, 250);
    } else {
      return;
    }
  };

  const handlePopoverLeave = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
    setEventMouseHover(false);
  };

  function onFormSubmitted(response: any) {
    setRefresh((prev: boolean) => !prev);
    handleCloseDialog();
    if (response?.status_message === "failed") {
      alert(response?.message);
    }
  }
  function fnSetIsOpenContextMenu(guestBasicInfo: any, value: any) {
    if (guestBasicInfo.booking_status !== "check_out") {
      setIsOpenContextMenu(value);
    }
  }
  const handleCustomButton1 = () => {
    // Handle the click for Button 1
    // setReservationDialogOpen(true)
    alert("Custom Button 1 clicked");
  };

  const handleCustomButton2 = () => {
    // Handle the click for Button 2
    alert("Custom Button 2 clicked");
  };
  useEffect(() => {
    getLastNightAuditDate(loggedUser?.hotel_code).then((response: any) => {
      setLastNightAuditDate(response?.data?.last_night_audit_last_date);
      const date = dayjs(new Date(response?.data?.last_night_audit_last_date));
      const newDate = date.subtract(0, "day");
      formatAndSetCalendarValue(newDate);
    });
    getHotelDetails(loggedUser?.hotel_code).then((response: any) => {
      setHotelDetailsObj(response?.data);
    });
    getHouseKeepingList(loggedUser?.hotel_code).then((response: any) => {
      setHouseKeepingData(response?.data);
    });
  }, []);
  useEffect(() => {
    const resourceDivs = document.querySelectorAll(".fc-timeline-header-row");

    // Loop through each element and apply styles
    resourceDivs.forEach((div) => {
      if (div instanceof HTMLElement) {
        div.style.backgroundColor = "#2c3e50";
        div.style.color = "white";
      }
    });

    const resourceDivs2 = document.querySelectorAll(
      "th:not([data-resource-id]) > .fc-datagrid-cell-frame"
    );
    // const filteredDivs = Array.from(resourceDivs2).filter(div => {
    //   // Add your additional condition here
    //   // For example, checking if the div contains a specific class
    //   return div.classList.contains('additional-class');
    // });

    // Loop through each element and apply styles
    resourceDivs2.forEach((div: any) => {
      if (div instanceof HTMLElement) {
        div.style.backgroundColor = "#2c3e50";
        div.style.color = "white";
      }
    });
    houseKeepingData?.forEach((ele: any) => {
      const divElement = document.querySelector(
        `[data-resource-id="${ele?.room_id}"]`
      );

      if (divElement) {
        (divElement as HTMLElement).style.borderColor = "#2c3e50";

        if (ele?.Availability?.toLowerCase() == "occupied") {
          (divElement as HTMLElement).style.backgroundColor =
            "rgb(255, 45, 45, 1)";
          // (divElement as HTMLElement).style.border = "1px solid black"
        } else if (ele?.Availability?.toLowerCase() == "departure") {
          (divElement as HTMLElement).style.backgroundColor = "#ffa630";
        } else if (
          ele?.Availability?.toLowerCase() == "available" &&
          ele?.status?.toLowerCase() == "dirty"
        ) {
          (divElement as HTMLElement).style.backgroundColor = "#fee402";
        } else if (
          ele?.Availability?.toLowerCase() == "available" &&
          ele?.status?.toLowerCase() == "clean"
        ) {
          (divElement as HTMLElement).style.backgroundColor = "#9bc53d";
        } else if (ele?.status?.toLowerCase() == "out_of_order") {
          (divElement as HTMLElement).style.backgroundColor =
            "rgba(168, 119, 17, 1)";
        } else if (ele?.status?.toLowerCase() == "management_block") {
          (divElement as HTMLElement).style.backgroundColor =
            "rgba(149, 109, 149, 1)";
        }

        // else {
        //   (divElement as HTMLElement).style.backgroundColor = "#fee402";
        // }
        // (divElement9bc53d fee402as HTMLElement).style.backgroundColor =
        //   ele?.Availability?.toLowerCase() === "Occu"
        //     ? "#d47486"
        //     : "rgba(34, 153, 84, 0.49)";
        // // Add more style properties as needed
        // console.log(ele?.status, "this stsuas");
      } else {
        console.error(`Div with data-resource-id "${ele?.id}" not found.`);
      }
      // });
    });

    // Cleanup function
    return () => {
      // Your cleanup logic goes here
      console.log("Component will unmount. Cleanup logic executed.");
    };
  }, [dynamicResource, houseKeepingData, bookedRooms]);

  useEffect(() => {
    const targetCssClass = "custom-style";
    const calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(
      dayjs(calendarValue).subtract(0, "day").format("YYYY-MM-DD")
    );
    const columnHeaderEls = calendarApi.el.querySelectorAll(
      ".fc-timeline-slot-lane"
    );
    columnHeaderEls.forEach((columnHeaderEl: any) => {
      // Get the date associated with the column
      const date = columnHeaderEl.getAttribute("data-date");

      // Check if the date matches the target date
      if (date === lastNightAuditDate) {
        columnHeaderEl.classList.add(targetCssClass); // Apply the CSS class
      }
    });
    // Use useEffect to ensure the DOM is ready

    // console.log("dynamic resource", dynamicResource);fnSetIsOpenContextMenu
  }, [calendarValue]);

  return (
    <div style={{ width: "100%" }}>
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          myInteractionPlugin,
          resourceTimelinePlugin,
        ]}
        initialView={"resourceTimelineCustomMonth"}
        ref={calendarComponentRef}
        initialDate={new Date(2018, 8, 5)}
        timeZone={"local"}
        resourceAreaHeaderContent={"Rooms"}
        headerToolbar={{
          start: "prev,next", // will normally be on the left. if RTL, will be on the right
          center: "title",
          end: "",
          right: "resourceTimelineCustomMonth,resourceTimelineCustomDay",
        }}
        views={{
          resourceTimelineCustomMonth: {
            type: "resourceTimeline",
            duration: { days: 30 },
            buttonText: "Month",
            slotDuration: { days: 1 },
          },
          resourceTimelineCustomDay: {
            type: "resourceTimeline",
            duration: { days: 3 },
            buttonText: "Day",
            slotDuration: { day: 1 },
          },
        }}
        // customButtons={{
        //   customButton1: {
        //     text: "Create temp booking",
        //     click: handleCustomButton1,
        //   },
        //   customButton2: {
        //     text: "View temp booking",
        //     click: handleCustomButton2,
        //   },
        // }}
        resourceOrder={"room_no"}
        height={"80vh"}
        selectable={true}
        resources={dynamicResource}
        select={(info) => OnDateSelected(info)}
        events={events}
        eventClick={(info) => onEventClick(info)}
        resourceAreaWidth={300}
        eventMouseEnter={(mouseEnterInfo: any) =>
          onMouseEnter(mouseEnterInfo, EventHover)
        }
        // eventMouseLeave={() => onMouseLeave()}
        resourceGroupField={"venue"}
        nextDayThreshold={"12:00:00 PM"}
        selectAllow={(info: any) => {
          if (
            isEqualOrAfter(
              moment(lastNightAuditDate).startOf("day"),
              dayjs(info.start)
            )
          )
            return false;
          return true;
        }}
        // eventContent={(arg) => {
        //   const customClassName = someCondition
        //     ? "custom-class-1"
        //     : "custom-class-2";
        //   return {
        //     className: `fc-datagrid-cell-frame ${customClassName}`,
        //   };
        // }}
        // dateClick={(info) => {
        //   console.log(info, "this is buttion================================================");

        //   // setIsOpenContextMenu(true);
        // }}
        eventOverlap={true}
        eventDidMount={(info) => {
          info.el.addEventListener(
            "contextmenu",
            (ev) => {
              ev.preventDefault();
              fnSetIsOpenContextMenu(
                info?.event?._def?.extendedProps?.guestBasicInfo,
                true
              );
              setTargetElement(info.el);
              setSelectedEventInfo(info);
              return false;
            },
            false
          );
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverLeave}
        anchorOrigin={{
          vertical: eventHoverY,
          horizontal: eventHoverX,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.25)",
            backdropFilter: "blur(10px) brightness(125%)",
            WebkitBackdropFilter: "blur(10px) brightness(25%)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <div
          onMouseLeave={handlePopoverLeave}
          onClick={() => onEventClick(selectedEvent)}
          onContextMenu={(ev) => {
            ev.preventDefault();
            fnSetIsOpenContextMenu(
              selectedEvent?.event?._def?.extendedProps?.guestBasicInfo,
              true
            );
            setTargetElement(selectedEvent.el);
            console.log("===> ????", selectedEvent);
            setSelectedEventInfo(selectedEvent);
            return false;
          }}
        >
          <EventHover selectedEvent={selectedEvent} />
        </div>
      </Popover>
      <Dialog
        open={isGroupReservationOpen}
        onClose={handleCloseGroupDialog}
        // onBackdropClick={handleOutsideClick}
        sx={{
          width: "calc(100% - 200px)",
          // minWidth: "690px",
          margin: "auto",
        }}
      >
        <DialogTitle
          sx={{ bgcolor: "#0284c7", color: "white", fontWeight: "600" }}
        >
          <div>
            <p>Add Quick Group Reservation</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <GroupReservationForm
            bookedRooms={bookedRooms}
            calendarInfo={calendarInfo}
            selectedRoomInfo={selectedRoomInfo}
            onFormSubmitted={onFormSubmitted}
            handleCloseReservationDialog={handleCloseDialog}
            roomTypeList1={roomTypeList}
            groupIndBtn={groupIndBtn}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={manageEditRoomsDialog}
        onClose={handleCloseDialogEditBlock}
        sx={{
          width: "calc(100% - 200px)",
          minWidth: "590px",
          margin: "auto",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <div
            className="bg-sky-600 px-4 py-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            <p>Manage Rooms</p>
          </div>
        </DialogTitle>

        <DialogContent sx={{ minWidth: "600px", height: "500px" }}>
          <EditRoomsBlock
            handleCloseDialogEditBlock={handleCloseDialogEditBlock}
            selectedEventInfo={selectedEventInfo}
            setRefresh={setRefresh}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isOpenReservationDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          minWidth: "590px",
          margin: "auto",
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <p>Add Quick Reservation</p>
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  backgroundColor:
                    groupIndBtn.status && groupIndBtn.btnType === "ind"
                      ? "white"
                      : "rgb(25, 118, 210)",
                  padding: "5px",
                  fontSize: "12px",
                  borderRadius: "4px",
                  color:
                    groupIndBtn.status && groupIndBtn.btnType === "ind"
                      ? "rgb(25, 118, 210)"
                      : "white",
                  boxShadow:
                    groupIndBtn.status && groupIndBtn.btnType === "ind"
                      ? "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
                      : "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                }}
                onClick={() => handleGroupIndBtnClick("ind")}
              >
                Individul Booking
              </button>
              <button
                style={{
                  backgroundColor:
                    groupIndBtn.status && groupIndBtn.btnType === "group"
                      ? "white"
                      : "rgb(25, 118, 210)",
                  padding: "5px",
                  fontSize: "12px",
                  borderRadius: "4px",
                  color:
                    groupIndBtn.status && groupIndBtn.btnType === "group"
                      ? "rgb(25, 118, 210)"
                      : "white",
                  boxShadow:
                    groupIndBtn.status && groupIndBtn.btnType === "group"
                      ? "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
                      : "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                }}
                onClick={() => handleGroupIndBtnClick("group")}
              >
                Group Booking
              </button>
            </div>
          </div>
        </DialogTitle>

        <DialogContent sx={{ minWidth: "600px", padding: 0 }}>
          <ReservationFrom
            bookedRooms={bookedRooms}
            calendarInfo={calendarInfo}
            selectedRoomInfo={selectedRoomInfo}
            onFormSubmitted={onFormSubmitted}
            handleCloseReservationDialog={handleCloseDialog}
            roomTypeList1={roomTypeList}
            groupIndBtn={groupIndBtn}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isOpenEditReservationDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          minWidth: "590px",
          margin: "auto",
        }}
      >
        <DialogContent sx={{ minWidth: "600px", padding: 0 }}>
          <DemoEdit
            bookedRooms={bookedRooms}
            calendarInfo={calendarInfo}
            selectedEventInfo={selectedEventInfo}
            resourceMap={resourceMap}
            selectedRoomInfo={selectedRoomInfo}
            handleCloseEditReservationDialog={handleCloseDialog}
            onFormSubmitted={onFormSubmitted}
            roomTypeList1={roomTypeList}
          />
        </DialogContent>
      </Dialog>
      <BasicMenu
        isOpenContextMenu={isOpenContextMenu}
        targetElement={targetElement}
        selectedEventInfo={selectedEventInfo}
        onHandleClose={onHandleClose}
      />
    </div>
  );
}

export default Calendar;
