import { format, formatDistanceToNow } from "date-fns";
import dayjs from "dayjs";
import moment from "moment";

type Props = {
  date: Date;
};

export function fDate(props: Props) {
  return format(new Date(props.date), "dd MMMM yyyy");
}

export function fDateTime(props: Props) {
  return format(new Date(props.date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(props: Props) {
  return format(new Date(props.date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(props: Props) {
  return formatDistanceToNow(new Date(props.date), {
    addSuffix: true,
  });
}
export function isBefore(date1: any, date2: any) {
  date1 = dayjs(moment(date1).toDate());
  date2 = dayjs(date2.toDate());
  if (date1.isBefore(date2)) {
    return true;
  } else {
    return false;
  }
}
export function isEqualOrAfter(date1: any, date2: any) {
  date1 = dayjs(date1);
  date2 = dayjs(date2);
  if (date1.isSame(date2) || date1.isAfter(date2)) {
    return true;
  } else {
    return false;
  }
}
export function isBetween(date1: any, date2: any, date3: any) {
  // date1 = dayjs(moment(date1).toDate());
  // date2 = dayjs(moment(date2).toDate());
  // date3 = dayjs(moment(date3).toDate());
  // console.log(date1, date2, date3);
  if (moment(date3).isBetween(date1, date2, undefined, "[)")) {
    return true;
  } else {
    return false;
  }
}
export function isEqual(date1: any, date2: any) {
  date1 = dayjs(moment(date1).toDate());
  date2 = dayjs(moment(date2).toDate());
  if (date1.isSame(date2)) {
    return true;
  } else {
    return false;
  }
}
export function isChekoutPossible(sDate: any, eDate: any) {
  return isEqualOrAfter(sDate, moment()) && isBefore(eDate, moment());
}
export function getMonth(date: any) {
  return date.toLocaleString("default", { month: "short" });
}
export function getDate(date: any) {
  return date.getDate();
}
export function formateDateInApiSupport(date: any) {
  return `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
}
