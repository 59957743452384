import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import AmenitiesCreate from "./AmenitiesCreate";
import {
  getBlockData,
  getCurrentUser,
  insertBlockData,
} from "../../api-config/config";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "block",
    headerName: "Block Name",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: true,
  },
  {
    field: "rooms",
    headerName: "Rooms",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "action",
    headerName: "Action",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

export default function BlockList() {
  const [blockList, setblockList] = useState();
  const loggedUser = getCurrentUser();
  const getblockList = (hotel_code: number) => {
    return getBlockData(hotel_code).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setblockList(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getblockList(loggedUser?.hotel_code);
  }, []);
  function onBlockAdded() {
    getblockList(loggedUser?.hotel_code);
  }

  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Block List
          </Typography>
          <BlockListGrid
            row={blockList}
            loggedUser={loggedUser}
            onBlockAdded={onBlockAdded}
          />
        </Container>
      </Page>
    </>
  );
}

function BlockListGrid(data: any) {
  const [open, setOpen] = React.useState(false);
  const [blockName, setBlockName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { row, loggedUser, onBlockAdded } = data;
  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        block: item.block_name,
        rooms: item.rooms,
        action: "",
        status: item.is_active ? "Active" : "Deactive",
      });
    });
  }
  function handleSubmit() {
    insertBlockData(loggedUser?.hotel_code, blockName).then((response) => {
      onBlockAdded();
      handleClose();
      alert("Block Added");
    });
  }

  return (
    <Box
      sx={{
        height: 480,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add a Block
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Block(S)
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Block List</h3>
      </Box>

      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add A Block</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Hotel Code"
                  variant="outlined"
                  sx={{ margin: "5px 0" }}
                  value={loggedUser?.hotel_code}
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Block Name"
                  variant="outlined"
                  sx={{ margin: "5px 0" }}
                  value={blockName}
                  onChange={(e: any) => setBlockName(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit()}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
