import { Box, Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import DropdownInput from "../../utils/dropMenu";
import BasicSelect from "../../utils/BasicSelect";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import {
  baseUrl,
  changeCheckingInfo,
  getBookingInfo,
  getCashierData,
  getCurrentUser,
  getFolioOptionsPms,
  insertPaymentData,
  insertPaymentsPms,
} from "../../api-config/config";
import {
  getCorporateData,
  getTravelAgentData,
} from "../../api-config/corporate.api";
import { sendEmail } from "../../utils/emailjs-utils";
import axios from "axios";
import moment from "moment";
import LoaderDotted from "../LoaderDotted/LoaderDotted";
import api from "../../api-config/axiosInstance";

const FolioOverviewPayments = (props: any) => {
  const { folioDetails, fetch } = props;
  const loggedUser = getCurrentUser();
  const [corporateList, setCorporateList] = useState([]);
  const [travelAgentList, setTravelAgentList] = useState([]);
  const [cashierData, setCashierData] = useState([]);
  const [selectedGuestForPayment, setSelectedGuestForPayment] = useState(
    {} as any
  );
  const [otherChargesDropDown, setOtherChargesDropDown] = useState([]);
  const [error, setError] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    mode: "cash",
    // type: "",
    amount:
      folioDetails?.charge_till_now?.balance > 0
        ? folioDetails?.charge_till_now?.balance
        : 0,
    // checque_no: null,
    receipt_no: "",
    description: "",
    corporate_id: "",
    travel_agent_id: "",
    online: 0,
    upi: 0,
    card: 0,
    cash: 0,
  } as any);
  const [loading, setLoading] = useState(false);
  const modesList = ["Select", "cash", "card", "UPI", "Net Banking", "Online"];

  useEffect(() => {
    getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
      setCorporateList(res.data)
    );
    getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
      setTravelAgentList(res.data)
    );
    getCashierData({
      hotel_code: loggedUser?.hotel_code,
      date: moment().format("YYYY-MM-DD"),
    }).then((res: any) => {
      // console.log(res.data, "cashier data for poooooooooo");
      setCashierData(res.data);
    });
  }, []);
  const billView = () => {
    let url = `${baseUrl}/invoice_pms?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`;
    if (folioDetails?.corporate?.corporate_name) {
      url = url + "&invoice_for=corporate";
    } else if (folioDetails?.travel_agent?.billing_company_name) {
      url = url + "&invoice_for=travel_agent";
    }
    api.get(url).then((res: any) => {
      window.open(res.data.file);
    });
  };
  const voucherView = (res: any) => {
    window.open(res.voucher);
  };

  const handleSubmit = (formData: any) => {
    if (
      !selectedGuestForPayment ||
      Object.keys(selectedGuestForPayment).length === 0
    ) {
      setError(true);
      return;
    }
    if (
      !(
        parseFloat(paymentDetails?.online) +
          parseFloat(paymentDetails?.upi) +
          parseFloat(paymentDetails?.card) +
          parseFloat(paymentDetails?.cash) >
        0
      )
    ) {
      // alert("lol");
      setPaymentDetails((prev: any) => ({
        ...prev,
        [paymentDetails.mode]: folioDetails?.charge_till_now?.balance,
      }));
    }

    const total_payment: any = cashierData?.[cashierData.length - 1];
    const payload: any = {
      booking_id: folioDetails?.booking_id,
      paid_amount:
        parseFloat(paymentDetails?.online) +
          parseFloat(paymentDetails?.upi) +
          parseFloat(paymentDetails?.card) +
          parseFloat(paymentDetails?.cash) ===
        0
          ? parseFloat(paymentDetails?.amount)
          : parseFloat(paymentDetails?.online) +
            parseFloat(paymentDetails?.upi) +
            parseFloat(paymentDetails?.card) +
            parseFloat(paymentDetails?.cash),
      // "paid_amount": (parseFloat(paymentDetails?.online)+ parseFloat(paymentDetails?.upi)+ parseFloat(paymentDetails?.card)+ parseFloat(paymentDetails?.cash) === 0) ? parseFloat(paymentDetails[paymentDetails?.mode]) : (parseFloat(paymentDetails?.online)+ parseFloat(paymentDetails?.upi)+ parseFloat(paymentDetails?.card)+ parseFloat(paymentDetails?.cash)),
      gross_amount: folioDetails?.gross_amount,
      receipt_no: paymentDetails.receipt_no,
      mode: paymentDetails?.mode || "cash",
      // corporate_id: paymentDetails?.corporate_id || "",
      // travel_agent_id: paymentDetails?.travel_agent_id || "",
      online: parseFloat(paymentDetails?.online),
      upi: parseFloat(paymentDetails?.upi),
      card: parseFloat(paymentDetails?.card),
      cash:
        parseFloat(paymentDetails?.online) +
          parseFloat(paymentDetails?.upi) +
          parseFloat(paymentDetails?.card) +
          parseFloat(paymentDetails?.cash) ===
        0
          ? parseFloat(paymentDetails?.amount)
          : parseFloat(paymentDetails?.cash),
      amount: parseFloat(paymentDetails?.amount),
      available_balance: total_payment.available_balance,
      description: paymentDetails?.description,
      comment: `Recevied payment from ${
        folioDetails.first_name + " " + folioDetails.last_name
      } ${folioDetails.room_booking[0].room_title} booking status ${
        folioDetails.booking_status === "pending" ? "Reserved" : "Check in"
      }`,
      // payment_type = data["type"] if ("type" in data) else "room"
      guest_id: selectedGuestForPayment?.guest_id,
      hotel_code: loggedUser?.hotel_code,
      corporate_id: selectedGuestForPayment?.corporate_id,
      group_booking_id: selectedGuestForPayment?.group_id,
      travel_agent_id: selectedGuestForPayment?.travel_agent_id,
      room_id: selectedGuestForPayment?.room_id,
      payment_type: selectedGuestForPayment?.type,
      // refund = data["refund"] if "refund" in data else False
    };
    console.log(payload);
    setLoading(true);
    insertPaymentsPms(payload).then((response: any) => {
      console.log(response);
      if (response.status_message === "success") {
        fetch();
        setSelectedGuestForPayment({});
        setLoading(false);
        voucherView(response);
        // window.location.reload();
        setPaymentDetails({
          mode: "cash",
          // type: "",
          amount: folioDetails?.charge_till_now?.balance || null,
          // checque_no: null,
          receipt_no: "",
          description: "",
          corporate_id: "",
          travel_agent_id: "",
          online: 0,
          upi: 0,
          card: 0,
          cash: 0,
        });
      }
      // onFeatureAdded();
      // handleClose();
    });
  };
  console.log(
    selectedGuestForPayment,
    selectedGuestForPayment?.charge_to,
    "this is selected guest from the dropdown"
  );
  console.log(
    otherChargesDropDown,

    "this ios other charges drop from payment"
  );

  const handleModeChange = (e: any) => {
    console.log("this is bill to company", e.target.value);

    setPaymentDetails((prev: any) => {
      const newMode = e.target.value;
      const newAmount =
        newMode === "bill_to_company"
          ? folioDetails.charge_till_now.balance
          : paymentDetails[newMode];

      return {
        mode: newMode,
        amount: newAmount,
        online: newMode === "online" ? newAmount : 0,
        upi: newMode === "upi" ? newAmount : 0,
        card: newMode === "card" ? newAmount : 0,
        cash: newMode === "cash" ? newAmount : 0,
      };
    });
  };

  useEffect(() => {
    // getFolioOptionsPms
    const payload = {
      hotel_code: loggedUser?.hotel_code || 0,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      group_booking_id: folioDetails?.group_booking_id || 0,
    };
    // setBookingId(folioDetails?.booking_id);
    getFolioOptionsPms(payload).then((res) =>
      setOtherChargesDropDown(res.data)
    );
  }, [folioDetails]);

  return (
    <>
      <div className=" w-[100%] py-4 bg-[#e8eff5] mt-5 flex justify-center text-sm flex-wrap">
        <div
          className="w-[95%] flex justify-evenly items-center font-bold"
          style={{ minWidth: "100px" }}
        >
          <FormGroup className="w-[15%]">
            <InputLabel htmlFor="mode" className="">
              Mode
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select1"
              value={paymentDetails?.mode || "cash"}
              label="Mode"
              className="p-4"
              onChange={(e) => {
                console.log("this is bill to company", e.target.value);

                setPaymentDetails((prev: any) => {
                  const newMode = e.target.value;
                  const newAmount =
                    newMode === "bill_to_company"
                      ? folioDetails.charge_till_now.balance
                      : paymentDetails[newMode];

                  return {
                    mode: newMode,
                    amount: newAmount,
                    online: newMode === "online" ? newAmount : 0,
                    upi: newMode === "upi" ? newAmount : 0,
                    card: newMode === "card" ? newAmount : 0,
                    cash: newMode === "cash" ? newAmount : 0,
                  };
                });
              }}
            >
              <MenuItem value={""} key="select">
                Select
              </MenuItem>
              <MenuItem value={"card"} key="card">
                Card
              </MenuItem>
              <MenuItem value={"cash"} key="cash">
                Cash
              </MenuItem>
              <MenuItem value={"upi"} key="upi">
                UPI
              </MenuItem>
              <MenuItem value={"online"} key="online">
                Online
              </MenuItem>
              {folioDetails?.corporate_id && (
                <MenuItem value={"bill_to_company"} key="bill_to_company">
                  Bill To Company
                </MenuItem>
              )}
            </Select>
          </FormGroup>
          {paymentDetails.mode === "billl_to_company" && (
            <FormGroup className="w-[15%]">
              <InputLabel htmlFor="mode" className="">
                Company
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select1"
                // value={paymentDetails?.mode || "cash"}
                label="Company"
                className="p-4"
                onChange={(e: any) => {
                  const value: string = e.target.value;
                  console.log(e.target.value, "this is value from corporate");

                  if (value.includes("corporate.")) {
                    setPaymentDetails((prev: any) => ({
                      ...prev,
                      corporate_id: e.target.value.slice(10),
                    }));
                  } else if (value.includes("travelAgent.")) {
                    setPaymentDetails((prev: any) => ({
                      ...prev,
                      travel_agent_id: e.target.value.slice(12),
                    }));
                  }
                }}
              >
                <MenuItem value={""} key="select">
                  Select
                </MenuItem>
                {corporateList?.length > 0 &&
                  corporateList?.map((item: any) => (
                    <MenuItem value={`corporate.${item.id}`} key={item.id}>
                      {item.corporate_name}
                    </MenuItem>
                  ))}
                {travelAgentList?.length > 0 &&
                  travelAgentList?.map((item: any) => (
                    <MenuItem value={`travelAgent.${item.id}`} key={item.id}>
                      {item?.billing_company_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup>
          )}
          <FormGroup className="w-[28%] relative">
            <InputLabel htmlFor="mode" className="">
              Guest
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select1"
              value={selectedGuestForPayment}
              label="Mode"
              // className="p-4"
              className={
                error
                  ? "p-4 focus:ring-red-500 focus:border-red-500 w-[100%]"
                  : "p-4 w-[100%]"
              }
              onChange={(e) => {
                setSelectedGuestForPayment(e.target.value);
                setError(false);
              }}
              style={{ width: "100%" }} // Set fixed width
            >
              {otherChargesDropDown?.map((guest: any, i: any) => (
                <MenuItem value={guest} key={i + 1}>
                  {guest?.charge_to}
                </MenuItem>
              ))}
              {/*  */}
            </Select>

            {error && (
              <span className="text-red-500">Please select a guest</span>
            )}
          </FormGroup>
          <FormGroup className="w-[15%]">
            <InputLabel htmlFor="amount">Amount</InputLabel>
            <OutlinedInput
              type="number"
              value={paymentDetails?.amount}
              name="amount"
              id="amount"
              onChange={(e: any) => {
                if (paymentDetails?.mode === "bill_to_company") {
                  return;
                } else {
                  const inputValue = e.target.value.trim(); // Trim leading and trailing whitespaces

                  // Allow empty input or positive numbers
                  if (
                    inputValue === "" ||
                    (!isNaN(parseFloat(inputValue)) &&
                      parseFloat(inputValue) >= 0)
                  ) {
                    setPaymentDetails((prev: any) => ({
                      ...prev,
                      amount: inputValue,
                      [prev.mode]: inputValue,
                    }));
                  }
                }
              }}
            />
          </FormGroup>
          {/* <FormGroup className="w-[15%]">
          <InputLabel htmlFor="receipt_no">Receipt No</InputLabel>
          <OutlinedInput
            type="text"
            value={paymentDetails?.receipt_no || ""}
            name="receipt_no"
            id="receipt_no"
            onChange={(e: any) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                receipt_no: e.target.value,
              }))
            }
          />
        </FormGroup> */}
          <FormGroup className="w-[15%]">
            <InputLabel htmlFor="description">Description</InputLabel>
            <OutlinedInput
              type="text"
              value={paymentDetails?.description || ""}
              name="description"
              id="description"
              onChange={(e: any) =>
                setPaymentDetails((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          </FormGroup>
          <FormGroup className="w-[15%] flex align-center justify-center">
            {loading ? (
              <LoaderDotted />
            ) : (
              <Button
                type="button"
                className="flex bg-sky-600 hover:bg-sky-400 text-[white] py-4 mt-5"
                // onClick={() => console.log(paymentDetails)}
                onClick={() => {
                  if (paymentDetails?.amount > 0) {
                    handleSubmit(paymentDetails);
                  } else {
                    alert("Amount should be Positive");
                  }
                }}
              >
                Pay Now
              </Button>
            )}
          </FormGroup>
        </div>
      </div>
      {/* <div className="p-4 bg-blue-100 rounded-lg shadow-md">
      <div className="flex items-center justify-between">
        <div className="w-1/3 pr-4">
          <BasicSelect title="Guest"  />
        </div>
        <div className="w-1/3 pr-4">
          <BasicSelect title="Mode"  data={modesList} handleModeChange={handleModeChange} handleState={paymentDetails?.mode}/>
        </div>
        <div className="w-1/6 pr-4">
          <TextField id="amount" label="Amount" variant="outlined" fullWidth />
        </div>
        <div className="w-1/3">
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            fullWidth
          />
        </div>
        <div className="w-1/6 pl-4">
          <Button variant="contained" color="primary">
            Pay Now
          </Button>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default FolioOverviewPayments;
