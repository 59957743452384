import {
  Container,
  Typography,
  Grid,
  Button,
  Stack,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "@mui/material";
import * as Yup from "yup";
import {
  getCurrentUser,
  insertGuestDataPms,
  updateGuestDataPms,
  changeGuestDataPms,
} from "../../api-config/config";
import { uploadGuestId } from "../../api-config/file-upload.api";
import {
  getAadhaarOtpPms,
  submitAadhaarOtpPms,
} from "../../api-config/folio.api";
import LoaderDotted from "../LoaderDotted/LoaderDotted";

const AddGuestInfo = (props: any) => {
  const {
    bookingInfo,
    handleCloseDialog,
    onGuestUpdated,
    selectedGuest,
    setSelectedGuest,
  } = props;

  const loggedUser = getCurrentUser();
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "",
    gender: "",
    state: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    id_number: "",
    id_prof_type: "",
    // maritalStatus: bookingInfo?.guest_data?.marital_status || "",
    date_of_birth: "",
    nationality: "",
    // gstNumber: bookingInfo?.guest_data?.gst_number || "",
    // companyName: bookingInfo?.guest_data?.corporate_name || "",
    // conmanyId: bookingInfo?.corporate_id,
    // isCompany: bookingInfo?.guest_data?.corporate_name ? true : false,
  };

  const [enteredAadhaar, setEnteredAadhaar] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [showResendButton, setShowResendButton] = useState(true);
  const [otpSwap, setOtpSwap] = useState(false);
  const [otp, setOTP] = useState("");
  const [timerId, setTimerId] = useState<number | null>(null); // Specify type as number or null
  const [error, setError] = useState(false);
  const [aadhaarSwap, setAadhaarSwap] = useState(false);
  const [guestData, setGuestData] = useState({} as any);
  const [retGuestLoader, setRetGuestLoader] = useState(false);
  const [successguest, setSuccessguest] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const formikRef = useRef<any>(null);
  const [guestMob, setGuestMob] = useState("");

  const [compId, setCompId] = useState(1 as any);
  const [file, setFile] = useState("not" as any);
  const handleFileChange = (event: any) => {
    event.preventDefault();
    setFile(event.target?.files[0]);
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("guest_id", bookingInfo.customer_id);
    uploadGuestId(formData).then((response: any) => {
      alert("Id upload" + " " + response.status_message);
    });
  };

  const formValidation = Yup.object().shape({
    // room_type: Yup.string().required("Please enter room type"),
    // // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // // image: Yup.string().required("Please enter City"),
    // adult: Yup.number().required("Please enter Base Occupancy"),
    // child: Yup.string().required("Please enter State"),
  });
  console.log(selectedGuest, "poooooooooo");

  const handleSubmit = (formData: any) => {
    console.log(formData);
    formData.id_number = formData.id_number === "" ? 0 : +formData.id_number;
    formData.zipcode = formData.code === "" ? 0 : +formData.zipcode;
    const formData1 = new FormData();
    formData1.append("file", file);
    formData1.append("first_name", formData.firstName);
    formData1.append("last_name", formData.lastName);
    formData1.append("booking_id", bookingInfo.booking_id);
    formData1.append("hotel_code", loggedUser?.hotel_code);
    formData1.append("mobile_number", guestMob);
    formData1.append("email", formData.email);
    formData1.append("dob", formData.date_of_birth);
    formData1.append("gender", formData.gender);
    formData1.append("nationality", formData.nationality);
    formData1.append("address", formData.address);
    formData1.append("id_proof_type", formData.id_prof_type);
    formData1.append("id_proof_number", formData.id_number);
    formData1.append("country", formData.country);
    formData1.append("state", formData.state);
    formData1.append("city", formData.city);
    formData1.append("zipcode", formData.zipcode);
    if (selectedGuest?.id > 0) {
      formData1.append("pms_guest_id", selectedGuest?.id);
      changeGuestDataPms(formData1).then((response: any) => {
        console.log(response);
        if (response?.status_message === "success") {
          onGuestUpdated();
          setSelectedGuest({});
        } else {
          alert(response?.message);
        }
      });

      return;
    }

    const payload = {
      //   pms_guest_id: selectedGuest.id,
      first_name: formData.firstName,
      last_name: formData.lastName,
      mobile_number: guestMob,
      email: formData.email,
      dob: formData.date_of_birth,
      gender: formData.gender,
      // marital_status: formData.maritalStatus,
      nationality: formData.nationality,
      address: formData.address,
      id_proof_type: formData.id_prof_type,
      id_proof_number: formData.id_number === "" ? 0 : +formData.id_number,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      zipcode: formData.zipcode,
      //   uploadId: formData1,
      // gstNumber: selectedComp.gst_number,
      // companyName: selectedComp.corporate_name,
      // companyId: selectedComp.id,
      booking_id: bookingInfo.booking_id,
      hotel_code: loggedUser?.hotel_code,
    };
    insertGuestDataPms(formData1).then((response: any) => {
      console.log(response);
      if (response?.status_message === "success") {
        onGuestUpdated();
      } else {
        alert(response?.message);
      }
    });
    console.log(payload);
  };
  const verifyAadhaar = () => {
    setOtpSwap(true);
    getAadhaarOtpPms({ aadhaar_number: enteredAadhaar }).then(
      (response: any) => {
        // setAadharOtpStatus(response.status);
        if (response.status === "success") {
          setAadhaarSwap(true);
          setOtpSwap(false);
        } else {
          setError(true);
          setOtpSwap(false);
        }
      }
    );
  };
  const verifyAadhaarOtp = (mobile: any) => {
    // setRefresh(true);
    setRetGuestLoader(true);
    submitAadhaarOtpPms({
      otp: otp,
      aadhaar_no: enteredAadhaar,
      mobile_number: mobile,
      // pms_guest_id: selectedGuest?.id,
      booking_id: bookingInfo.booking_id,
    }).then((response: any) => {
      if (response.status === "success") {
        setRetGuestLoader(false);
        setSuccessguest(true);
        setGuestData(response.data);
        // setFormValues({
        //   firstName: response.data?.first_name || "",
        //   lastName: response.data?.last_name || "",
        //   email: response.data?.last_name || "",
        //   mobNumber: "",
        //   gender: response.data?.gender || "",
        //   state: response.data?.state || "",
        //   address: response.data?.care_of + response.data?.address || "",
        //   country: response.data?.gender || "",
        //   city: response.data?.gender || "",
        //   zipcode: response.data?.zipcode || "",
        //   id_number: response.data?.gender || "",
        //   id_prof_type: "",
        //   // maritalStatus: bookingInfo?.guest_data?.marital_status || "",
        //   date_of_birth: response.data?.gender || "",
        //   nationality: response.data?.gender || "",
        //   // gstNumber: bookingInfo?.guest_data?.gst_number || "",
        //   // companyName: bookingInfo?.guest_data?.corporate_name || "",
        //   // conmanyId: bookingInfo?.corporate_id,
        //   // isCompany: bookingInfo?.guest_data?.corporate_name ? true : false,
        // });
        // setRefresh(false);
        // setOtpVerified(true);
        // setOtpMessage(response.message);
        // setSelectedGuest((prev: any) => ({ ...prev, ...response.data }));
        // getFolioDetails(
        //   parseInt(folioDetailsObj?.booking_id),
        //   setFolioDetailsObj
        // );
        // setDefaultValues1(!defaultValues1);
        // handleCloseDialog();
      }
    });
  };
  useEffect(() => {
    // Update formValues state before Formik initializes
    formikRef.current &&
      formikRef.current.setValues({
        firstName: guestData?.first_name || "",
        lastName: guestData?.last_name || "",
        email: guestData?.last_name || "",
        mobNumber: "",
        gender: guestData?.gender || "",
        state: guestData?.state || "",
        address: guestData?.care_of + guestData?.address || "",
        country: guestData?.country || "",
        city: guestData?.city || "",
        zipcode: guestData?.zipcode || "",
        id_number: guestData?.id_proof_number || "",
        id_prof_type: "",
        date_of_birth: guestData?.dob || "",
        nationality: guestData?.nationality || "",
      });
  }, [guestData]);

  useEffect(() => {
    if (seconds > 0 && aadhaarSwap) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            setShowResendButton(true);
            clearInterval(timerId!);
          }
          console.log("Seconds remaining:", prevSeconds);
          return prevSeconds - 1;
        });
      }, 1000);
      setTimerId(timer as any as number);
      return () => clearInterval(timer);
    }
  }, [seconds, aadhaarSwap]);

  useEffect(() => {
    if (seconds === 0 && timerId) {
      clearInterval(timerId);
    }
  }, [seconds, timerId]);
  console.log(guestData, "thi sthe guest dTAA/////");

  const handleResendClick = () => {
    // Implement any logic for resending OTP
    // For example:
    // onResendOTP();
    setShowResendButton(false);
  };
  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#ffffff",
            fontWeight: 600,
            fontSize: 16,
            background: "#1976d2",
            padding: "12px",
          }}
        >
          Guest Information:
        </Typography>
        <>
          <div
            style={{
              display: "flex",
              border: "solid black",
              borderWidth: "thin",
              padding: "5px",
            }}
          >
            <div style={{ flex: 1 }}>
              <p>Guest Details:</p>
            </div>
            {/* <div style={{ flex: 1 }}>
              <p>
                Guest Id:{" "}
                <span style={{ color: "blue", fontWeight: 600 }}>
                  {selectedGuest?.id}
                </span>
              </p>
            </div> */}
          </div>
          <Formik
            innerRef={formikRef}
            initialValues={defaultValues}
            validationSchema={formValidation}
            onSubmit={(event) => handleSubmit(event)}
          >
            {({ values }) => (
              <Form className="grid gap-4 lg:grid-cols-1 p-8">
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">First Name:</label>
                      <Field
                        name="firstName"
                        className="con-field"
                        placeholder="Enter First Name"
                        id="firstName"
                        type="text"
                        required
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="firstName" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Last Name:</label>
                      <Field
                        name="lastName"
                        className="con-field"
                        placeholder="Enter Last Name"
                        type="text"
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="lastName" />
                    </p>
                  </FormGroup>
                </div>
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Gender:</label>
                      <Field as="select" type="string" name="gender" required>
                        <option value={""}>Select</option>
                        <option value={"Male"}>Male</option>
                        <option value={"Female"}>Female</option>
                      </Field>
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="firstName" />
                    </p>
                  </FormGroup>
                </div>
                <hr />
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Select Id:</label>
                      <Field as="select" type="string" name="id_prof_type">
                        <option value="">Select</option>
                        <option value={"Aadhaar"}>Aadhar</option>
                        <option value={"Passport"}>Passport</option>
                        <option value={"Driving Licence"}>
                          Driving Licence
                        </option>
                      </Field>
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="adult" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Mobile:</label>
                      <Field
                        name="mobNumber"
                        className="con-field"
                        placeholder="Enter Mobile no*"
                        id="mobNumber"
                        type="text"
                        value={guestMob}
                        onChange={(e: any) => setGuestMob(e.target.value)}
                        required
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="mobNumber" />
                    </p>
                  </FormGroup>
                  {/* <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Enter Id/Visa#:</label>
                      <Field
                        name="id_number"
                        className="con-field"
                        placeholder="Enter Id Number"
                        id="id"
                        type="text"
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="id" />
                    </p>
                  </FormGroup> */}
                </div>

                {!aadhaarSwap && values.id_prof_type === "Aadhaar" && (
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
                    <FormGroup>
                      <label htmlFor="" className="pb-2">
                        Enter Aadhaar No
                      </label>
                      <Field
                        name="id_proof_number"
                        className="con-field"
                        placeholder="Enter Aadhaar no*"
                        id="id_proof_number"
                        type="number"
                        required
                        onChange={(e: any) => setEnteredAadhaar(e.target.value)}
                      />
                      <p className="text-red-600">
                        <ErrorMessage name="image" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <Button
                        disabled={!(enteredAadhaar.length > 10)}
                        name="cancel"
                        className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                        onClick={() => verifyAadhaar()}
                      >
                        Send Otp
                      </Button>
                    </FormGroup>
                  </div>
                )}
                {error && (
                  <p style={{ textAlign: "center", color: "red" }}>
                    Somthing went wrong , please try again later
                  </p>
                )}
                {otpSwap ? (
                  <LoaderDotted />
                ) : retGuestLoader ? (
                  <>
                    <p>Getting guest data....</p>
                    <LoaderDotted />
                  </>
                ) : successguest ? (
                  <>
                    <p
                      style={{
                        fontWeight: "600",
                        color: "green",
                        textAlign: "center",
                        padding: "4px",
                      }}
                    >
                      {" "}
                      Aadhaar is verified
                    </p>
                  </>
                ) : (
                  <>
                    {aadhaarSwap && values.id_prof_type === "Aadhaar" && (
                      // {otpSwap ? <}
                      <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
                        <>
                          <FormGroup>
                            <label htmlFor="" className="pb-2">
                              Enter Otp
                            </label>
                            <Field
                              // name="id_proof_number"
                              className="con-field"
                              placeholder="Enter Otp"
                              // id="id_proof_number"
                              type="number"
                              value={otp}
                              required
                              onChange={(e: any) => setOTP(e.target.value)}
                            />
                            <p className="text-red-600">
                              <ErrorMessage name="image" />
                            </p>
                          </FormGroup>
                          <FormGroup>
                            <Button
                              disabled={!(otp.length >= 5)}
                              name="cancel"
                              className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                              onClick={() => verifyAadhaarOtp(guestMob)}
                            >
                              Submit Otp
                            </Button>
                          </FormGroup>
                          <p>Resend OTP in: {seconds} seconds</p>
                          {showResendButton && (
                            <button
                              className="w-48 my-auto text-blue-500 border-none bg-transparent"
                              disabled={seconds > 0}
                              onClick={handleResendClick}
                            >
                              Resend OTP
                            </button>
                          )}
                        </>
                      </div>
                    )}
                  </>
                )}

                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Upload Id
                    </label>
                    <Field
                      name="image"
                      className="con-field"
                      id="image"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <p className="text-red-600">
                      <ErrorMessage name="image" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      disabled={!file}
                      name="cancel"
                      className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                      onClick={() => handleUpload()}
                    >
                      Upload
                    </Button>
                  </FormGroup>
                </div>
                <hr />
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="block">
                      <label className="mr-4">Nationality:</label>
                      <Field
                        name="nationality"
                        className="con-field"
                        placeholder="Enter Nationality"
                        id="nationality"
                        type="text"
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="nationality" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="block">
                      <label className="mr-4">DOB:</label>
                      <Field
                        name="date_of_birth"
                        className="con-field"
                        placeholder="YYYY/MM/DD*"
                        id="date_of_birth"
                        type="date"
                        required
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="date_of_birth" />
                    </p>
                  </FormGroup>
                </div>
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Email:</label>
                      <Field
                        name="email"
                        className="con-field"
                        placeholder="Enter Email id"
                        id="email"
                        type="text"
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="email" />
                    </p>
                  </FormGroup>
                </div>
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Marital Status:</label>
                      <Field as="select" type="string" name="maritalStatus">
                        <option value={"Single"}>Single</option>
                        <option value={"Married"}>Married</option>
                      </Field>
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="maritalStatus" />
                    </p>
                  </FormGroup>
                </div>

                <hr />
                {/* <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup className="">
                    <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                      <InputLabel htmlFor="isCompany">Company</InputLabel>
                      <Checkbox
                        className="p-4"
                        checked={isCompany || false}
                        onChange={(event: any) => setIsCompany(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </FormGroup>{

                    isCompany && (
                      <Autocomplete
                        id="hotel-autocomplete"
                        options={data || []}
                        getOptionLabel={(comp) => comp.corporate_name}
                        value={compId.id}
                        onInputChange={(event, newInputValue) => {
                          // registeredSelect(newInputValue);
                        }}
                        onChange={(e, newValue) => {
                          console.log(newValue);
                          setSelectedComp(newValue);
                          setCompId(newValue ? newValue.id : "");
                          // checkDetailsEntered(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={(e) => console.log(e)}
                            label="Select Company"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // Disable browser autocomplete
                            }}
                          />
                        )}
                      />
                    )}


                </div> */}

                <FormGroup>
                  <div className="flex">
                    <label className="mr-4">Address:</label>
                    <Field
                      name="address"
                      className="con-field width-full"
                      placeholder="Enter Address*"
                      id="address"
                      type="text"
                      // value={selectedComp.main_office_address}
                      // onChange={(e: any) => {
                      //   console.log(e.target.value);
                      //   setSelectedComp((prev) => ({
                      //     ...prev,
                      //     main_office_address: e.target.value,
                      //   }));
                      // }}
                    />
                  </div>
                  <p className="text-red-600">
                    <ErrorMessage name="mobNumber" />
                  </p>
                </FormGroup>
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Country:</label>
                      <Field
                        name="country"
                        className="con-field"
                        placeholder="Enter Country"
                        id="country"
                        type="text"
                        // value={selectedComp.country}
                        // onChange={(e: any) => {
                        //   console.log(e.target.value);
                        //   setSelectedComp((prev) => ({
                        //     ...prev,
                        //     country: e.target.value,
                        //   }));
                        // }}
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="country" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">State:</label>
                      <Field
                        name="state"
                        className="con-field"
                        placeholder="Enter state*"
                        id="state"
                        type="text"
                        // value={selectedComp.state}
                        // onChange={(e: any) => {
                        //   console.log(e.target.value);
                        //   setSelectedComp((prev) => ({
                        //     ...prev,
                        //     state: e.target.value,
                        //   }));
                        // }}
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="state" />
                    </p>
                  </FormGroup>
                </div>
                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">City:</label>
                      <Field
                        name="city"
                        className="con-field"
                        placeholder="Enter City"
                        id="city"
                        type="text"
                        // value={selectedComp.city}
                        // onChange={(e: any) => {
                        //   console.log(e.target.value);
                        //   setSelectedComp((prev) => ({
                        //     ...prev,
                        //     city: e.target.value,
                        //   }));
                        // }}
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="city" />
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Zip Code:</label>
                      <Field
                        name="zipcode"
                        className="con-field"
                        placeholder="Enter Zip Code*"
                        id="zipcode"
                        type="text"
                        // value={selectedComp.zip_code}
                        // onChange={(e: any) => {
                        //   console.log(e.target.value);
                        //   setSelectedComp((prev) => ({
                        //     ...prev,
                        //     zip_code: e.target.value,
                        //   }));
                        // }}
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="zipcode" />
                    </p>
                  </FormGroup>
                </div>
                {/* <FormGroup>
                  <div className="flex">
                    <label className="mr-4">GST / VAT No:</label>
                    <Field
                      name="gstNumber"
                      className="con-field width-full"
                      placeholder="Enter GST number"
                      type="text"
                      value={selectedComp.gst_number}
                      onChange={(e: any) => {
                        console.log(e.target.value);
                        setSelectedComp((prev) => ({
                          ...prev,
                          gst: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <p className="text-red-600">
                    <ErrorMessage name="gstNumber" />
                  </p>
                </FormGroup> */}
                <hr />
                <div className="grid gap-4 lg:grid-cols-2">
                  <FormGroup>
                    <Button
                      type="button"
                      name="cancel"
                      className="bg-sky-300 hover:bg-sky-400"
                      onClick={() => handleCloseDialog()}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="submit"
                      name="update"
                      className="bg-sky-300 hover:bg-sky-400"
                    >
                      Add
                    </Button>
                  </FormGroup>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Container>
    </>
  );
};

export default AddGuestInfo;
