import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import FloorListCreate from "./FloorListCreate";
import {
  getBlockData,
  getCurrentUser,
  getFloorData,
  insertFloorData,
} from "../../api-config/config";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "floor",
    headerName: "Floor Name",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: true,
  },
  {
    field: "rooms",
    headerName: "Rooms",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "action",
    headerName: "Action",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

function FloorListGrid(props: any) {
  const [open, setOpen] = React.useState(false);
  const [floorTitle, setFloorTitle] = React.useState("");
  const { row, selectedBlock, floorSubmitted } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    insertFloorData(selectedBlock.id, floorTitle).then((response: any) => {
      if (response.status_message === "failed") {
        alert(response.message);
      }
      floorSubmitted();
      handleClose();
    });
  };

  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        floor: item.floor_name,
        rooms: "",
        action: "",
        status: item.is_active,
      });
    });
  }
  return (
    <Box
      sx={{
        height: 164,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add a Floor
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Floor(S)
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Floor List</h3>
      </Box>

      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add Floor</DialogTitle>
        <DialogContent sx={{ minWidth: "500px" }}>
          <DialogContentText>
            <Grid container>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Block"
                  disabled
                  value={selectedBlock.block_name}
                  variant="outlined"
                  sx={{ margin: "5px 0" }}
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={floorTitle}
                  onChange={(e) => setFloorTitle(e.target.value)}
                  sx={{ margin: "5px 0" }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit()}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* <FloorListCreate /> */}
    </Box>
  );
}

export default function FloorList() {
  const [floorList, setfloorList] = useState();
  const [selectedBlock, setSelectedBlock] = useState({} as any);
  const [selectedBlockId, setSelectedBlockId] = useState(0);
  const [blockList, setBlockList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const getfloorList = (block_id: number) => {
    return getFloorData(block_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setfloorList(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  function handleBlockChange(value: any) {
    const block = blockList.find((block: any) => block.id === Number(value));
    if (block) {
      setSelectedBlock({ id: block.id, block_name: block.block_name });
    }
    console.log({ id: block.id, block_name: block.block_name });
  }
  function getBlockList(hotel_code: any) {
    getBlockData(hotel_code).then((response: any) => {
      setBlockList([...response.data]);
      if (response.data.length) {
        setSelectedBlock({
          ...blockList,
          id: response.data[0].id,
          block_name: response.data[0].block_name,
        });
      }
    });
  }
  function onFloorAdded() {
    getfloorList(selectedBlock.id);
  }
  useEffect(() => {
    getBlockList(loggedUser?.hotel_code);
  }, []);
  useEffect(() => {
    if (selectedBlock.id) {
      setSelectedBlockId(selectedBlock.id);
      getfloorList(selectedBlock.id);
    }
  }, [selectedBlock]);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Floor List
          </Typography>
          <div className="flex justify-between p-2">
            <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
              <div className="border">
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  className="w-64"
                  value={selectedBlockId || 0}
                  onChange={(e) => handleBlockChange(e.target.value)}
                  autoWidth
                  label="Age"
                >
                  <MenuItem value="0">Select Block</MenuItem>
                  {blockList.map((list: any, key: any) => {
                    return (
                      <MenuItem
                        value={list.id}
                        sx={{ minWidth: 200 }}
                        key={key}
                      >
                        {list.block_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Stack>
          </div>
          <FloorListGrid
            row={floorList}
            selectedBlock={selectedBlock}
            floorSubmitted={onFloorAdded}
          />
        </Container>
      </Page>
    </>
  );
}
