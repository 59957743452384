import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../components/Page";
import {
  getCurrentUser,
  getLastNightAuditDate,
  getNightAudit,
  insertCashierDaliyPaymentData,
  performNightAudit,
} from "../api-config/config";
import AddFeatureForm from "../components/Features/AddFeatureForm";
import { useNavigate } from "react-router-dom";
import CashCounter from "./CashCounter";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const countersColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "counter",
    headerName: "Counter",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "user_name",
    headerName: "User Name",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "employee_id",
    headerName: "Employee ID",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pending_withdrawals",
    headerName: "Pending Withdrawals",
    width: 400,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
];

const infoColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "third_party_connection",
    headerName: "Third Party Connection",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
];

function InfoGrid(data: any) {
  const { row, featureAdded } = data;

  const auditFields: any[] = [
    // {
    //   id: 1,
    //   third_party_connection: "Default Counter",
    //   action: null
    // }
  ];

  return (
    <Box
      sx={{
        height: 200,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        {/* <h3 style={{ marginTop: -2 }}>Account Details</h3> */}
      </Box>
      <DataGrid
        rows={auditFields}
        columns={infoColumns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function NightAudit() {
  const [auditList, setAudititList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const navigate = useNavigate();
  const [openCounter, setOpenCounter] = useState(false);
  const [nightAuditDate, setNightAuditDate] = useState(new Date());
  const [calendarValue, setCalendarValue] = useState(new Date());
  // const [ calendarValue, setCalendarValue ] = useState(new Date(Date.now() - 86400000));
  const handleChange = (newValue: any) => {
    getNightAuditList(loggedUser?.hotel_code);
    const date: any = new Date(newValue).toISOString().slice(0, 10);
    console.log(date);
    // console.log(date);
    setCalendarValue(newValue);
  };

  const handleSubmitCashierData = async (formData: any) => {
    console.log(formData, loggedUser);
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      date: calendarValue.toISOString().slice(0, 10),
      select_action: formData.select_action,
      booking_id: formData.booking_id,
    };
    await insertCashierDaliyPaymentData(payload).then((response: any) => {
      console.log(response);
      // onFeatureAdded();
      // handleClose();
    });
  };
  function AccountDetailsGrid(data: any) {
    const countersColumns: GridColDef[] = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "counter",
        headerName: "Counter",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "user_name",
        headerName: "User Name",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "employee_id",
        headerName: "Employee ID",
        width: 200,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "pending_withdrawals",
        headerName: "Pending Withdrawals",
        width: 400,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 500,
        headerClassName: "super-app-theme--header",
        renderCell: (params: GridRenderCellParams<number>) => (
          <div className="flex justify-between w-[100%]">
            <Button
              className="w-[30%]"
              onClick={() => {
                navigate("/main/cashcounter");
                // setOpenCounter(true)
              }}
            >
              Close & Withdraw
            </Button>
            <Button
              className="w-[30%]"
              onClick={() => alert("This will be ignored!")}
            >
              Ignore
            </Button>
            {/* <Button
                className="w-[30%]"
                onClick={() => setOpenCounter(true)}
              >
                No Show
              </Button> */}
          </div>
        ),
      },
    ];
    const auditFields: any[] = [];
    if (data?.cash_counter_details) {
      console.log("hi");
    }
    console.log(data);
    const list: any = data?.row?.cash_counter_details;
    // const list: any[] = (obj);
    console.log("===> this is the list to be shown", list);
    list?.forEach((el: any, i: any) => {
      auditFields.push({
        id: i + 1,
        counter: el.counter,
        user_name: el.user_name,
        employee_id: el.employee_id,
        pending_withdrawals: el.pending_withdrawals,
        // from_date: el.from_date,
        // balance: el.balance_amount,
      });
    });

    console.log(auditFields);
    // const auditFields: any[] = [
    // {
    //   id: 1,
    //   counter: "Default Counter",
    //   user_name: "Sumant Hegde",
    //   employee_id: "657384",
    //   pending_withdrawals: 172.5,
    //   actions: "close & withdraw",
    // }
    // ];

    return (
      <Box
        sx={{
          height: 200,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          {/* <h3 style={{ marginTop: -2 }}>Account Details</h3> */}
        </Box>
        <DataGrid
          rows={auditFields}
          columns={countersColumns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          // checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "booking_id",
      headerName: "Booking Id",
      headerClassName: "super-app-theme--header",
      width: 100,
    },
    {
      field: "first_name",
      headerName: "Guest Name",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "room_type",
      headerName: "Room Type",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "room_number",
      headerName: "Room Number",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "from_date",
      headerName: "Check In/ Check Out",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 600,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params: GridRenderCellParams<number>) => (
    //     <div className="flex justify-between w-[100%]">
    //       <Button
    //         className="w-[25%]"
    //         onClick={() => setOpenCounter(true)}
    //       >
    //         Check Out
    //       </Button>
    //       <Button
    //         onClick={() => handleSubmitCashierData({ select_action: "check_in", booking_id: params.row.booking_id})}
    //       >
    //         Check-in
    //       </Button>
    //       <Button
    //         onClick={() => handleSubmitCashierData({ select_action: "no_show", booking_id: params.row.booking_id})}
    //       >
    //         No Show
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const guestCheckOutColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "booking_id",
      headerName: "Booking Id",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "first_name",
      headerName: "Guest Name",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "room_type",
      headerName: "Room Type",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "room_number",
      headerName: "Room Number",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "from_date",
      headerName: "Check In/ Check Out",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params: GridRenderCellParams<number>) => (
        <div className="flex justify-between w-[100%]">
          {/* <Button
            className="w-[30%]"
            onClick={() => setOpenCounter(true)}
          >
            Close & Withdraw
          </Button> */}
          <Button
            className="w-[30%]"
            onClick={async () => {
              // await handleSubmitCashierData({ select_action: "check_in", booking_id: params.row.booking_id})
              // getNightAuditList(loggedUser?.hotel_code)
              navigate("/main/home", {
                state: {
                  data: params.row,
                },
              });
              console.log(params);
            }}
          >
            Check-in
          </Button>
          <Button
            className="w-[30%]"
            onClick={async () => {
              // await handleSubmitCashierData({ select_action: "no_show", booking_id: params.row.booking_id})
              // getNightAuditList(loggedUser?.hotel_code)
            }}
          >
            No Show
          </Button>
        </div>
      ),
    },
  ];

  function NightAuditGrid(data: any) {
    const auditFields: any[] = [];
    if (data?.list_of_check_outs_due) {
      console.log("hi");
    }
    console.log(data);
    const list: any = data?.row?.list_of_check_outs_due.filter(
      (el: any) => el.booking_status === "check_in"
    );
    // const list: any[] = (obj);
    console.log("===> this is the list to be shown", list);
    list?.forEach((el: any, i: any) => {
      auditFields.push({
        id: i + 1,
        booking_id: el.booking_id,
        first_name: el.first_name,
        room_type: el.room_type,
        room_number: el.room_number,
        from_date: el.from_date,
        balance: el.balance_amount,
      });
    });

    console.log(auditFields);

    return (
      <Box
        sx={{
          height: 250,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          {/* <h3 style={{ marginTop: -2 }}>Night Audit</h3> */}
        </Box>
        <DataGrid
          rows={auditFields}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          // checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }

  function NightAuditGridCheckOut(data: any) {
    const auditFields: any[] = [];
    if (data?.list_of_check_outs_due) {
      console.log("hi");
    }
    console.log(data);
    const list: any = data?.row?.list_of_check_outs_due.filter(
      (el: any) => el.booking_status === "check_out"
    );
    // const list: any[] = (obj);
    console.log("===> this is the list to be shown", list);
    list?.forEach((el: any, i: any) => {
      auditFields.push({
        id: i + 1,
        booking_id: el.booking_id,
        first_name: el.first_name,
        room_type: el.room_type,
        room_number: el.room_number,
        from_date: el.from_date,
        balance: el.balance_amount,
      });
    });

    console.log(auditFields);

    return (
      <Box
        sx={{
          height: 250,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          {/* <h3 style={{ marginTop: -2 }}>Night Audit</h3> */}
        </Box>
        <DataGrid
          rows={auditFields}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          // checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }

  function GuestCheckoutGrid(data: any) {
    const auditFields: any[] = [];
    if (data?.list_of_check_outs_due) {
      console.log("hi");
    }
    console.log(data);
    const list: any = data?.row?.list_of_check_outs_due.filter(
      (el: any) => el.booking_status === "pending"
    );
    // const list: any[] = (obj);
    console.log("===> this is the list to be shown", list);
    list?.forEach((el: any, i: any) => {
      auditFields.push({
        id: i + 1,
        booking_id: el.booking_id,
        first_name: el.first_name,
        room_type: el.room_type,
        room_number: el.room_number,
        from_date: el.from_date,
        balance: el.balance_amount,
      });
    });

    return (
      <Box
        sx={{
          height: 250,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          {/* <h3 style={{ marginTop: -2 }}>House Keeping Detals</h3> */}
        </Box>
        <DataGrid
          rows={auditFields}
          columns={guestCheckOutColumns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          // checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser);
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      name: formData?.name,
      online: formData?.online,
      card: formData?.card,
      upi: formData?.upi,
      cash: formData?.cash,
      wallet: formData?.wallet,
      total_payment: formData?.total_payment,
      date: formData?.date,
    };
    insertCashierDaliyPaymentData(payload).then((response: any) => {
      console.log(response);
      // onFeatureAdded();
      // handleClose();
    });
  };
  const getNightAuditList = (hotel_code: number) => {
    return getNightAudit({
      hotel_code,
      // data: (new Date(`${calendarValue.year()}-${calendarValue.month()}-${calendarValue.date()}`)),
      date: calendarValue.toISOString().slice(0, 10),
      // data: new Date().toString().slice(0, 10),
    }).then(
      (res: any) => {
        // if (res?.status_message === "success") {
        console.log("===> this is the response", res);
        setAudititList(res.data);
        // } else {
        //   console.log(res.message);
        // }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getNightAuditList(loggedUser?.hotel_code);
    getLastNightAuditDate(loggedUser?.hotel_code).then((res) => {
      setNightAuditDate(
        new Date(
          new Date(res.data.last_night_audit_last_date).getTime() + 86400000
        )
      );
      setCalendarValue(
        new Date(
          new Date(res.data.last_night_audit_last_date).getTime() + 86400000
        )
      );
    });
  }, []);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3} sx={{ margin: 1 }}>
              <DesktopDatePicker
                label=""
                inputFormat="MM/DD/YYYY"
                maxDate={nightAuditDate}
                // maxDate={new Date(Date.now() - 86400000)}
                value={calendarValue}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Perform Night Audit for {calendarValue?.toISOString().slice(0, 10)}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              color: "#000000",
              textAlign: "center",
              marginLeft: 20,
              marginTop: 25,
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Please resolve the following issues to remove discrepencies and
            complete Night Audit for {calendarValue?.toISOString().slice(0, 10)}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 25,
              fontSize: 16,
            }}
          >
            Please update to continue:
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              color: "#000000",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            (
            <i>
              This will complete the Night Audit and remove any possible
              discrepencies
            </i>
            )
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 25,
              fontSize: 16,
            }}
          >
            1. List of Check-ins on {calendarValue?.toISOString().slice(0, 10)}{" "}
            - 0 Please take correct action:
          </Typography>
          {auditList?.list_of_check_outs_due && (
            <NightAuditGrid row={auditList} />
          )}
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 80,
              fontSize: 16,
            }}
          >
            2. List of Check-outs on {calendarValue?.toISOString().slice(0, 10)}{" "}
            - 0 Please take correct action:
          </Typography>
          {auditList?.list_of_check_outs_due && (
            <NightAuditGridCheckOut row={auditList} />
          )}
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 80,
              fontSize: 16,
            }}
          >
            3. List of Guest Check-ins due on{" "}
            {calendarValue?.toISOString().slice(0, 10)} - 0 Please take correct
            action:
          </Typography>
          {auditList?.list_of_check_outs_due && (
            <GuestCheckoutGrid row={auditList} />
          )}
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 80,
              fontSize: 16,
            }}
          >
            4. List of Counters not closed on{" "}
            {calendarValue?.toISOString().slice(0, 10)} - 0 Please take correct
            action:
            <br />
            <span className="text-sm text-[black]">
              <i>( Note- If you choose to ignore the balance carry forward )</i>
            </span>
          </Typography>
          <AccountDetailsGrid row={auditList} />
          {/* <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              marginTop: 80,
              fontSize: 16,
            }}
          >
            5. Information synced to Third Party on{" "}
            {calendarValue?.toISOString().slice(0, 10)} - 0 Please take correct action:
          </Typography>
          <InfoGrid row={auditList} /> */}
          <div className="mt-12 w-[80%] m-auto">
            <div className="flex w-[75%] mt-4 justify-evenly m-auto">
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b]"
                onClick={() => navigate("/main/home")}
              >
                Return To Frontdesk
              </Button>
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b]"
                onClick={async () => {
                  // await performNightAudit({
                  //   hotel_code: loggedUser?.hotel_code,
                  //   audit_date: (new Date()).toISOString().slice(0, 10)
                  // })
                  navigate("/main/nightauditsummary", {
                    state: {
                      date: calendarValue?.toISOString().slice(0, 10),
                    },
                  });
                }}
              >
                Continue Night Audit
              </Button>
            </div>
          </div>
        </Container>
        <Dialog
          open={openCounter}
          onClose={() => setOpenCounter(false)}
          sx={{
            width: "calc(100% - 200px)",
            margin: "auto",
          }}
        >
          {/* <DialogTitle>Guest Info</DialogTitle> */}
          <DialogContent sx={{ minWidth: "850px", textAlign: "center" }}>
            <CashCounter
              date={calendarValue.toISOString().slice(0, 10)}
              setOpenCounter={setOpenCounter}
            />
          </DialogContent>
        </Dialog>
      </Page>
    </>
  );
}
