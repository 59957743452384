import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import {
  getCurrentUser,
  getFeaturesList,
  getRoomType,
} from "../../api-config/config";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Iconify from "../../components/Iconify";
import AddFeatureForm from "../../components/Features/AddFeatureForm";

const label = { inputProps: { "aria-label": "Checkbox" } };

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: "Features",
    headerClassName: "super-app-theme--header",
    width: 150,
    // editable: true,
  },
  {
    field: "image",
    headerName: "Image",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },

  // {
  //   field: "floor_id",
  //   headerName: "Actions",
  //   width: 100,
  //   editable: true,
  //   headerClassName: "super-app-theme--header",
  // },
  // {
  //   field: "block",
  //   headerName: "Status",
  //   width: 100,
  //   editable: true,
  //   headerClassName: "super-app-theme--header",
  // },
];

function FeaturesListGrid(data: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [base, setBase] = React.useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = React.useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = React.useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const { row, featureAdded } = data;
  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        name: item.name,
        image: item.image,
        // roomtype: item.room_type_name,
      });
    });
  }

  function onFeatureAdded() {
    featureAdded();
  }

  return (
    <Box
      sx={{
        height: 265,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add Feature
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Feature(S)
          </Button>
          {/* <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Save Short Order
          </Button> */}

          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main | Features List</h3>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add Feature</DialogTitle>
        <DialogContent sx={{ minWidth: "580px" }}>
          <h4 style={{ marginTop: -2 }}>
            Please Enter the Feature Details below.
          </h4>
          <AddFeatureForm
            handleClose={handleClose}
            onFeatureAdded={onFeatureAdded}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default function FeaturesList() {
  const [featureList, setFeatureList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const getFeatureList = async () => {
    const result = await getFeaturesList({
      id: 1,
      room_type_id: 9,
    });
    setFeatureList(result.data);
  };
  // const getRoomList = (hotel_code: number, floor_id?: number) => {
  //   return getRoomType(hotel_code, floor_id).then(
  //     (res: any) => {
  //       if (res?.status_message === "success") {
  //         const features = [] as any;
  //         res.data?.forEach((roomType: any) => {
  //           roomType.rooms?.forEach((room: any) => {
  //             room.room_type_name = roomType.room_type_name;
  //           })
  //           features.push(...roomType.rooms);
  //         })
  //         console.log(features);
  //         setFeatureList([...features]);
  //       } else {
  //         console.log(res.message);
  //       }
  //     },
  //     (error: any) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //       console.log("error", resMessage);
  //     }
  //   );
  // };
  // useEffect(() => {
  //   getRoomList(loggedUser?.hotel_code);
  // }, []);
  // function onFeatureAdded() {
  //   getRoomList(loggedUser?.hotel_code);
  // }
  // function onFeatureAdded() {
  //   getFeatureList();
  // }
  useEffect(() => {
    getFeatureList();
  }, []);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Features List
          </Typography>
          <FeaturesListGrid row={featureList} featureAdded={getFeatureList} />
        </Container>
      </Page>
    </>
  );
}
