import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../api-config/config";

export default function BasicMenu(props) {
  const { isOpenContextMenu, targetElement, selectedEventInfo, onHandleClose } =
    props;
  const navigate = useNavigate();

  const handleClose = () => {
    onHandleClose();
  };
  function onFolioClick() {
    navigate("/main/folio", {
      state: {
        booking_id: selectedEventInfo.event._def.title.split("_")[0],
      },
    });
    handleClose();
  }
  function onPaymentFolioClick() {
    navigate("/main/foliodetails", {
      state: {
        booking_id: selectedEventInfo.event._def.title.split("_")[0],
      },
    });
    handleClose();
  }
  function onRegistrationCardClick() {
    window.open(
      `${baseUrl}/reg_card_pms?booking_id=${selectedEventInfo?.event?._def?.extendedProps?.guestBasicInfo?.booking_id}`
    );
    console.log(selectedEventInfo, "this is selected event info---------");
  }
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={targetElement}
        open={isOpenContextMenu}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onFolioClick()}>Guest Folio</MenuItem>
        <MenuItem onClick={() => onPaymentFolioClick()}>Payment Folio</MenuItem>
        <MenuItem onClick={() => onRegistrationCardClick()}>
          Registration Card
        </MenuItem>
      </Menu>
    </div>
  );
}
