import { useState, useEffect } from "react";
import { getCurrentUser, getManagerFlashReport } from "../api-config/config";
import { Container, Typography, Stack } from "@mui/material";
import { Page } from "../components/Page";
import ManagerReport from "../components/ManagerReport/ManagerReport";
import LoaderDotted from "../components/LoaderDotted/LoaderDotted";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function ManagerFlashReport() {
  const loggedUser = getCurrentUser();
  const dateNow = new Date();
  const oneDayInMillis = 86400 * 1000;
  const [range, setRange] = useState({
    startDate: new Date(dateNow.getTime() - oneDayInMillis),
    endDate: new Date(),
    key: "selection",
  });
  const [loaderT, setLoaderT] = useState(false);
  const [flashData, setFlashData] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    moment().utcOffset("+05:30").toDate()
  );

  const fetchFlashReport = (params: object) => {
    setLoaderT(true);
    getManagerFlashReport(params).then((response: any) => {
      setFlashData(response.data[0]);
      setLoaderT(false);
    });
  };

  useEffect(() => {
    fetchFlashReport({
      pms_hotel_code: loggedUser?.hotel_code,
      start_date: range.startDate.toISOString().slice(0, 10),
      end_date: range.endDate.toISOString().slice(0, 10),
    });
  }, []);

  const handleDateChange = (date: any) => {
    // Allow only current and past dates
    const currentDate = moment();
    if (date <= currentDate) {
      setSelectedDate(date);
    }
  };

  const handleSearch = () => {
    const momemntDate = moment(selectedDate).format("YYYY-MM-DD");
    setRange({
      startDate: moment(selectedDate).toDate(),
      endDate: moment(selectedDate).add(1, "day").toDate(),
      key: "selection",
    });
    fetchFlashReport({
      pms_hotel_code: loggedUser?.hotel_code,
      start_date: momemntDate,
      end_date: moment(selectedDate).add(1, "day").format("YYYY-MM-DD"),
    });
  };
  // const [data, setData] = useState([]);
  // function onSetRange(value: any) {
  //   console.log(value);
  //   setRange(value);
  // }

  // function searchWithDateRange() {
  //   const startDateString = `${range.startDate.getFullYear()}-${
  //     range.startDate.getMonth() + 1
  //   }-${range.startDate.getDate()}`;
  //   const endDateString = `${range.endDate.getFullYear()}-${
  //     range.endDate.getMonth() + 1
  //   }-${range.endDate.getDate()}`;
  //   getSettleBills(loggedUser?.hotel_code, startDateString, endDateString).then(
  //     (res) => setData(res.data)
  //   );
  // }
  // useEffect(() => {
  //   getSettleBills(
  //     loggedUser?.hotel_code,
  //     range.startDate.toISOString().slice(0, 10),
  //     range.endDate.toISOString().slice(0, 10)
  //   ).then((res) => setData(res.data));
  // }, []);
  if (loaderT) {
    return <LoaderDotted />;
  } else {
    return (
      <>
        <p className="text-center text-xl text-blue-800 font-semibold">
          Manager Flash Report For {range.startDate.toISOString().slice(0, 10)}
        </p>
        <div className="text-md mt-12">
          <p>{loggedUser?.hotel_name}</p>
          <p>{`${loggedUser?.city}, ${loggedUser?.state}, ${loggedUser?.country}`}</p>
          <p>{`Phone : ${loggedUser?.mobile_number}`}</p>
          <p>{`Fax No : ${loggedUser?.billing_fax_number}`}</p>
          <p>{`Email : ${loggedUser?.email_id}`}</p>
          <p>{`Website : ${loggedUser?.website_link}`}</p>
        </div>
        <>
          <Page title="Home">
            <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
              <div
                style={{
                  // border: "1px solid black",

                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  showIcon
                  withPortal
                  selected={selectedDate}
                  onChange={handleDateChange}
                  maxDate={moment().utcOffset("+05:30").toDate()} // Restrict future dates
                  className="my-calendar"
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
              <div className="flex-col p-2">
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "#0323f8",
                    marginLeft: 20,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Manager Flash Report Of{" "}
                  {` ${range.startDate.toISOString().slice(0, 10)}`}
                </Typography>
                <div className="flex justify-start mx-4">
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: "start" }}
                  ></Stack>
                </div>
              </div>
              <hr />

              <ManagerReport report={flashData} />
            </Container>
          </Page>
        </>
      </>
    );
  }
}
