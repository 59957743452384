import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import { Page } from "../../components/Page";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from "@mui/material/IconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import AmenitiesCreate from "./AmenitiesCreate";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "amenity",
    headerName: "Amenities",
    headerClassName: "super-app-theme--header",
    width: 240,
    editable: true,
  },
  {
    field: "descript",
    headerName: "Description",
    width: 270,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "action",
    headerName: "Action",
    width: 240,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

const rows = [
  {
    id: 1,
    amenity: "Light",
    descript: "Light",
    action: "Edit",
    status: "",
  },
];

const currencies = [
  {
    value: "Blue",
    label: "Blue",
  },
  {
    value: "Red",
    label: "Red",
  },
  {
    value: "Black",
    label: "Black",
  },
  {
    value: "White",
    label: "White",
  },
];

function AmenitiesListGrid() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const methods = useForm({});

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <Box
      sx={{
        height: 164,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "65px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add an Amenity
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Amenities
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Amenities List</h3>
      </Box>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog open={open} onClose={handleClose} sx={{
        width: 'calc(100% - 200px)',
        margin: 'auto'
      }}>
        <DialogTitle>Add Amenity</DialogTitle>
        <DialogContent sx={{ minWidth: "500px" }}>
          <DialogContentText>
            <Grid container>
              <Grid item sm={12} md={12} lg={12}>
                {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Amenities"
                  variant="outlined"
                  sx={{ margin: "5px 0" }}
                />
                <br></br>
                <TextField
                  fullWidth
                  id="filled-basic"
                  label="upload Images"
                  variant="outlined"
                  sx={{ margin: "5px 0" }}
                />
                <br></br>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Icon"
                  defaultValue="EUR"
                  sx={{ margin: "5px 0", height: "50px" }}
                  helperText="Please select color"
                >
                  {currencies.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ height: "50px" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br></br>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  sx={{ margin: "5px 0" }}
                // defaultValue="Description"
                />
                <br></br>
                {/* <LoadingButton
                  size="large"
                  type="submit"
                  sx={{ background: "#0323f8" }}
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
                <LoadingButton
                  size="large"
                  type="submit"
                  sx={{ background: "#0323f8" }}
                  variant="contained"
                  loading={isSubmitting}
                >
                  Cancel
                </LoadingButton> */}
                {/* </FormProvider> */}
              </Grid>
            </Grid>
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Create Amenities
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <AmenitiesCreate />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> *
      </BootstrapDialog> */}
    </Box >
  );
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AmenitiesList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Amenities List
          </Typography>
          <AmenitiesListGrid />
        </Container>
      </Page>
    </>
  );
}
