export const calcDiscountValue = (selectedEventInfo) => {
  let discountValue = 0;
  if (
    selectedEventInfo.event._def.extendedProps.guestBasicInfo.discount_type ==
    "Percentage"
  ) {
    discountValue = Math.round(
      (selectedEventInfo.event._def.extendedProps.guestBasicInfo
        .discount_value /
        selectedEventInfo.event._def.extendedProps.guestBasicInfo
          .total_sale_amount) *
        100
    );
  } else if (
    selectedEventInfo.event._def.extendedProps.guestBasicInfo.discount_type ==
    "Amount"
  ) {
    discountValue =
      selectedEventInfo.event._def.extendedProps.guestBasicInfo.discount_value;
  }

  return discountValue;
};

export function findRoomInfoAndSetPrice(
  data,
  discountObj,
  initBookingData,
  originalPrice,
  calendarInfo
) {
  const priceObj = {};
  let price = originalPrice;
  console.log("===> crazy logic? edit reservation", initBookingData);
  console.log("called main function");
  data?.rooms?.forEach((roomInfo, key) => {
    if (initBookingData.room_type_id === roomInfo.room_type_id) {
      
      price = originalPrice;
      priceObj.total_sale_price = price;
      priceObj.service_charge_in_percentage = roomInfo.service_charge;
      // console.log("===> this is the priceObj", priceObj);
      let discountAmount = 0;
      if (
        discountObj &&
        discountObj.discountMethod &&
        discountObj.discountValue
      ) {
        if (discountObj.discountMethod === "Percentage") {
          discountAmount = (price * discountObj.discountValue) / 100;
        } else if (discountObj.discountMethod === "Amount") {
          discountAmount = discountObj.discountValue;
        }
        price = price - discountAmount;
        priceObj.discountAmount = discountAmount;
        priceObj.total_without_service = price;
      } else {
        price = price - discountAmount;
        priceObj.discountAmount = discountAmount;
        priceObj.total_without_service = price;
      }
      const room_service_charge = roomInfo.service_charge
        ? (price * roomInfo.service_charge) / 100
        : 0;
      price = price + room_service_charge;
      priceObj.room_service_charge = room_service_charge;
      priceObj.total_without_tax = price;
      // roomInfo.tax_info.forEach((taxInfoObj, key) => {
      //   if (
          
      //     price / calendarInfo.noOfNights >= taxInfoObj.min_amount &&
      //     price / calendarInfo.noOfNights <= taxInfoObj.max_amount
      //   ) {
      //     // const tax = 0;
      //     const tax = price * taxInfoObj.tax_value / 100;
      //     priceObj.taxInfo = taxInfoObj;
      //     priceObj.total_tax = tax;
      //     // priceObj.total_tax = 0;
      //     price = price + tax;
      //     priceObj.total_price_with_tax = price;
      //   } else if  (price / calendarInfo.noOfNights > 7500){
      //     let tax = 0;
      //     tax = (price * 18) / 100;
      //     priceObj.total_tax = tax;
      //     price = price + tax;
      //     priceObj.total_price_with_tax = price;
      //   } else {
      //     let tax = 0;
      //     tax = (price * 12) / 100;
      //     priceObj.total_tax = tax;
      //     price = price + tax;
      //     priceObj.total_price_with_tax = price;
      //   }
      // });
       if  (price / calendarInfo.noOfNights > 7500){
            let tax = 0;
            tax = (price * 18) / 100;
            priceObj.total_tax = tax;
            price = price + tax;
            priceObj.total_price_with_tax = price;
          } else {
            let tax = 0;
            tax = (price * 12) / 100;
            priceObj.total_tax = tax;
            price = price + tax;
            priceObj.total_price_with_tax = price;
          }
    }
  });
  console.log(priceObj, "sudeep");
  return priceObj;
}
