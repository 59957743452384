// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { DetailsProvider } from "./contexts/DetailsContext";
import { RoomContextProvider } from "./contexts/RoomContext";
import api from "./api-config/axiosInstance";

const initializeApp = async () => {
  const checkSessionStatus = async () => {
    try {
      const response = await api.get("/session_status", {});

      if (response.status === 200) {
        const data = response.data;
        return data.msg === "Session is active";
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking session status:", error);
      return false;
    }
  };

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  if (token && refreshToken) {
    const isSessionActive = await checkSessionStatus();

    if (!isSessionActive) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
    }
  }

  const root = ReactDOM.createRoot(document.getElementById("root")!);

  root.render(
    <HelmetProvider>
      <DetailsProvider>
        <BrowserRouter>
          <RoomContextProvider>
            <App />
          </RoomContextProvider>
        </BrowserRouter>
      </DetailsProvider>
    </HelmetProvider>
  );

  // If you want to enable client cache, register instead.
  serviceWorker.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(null);
};

initializeApp();
