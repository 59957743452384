import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

function DiscrepnacyReport() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Room No", width: 100 },
    { field: "roomType", headerName: "Room Type", width: 130 },
    { field: "sellingstatusFO", headerName: "Selling Status(FO)", width: 140 },
    { field: "sellingstatusHK", headerName: "Selling Status(HK)", width: 140 },
    { field: "paxFO", headerName: "PAX(FO)", width: 100 },
    { field: "paxHK", headerName: "PAX(HK)", width: 100 },
  ];

  const rows = [
    {
      id: 1,
      roomType: "Snow",
      sellingstatusFO: "VACANT",
      sellingstatusHK: "VACANT",
      paxFO: "...",
      paxHK: "...",
    },
    {
      id: 2,
      roomType: "Lannister",
      sellingstatusFO: "VACANT",
      sellingstatusHK: "VACANT",
      paxFO: "...",
      paxHK: "...",
    },
    {
      id: 3,
      roomType: "Lannister",
      sellingstatusFO: "VACANT",
      sellingstatusHK: "VACANT",
      paxFO: "...",
      paxHK: "...",
    },
    {
      id: 4,
      roomType: "Stark",
      sellingstatusFO: "VACANT",
      sellingstatusHK: "VACANT",
      paxFO: "...",
      paxHK: "...",
    },
  ];
  return (
    <>
      <div className="font-bold text-lg mb-16">Discrepancy Report</div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
    </>
  );
}

export default DiscrepnacyReport;
