import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer, Link, Grid, Avatar, Chip } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import Logo from "../components/Logo";
import { Scrollbar } from "../components/Scrollbar";
import NavSection from "../components/NavSection";
import navConfig from "./admin-dashboard/NavConfig";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import navConfigReport from "./admin-dashboard/NavConfigReport";
import NestedNav from "./admin-dashboard/NavConfigReport";

const DRAWER_WIDTH = 220;
const listDot = { display: "list-item" };
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

export default function AdminDashboardSidebar(props: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const [checkLocation, setCheckLocation] = React.useState("");
  const isDesktop = useResponsive("up", "lg", "xs", "lg");
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs("2014-08-18T21:11:54")
  );
  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };
  const val = true;
  useEffect(() => {
    if (props.isOpenSidebar) {
      props.onCloseSidebar();
    }
    setCheckLocation(window.location.pathname);
  }, [pathname, window.location.pathname]);
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const renderContent = (
    <Scrollbar
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo sx={[]} />
      </Box>
      {!checkLocation.includes("adminReports") && (
        <div style={val ? { display: "block" } : { display: "block" }}>
          <Box>
            <h4 style={{ marginLeft: 20, marginTop: 0 }}>Room Operations</h4>
            <NavSection navConfig={navConfig} />
          </Box>
        </div>
      )}
      {checkLocation.includes("adminReports") && (
        <div style={val ? { display: "block" } : { display: "none" }}>
          <Box>
            <NavSection navConfig={NestedNav} />
          </Box>
        </div>
      )}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={props.isOpenSidebar}
          onClose={props.onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
