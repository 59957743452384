import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Tooltip,
  listItemSecondaryActionClasses,
} from "@mui/material";
import { Page } from "../components/Page";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { getCheckInOrders, getCurrentUser } from "../api-config/config";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { voucherView } from "../utils/miscellaneous";
import moment from "moment";

function CheckInListGrid() {
  const [checkinlist, setCheckinlist] = useState([] as any);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = moment(range.startDate).format("YYYY-MM-DD");
    const endDateString = moment(range.endDate).format("YYYY-MM-DD");
    fetchCheckinOrder(startDateString, endDateString);
  }
  const loggedUser = getCurrentUser();
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth - 1),
      end: eDate.setMonth(currentMonth + 1),
    };
  }
  function isDateInRange(dateToCheck: any, startDate: any, endDate: any) {
    const checkDate = new Date(dateToCheck);
    checkDate.setHours(checkDate.getHours() - 5);
    checkDate.setMinutes(checkDate.getMinutes() - 30);
    const rangeStartDate = new Date(startDate);
    const rangeEndDate = new Date(endDate);

    return checkDate >= rangeStartDate && checkDate <= rangeEndDate;
  }
  function getRows(data: any) {
    const allRows = [] as any;
    let id = 1;
    data.forEach((listData: any, key: any) => {
      if (
        listData.booking_status === "check_in" ||
        listData.booking_status === "check_out"
      ) {
        if (
          isDateInRange(
            listData.check_in_time,
            range.startDate,
            moment(range.endDate).add(1, "days").valueOf()
          )
        ) {
          const date1 = new Date(listData.from_date);
          const month1 = date1.toLocaleString("default", { month: "short" });
          const date2 = new Date(listData.to_date);
          const month2 = date2.toLocaleString("default", { month: "short" });
          const diffDays = date2.getDate() - date1.getDate();
          const obj = {
            id: id++,
            resId: listData.booking_id,
            guestName: listData.guest_first_name,
            guestEmail: listData.email,
            guestMobile: listData.mobile_number,
            stayDuration: `${date1.getDate()} ${month1} - ${date2.getDate()} ${month2}(${diffDays})`,
            roomType: listData.room_booking_info.room_name,
            pax: `${listData?.no_of_adults}(A)0(C)`,
            status: listData.booking_status,
            amount: listData.gross_amount,
            guest_id: listData?.guest_id,
          };
          allRows.push(obj);
        }
      }
    });
    setDynamicRows([...allRows]);
    console.log("dynamicRows", dynamicRows);
  }
  function fetchCheckinOrder(startDateString: any, endDateString: any) {
    getCheckInOrders(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setCheckinlist(response.data);
      getRows(response.data);
    });
  }

  useEffect(() => {
    const getObj = getDateRange(new Date(), new Date());
    const start = new Date(getObj.start);
    const end = new Date(getObj.end);
    const startDateString = moment(start).format("YYYY-MM-DD");
    const endDateString = moment(end).format("YYYY-MM-DD");
    fetchCheckinOrder(startDateString, endDateString);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "# Group ID",
      width: 90,
      headerClassName: "super-app-theme--header",
      hide: true,
    },
    {
      field: "resId",
      flex: 1,
      headerName: "Booking ID",
      headerClassName: "super-app-theme--header",
      width: 50,
      editable: false,
    },
    {
      field: "roomType",
      flex: 1,
      headerName: "Room#/Type",
      width: 140,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestName",
      flex: 1,
      headerName: "Guest Name",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestEmail",
      flex: 1.5,
      headerName: "Guest email",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestMobile",
      flex: 1,
      headerName: "Mobile",
      // width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stayDuration",
      flex: 1,
      headerName: "Stay Duration",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "pax",
      flex: 1,
      headerName: "Pax",
      width: 90,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      flex: 1,
      headerName: "Status",
      width: 110,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      flex: 1,
      headerName: "Amount",
      width: 110,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 100,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={(event) => {
              voucherView(cellValues.row);
            }}
          >
            <Button
              type="button"
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              View
            </Button>
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <Box
      sx={{
        height: "100vh",
        width: "inherit",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <p className="m-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink data={dynamicRows} filename={"checkin-list.csv"}>
            <Tooltip title="Download Excel">
              {/* <FileDownloadIcon></FileDownloadIcon> */}
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Download
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataGrid
        autoHeight
        rows={dynamicRows}
        columns={columns}
        rowsPerPageOptions={[10, 50, 100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "resId", sort: "desc" }],
          },
        }}
      />
      {/* <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Add to New Group
          </Button>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Remove From Group
          </Button>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Add to Group
          </Button>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Print
          </Button>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Close
          </Button>
        </Stack>
      </Grid> */}
    </Box>
  );
}

export default function CheckInList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Check In Report
          </Typography>
          <CheckInListGrid />
        </Container>
      </Page>
    </>
  );
}
