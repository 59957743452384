import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import { IconButton, Tooltip } from "@mui/material";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const corporate = (props: any) => {
  const { folioDetails, fetch, setAddCorporateDialogOpen } = props;
  return (
    <>
      <div className="relative ">
        <div className="grid grid-cols-1 gap-2 p-1 ">
          <div className="bg-gray-50 p-2 shadow-md">
            <div className="p-2 bg-[#1976d2]">
              <h2 className="text-white mt-0">
                <strong>Corporate Information:</strong>
              </h2>
            </div>

            {folioDetails?.corporate_id ? (
              <div>
                <div className="flex flex-row p-1">
                  <div className="font-bold basis-1/5 text-sm">
                    Corporate Name
                  </div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.corporate_name}
                  </div>
                  <div className="font-bold basis-1/5 text-sm">GSTIN/PAN</div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.GSTIN}
                  </div>
                </div>

                <div className="flex flex-row p-1">
                  <div className="font-bold basis-1/5 text-sm">Address</div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.main_office_address}
                  </div>
                  <div className="font-bold basis-1/5 text-sm">City</div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.city}
                  </div>
                </div>

                <div className="flex flex-row p-1">
                  <div className="font-bold basis-1/5 text-sm">State</div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.state}
                  </div>
                  <div className="font-bold basis-1/5 text-sm">Country</div>
                  <div className="basis-1/10 text-sm">:</div>
                  <div className="basis-1/3 ml-1 text-sm">
                    {folioDetails.corporate.country}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="absolute top-2 right-2 flex justify-end">
              <Tooltip
                title={
                  folioDetails?.corporate_id
                    ? "Add Corporate"
                    : "Edit Corporate Details"
                }
              >
                <IconButton
                  className=""
                  aria-label="edit"
                  onClick={() => setAddCorporateDialogOpen(true)}
                >
                  {folioDetails?.corporate_id ? (
                    <ModeEditRoundedIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default corporate;
