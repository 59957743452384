import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getSettleBills } from "../api-config/bill.api";
import { getCurrentUser } from "../api-config/config";
import { Button, Stack } from "@mui/material";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const columns = [
  { field: "id", headerName: "#", width: 70 },
  { field: "date", headerName: "Date", width: 130 },
  { field: "room", headerName: "Room", width: 80 },
  {
    field: "guestname",
    headerName: "Guest Name",
    width: 130,
    renderCell: (params: any) => {
      console.log(params);
      return (
        <p>
          {params.row.first_name} {params.row.last_name}
        </p>
      );
    },
  },
  {
    field: "account_id",
    headerName: "Account Code",
    width: 120,
  },
  {
    field: "invoice_number",
    headerName: "Invoice Number",
    width: 120,
  },
  {
    field: "payment_mode",
    headerName: "Payment Mode",
    width: 120,
  },
  {
    field: "corporate_name",
    headerName: "Corporate Name",
    width: 120,
  },
  {
    field: "travel_agent_name",
    headerName: "Travel Agent Name",
    width: 120,
  },
  {
    field: "description",
    headerName: "Description",
    width: 160,
  },
  {
    field: "currency",
    headerName: "Currency",
    width: 90,
  },
  {
    field: "debit",
    headerName: "Debits",
    width: 90,
  },
  {
    field: "credits",
    headerName: "Credits",
    width: 90,
  },
  {
    field: "GST",
    headerName: "GST",
    width: 90,
  },
  {
    field: "SGST",
    headerName: "SGST",
    width: 90,
  },
  {
    field: "CGST",
    headerName: "CGST",
    width: 90,
  },
  {
    field: "VAT",
    headerName: "VAT",
    width: 90,
  },
  {
    field: "service_charge",
    headerName: "Service Charge",
    width: 120,
  },
  {
    field: "user_name",
    headerName: "Username",
    width: 160,
  },
  {
    field: "discount",
    headerName: "Discount",
    width: 90,
  },
  {
    field: "extra_charge",
    headerName: "Extra Charge",
    width: 120,
  },

  {
    field: "allowance",
    headerName: "Allowance",
    width: 90,
  },
  {
    field: "refund",
    headerName: "Refund",
    width: 90,
  },
];

export default function JournalReports() {
  const loggedUser = getCurrentUser();
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const [data, setData] = useState([]);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function getRows(data: any) {
    const allRows: any = [] as any;
    let id = 1;
    data.forEach((data: any, key: any) => {
      const obj = {
        id: id++,
        invoiceNumber: data.invoice_number,
        guestname: `${data.first_name} ${data.last_name}`,
        account_code: data.account_id,
        description: data.description,
        currency: data.currency,
        debit: data.debit,
        credits: data.credits,
        GST: data.GST,
        SGST: data.SGST,
        CGST: data.CGST,
        VAT: data.VAT,
        serviceCharge: data.service_charge,
        user_name: data.user_name,
        discount: data.discount,
        extraCharge: data.extra_charge,
        allowance: data.allowance,
        refund: data.refund,
      };
      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }

  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    getSettleBills(loggedUser?.hotel_code, startDateString, endDateString).then(
      (res) => setData(res.data)
    );
  }
  useEffect(() => {
    getSettleBills(
      loggedUser?.hotel_code,
      range.startDate.toISOString().slice(0, 10),
      range.endDate.toISOString().slice(0, 10)
    ).then((res) => setData(res.data));
  }, []);
  useEffect(() => {
    getRows(data);
  }, [data]);
  return (
    <>
      <div className="font-bold text-xl mb-16 text-blue-800">Journal Report</div>
      <p className="m-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink data={dynamicRows} filename={"tax-report.csv"}>
            <Tooltip title="Download Excel">
              {/* <FileDownloadIcon></FileDownloadIcon> */}
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Download
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={data} columns={columns} checkboxSelection />
      </div>
    </>
  );
}
