/* eslint-disable no-undef */
import React from "react";
import ReactToPrint from "react-to-print";
import { ToWords } from "to-words";
import { Page } from "../components/Page";
import { Box, Container, Typography } from "@mui/material";
import {
  DataGrid
} from "@mui/x-data-grid";

// this code link

// https://codesandbox.io/embed/react-to-print-96kg7

class ComponentToPrint extends React.Component {
  // location = useLocation();
  toWords = new ToWords();
  nightAuditDets = {};
  nightAuditDate = "";
  // payment = "COD";
  // company = "Furniba";
  // customer = "Swapnil";
  // // phoneNumber = 9428333101;
  // address = (
  //   <p style={{ fontSize: 14, paddingTop: 5, margin: 0 }}>
  //     <div>
  //       <span>ADDRESS:</span>
  //       <br />
  //       customername:"{this.customer}"
  //       <br />
  //       flatnumber:"heritage elegant apartments"
  //       <br />
  //     </div>
  //     {/* <br/> */}
  //   </p>
  // );
  // addresss end
  //
  render() {
    const columns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "room_details",
        headerName: "Room Details",
        headerClassName: "super-app-theme--header",
        width: 400,
      },
      {
        field: "no_of_rooms",
        headerName: "No of Room(s)",
        headerClassName: "super-app-theme--header",
        width: 400,
      },
      {
        field: "no_of_guests",
        headerName: "No of Guest(s)",
        width: 200,
        headerClassName: "super-app-theme--header",
      },
    ];


    const housekeepingColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "room_details",
        headerName: "Room Details",
        headerClassName: "super-app-theme--header",
        width: 800,
      },
      {
        field: "no_of_rooms",
        headerName: "No of Room(s)",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
    ];


    const accountDetailsColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "counter",
        headerName: "Counter",
        headerClassName: "super-app-theme--header",
        width: 150,
      },
      {
        field: "cash_received",
        headerName: "Cash Received",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "others_received",
        headerName: "Revenue received from other sources",
        width: 200,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "cash_withdrawn",
        headerName: "Revenue received by cash",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "others_withdrawn",
        headerName: "Revenue withdrawn from other sources",
        width: 200,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "balance",
        headerName: "Balance",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        headerClassName: "super-app-theme--header",
      },
    ];


    const revenueColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "revenue_stream",
        headerName: "Revenue Stream",
        headerClassName: "super-app-theme--header",
        width: 1000,
      },
      {
        field: "amount",
        headerName: "Amount",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
    ];


    const taxColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "tax_name",
        headerName: "Tax Name",
        headerClassName: "super-app-theme--header",
        width: 800,
      },
      {
        field: "amount",
        headerName: "Amount",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
    ];
    
    
    const roomInclusionColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "source",
        headerName: "Restaurant",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "total_price",
        headerName: "Total Price",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "total_tax",
        headerName: "Total Tax",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
      {
        field: "amount",
        headerName: "Amount",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
    ];


    const bookingRevenueColumns = [
      {
        field: "id",
        headerName: "#",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "revenue_stream",
        headerName: "Revenue Stream",
        headerClassName: "super-app-theme--header",
        width: 1000,
      },
      {
        field: "amount",
        headerName: "Amount",
        headerClassName: "super-app-theme--header",
        width: 200,
      },
    ];

    function NightAuditGrid(data) {
      const { row, featureAdded } = data;

      const auditFields = [];
      if (data?.room_details) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.room_details;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          room_details: el.replace(/_/g, " "),
          no_of_rooms: obj[el].rooms,
          no_of_guests: obj[el].guest,
        });
      });

      return (
        <Box
          sx={{
            height: 530,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "5px 1px",
            }}
          >
            <h3 style={{ marginTop: 2 }}>Night Audit</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={columns}
            pageSize={8}
            // components={{Toolbar: () => {
            //   return <GridToolbarContainer><GridToolbarExport sx={{justifyContent: "flex-end"}} /></GridToolbarContainer>
            // }}}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function HouseKeepingGrid(data) {
      const { row, featureAdded } = data;

      const auditFields = [];
      if (data?.housekeeping_details) {
        console.log("hi");
      }
      const obj = data?.row?.housekeeping_details;
      const list = Object.keys(obj);
      console.log(data);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          room_details: el.replace(/_/g, " "),
          no_of_rooms: obj[el],
        });
      });

      return (
        <Box
          sx={{
            height: 300,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>House Keeping Detals</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={housekeepingColumns}
            pageSize={8}
            // components={{Toolbar: () => {
            //   return <GridToolbarContainer><GridToolbarExport sx={{justifyContent: "flex-end"}} /></GridToolbarContainer>
            // }}}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function AccountDetailsGrid(data) {
      const auditFields = [];
      if (data?.account_details) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.account_details;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          counter: el.replace(/_/g, " "),
          cash_received: obj[el].revenue_received,
          others_received: 0.0,
          cash_withdrawn: obj[el].withdrawals,
          others_withdrawn: 0.0,
          balance: obj[el].balance,
          status: obj[el].status,
        });
      });

      return (
        <Box
          sx={{
            height: 320,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>Account Details</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={accountDetailsColumns}
            pageSize={8}
            rowsPerPageOptions={[8]}
            // components={{
            //   Toolbar: () => {
            //     return (
            //       <GridToolbarContainer>
            //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
            //       </GridToolbarContainer>
            //     );
            //   },
            // }}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function RevenueGrid(data) {
      const auditFields = [];
      if (data?.revenue_list) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.revenue_list;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          revenue_stream: el.replace(/_/g, " "),
          amount: obj[el],
        });
      });

      return (
        <Box
          sx={{
            height: 550,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>Revenue List</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={revenueColumns}
            pageSize={8}
            // components={{
            //   Toolbar: () => {
            //     return (
            //       <GridToolbarContainer>
            //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
            //       </GridToolbarContainer>
            //     );
            //   },
            // }}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function RoomInclusionGrid(data) {
      const auditFields = [];
      if (data?.room_inclusions) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.room_inclusions;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          source: el.replace(/_/g, " "),
          quantity: obj[el].quantity,
          total_price: obj[el].total_price,
          total_tax: obj[el].total_tax,
          amount: obj[el].amount,
        });
      });

      return (
        <Box
          sx={{
            height: 450,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>Room Inclusion</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={roomInclusionColumns}
            pageSize={8}
            // components={{
            //   Toolbar: () => {
            //     return (
            //       <GridToolbarContainer>
            //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
            //       </GridToolbarContainer>
            //     );
            //   },
            // }}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function TaxGrid(data) {
      const auditFields = [];
      if (data?.room_tax) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.room_tax;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          tax_name: el.replace(/_/g, " "),
          amount: obj[el],
        });
      });

      return (
        <Box
          sx={{
            height: 320,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>Room Tax(es)</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={taxColumns}
            pageSize={8}
            // components={{
            //   Toolbar: () => {
            //     return (
            //       <GridToolbarContainer>
            //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
            //       </GridToolbarContainer>
            //     );
            //   },
            // }}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }

    function BookingRevenueGrid(data) {
      const auditFields = [];
      if (data?.booking_revenue) {
        console.log("hi");
      }
      console.log(data);
      const obj = data?.row?.booking_revenue;
      const list = Object.keys(obj);
      console.log("===> this is the list to be shown", list);
      list?.forEach((el, i) => {
        auditFields.push({
          id: i + 1,
          revenue_stream: el.replace(/_/g, " "),
          amount: obj[el],
        });
      });

      return (
        <Box
          sx={{
            height: 550,
            width: "100%",
            marginTop: 4,
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              color: "black",
              background: "#e7e7e7",
              padding: "12px",
              height: "40px",
              width: "100%",
              margin: "1px",
            }}
          >
            <h3 style={{ marginTop: -2 }}>Booking Revenue</h3>
          </Box>
          <DataGrid
            rows={auditFields}
            columns={bookingRevenueColumns}
            pageSize={8}
            // components={{
            //   Toolbar: () => {
            //     return (
            //       <GridToolbarContainer>
            //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
            //       </GridToolbarContainer>
            //     );
            //   },
            // }}
            rowsPerPageOptions={[8]}
            // checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      );
    }
    // console.log(JSON.parse(localStorage.getItem("orderDetails")));
    // eslint-disable-next-line no-undef
    console.log("hi");
    // eslint-disable-next-line no-undef
    this.nightAuditDets = JSON.parse(localStorage.getItem("nightAuditDets"));
    // eslint-disable-next-line no-undef
    this.nightAuditDate = new Date(localStorage.getItem("nightAuditDate"));
    // eslint-disable-next-line no-undef
    console.log("==> am I lucky>", this.nightAuditDets, this.nightAuditDate);
    return (
      <>
        <Page title="Home">
          <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{
                color: "#0323f8",
                textAlign: "center",
                marginLeft: 20,
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Night Audit Summary for {`${this.nightAuditDate.toDateString()}`}
            </Typography>
            {this.nightAuditDets?.room_details && (
              <NightAuditGrid row={this.nightAuditDets} />
            )}
            {this.nightAuditDets?.housekeeping_details && (
              <HouseKeepingGrid row={this.nightAuditDets} />
            )}
            {this.nightAuditDets?.account_details && (
              <AccountDetailsGrid row={this.nightAuditDets} />
            )}
            {this.nightAuditDets?.revenue_list && (
              <RevenueGrid row={this.nightAuditDets} />
            )}
            {/* <InfoGrid row={featureList} /> */}
            {this.nightAuditDets?.room_inclusions && (
              <RoomInclusionGrid row={this.nightAuditDets} />
            )}
            {this.nightAuditDets?.room_tax && (
              <TaxGrid row={this.nightAuditDets} />
            )}
            {/* <PosTaxGrid row={featureList} /> */}
            {this.nightAuditDets?.booking_revenue && (
              <BookingRevenueGrid row={this.nightAuditDets} />
            )}
          </Container>
        </Page>
      </>
    );
  }
}

class NightAuditReport extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#" style={{ fontWeight: "bold", color: "#ffffff", backgroundColor: "blue", padding: "1rem 2rem"}}>Print</a>}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />

        {/* for double print uncomment below line */}
        {/* <ComponentToPrint ref={(el) => (this.componentRef = el)} /> */}
      </div>
    );
  }
}

export default NightAuditReport;
