import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Tooltip,
  listItemSecondaryActionClasses,
} from "@mui/material";
import { Page } from "../components/Page";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import {
  getExpectedDepartureReports,
  getCurrentUser,
  getDiscountReports,
  getAllowanceReports,
} from "../api-config/config";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { voucherView } from "../utils/miscellaneous";
import LoaderDotted from "../components/LoaderDotted/LoaderDotted";

function ExpectedDepartureGrid() {
  const [expectedDeparture, setExpecDepart] = useState([] as any);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [loader, setLoader] = useState(false);
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchCheckOutOrder(startDateString, endDateString);
  }
  const loggedUser = getCurrentUser();
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth),
      end: eDate.setMonth(currentMonth),
    };
  }
  function getRows(data: any) {
    const allRows = [] as any;
    console.log(data, "discount data");
    let id = 1;
    data.forEach((listData: any, key: any) => {
      const date1 = new Date(listData.from_date);
      const month1 = date1.toLocaleString("default", { month: "short" });
      const date2 = new Date(listData.to_date);
      const month2 = date2.toLocaleString("default", { month: "short" });
      const diffDays = date2.getDate() - date1.getDate();
      const obj = {
        id: id++,
        guest_id: listData?.guest_id,
        resId: listData.booking_id,
        roomDetails: listData?.room_booking_info?.room_title,
        guestName: listData.guest_first_name + listData.last_name,
        guestEmail: listData.email,
        guestMobile: listData.mobile_number,
        // stayDuration: `${date2.getDate()} ${month2}`,
        stayDuration: `${date1.getDate()} ${month1} - ${date2.getDate()} ${month2}(${diffDays})`,
        // roomType: listData.room_booking_info.room_title,
        pax: `${listData?.no_of_adults}(A)0(C)`,
        status: listData.booking_status,
        allowance: listData?.allowance,
        amount: listData.gross_amount,
      };
      allRows.push(obj);
    });
    setDynamicRows([...allRows]);
    console.log("dynamicRows", allRows);
  }
  function fetchCheckOutOrder(startDateString: any, endDateString: any) {
    setLoader(true);
    getAllowanceReports(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setLoader(false);

      setExpecDepart(response.data);
      getRows(response.data);
    });
  }

  useEffect(() => {
    const getObj = getDateRange(new Date(), new Date());
    const start = new Date(getObj.start);
    const end = new Date(getObj.end);
    // console.log(start.toISOString().slice(0, 10));
    // const startDateString = `${start.getFullYear()}-${
    //   start.getMonth() + 1
    // }-${start.getDate()}`;
    // const endDateString = `${end.getFullYear()}-${
    //   end.getMonth() + 1
    // }-${end.getDate()}`;
    fetchCheckOutOrder(
      start.toISOString().slice(0, 10),
      end.toISOString().slice(0, 10)
    );
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "# Group ID",
      width: 90,
      headerClassName: "super-app-theme--header",
      hide: true,
    },
    {
      field: "resId",
      flex: 1,
      headerName: "Booking ID",
      headerClassName: "super-app-theme--header",
      width: 50,
      editable: false,
    },
    {
      field: "roomDetails",
      flex: 1,
      headerName: "Room#/Type",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestName",
      flex: 1,
      headerName: "Guest Name",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestEmail",
      flex: 1.5,
      headerName: "Guest email",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestMobile",
      flex: 1,
      headerName: "Mobile",
      // width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stayDuration",
      flex: 1,
      headerName: "Stay Duration",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "pax",
      flex: 1,
      headerName: "Pax",
      width: 90,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      flex: 1,
      headerName: "Status",
      width: 110,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "allowance",
      flex: 1,
      headerName: "Allowance",
      width: 110,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "amount",
    //   flex: 1,
    //   headerName: "Amount",
    //   width: 110,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    // },

    // {
    //   field: "Action",
    //   width: 100, discount_value

    //   renderCell: (cellValues) => {
    //     return (
    //       <span
    //         style={{ marginLeft: "20px", marginRight: "20px" }}
    //         onClick={(event) => {
    //           voucherView(cellValues.row);
    //         }}
    //       >
    //         <Button
    //           type="button"
    //           className="bg-sky-600 hover:bg-sky-400 text-stone-50"
    //         >
    //           View
    //         </Button>
    //       </span>
    //     );
    //   },
    //   headerClassName: "super-app-theme--header",
    // },
  ];
  if (loader) {
    return <LoaderDotted />;
  } else {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "inherit",
          marginTop: 4,
          "& .super-app-theme--header": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      >
        <p className="m-2.5">Select Date Range</p>
        <div className="flex justify-between p-2">
          <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
            <div className="border">
              <DateRangeComponent
                range={range}
                onSetRange={onSetRange}
                isShowPastDate={true}
              />
            </div>
            <Button
              type="button"
              onClick={() => searchWithDateRange()}
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              Search
            </Button>
          </Stack>
          <div className="flex justify-end mx-4">
            <CSVLink data={dynamicRows} filename={"Discount-Report-list.csv"}>
              <Tooltip title="Download Excel">
                <Button
                  type="button"
                  // onClick={() => searchWithDateRange()}
                  className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                >
                  Download
                </Button>
              </Tooltip>
            </CSVLink>
          </div>
        </div>

        <DataGrid
          autoHeight
          rows={dynamicRows}
          columns={columns}
          rowsPerPageOptions={[10, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          initialState={{
            sorting: {
              sortModel: [{ field: "resId", sort: "desc" }],
            },
          }}
        />
      </Box>
    );
  }
}

export default function ExpectedDepartureList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Allowance Reports
          </Typography>
          <ExpectedDepartureGrid />
        </Container>
      </Page>
    </>
  );
}
