import Iconify from "../../components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 22, height: 22 }} />
);

const navConfig = [
  {
    title: "Dashboard",
    path: "/main/home",
    // icon: getIcon("bytesize:home"),
  },
  {
    title: "Company Profile",
    path: "/admin/companydetails",
    // icon: getIcon("bytesize:home"),
  },
  {
    title: "Hotel Information",
    path: "/admin/hoteldetails",
    // icon: getIcon("bytesize:home"),
  },
  // {
  //   title: "Amenities List",
  //   path: "/admin/amenitieslist",
  //   // icon: getIcon("bytesize:home"),
  // },
  {
    title: "Tax List",
    path: "/admin/taxlist",
    // icon: getIcon("bytesize:home"),
  },
  {
    title: "Block List",
    path: "/admin/blocklist",
    // icon: getIcon("bytesize:home"),
  },
  {
    title: "Floor List",
    path: "/admin/floorlist",
  },
  {
    title: "Room Type List",
    path: "/admin/roomtypelist",
  },
  {
    title: "Rooms Rate Plan",
    path: "/admin/roomsrateplan",
  },
  {
    title: "Rooms",
    path: "/admin/roomlist",
  },
  {
    title: "Features",
    path: "/admin/featureslist",
  },
  {
    title: "Registered Corporates",
    path: "/admin/registeredcorporates",
  },
  {
    title: "Add Corporate",
    path: "/admin/addcorporate",
  },
  {
    title: "Create User",
    path: "/admin/createuser",
  },
  {
    title: "Users List",
    path: "/admin/userslist",
  },
  {
    title: "Registered Travel Agent",
    path: "/admin/registeredtravelagent",
  },
  {
    title: "Add Travel Agent",
    path: "/admin/addtravelagent",
  },
  {
    title: "OTA",
    path: "/admin/ota",
  },
  // {
  //   title: "Cash Counter",
  //   path: "/admin/cashcounter",
  // },
  // {
  //   title: "Create Room Type List",
  //   path: "/admin/roomtypelistcreate",
  // },
];

export default navConfig;
