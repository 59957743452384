import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Link,
} from "@mui/material";
import { Page } from "../../components/Page";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

/*const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "floor",
    headerName: "Floor Name",
    headerClassName: "super-app-theme--header",
    width: 250,
    editable: true,
  },
  {
    field: "rooms",
    headerName: "Rooms",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "action",
    headerName: "Action",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

const rows = [
  {
    id: 1,
    floor: "",
    rooms: "",
    action: "",
    status: "",
  },
];*/
function RoomTypeListCreateGrid() {
  const [base, setBase] = React.useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = React.useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = React.useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  return (
    <Box
      sx={{
        height: 164,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Save
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "1195px",
          margin: "4px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Room Type List/Add a Room Type</h3>
      </Box>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <h4 style={{ marginTop: -2 }}>
          Please Enter the Room Type Details below.
        </h4>
      </Box>
      <Grid item sm={12} md={6} lg={6} sx={{ marginTop: 2 }}>
        <TextField
          id="filled-basic"
          label="Name the Room Type"
          variant="outlined"
          sx={{ margin: "5px 0", width: 400 }}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} sx={{ marginTop: 0 }}>
        <TextField
          id="filled-basic"
          label="Short Name"
          variant="outlined"
          sx={{ margin: "5px 0", width: 200 }}
        />
        <p style={{ float: "right", marginRight: "200px" }}>
          (This abbreviated form is used in many places to display the room
          type.So Please make it relevant)
        </p>
      </Grid>
      <Grid item sm={12} md={6} lg={6} sx={{ marginTop: 0 }}>
        <TextField
          id="filled-basic"
          label="Upload Images"
          variant="outlined"
          sx={{ margin: "5px 0", width: 300 }}
        />
        <p style={{ float: "right", marginRight: "580px" }}>
          (Image dimension 300px*225px)
        </p>
        <Grid
          item
          xs={6}
          sx={{ float: "right", marginTop: "-58px", marginRight: "500px" }}
        >
          <Checkbox {...label} />
        </Grid>
        <p style={{ float: "right", marginRight: "320px", marginTop: "-48px" }}>
          Force Upload XL Image
        </p>
      </Grid>
      <Grid item sm={12} md={6} lg={6} sx={{ marginTop: 0 }}>
        <TextField
          id="filled-basic"
          label="Image Caption"
          variant="outlined"
          sx={{ margin: "5px 0", width: 300 }}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} sx={{ marginTop: 0 }}>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
          sx={{ margin: "5px 0", width: 700 }}
          // defaultValue="Description"
        />
      </Grid>
      <Grid container sx={{ padding: "4px 0px" }}>
        <Grid item xs={4}>
          Base Occupancy
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ m: 0, minWidth: 250 }} size="small">
            <InputLabel></InputLabel>
            <Select
              value={base}
              label=""
              onChange={baseChange}
              sx={{ marginRight: "150px" }}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <p style={{ float: "right", marginRight: "640px", marginTop: "-36px" }}>
        Person
      </p>

      <Grid container sx={{ padding: "4px 0px" }}>
        <Grid item xs={4}>
          Higher Occupancy
        </Grid>
        <Grid item xs={6} sx={{}}>
          <FormControl sx={{ minWidth: 250 }} size="small">
            <InputLabel></InputLabel>
            <Select
              value={extra}
              label=""
              onChange={extraChange}
              sx={{ marginRight: "150px" }}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <p style={{ float: "right", marginRight: "640px", marginTop: "-36px" }}>
        Person
      </p>
      <Box
        onClick={preventDefault}
        sx={{ float: "right", marginRight: "360px", marginTop: "-36px" }}
      >
        <Link href="#" underline="none">
          {"Specify Audit/Child"}
        </Link>
      </Box>
      <Grid container sx={{ padding: "4px 0px" }}>
        <Grid item xs={4}>
          Extra Bed Allowed?
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ float: "right", marginTop: "-35px", marginRight: "770px" }}
      >
        <Checkbox {...label} />
      </Grid>
      <p style={{ float: "right", marginRight: "720px", marginTop: "-30px" }}>
        Yes.
      </p>
      <Grid
        item
        xs={6}
        sx={{ float: "right", marginTop: "-45px", marginRight: "450px" }}
      >
        <FormControl sx={{ minWidth: 250 }} size="small">
          <InputLabel></InputLabel>
          <Select
            value={higher}
            label=""
            onChange={higherChange}
            sx={{ marginRight: "150px" }}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <p style={{ float: "right", marginRight: "450px", marginTop: "-36px" }}>
        Extra Bed Allowed
      </p>
      <p style={{}}>Number of Rooms of this Room Type 0 Rooms</p>
      <p style={{ marginTop: "30px" }}>Select Amenities*</p>
      <Typography paragraph={true}>Select Amenities*</Typography>
    </Box>
  );
}

export default function RoomTypeListCreate() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Create Room Type List
          </Typography>
          <RoomTypeListCreateGrid />
        </Container>
      </Page>
    </>
  );
}
