import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import { Page } from "../components/Page";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "# Group ID",
    width: 90,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "resId",
    headerName: "Res ID",
    headerClassName: "super-app-theme--header",
    width: 90,
    editable: true,
  },
  {
    field: "guestName",
    headerName: "Guest Name",
    width: 140,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "phone",
    headerName: "Phone/Mobile",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "email",
    headerName: "Email",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "stayDuration",
    headerName: "Stay Duration",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "roomType",
    headerName: "Room#/Type",
    width: 130,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pax",
    headerName: "Pax",
    width: 80,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "preferences",
    headerName: "Preferences/Notes",
    width: 135,
    editable: true,
    headerClassName: "super-app-theme--header",
  },

  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "action",
    headerName: "Action",
    width: 80,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

const rows = [
  {
    id: 1,
    resId: "0824346",
    guestName: "Shanon Waire",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 2,
    resId: "0823342",
    guestName: "Salman Khan",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 3,
    resId: "1023474",
    guestName: "Deccan Barois",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 4,
    resId: "1025475",
    guestName: "Boman Irani",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 5,
    resId: "1025476",
    guestName: "Boman Irani",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 6,
    resId: "1025477",
    guestName: "Priya Oberoi",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 7,
    resId: "1025478",
    guestName: "Deccan Barois",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 8,
    resId: "1025479",
    guestName: "Deccan Barois",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 9,
    resId: "1025480",
    guestName: "Deccan Barois",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 10,
    resId: "1025481",
    guestName: "Deccan Barois",
    phone: "8787992020",
    email: "",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
  {
    id: 11,
    resId: "1025482",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    amount: "$27,908.00",
    preferences: "",
    action: "",
  },
];

function ReservationListGrid() {
  return (
    <Box
      sx={{
        height: 423,
        width: "100%",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "0px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Print
          </Button>
          <Button variant="contained" sx={{ margin: "0px 4px" }}>
            Close
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
}

export default function TempRoomList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Temp Room List
          </Typography>
          <ReservationListGrid />
        </Container>
      </Page>
    </>
  );
}
