import {
  Container,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  Stack,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import { getCurrentUser } from "../../api-config/config";
import { insertTravelAgent } from "../../api-config/corporate.api";
import { useState } from "react";
import DiscountPopup from "../../components/EditPosCommission";

export default function AddTravelagent() {
  const loggedUser = getCurrentUser();
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const [isOpenDiscountPopup, setDiscountPopupOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      iata_code: "",
      travel_agent_name: "",
      file: "",
      tax_type: "",
      deduction: "",
      deduct_after_applying_tax: "12",
      market_segment: "",
      main_office_address: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      salutation: "",
      contact_person_first_name: "",
      contact_person_last_name: "",
      contact_person_designation: "",
      contact_person_office_phone: "",
      contact_person_extn: "",
      contact_person_office_fax: "",
      contact_person_email_id: "",
      contact_person_mobile: "",
      date_of_birth: "",
      website: "",
      main_billing_address: "",
      billing_company_name: "",
      billing_company_country: "",
      billing_company_state: "",
      billing_company_city: "",
      billing_company_zip_code: "",
      billing_company_salutation: "",
      main_billing_contact_person_first_name: "",
      main_billing_contact_person_last_name: "",
      main_billing_contact_person_designation: "",
      main_billing_contact_person_office_phone: "",
      main_billing_contact_person_office_fax: "",
      main_billing_contact_person_extn: "",
      main_billing_contact_person_email_id: "",
      main_billing_contact_person_mobile: "",
      main_billing_contact_person_gender: "",
      main_billing_contact_person_date_of_birth: "",
      main_billing_contact_person_website: "",
      hotel_code: loggedUser?.hotel_code.toString(),
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      insertTravelAgent({
        ...values,
        deduction: parseFloat(values.deduction),
        deduct_after_applying_tax: parseFloat(values.deduct_after_applying_tax),
      }).then((res) => {
        console.log(res);
        if (res.status_message === "success") {
          setOpenSnackSuccess(true);
        } else {
          setOpenSnackFailure(true);
        }
      });
      console.log(values);
    },
  });

  const openPopup = (e: any) => {
    e.preventDefault();
    setDiscountPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setDiscountPopupOpen(false);
  };

  // Function to handle saving the discount (you can customize this)
  const handleSaveDiscount = (discountData: any) => {
    console.log("Discount data:", discountData);
    // Implement your logic to save the discount here
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Snackbar
          open={openSnackSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSnackSuccess(false)}
        >
          <Alert
            onClose={() => setOpenSnackSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully created.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackFailure}
          autoHideDuration={6000}
          onClose={() => setOpenSnackFailure(false)}
        >
          <Alert
            onClose={() => setOpenSnackFailure(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error while adding the OTA.
          </Alert>
        </Snackbar>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          Add/Edit OTA
        </Typography>
        <div
          className="bg-slate-200 py-4 "
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 18,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold">
            {" "}
            Main / List of OTAs / Add/Edit OTA
          </span>
          <span className="mr-auto">EDIT</span>
        </div>
        {/* corporate details start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold ">
            Please enter the OTA details below.
          </span>
        </div>
        {/* form start cdetails */}
        <div className="ml-16 py-5">
          <div className="mb-2 ml-32">ID #</div>
          <div className="mb-2 ml-[75px]">
            <label className="">IATA Code</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="iata_code"
              name="iata_code"
              value={formik.values.iata_code}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="">OTA Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="travel_agent_name"
              name="travel_agent_name"
              value={formik.values.travel_agent_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-14">
            <label className="">Upload Logo</label>
            <input
              type="file"
              className="ml-4 py-1 border 2px #666666"
              id="file"
              name="file"
              value={formik.values.file}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-32">
            <label className="">VAT</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="tax_type"
              name="tax_type"
              value={formik.values.tax_type}
              onChange={formik.handleChange}
            />
            <a href="" className="underline text-blue-600 ml-2">
              Tax Type
            </a>
          </div>
          <div className="mb-2 ml-20">
            <label className="">Deduction</label>
            <input
              type="text"
              placeholder="0.00"
              id="deduction"
              name="deduction"
              value={formik.values.deduction}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
            />{" "}
            %
            <input
              type="checkbox"
              className="ml-4 mr-4 py-1 border 2px #666666"
              id="deduct_after_applying_tax"
              name="deduct_after_applying_tax"
              value={formik.values.deduct_after_applying_tax}
              onChange={formik.handleChange}
            />
            Deduct after applying tax
          </div>
          <div className="mb-2 ml-7">
            <label className="">Market Segment </label>
            <Select
              labelId="market-segment-label"
              id="market_segment"
              name="market_segment"
              value={formik.values.market_segment}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="Business">Business</MenuItem>
              <MenuItem value="Corporates">Corporates</MenuItem>
              <MenuItem value="Events">Events</MenuItem>
              <MenuItem value="Government/PSU">Government/PSU</MenuItem>
              <MenuItem value="Leisure">Leisure</MenuItem>
              <MenuItem value="Tour Operators">Tour Operators</MenuItem>
              <MenuItem value="Wedding">Wedding</MenuItem>
            </Select>
          </div>
        </div>
        {/* form end cdetails */}
        {/* corporate details end */}
        {/* main office address start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold ">Main Office Address :</span>
        </div>
        <div className="ml-16 py-5">
          <div className="mb-2 ml-8">
            <label className="">Address Line 1 *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_office_address"
              name="main_office_address"
              value={formik.values.main_office_address}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-11">
            <label className="">Address Line 2</label>
            <input type="text" className="ml-4 py-1 border 2px #666666" />
          </div>
          <div className="mb-2 ml-[85px]">
            <label className="">Country* </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="country"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[107px]">
            <label className="">State* </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[115px]">
            <label className="">City *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[79px]">
            <label className="">Zip Code*</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="zip_code"
              name="zip_code"
              value={formik.values.zip_code}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {/* main office address end */}
        {/* contact person details start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold ">Contact Person Details :</span>
        </div>
        <div className="ml-16 py-5">
          <div className="mb-2 ml-20">
            <label className="">Salutation</label>
            <Select
              labelId="market-segment-label"
              id="salutation"
              name="salutation"
              value={formik.values.salutation}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="segment1">Dr.</MenuItem>
              <MenuItem value="segment2">Mr.</MenuItem>
              <MenuItem value="segment3">Mrs.</MenuItem>
              <MenuItem value="segment3">Ms.</MenuItem>
            </Select>
          </div>

          <div className="mb-2 ml-16">
            <label className="">First Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_first_name"
              name="contact_person_first_name"
              value={formik.values.contact_person_first_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-16">
            <label className="">Last Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_last_name"
              name="contact_person_last_name"
              value={formik.values.contact_person_last_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-14">
            <label className="">Designation *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_designation"
              name="contact_person_designation"
              value={formik.values.contact_person_designation}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-12">
            <label className="">Office Phone *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_office_phone"
              name="contact_person_office_phone"
              value={formik.values.contact_person_office_phone}
              onChange={formik.handleChange}
            />
            <label className="ml-4"> Extn# </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_extn"
              name="contact_person_extn"
              value={formik.values.contact_person_extn}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-20">
            <label className="">Office Fax</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_office_fax"
              name="contact_person_office_fax"
              value={formik.values.contact_person_office_fax}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[85px]">
            <label className="">Email Id *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_email_id"
              name="contact_person_email_id"
              value={formik.values.contact_person_email_id}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[102px]">
            <label className="">Mobile </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="contact_person_mobile"
              name="contact_person_mobile"
              value={formik.values.contact_person_mobile}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[102px]">
            <label className="">Gender</label>
            <Select
              labelId="market-segment-label"
              id="market-segment"
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="segment1">Male</MenuItem>
              <MenuItem value="segment2">Female</MenuItem>
            </Select>
          </div>
          <div className="mb-2 ml-[52px]">
            <label className="">Date Of Birth </label>
            <input
              type="date"
              className="ml-4 py-1 border 2px #666666"
              id="date_of_birth"
              name="date_of_birth"
              value={formik.values.date_of_birth}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[96px]">
            <label className="">Website</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="website"
              name="website"
              value={formik.values.website}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {/* contact person details end*/}
        {/*  Main billing address  start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold "> Main Billing Address :</span>
        </div>
        <div className=" ml-10">
          Same as main Office Address
          <input type="checkbox" className="ml-2" />
        </div>
        <div className="ml-16 py-5">
          <div className="mb-2">
            <label className="">Billing Company Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="billing_company_name"
              name="billing_company_name"
              value={formik.values.billing_company_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[60px]">
            <label className="">Address Line 1 *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_address"
              name="main_billing_address"
              value={formik.values.main_billing_address}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[72px]">
            <label className="">Address Line 2</label>
            <input type="text" className="ml-4 py-1 border 2px #666666" />
          </div>
          <div className="mb-2 ml-[116px]">
            <label className="">Country* </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="billing_company_country"
              name="billing_company_country"
              value={formik.values.billing_company_country}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[138px]">
            <label className="">State* </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="billing_company_state"
              name="billing_company_state"
              value={formik.values.billing_company_state}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[146px]">
            <label className="">City *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="billing_company_city"
              name="billing_company_city"
              value={formik.values.billing_company_city}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[110px]">
            <label className="">Zip Code*</label>
            <input type="text" className="ml-4 py-1 border 2px #666666" />
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="billing_company_zip_code"
              name="billing_company_zip_code"
              value={formik.values.billing_company_zip_code}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {/*  Main billing address  end */}
        {/*  Main billing contact  start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold "> Main Billing contact :</span>
        </div>
        <div className="mr-4 ml-10">
          Same as Contact Person <input type="checkbox" />
        </div>
        <div className="ml-16 py-5">
          <div className="mb-2 ml-[105px]">
            <label className="">Salutation</label>
            <Select
              labelId="market-segment-label"
              id="billing_company_salutation"
              name="billing_company_salutation"
              value={formik.values.billing_company_salutation}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="segment1">Dr.</MenuItem>
              <MenuItem value="segment2">Mr.</MenuItem>
              <MenuItem value="segment3">Mrs.</MenuItem>
              <MenuItem value="segment3">Ms.</MenuItem>
            </Select>
          </div>

          <div className="mb-2 ml-[93px]">
            <label className="">First Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_first_name"
              name="main_billing_contact_person_first_name"
              value={formik.values.main_billing_contact_person_first_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[95px]">
            <label className="">Last Name *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_last_name"
              name="main_billing_contact_person_last_name"
              value={formik.values.main_billing_contact_person_last_name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[85px]">
            <label className="">Designation *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_designation"
              name="main_billing_contact_person_designation"
              value={formik.values.main_billing_contact_person_designation}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[78px]">
            <label className="">Office Phone *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_office_phone"
              name="main_billing_contact_person_office_phone"
              value={formik.values.main_billing_contact_person_office_phone}
              onChange={formik.handleChange}
            />
            <label className="ml-4"> Extn# </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_extn"
              name="main_billing_contact_person_extn"
              value={formik.values.main_billing_contact_person_extn}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[110px]">
            <label className="">Office Fax</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_office_fax"
              name="main_billing_contact_person_office_fax"
              value={formik.values.main_billing_contact_person_office_fax}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[115px]">
            <label className="">Email Id *</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_email_id"
              name="main_billing_contact_person_email_id"
              value={formik.values.main_billing_contact_person_email_id}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[130px]">
            <label className="">Mobile </label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_mobile"
              name="main_billing_contact_person_mobile"
              value={formik.values.main_billing_contact_person_mobile}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[128px]">
            <label className="">Gender</label>
            <Select
              labelId="market-segment-label"
              id="main_billing_contact_person_gender"
              name="main_billing_contact_person_gender"
              value={formik.values.main_billing_contact_person_gender}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="segment1">Male</MenuItem>
              <MenuItem value="segment2">Female</MenuItem>
            </Select>
          </div>
          {/* <div className="mb-2">
              <label className="">Date Of Birth </label>
              <Select
                labelId="market-segment-label"
                id="market-segment"
                className="ml-4 py-1 border 2px #666666"
                style={{ minWidth: "50px" }}
              >
                <MenuItem value="segment1">Date</MenuItem>
              </Select>
              <Select
                labelId="market-segment-label"
                id="market-segment"
                className="ml-4 py-1 border 2px #666666"
                style={{ minWidth: "120px" }}
              >
                <MenuItem value="segment1">Month</MenuItem>
              </Select>
              <Select
                labelId="market-segment-label"
                id="market-segment"
                className="ml-4 py-1 border 2px #666666"
                style={{ minWidth: "100px" }}
              >
                <MenuItem value="segment1">Year</MenuItem>
              </Select>
            </div> */}
          <div className="mb-2 ml-[77px]">
            <label className="">Date Of Birth *</label>
            <input
              type="date"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_date_of_birth"
              name="main_billing_contact_person_date_of_birth"
              value={formik.values.main_billing_contact_person_date_of_birth}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 ml-[125px]">
            <label className="">Website</label>
            <input
              type="text"
              className="ml-4 py-1 border 2px #666666"
              id="main_billing_contact_person_website"
              name="main_billing_contact_person_website"
              value={formik.values.main_billing_contact_person_website}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        {/* Main billing contact end*/}
        {/* credit card details start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold">Credit Card Details</span>
        </div>
        <div className="text-center py-5">
          <table className="border-collapse w-full">
            <thead className="text-white bg-black">
              <tr>
                <th className="border py-2 px-4">#</th>
                <th className="border py-2 px-4"> Name</th>
                <th className="border py-2 px-4">Card Number</th>
                <th className="border py-2 px-4">Type</th>
                <th className="border py-2 px-4">Expiry</th>
                <th className="border py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border py-2 px-4">1</td>
                <td className="border py-2 px-4">SHIV</td>
                <td className="border py-2 px-4">7894561230</td>
                <td className="border py-2 px-4">PLATINUM</td>
                <td className="border py-2 px-4">2028</td>
                <td className="border py-2 px-4">DELETE</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="underline text-blue-600">
          <a href="">Add Credit Card Details</a>{" "}
        </div>
        <div className="mb-4 pt-16">
          <label className="">Credit Allowed </label>
          <Select
            labelId="market-segment-label"
            id="market-segment"
            className="ml-4 py-1 border 2px #666666"
            style={{ minWidth: "100px" }}
          >
            <MenuItem value="segment1">No</MenuItem>
            <MenuItem value="segment2">Yes</MenuItem>
            <MenuItem value="segment2">Unlimited</MenuItem>
          </Select>
        </div>
        <div className="mb-4">
          <label className="">Credit Limit : </label>
          <input type="text" className="ml-4 py-1 border 2px #666666" />
        </div>
        <div className="mb-4">
          <label className="">Payment Terms : </label>
          <input type="text" className="ml-4 py-1 mr-2 border 2px #666666" />
          Day(s)
        </div>
        <div className="font-bold">
          <span className="flex">
            <label className="mr-4">
              Fixed Commission
              <input type="radio" name="commissionType" className="ml-2" />
            </label>
            <label>
              Volume Based Commission
              <input type="radio" name="commissionType" className="ml-2" />
            </label>
          </span>
        </div>
        {/* credit card details end */}
        {/* validity details start */}
        <div
          className="bg-slate-200 py-2 my-8"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8  font-bold "> Validity Details</span>
        </div>
        <div className="">
          {" "}
          <a href="" className="underline text-blue-600 ml-16">
            Add a Validity
          </a>
        </div>
        {/* validity details end */}
        {/* other commision start */}
        <div
          className="bg-slate-200 py-2 my-8"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8  font-bold "> Other commission :</span>
        </div>
        <div className="text-center py-5">
          <table className="border-collapse ">
            <thead className="text-white bg-black">
              <tr>
                <th className="border py-2 px-4"> </th>
                <th className="border py-2 px-4"> POS</th>
                <th className="border py-2 px-4"> Commisions</th>
                <th className="border py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border py-2 px-4"> </td>
                <td className="border py-2 px-4"> </td>
                <td className="border py-2 px-4"> </td>
                <td className="border py-2 px-4"> </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="">
          {" "}
          <a
            href=""
            className="underline text-blue-600 ml-16"
            onClick={openPopup}
          >
            Add/Edit POS point commissions
          </a>
          <DiscountPopup
            open={isOpenDiscountPopup}
            onClose={closePopup}
            onSave={handleSaveDiscount}
          />
        </div>
        {/* other commision end */}
        <div className="ml-16 mt-8">
          Note: Fields marked with an * are compulsory
        </div>
        <div className="ml-16 mt-8">
          <Button
            className="bg-slate-200 font-bold"
            style={{ border: "3px solid #ccc" }}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
          <Button
            className="ml-4 bg-slate-200 font-bold"
            style={{ border: "3px solid #ccc" }}
          >
            Cancel
          </Button>
        </div>
      </Container>
    </>
  );
}
