import axios from "axios";
import { baseUrl } from "./config";
import api from "./axiosInstance";

export const uploadHotelLogo = async (formData: any) => {
  return api
    .post(baseUrl + "/hotel_logo_upload", formData)
    .then((response: any) => {
      return response.data;
    });
};
export const uploadGuestId = async (formData: any) => {
  return api
    .post(baseUrl + "/upload_guest_id_prof_file", formData)
    .then((response: any) => {
      return response.data;
    });
};
