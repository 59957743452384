import React from "react";
import { baseUrl, getCurrentUser } from "../../api-config/config";
import axios from "axios";
import { Button } from "@mui/material";
import api from "../../api-config/axiosInstance";

const IndFolioOverviewBottomLeft = (props: any) => {
  const { fetch, folioDetails, selectedIndFolio, selectedIndInv } = props;
  const loggedUser = getCurrentUser();

  const billView = () => {
    let url = `${baseUrl}/invoice_pms?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`;
    if (folioDetails?.corporate?.corporate_name) {
      url = url + "&invoice_for=corporate";
    } else if (folioDetails?.travel_agent?.billing_company_name) {
      url = url + "&invoice_for=travel_agent";
    }
    api.get(url).then((res: any) => {
      window.open(res.data.file);
    });
  };
  const provisionalBillView = () => {
    // api
    //   .get(
    //     `https://api.ratebotai.com:8443/invoice_pms?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`
    //   )
    //   .then((res: any) => {
    //     window.open(res.data.file);
    //   });
    window.open(
      `${baseUrl}/provissional_invoice_pdf?prov_inv_no=${
        selectedIndInv?.prov_inv_no || selectedIndInv?.inv_no
      }&hotel_code=${loggedUser?.hotel_code}`
    );
  };
  console.log(selectedIndFolio, "this is selected ind folio");

  return (
    <div className=" w-[100%] mt-5 flex justify-between text-sm flex-wrap">
      <div className="w-[100%] mb-3 ml-20">
        {folioDetails?.booking_status === "check_out" ? (
          <>
            <Button
              variant="contained"
              className="bg-[#2c96c4] mr-2 "
              onClick={() => billView()}
            >
              View & Print bill
            </Button>
            {/* <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => {
            setAction("settle"), setIsSettleOpen(true);
          }}
        >
          Settle bill
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => {
            setAction("cancel"), setIsCancelOpen(true);
          }}
        >
          Cancel bill
        </Button> */}
          </>
        ) : (
          <>
            <Button
              variant="contained"
              className="bg-[#2c96c4] mr-2"
              onClick={() => provisionalBillView()}
            >
              Provisional Invoice
            </Button>
            {/* <Button variant="contained" className="bg-[#2c96c4] mr-2">
          Returns
        </Button>
        <Button variant="contained" className="bg-[#2c96c4] mr-2">
          Route Payment
        </Button> */}
            {/* <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => setIsSplitOpen(true)}
        >
          Split Bill
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => onCheckout()}
        >
          Checkout
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => genrateFolio()}
        >
          Generate Folio
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4] mr-2"
          onClick={() => setRouteCharges(true)}
        >
          Route Payment
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4]"
          onClick={() => setRouteCharges(true)}
        >
          Route Charges
        </Button>
        <Button
          variant="contained"
          className="bg-[#2c96c4] mt-2"
          onClick={() => {
            setGenrateInvoice(true);
          }}
        >
          Genrate Invoice
        </Button> */}
          </>
        )}
      </div>
    </div>
  );
};

export default IndFolioOverviewBottomLeft;
