import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import { Page } from "../components/Page";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { getCurrentUser, getPaymentReport } from "../api-config/config";
import ReportTable from "../components/ReportTable";
import PaymentReportTable from "../components/ReportTable/PaymentReportTable";
import DateRangeComponent from "../components/DateRangePicker";

export default function PaymentReport() {
  const loggedUser = getCurrentUser();
  const [paymentReport, setPaymentReport] = useState({} as any);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchPaymentReports(loggedUser?.hotel_code, startDateString, endDateString);
  }
  function fetchPaymentReports(
    hotel_code: any,
    startDateString: string,
    endDateString: string
  ) {
    getPaymentReport(hotel_code, startDateString, endDateString).then(
      (response) => {
        setPaymentReport(response.data);
      }
    );
  }

  useEffect(() => {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchPaymentReports(loggedUser?.hotel_code, startDateString, endDateString);
  }, []);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <div className="flex-col p-2">
            <Typography
              variant="h5"
              gutterBottom
              style={{
                color: "#0323f8",
                marginLeft: 10,
                fontWeight: 600,
                fontSize: 22,
              }}
            >
              Payment Report
            </Typography>
            <div className="flex justify-start mx-4">
              <Stack
                direction="row"
                spacing={1}
                sx={{ justifyContent: "start" }}
              >
                <div className="border">
                  <DateRangeComponent
                    range={range}
                    onSetRange={onSetRange}
                    isShowPastDate={true}
                  />
                </div>
                <Button
                  type="button"
                  onClick={() => searchWithDateRange()}
                  className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                >
                  Search
                </Button>
              </Stack>
            </div>
          </div>
          <hr />
          <PaymentReportTable report={paymentReport} />
        </Container>
      </Page>
    </>
  );
}
