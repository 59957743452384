import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { hotelUserRegisteration } from "../../../api-config/config";

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    hotel_name: Yup.string().required("Hotel name required"),
    first_name: Yup.string().required("First name required"),
    last_name: Yup.string().required("Last name required"),
    mobile_number: Yup.string().required("Mobile No required"),
    email_id: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    // firstName: "",
    // lastName: "",
    // email_id: "",
    // password: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit
  } = methods;

  const onSubmit = async (formValue: {
    hotel_name: string;
    first_name: string;
    last_name: string;
    email_id: string;
    mobile_number: string;
    password: string;
    noOfRoom?: string;
    country?: string;
    state?: string;
  }) => {
    console.log(formValue);
    const {hotel_name, first_name, last_name, email_id, mobile_number, password, noOfRoom, country, state } = formValue;
    setIsSubmitting(true);
    hotelUserRegisteration(first_name, last_name, hotel_name, mobile_number, email_id, password, country as string, state as string, Number(noOfRoom)).then(
      (res: any) => {
        if (res.status_message === "success") {
          // setAlert(true);
          // setError(false);
          setTimeout(() => {
            setIsSubmitting(false);
            navigate("/main/home", { replace: true });
          }, 500);
        } else {
          // console.log(res.message);
          // setAlert(false);
          // setError(true);
          setIsSubmitting(false);
        }
      },
      (error: any) => {
        setIsSubmitting(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
        // setLoading(false);
        // setMessage(resMessage);
      }
    );
    navigate("/main", { replace: true });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField
          name="hotel_name"
          label="Hotel Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"ri:hotel-line"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
        {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField name="firstName" label="First name" other={{}} />
          <RHFTextField name="lastName" label="Last name" other={{}} />
        </Stack> */}
        <RHFTextField
          name="first_name"
          label="First Name"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:user"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="last_name"
          label="Last Name"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:user"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="email_id"
          label="Email address"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"ic:outline-email"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="noOfRoom"
          label="Number of Rooms"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:building"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    sx={{}}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
        <RHFTextField
          name="county"
          label="Country"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:globe"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="state"
          label="State"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"material-symbols:location-on"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="mobile_number"
          label="Mobile Number"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"material-symbols:phone-android"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          sx={{ background: "#0323f8" }}
        >
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
