import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  InputAdornment,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Page } from "../../components/Page";
import {
  deleteRoomRatePlan,
  getCurrentUser,
  getRoomRatePlan,
  getRoomType,
} from "../../api-config/config";
import RoomType from "../../components/Rooms";
import EditIcon from "@mui/icons-material/Edit";
import RoomRatePlanType from "../../components/RoomRatePlan";
import Snackbar from "../../components/CustomSnackBar/AlertSnack";
import { set } from "lodash";
// import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import AlertSnack from "../../components/CustomSnackBar/AlertSnack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "25px",
  boxShadow: 24,
  p: 2,
};

function RoomTypeListGrid(props: any) {
  const [open, setOpen] = React.useState(false);
  const [roomTypeData, setRoomTypeData] = React.useState({} as any);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [open1, setOpen1] = useState(false);

  function onEditClick(rowData: any) {
    setRoomTypeData(rowData);
    setOpen(true);
  }
  const onDeleteClick = (rowData: any) => {
    setRoomTypeData(rowData);
    // console.log(rowData, "delete row data");

    setDeleteOpen(true);
  };
  const handleDeleteRoomPlan = () => {
    console.log(roomTypeData, "mydeleted stuff");
    // console.log(rowData,"my rowdata on delete click");
    deleteRoomRatePlan({ id: roomTypeData.id }).then((response) => {
      setOpen1(true);
      setSnackbarMessage(response);
      // setMessageType(response);
      setRoomTypeData({});
      setDeleteOpen(false);
      onRoomTypeAdded();
    });
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "image",
    //   headerName: "Image",
    //   headerClassName: "super-app-theme--header",
    //   width: 150,
    //   editable: true,
    // },
    {
      field: "roomtype",
      headerName: "Room Type",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "planname",
      headerName: "Plan name",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "included",
      headerName: "Included",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 120,

      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onClick={(event) => {
                  onEditClick(cellValues.row);
                  console.log(cellValues.row);
                }}
              >
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onClick={(event) => {
                  onDeleteClick(cellValues.row);
                  console.log(cellValues.row);
                }}
              >
                <DeleteForeverIcon style={{ color: "red" }} />
              </span>
            </Tooltip>
          </>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRoomTypeData({});
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setRoomTypeData({});
  };

  const [base, setBase] = React.useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = React.useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = React.useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const { row, roomTypeAdded, roomTypes } = props;
  console.log(roomTypes, "my roomtypes data for form");

  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: item?.id,
        roomtype: item.room_type_name,
        planname: item.name,
        included: item.included === "" ? "N/A" : item.included,
        amount: item.rate_amount,
        description: item.description === "" ? "N/A" : item.description,
        room_type_id: item.room_type_id,
      });
    });
  }
  function onRoomTypeAdded() {
    roomTypeAdded();
  }

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      {/* { <Snackbar message={snackbarMessage} type={messageType} duration={4000} />} */}
      {open1 && (
        <AlertSnack
          response={snackbarMessage}
          open={open1}
          setOpen={setOpen1}
        />
      )}
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            // sx={{ margin: "0px 2px",bgcolor:"#14A44D" }}
            style={{ backgroundColor: "#3B71CA", color: "white" }}
            onClick={handleClickOpen}
          >
            Add a Rate Plan
          </Button>
          {/* <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Room Type(S)
          </Button> */}
          {/* <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Save Short Order
          </Button> */}

          {/* <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button> */}
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Admin/Room Rate Plan List</h3>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
        getRowHeight={() => 60}
        rowsPerPageOptions={[10, 20, 40]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add Room Rate Plan Type</DialogTitle>
        <DialogContent sx={{ minWidth: "580px" }}>
          <h4 style={{ marginTop: -2 }}>
            Please Enter the Rate Plan Type Details below.
          </h4>
          <RoomRatePlanType
            handleClose={handleClose}
            onRoomTypeAdded={onRoomTypeAdded}
            roomTypeData={roomTypeData}
            roomTypes={roomTypes}
            setSnackbarMessage={setSnackbarMessage}
            open1={open1}
            setOpen1={setOpen1}
          />
        </DialogContent>
      </Dialog>
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
            style={{ margin: "2rem auto 4rem auto" }}
          >
            Are you sure you wanna delete?
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
            style={{ margin: "4rem auto" }}
          >
            {"Room Rate Plan : "}
            {roomTypeData.roomtype} {roomTypeData.planname}
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "2rem",
            }}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "#3B71CA", color: "white" }}
              onClick={() => handleDeleteRoomPlan()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={handleDeleteClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default function RoomsRatePlan() {
  const [roomtyelist, setroomtyelist] = useState();
  const [roomTypes, setRoomTypes] = React.useState({} as any);
  const loggedUser = getCurrentUser();
  const getRoomRatePlanList = (hotel_code: number) => {
    return getRoomRatePlan({ hotel_code }).then(
      (res: any) => {
        if (res?.status_message === "success") {
          // setSnackbarMessage(res.status_message)
          setroomtyelist(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  const getRoomTypeList = (hotel_code: number) => {
    return getRoomType(hotel_code).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setRoomTypes(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getRoomRatePlanList(loggedUser?.hotel_code);
    getRoomTypeList(loggedUser?.hotel_code);
  }, []);
  function roomTypeAdded() {
    getRoomRatePlanList(loggedUser?.hotel_code);
  }
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Rooms Rate Plan List
          </Typography>
          <RoomTypeListGrid
            row={roomtyelist}
            roomTypeAdded={roomTypeAdded}
            roomTypes={roomTypes}
          />
        </Container>
      </Page>
    </>
  );
}
