import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  getBlockData,
  getCurrentUser,
  getFloorData,
  getRoomType,
  insertRoomData,
  insertRoomType,
} from "../../api-config/config";
import { GridRowEditStartReasons } from "@mui/x-data-grid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItems = ["Light", "AC", "TV", "Fan"];
const AddRoomForm = (props: any) => {
  const { handleClickOpen, handleClose, onRoomAdded, roomData } = props;
  const [roomTypeList, setRoomTypeList] = useState([] as any);
  const [isInputSelectable, setInputSelectable] = useState(false);
  const [floorList, setfloorList] = useState([] as any);
  const [selectedBlock, setSelectedBlock] = useState({} as any);
  const [selectedFloor, setSelectedFloor] = useState({} as any);
  const [selectedBlockId, setSelectedBlockId] = useState(roomData?.block_id);
  const [selectedFloorId, setSelectedFloorId] = useState(roomData?.floor_id);
  const [blockList, setBlockList] = useState([] as any);
  const loggedUser = getCurrentUser();

  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          const roomTypeArray = [] as any;
          res.data?.forEach((roomType: any) => {
            const obj = {
              id: roomType.id,
              room_type_name: roomType.room_type_name,
            };
            roomTypeArray.push(obj);
          });
          setRoomTypeList([...roomTypeArray]);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);

  const defaultValues = {
    room_type_id: roomData?.room_type_id,
    room_number: Number(
      roomData?.room?.split(" ")[roomData?.room?.split(" ").length - 1]
    ),
    room_heading: roomData?.room?.split(" ")[0],
    floor_id: roomData?.floor_id,
    block_id: roomData?.block_id,
    description: roomData?.description,
  };

  const formValidation = Yup.object().shape({
    room_type_id: Yup.string().required("Please selecte Room type"),
    // room_number: Yup.number().required("Please enter Room number"),
    room_heading: Yup.string().required("Please selecte Room Heading"),
  });
  function onCancel() {
    console.log("close");
  }

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser);
    const payload = {
      room_number: formData.room_number,
      room_heading: formData.room_heading,
      hotel_code: loggedUser?.hotel_code,
      room_type_id: formData.room_type_id,
      floor_id: Number(selectedFloorId),
      block_id: Number(selectedBlockId),
      description: formData.description,
    };
    console.log(payload);
    if (payload.block_id && payload.floor_id) {
      insertRoomData(payload).then((response: any) => {
        console.log(response);
        onRoomAdded();
        handleClose();
      });
    }
  };

  function getBlockList(hotel_code: any) {
    getBlockData(hotel_code).then((response: any) => {
      setBlockList([...response.data]);
      //   if(response.data.length) {
      //     setSelectedBlock({... blockList, id: response.data[0].id, block_name: response.data[0].block_name});
      //   }
    });
  }
  const getfloorList = (block_id: number) => {
    return getFloorData(block_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setfloorList(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  function onBlockSelected(block_id: number) {
    setSelectedBlockId(block_id);
    const block = blockList.find((block: any) => block.id === Number(block_id));
    if (block) {
      setSelectedBlock({ id: block.id, block_name: block.block_name });
    }
  }
  function onFloorSelected(floor_id: number) {
    setSelectedFloorId(floor_id);
    const floor = floorList.find((block: any) => block.id === Number(floor_id));
    if (floor) {
      setSelectedFloor({ id: floor.id, block_name: floor.floor_name });
    }
  }
  useEffect(() => {
    if (loggedUser?.hotel_code) {
      getBlockList(loggedUser?.hotel_code);
    }
  }, []);
  useEffect(() => {
    if (selectedBlock.id) {
      setSelectedBlockId(selectedBlock.id);
      getfloorList(selectedBlock.id);
    }
  }, [selectedBlockId]);
  useEffect(() => {
    if (roomData?.block) {
      getfloorList(roomData.block_id);
    }
  }, [roomData?.block_id]);

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={formValidation}
      onSubmit={(event) => handleSubmit(event)}
    >
      <Form className="grid gap-4 lg:grid-cols-1 p-8">
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Room Type
          </label>
          <Field as="select" type="number" name="room_type_id">
            <option value="">Select</option>
            {roomTypeList.map((list: any, key: number) => {
              return (
                <option value={list.id} key={key}>
                  {list.room_type_name}
                </option>
              );
            })}
          </Field>
          <p className="text-red-600">
            <ErrorMessage name="room_type_id" />
          </p>
        </FormGroup>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Room Heading
          </label>
          <Field
            name="room_heading"
            className="con-field"
            placeholder="Enter Room heading"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="room_heading" />
          </p>
        </FormGroup>

        <FormGroup>
          <label htmlFor="" className="pb-2">
            Room Number
          </label>
          <Field
            disabled={roomData?.room}
            name="room_number"
            className="con-field"
            placeholder="Enter Room number"
            type="number"
          />
          <p className="text-red-600">
            <ErrorMessage name="room_number" />
          </p>
        </FormGroup>

        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Block
            </label>
            <Field
              as="select"
              type="number"
              name="block_id"
              value={selectedBlockId}
              onChange={(e: any) => onBlockSelected(e.target.value)}
            >
              <option value="">Select</option>
              {blockList.map((list: any, key: number) => {
                return (
                  <option value={list.id} key={key}>
                    {list.block_name}
                  </option>
                );
              })}
            </Field>
            <p className="text-red-600">
              <ErrorMessage name="block_id" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Floor
            </label>
            <Field
              as="select"
              type="number"
              name="floor_id"
              value={selectedFloorId}
              onChange={(e: any) => onFloorSelected(e.target.value)}
            >
              <option value="">Select</option>
              {floorList.map((list: any, key: number) => {
                return (
                  <option value={list.id} key={key}>
                    {list.floor_name}
                  </option>
                );
              })}
            </Field>
            <p className="text-red-600">
              <ErrorMessage name="floor_id" />
            </p>
          </FormGroup>
        </div>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Description
          </label>
          <Field
            name="description"
            className="con-field"
            placeholder="Enter Description"
            id="description"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="description" />
          </p>
        </FormGroup>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <Button
              type="button"
              onClick={() => handleClose()}
              className="bg-sky-300 hover:bg-sky-400"
            >
              Cancel
            </Button>
          </FormGroup>
          <FormGroup>
            <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
              Save
            </Button>
          </FormGroup>
        </div>
      </Form>
    </Formik>
  );
};

export default AddRoomForm;
