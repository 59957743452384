import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  Link,
  ListItemButton,
} from "@mui/material";
import Iconify from "./Iconify";
import { blue } from "@mui/material/colors";

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 26,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 22,
//   height: 22,
//   color: "inherit",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// });

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "#1976d2 !important",
    fontWeight: "fontWeightMedium",
    whiteSpace: "nowrap",
    
    // bgcolor: "primary.main",
  };

  const activeSubStyle = {
    color: "#1976d2",
    fontWeight: "fontWeightMedium",
  };
  const listDot = {
    height: "6px",
    width: "6px",
    background: "red",
    borderRadius: "24px",
    margin: "6px",
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            listDot,
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
          <ListItemText
            disableTypography
            primary={title}
            sx={activeRootStyle}
          />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={activeRootStyle}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        // marginLeft: 2,
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <Box sx={listDot} />
      {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
      <ListItemText disableTypography primary={title} sx={activeRootStyle} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [openItems, setOpenItems] = useState({});
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const NestedList = styled("ul")({
    listStyleType: "none",
    // paddingLeft: "5px", // Adjust the indentation to your preference
  });

  const NestedListItem = styled("li")({
    fontWeight: "bold",
    marginBottom: "4px",
    color: "blue", // Set the color to blue
    fontFamily: "Arial, sans-serif", // Change the font family to your preferred choice
    fontSize: "14px", // Adjust the font size as needed
    // You can also add more styles here as needed
  });

  const NestedNestedList = styled("ul")({
    listStyleType: "square", // Use a different bullet style for nested lists
    paddingLeft: "20px", // Adjust the indentation for nested lists
  });

  const NestedNestedListItem = styled("li")({
    marginBottom: "2px",
    fontWeight: 500,
    fontSize: "13px",
   
    "& a": {
      textDecoration: "none",
      color: "#000000",
    },
     // Adjust spacing between nested list items
  }); 

  const toggleItem = (title) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };
  
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        <NestedList>
          {navConfig.map((item) => (
            item.sublinks && item.sublinks.length > 0 ? (
              <NestedListItem key={item.title}  onClick={() => toggleItem(item.title)}>
                {openItems[item.title] ? "[-] " : "[+] "}
                {item.title}
                {openItems[item.title] && (
                <NestedNestedList>
                  {item.sublinks.map((sublink) => (
                    <NestedNestedListItem key={sublink.title}>
                      <Link href={sublink.path} active={match}>{sublink.title}</Link>
                    </NestedNestedListItem>
                  ))}
                </NestedNestedList>
                )}
              </NestedListItem>
            )
              : (
                <NavItem key={item.title} item={item} active={match} />
              )

          ))}

        </NestedList>
        {/* {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))} */}
      </List>
    </Box>
  );
}
