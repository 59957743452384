import React, { useState } from "react";
import { DateRangePicker as DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangePicker = (props: any) => {
  const { value, setvalue, handleClose, isShowPastDate, startFalse } = props;

  function handleSelect(date: any) {
    console.log(value);
    console.log(date);
    if (startFalse) {
      date.selection.startDate = value.startDate
    }
    setvalue(date.selection);
  }

  return (
    <>
      <DateRange ranges={[value]} onChange={handleSelect}
      showPreview={false}
      className="border-2 border-gray-500"
      minDate={isShowPastDate? undefined : new Date()}
      />;
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: "gray" }} onClick={() => handleClose()}>
        <button>Close</button>
      </div>
    </>
  )
};
const DateRangeComponent = (props: any) => {
  const { range, onSetRange, isShowPastDate, startFalse } = props;
  const [isShowDateRangePicker, setIsShowDateRangePicker] = useState(false);
  console.log(range)

  function showDateRangePicker() {
    setIsShowDateRangePicker((prev: any) => !prev);
  }
  return (
    <>
      <div className="date-btn border-0 border-gray-500 py-1 px-1 w-52" onClick={() => showDateRangePicker()}>
        <h3 className="text-blue-600 cursor-pointer">
          {range?.startDate?.toLocaleDateString("default", { weekday: "long" })?.slice(0, 3)} {range?.startDate?.getDate()}{" "}
          {range?.startDate?.toLocaleString("default", { month: "long" })?.slice(0, 3)} -{" "}
          {range?.endDate?.toLocaleDateString("default", { weekday: "long" })?.slice(0, 3)} {range?.endDate?.getDate()}{" "}
          {range?.endDate?.toLocaleString("default", { month: "long" })?.slice(0, 3)}
        </h3>

      </div>
      {
        isShowDateRangePicker ?
          <div className="absolute z-50">
            <DateRangePicker value={range} startFalse={startFalse} setvalue={onSetRange} handleClose={showDateRangePicker} isShowPastDate={isShowPastDate}/>
          </div> : <></>
      }
    </>
  )
}

export default DateRangeComponent;
