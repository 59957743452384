// function OccupancyForecastingReport() {
//     return (
//       <>
//         <div className="font-bold text-lg mb-16">OccupancyForecastingReport</div>
//       </>
//     );
//   }

//   export default OccupancyForecastingReport;

import React, { useEffect, useState } from "react";
import { data } from ".././Occupancy Report/data";
import {
  getCurrentUser,
  getHistoryAndForecastReport,
} from "../../../api-config/config";
import LoaderDotted from "../../../components/LoaderDotted/LoaderDotted";
import { CSVLink } from "react-csv";
import FileDownload from "@mui/icons-material/FileDownload";
import { Tooltip } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";

function OccupancyForecastingReport() {
  const loggedUser = getCurrentUser();
  const [statementData1, setStatementData1] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [printType, setPrintType] = useState("Pdf");
  const [loaderT, setLoaderT] = useState(false);
  const [flashData, setFlashData] = useState({
    // previous_week_data: [],
    // previous_week_data_sum: {
    //   ARR: 0,
    //   Compliment_Rooms: 0,
    //   Departure_Rooms: 0,
    //   Expected_Arrival_Rooms: 0,
    //   Hold: 0,
    //   OOO_Rooms: 0,
    //   "Occupancy%": 0,
    //   Pax: 0,
    //   Room_Revenue: 0,
    //   Rooms_for_Sale: 0,
    //   Rooms_Sold: 0,
    //   Total_Room_Inventory: 0,
    // },
    next_month_data: [],
    next_month_data_sum: {
      ARR: 0,
      Compliment_Rooms: 0,
      Departure_Rooms: 0,
      Expected_Arrival_Rooms: 0,
      Hold: 0,
      OOO_Rooms: 0,
      "Occupancy%": 0,
      Pax: 0,
      Room_Revenue: 0,
      Rooms_for_Sale: 0,
      Rooms_Sold: 0,
      Total_Room_Inventory: 0,
    },
    today_data_required: [],
    today_data_required_sum: {
      ARR: 0,
      Compliment_Rooms: 0,
      Departure_Rooms: 0,
      Expected_Arrival_Rooms: 0,
      Hold: 0,
      OOO_Rooms: 0,
      "Occupancy%": 0,
      Pax: 0,
      Room_Revenue: 0,
      Rooms_for_Sale: 0,
      Rooms_Sold: 0,
      Total_Room_Inventory: 0,
    },
  });
  function formatDate(dateString: string) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const [year, month, day] = new Date(dateString)
      .toLocaleDateString(undefined, options)
      .split("/"); // Adjust the split character based on your locale
    console.log(year, "my year poleasrp[kpkfjdlk");

    return `${day}-${month}-${year}`;
  }
  const fetchForecastReport = (params: object) => {
    setLoaderT(true);
    getHistoryAndForecastReport(params).then((response: any) => {
      console.log(response, "from flash data........");

      setFlashData(response);
      setLoaderT(false);
    });
  };

  useEffect(() => {
    const todayDate = moment().format("YYYY-MM-DD");
    console.log(todayDate, "..........today");

    fetchForecastReport({
      pms_hotel_code: loggedUser?.hotel_code,
      // start_date: todayDate,
      start_date: todayDate,
    });
  }, []);

  const handleDateChange = (date: any) => {
    // Allow only current and past dates
    const currentDate = new Date();
    if (date <= currentDate) {
      setSelectedDate(date);
    }
  };
  const handleSearch = () => {
    const date = new Date(selectedDate).toLocaleDateString().split("/");
    const formatdate = `${date[2]}-${date[0]}-${date[1]}`;
    console.log(formatdate, "formatred date-------------------");
    console.log(
      moment(selectedDate).format("YYYY-MM-DD"),
      "this is moment data"
    );

    fetchForecastReport({
      pms_hotel_code: loggedUser?.hotel_code,
      // start_date: todayDate,
      start_date: moment(selectedDate).format("YYYY-MM-DD"),
    });
  };

  const onPrintTypeSelected = (value: any) => {
    setPrintType(value);
  };

  const generatePDF = () => {
    const pdf = new jsPDF();
    const table: any = document.getElementById("myTable");

    pdf.setFontSize(10);

    // First line
    pdf.text(
      `Account Statement for ${new Date(selectedDate).toLocaleDateString()}`,
      10,
      10
    );

    // Second line (increase y-coordinate to create a new line)
    pdf.text(loggedUser.hotel_name, 10, 15);

    // Third line (increase y-coordinate again)
    pdf.text(loggedUser.address, 10, 20);

    html2canvas(table).then((canvas: any) => {
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 30, 190, 0);
      pdf.save("statement.pdf");
    });
  };

  if (loaderT) {
    return <LoaderDotted />;
  } else {
    // return <LoaderDotted/>
    return (
      <div className="overflow-x-auto">
        <div className="font-bold text-xl mb-16 text-blue-800">
          Occupancy Forecasting Report
        </div>
        <div className="text-md mt-12">
          <p>{loggedUser?.hotel_name}</p>
          <p>{`${loggedUser?.city}, ${loggedUser?.state}, ${loggedUser?.country}`}</p>
          <p>{`Phone : ${loggedUser?.mobile_number}`}</p>
          <p>{`Fax No : ${loggedUser?.billing_fax_number}`}</p>
          <p>{`Email : ${loggedUser?.email_id}`}</p>
          <p>{`Website : ${loggedUser?.website_link}`}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
            margin: "10px 0 20px 0",
            padding: "18px 12px",
          }}
        >
          <div
            style={{
              // border: "1px solid black",

              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DatePicker
              showIcon
              withPortal
              selected={selectedDate}
              onChange={handleDateChange}
              maxDate={new Date()} // Restrict future dates
              className="my-calendar"
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div style={{}}>
            {/* <InputLabel sx={{ color: "black" }}>Select Download Type</InputLabel> */}
            <Select
              sx={{ backgroundColor: "#fff", color: "black" }}
              value={printType}
              // label={printType}
              onChange={(e: any) => onPrintTypeSelected(e.target.value)}
            >
              {/* <MenuItem value={"Select"}>Select</MenuItem> */}
              <MenuItem value={"Pdf"}>PDF</MenuItem>
              <MenuItem value={"Excel"}>EXCEL</MenuItem>
            </Select>
            {printType === "Pdf" ? (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
                onClick={generatePDF}
              >
                Download
              </button>
            ) : (
              <CSVLink data={statementData1} filename={"Account-statement.csv"}>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
                  // onClick={generatePDF}
                >
                  Download
                </button>
              </CSVLink>
            )}
            {/* <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-4"
              onClick={generatePDF}
            >
              PDF
            </button> */}
          </div>
        </div>

        <div style={{ width: "100%", overflowX: "auto" }}>
          <table
            id="myTable"
            className="min-w-full bg-white border border-gray-300"
          >
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="border px-6 py-3 text-left text-sm w-32">
                  Date
                </th>
                {/* <th className="border px-6 py-3 text-left text-sm">Day</th> */}
                <th className="border px-6 py-3 text-left text-sm">
                  Rooms Occupied
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  Rooms for Sale
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  Expected Arrival Rooms
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  Compliment Rooms
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  House Use
                </th>
                <th className="border px-6 py-3 text-left text-sm">Hold</th>
                <th
                  className="border px-6 py-3 text-center text-sm"
                  style={{ minWidth: "10rem" }}
                >
                  Occupancy %
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  Room Revenue
                </th>
                <th className="border px-6 py-3 text-left text-sm">ARR</th>
                <th className="border px-6 py-3 text-left text-sm">
                  Departure Rooms
                </th>
                <th className="border px-6 py-3 text-left text-sm">
                  OOO Rooms
                </th>
                <th className="border px-6 py-3 text-left text-sm">Pax</th>
                <th className="border px-6 py-3 text-left text-sm">
                  Total Room Inventory
                </th>
              </tr>
            </thead>

            <tbody>
              {/* <tr className="bg-grey-400">
                <td
                  className="border px-8 py-2 text-left text-white-500"
                  colSpan={15}
                >
                  History
                </td>
              </tr> */}
              {/* {flashData?.previous_week_data?.map((entry: any) => (
                <tr key={entry.id}>
                  <td
                    className="border px-6 py-2"
                    style={{ minWidth: "12rem" }}
                  >
                    {`${entry.Date}, ${entry.Day.slice(0, 3)}`}
                  </td>
                  <td className="border px-4 py-2">{entry.Day}</td>
                  <td className="border px-4 py-2">{entry.Rooms_Occupied}</td>
                  <td className="border px-4 py-2">{entry.Rooms_for_Sale}</td>
                  <td className="border px-4 py-2">
                    {entry.Expected_Arrival_Rooms}
                  </td>
                  <td className="border px-4 py-2">{entry.Compliment_Rooms}</td>
                  <td className="border px-4 py-2">0</td>
                  <td className="border px-4 py-2">{entry.Hold}</td>
                  <td className="border px-4 py-2">{entry["Occupancy%"]}</td>
                  <td className="border px-4 py-2">{entry.Room_Revenue}</td>
                  <td className="border px-4 py-2">{entry.ARR}</td>
                  <td className="border px-4 py-2">{entry.Departure_Rooms}</td>
                  <td className="border px-4 py-2">{entry.OOO_Rooms}</td>
                  <td className="border px-4 py-2">{entry.Pax}</td>
                  <td className="border px-4 py-2">
                    {entry.Total_Room_Inventory}
                  </td>
                </tr>
              ))} */}
              {/* <tr className="bg-gray-200">
                <td className="border px-4 py-2">Total</td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Rooms_Sold}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Rooms_for_Sale}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Expected_Arrival_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Compliment_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {data.previous_week_data_sum.House_Use}0
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Hold}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum["Occupancy%"].toFixed(2)}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Room_Revenue}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.ARR}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Departure_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.OOO_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Pax}
                </td>
                <td className="border px-4 py-2">
                  {flashData.previous_week_data_sum.Total_Room_Inventory}
                </td>
              </tr> */}
              <tr>
                <td className="border px-4 py-2" colSpan={15}>
                  Today
                </td>
              </tr>
              {flashData?.today_data_required?.map((entry: any) => (
                <tr key={entry.id}>
                  {/* <td className="border px-6 py-2 w-32">{entry.Date}</td> */}
                  {/* <td className="border px-4 py-2">{entry.Day}</td> */}
                  <td
                    className="border px-6 py-2"
                    style={{ minWidth: "12rem" }}
                  >
                    {`${entry.Date}, ${entry.Day.slice(0, 3)}`}
                  </td>
                  <td className="border px-4 py-2">{entry.Rooms_Occupied}</td>
                  <td className="border px-4 py-2">{entry.Rooms_for_Sale}</td>
                  <td className="border px-4 py-2">
                    {entry.Expected_Arrival_Rooms}
                  </td>
                  <td className="border px-4 py-2">{entry.Compliment_Rooms}</td>
                  <td className="border px-4 py-2">0</td>
                  <td className="border px-4 py-2">{entry.Hold}</td>
                  <td className="border px-4 py-2">{entry["Occupancy%"]}</td>
                  <td className="border px-4 py-2">{entry.Room_Revenue}</td>
                  <td className="border px-4 py-2">{entry.ARR}</td>
                  <td className="border px-4 py-2">{entry.Departure_Rooms}</td>
                  <td className="border px-4 py-2">{entry.OOO_Rooms}</td>
                  <td className="border px-4 py-2">{entry.Pax}</td>
                  <td className="border px-4 py-2">
                    {entry.Total_Room_Inventory}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-200">
                <td className="border px-4 py-2">Total</td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Rooms_Sold}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Rooms_for_Sale}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Expected_Arrival_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Compliment_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {/* {data.previous_week_data_sum.House_Use} */}0
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Hold}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum["Occupancy%"]}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Room_Revenue}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.ARR}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Departure_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.OOO_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Pax}
                </td>
                <td className="border px-4 py-2">
                  {flashData.today_data_required_sum.Total_Room_Inventory}
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2" colSpan={15}>
                  Forecast
                </td>
              </tr>
              {flashData?.next_month_data?.map((entry: any) => (
                <tr key={entry.id}>
                  <td
                    className="border px-6 py-2"
                    style={{ minWidth: "12rem" }}
                  >
                    {`${entry.Date}, ${entry.Day.slice(0, 3)}`}
                  </td>
                  <td className="border px-4 py-2">{entry.Rooms_Occupied}</td>
                  <td className="border px-4 py-2">{entry.Rooms_for_Sale}</td>
                  <td className="border px-4 py-2">
                    {entry.Expected_Arrival_Rooms}
                  </td>
                  <td className="border px-4 py-2">{entry.Compliment_Rooms}</td>
                  <td className="border px-4 py-2">0</td>
                  <td className="border px-4 py-2">{entry.Hold}</td>
                  <td className="border px-4 py-2">{entry["Occupancy%"]}</td>
                  <td className="border px-4 py-2">{entry.Room_Revenue}</td>
                  <td className="border px-4 py-2">{entry.ARR}</td>
                  <td className="border px-4 py-2">{entry.Departure_Rooms}</td>
                  <td className="border px-4 py-2">{entry.OOO_Rooms}</td>
                  <td className="border px-4 py-2">{entry.Pax}</td>
                  <td className="border px-4 py-2">
                    {entry.Total_Room_Inventory}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-200">
                <td className="border px-4 py-2">Total</td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Rooms_Sold}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Rooms_for_Sale}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Expected_Arrival_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Compliment_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {/* {data.previous_week_data_sum.House_Use} */}0
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Hold}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.["Occupancy%"].toFixed(2)}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Room_Revenue}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.ARR}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Departure_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.OOO_Rooms}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Pax}
                </td>
                <td className="border px-4 py-2">
                  {flashData?.next_month_data_sum?.Total_Room_Inventory}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default OccupancyForecastingReport;
