import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, IconButton, InputAdornment, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { hotelLogin } from "../../../api-config/config";

export default function LoginForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    // mobilenumber: Yup.string().required("User name is required"),
    hotelcode: Yup.number().required("Hotel Code is required"),
    email: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    mobilenumber: "",
    email: "",
    hotelcode: 0,
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formValue: {
    mobilenumber: string;
    email: string;
    hotelcode: number;
    password: string;
  }) => {
    const { mobilenumber, email, hotelcode, password } = formValue;
    setIsSubmitting(true);
    hotelLogin(mobilenumber, email, hotelcode, password).then(
      (res: any) => {
        if (res.status_message === "success") {
          if (res.status_message === "success") {
            localStorage.setItem("token", res.token);
            localStorage.setItem("refreshToken", res.refreshToken);
            localStorage.setItem("user", JSON.stringify(res.data));
          }
          setAlert(true);
          setError(false);
          setTimeout(() => {
            setIsSubmitting(false);
            navigate("/main/home", { replace: true });
          }, 500);
        } else {
          setIsSubmitting(false);
          setAlert(false);
          setErrorMessage(res.message);
          setError(true);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {alert && <Alert severity="success">Login successful !</Alert>}

      <Stack spacing={3}>
        <RHFTextField name="hotelcode" label="Hotel Code" other={{}} />
        <RHFTextField name="mobilenumber" label="Mobile Number" other={{}} />
        <RHFTextField name="email" label="Email Address" other={{}} />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    sx={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ color: "#0323f8", fontWeight: 700 }}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        className="bg-sky-600 hover:bg-sky-400 text-stone-50"
        sx={{ background: "red" }}
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {errorMessage}
        </Alert>
      )}
    </FormProvider>
  );
}
