import React, { useEffect, useState } from "react";
import AvaibilityAnalysis from "./AvaibilityAnalysis";
import HotelStatus from "./HotelStatus";
import RevenueStatus from "./RevenueStatus";
import OccupancyChart from "./OccupancyChart";
import RevenueChart from "./RevenueChart";
import Bob from "./Bob";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Tooltip,
  listItemSecondaryActionClasses,
} from "@mui/material";
import DateRangeComponent from "../../components/DateRangePicker";
import { CSVLink } from "react-csv";
import {
  availability_analysis_pms,
  bob_analysis_pms,
  getCurrentUser,
} from "../../api-config/config";
import moment from "moment";
import Source from "./Source";
import MarketAnalysis from "./MarketAnalysis";
import OtaPerformance from "./OtaPerformance";
import Demo from "./Demo";
import DemoData from "./DemoData";
import Pdf from "./Pdf";
import AvaibilityTable from "./AvaibilityTable";

// const hotel_code: any = 100087;
const from_date: string = moment().format("YYYY-MM-DD");
const to_date: string = moment().add(1, "days").format("YYYY-MM-DD");

const loggedUser = getCurrentUser();
interface AnalysisData {
  data?: any;
  title?: string;
  // ocp?: any;
  // revPar?: any;
}
// interface BobData {}
// interface RoomData {
//   // Define the properties based on your actual structure
//  arr: string;

//   // Add other properties if needed
// }

// availability_analysis_pms(from_date, to_date, loggedUser?.hotel_code).then(
//   (response: any) => {
//     console.log(response.data);
//   }
// );
// console.log(
//   availability_analysis_pms(from_date, to_date, loggedUser?.hotel_code),
//   "data fetched api "
// );

// useEffect(() => {
//     availability_analysis_pms( "2024-01-01" ,"2024-01-15" ,loggedUser?.hotel_code);
// }, []);

const ManagementDashboard = () => {
  const [loader, setLoader] = useState(false);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [occupancyChartData, setOccupancyChartData] = useState([]);
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  const [arrData, setArrData] = useState<AnalysisData[]>([]);
  const [bobData, setBobData] = useState([]);
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchCheckOutOrder(startDateString, endDateString);
    bob_analysis_pms(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setArrData([
        { data: response.data.ARR, title: "ARR" },
        { data: response.data.OCP, title: "OCP" },
        { data: response.data.RevPAR, title: "RevPAR" },
      ]);
      setBobData(response.data.bob);
    });
  }
  function fetchCheckOutOrder(startDateString: any, endDateString: any) {
    setLoader(true);
    availability_analysis_pms(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setLoader(false);
      setOccupancyChartData(response.data);

      // setExpecDepart(response.data);
      // getRows(response.data);
    });
  }

  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth),
      end: eDate.setMonth(currentMonth),
    };
  }
  const checkOccdata = () => {
    return occupancyChartData?.length > 10 ? true : false;
  };

  useEffect(() => {
    // fetchCheckOutOrder(range.startDate, range.endDate);
    const getObj = getDateRange(new Date(), new Date());
    const start = moment().format("YYYY-MM-DD");
    const end = new Date(getObj.end);

    fetchCheckOutOrder(from_date, start);
    bob_analysis_pms(from_date, start, loggedUser?.hotel_code).then(
      (response: any) => {
        setArrData([
          { data: response.data.ARR, title: "ARR" },
          { data: response.data.OCP, title: "OCP" },
          { data: response.data.RevPAR, title: "RevPAR" },
        ]);
        setBobData(response.data.bob);
      }
    );
  }, []);

  return (
    <>
      <div
        style={{
          color: "#0323f8",
          marginLeft: 10,
          fontWeight: 600,
          fontSize: 22,
        }}
      >
        Management Dashboard
      </div>
      <Box
        sx={{
          // height: "100vh",
          width: "inherit",
          marginTop: 4,
          "& .super-app-theme--header": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      >
        <p className="m-2.5">Select Date Range</p>
        <div className="flex justify-between p-2">
          <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
            <div className="border">
              <DateRangeComponent
                range={range}
                onSetRange={onSetRange}
                isShowPastDate={true}
              />
            </div>
            <Button
              type="button"
              onClick={() => searchWithDateRange()}
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              Search
            </Button>
          </Stack>
          {/* <div className="flex justify-end mx-4">
            <CSVLink 
            data={dynamicRows} filename={"Discount-Report-list.csv"}>
              <Tooltip title="Download Excel">
                <Button
                  type="button"
                  // onClick={() => searchWithDateRange()}
                  className="bg-sky-600 hover:bg-sky-400 text-stone-50"
                >
                  Download
                </Button>
              </Tooltip>
            </CSVLink>
          </div> */}
        </div>
      </Box>
      <div className="grid grid-cols-4 gap-5 h-72">
        {arrData.map((ele: any, i: any) => (
          <RevenueStatus key={i + 1} ele={ele} />
        ))}

        {/* <RevenueStatus />
        <RevenueStatus /> */}
        <HotelStatus />
      </div>
      <div
        className={`grid grid-cols-${checkOccdata() ? "1" : "2"} gap-5 my-7`}
      >
        <div style={{ marginBottom: "10px" }}>
          <OccupancyChart occupancyChartData={occupancyChartData} />
        </div>
        {!checkOccdata() ? (
          <div>
            <Bob bobData={bobData} />
          </div>
        ) : null}
      </div>

      {checkOccdata() ? (
        <div>
          <Bob bobData={bobData} />
        </div>
      ) : null}
      <div className="grid grid-cols-2 gap-5 ">
        <RevenueChart />
        <OtaPerformance />
      </div>

      {/* <div className="grid grid-cols-2 gap-5 my-7">
        <Source />
        <MarketAnalysis />
      </div> */}
      {/* <AvaibilityAnalysis /> */}
      {/* <Demo /> */}
      {/* <DemoData /> */}
      {/* <Pdf /> */}
      {/* <AvaibilityTable /> */}
    </>
  );
};

export default ManagementDashboard;
