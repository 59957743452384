import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import { getCurrentUser, getRoomType } from "../../api-config/config";
import RoomType from "../../components/Rooms";
import EditIcon from "@mui/icons-material/Edit";

function RoomTypeListGrid(props: any) {
  const [open, setOpen] = React.useState(false);
  const [roomTypeData, setRoomTypeData] = React.useState({} as any);
  function onEditClick(rowData: any) {
    setRoomTypeData(rowData);
    setOpen(true);
  }
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: true,
    },
    {
      field: "roomtype",
      headerName: "Room Type",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "occupancy",
      headerName: "Occupancy",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "rooms",
      headerName: "Rooms",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "extra",
      headerName: "Extra Bed(s) Allowed",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 100,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={(event) => {
              onEditClick(cellValues.row);
              console.log(cellValues.row);
            }}
          >
            <EditIcon />
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRoomTypeData({});
  };
  const [base, setBase] = React.useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = React.useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = React.useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const { row, roomTypeAdded } = props;
  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        image: "N/A",
        roomtype: item.room_type_name,
        occupancy: item.higher_occupancy,
        rooms: item.no_rooms_stock,
        extra: item.extra_bed,
        action: "",
        status: item.is_active,
        sort: 0,
        price: item.price,
        floor_id: item.floor_id,
        hotel_code: item.hotel_code,
        base_occupancy: item.base_occupancy,
        stock: item.no_rooms_stock,
        extra_bed_price: item.extra_bed_price,
        amenities_ids: item.amenities_ids,
        service_charge: item.service_charge,
        tax_ids: item.tax_ids,
      });
    });
  }
  function onRoomTypeAdded() {
    roomTypeAdded();
  }

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add a Room Type
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Room Type(S)
          </Button>
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Save Short Order
          </Button>

          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Main/Room Type List</h3>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={8}
        getRowHeight={() => 60}
        rowsPerPageOptions={[]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add Room Type</DialogTitle>
        <DialogContent sx={{ minWidth: "580px" }}>
          <h4 style={{ marginTop: -2 }}>
            Please Enter the Room Type Details below.
          </h4>
          <RoomType
            handleClose={handleClose}
            onRoomTypeAdded={onRoomTypeAdded}
            roomTypeData={roomTypeData}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default function RoomTypeList() {
  const [roomtyelist, setroomtyelist] = useState();
  const loggedUser = getCurrentUser();
  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setroomtyelist(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);
  function roomTypeAdded() {
    getRoomTypeList(loggedUser?.hotel_code, 1);
  }
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Room Type List
          </Typography>
          <RoomTypeListGrid row={roomtyelist} roomTypeAdded={roomTypeAdded} />
        </Container>
      </Page>
    </>
  );
}
