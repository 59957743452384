import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import { Navigate, useNavigate } from "react-router-dom";
import { changeCheckingInfo, checkInFromHotel } from "../../api-config/config";
import moment from "moment";

const FolioDetailsRight = (props: any) => {
  const { folioDetails } = props;
  const navigate = useNavigate();

  function onPaymentFolioClick() {
    navigate("/main/foliodetails", {
      state: {
        booking_id: folioDetails?.booking_id,
      },
    });
  }

  const handleCheckIn = async () => {
    folioDetails.check_in_time = moment().format("YYYY-MM-DD HH:mm:ss");
    folioDetails.booking_status = "check_in";
    folioDetails.room_type_id = folioDetails.room_booking[0].room_type_id;
    const res = await changeCheckingInfo(folioDetails);
    if (res.status_message === "success") {
      window.location.reload();
    }
  };

  return (
    <div className="flex justify-between text-sm flex-wrap">
      <div className="w-[49.5%]">
      <div className="w-[100%]">
          <p className="text-lg">
            <b>Stay Details</b>
          </p>
          <br />
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Check-In: </b>
            </span>
            <span>{new Date(folioDetails?.from_date)?.toDateString()}</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Duration: </b>
            </span>
            <span>{folioDetails?.nights} Night (s)</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Check-Out: </b>
            </span>
            <span>{new Date(folioDetails?.to_date)?.toDateString()}</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Extra Bed: </b>
            </span>
            <span>0</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Purpose: </b>
            </span>
            <span></span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Source: </b>
            </span>
            <span>{folioDetails?.booking_from}</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Type: </b>
            </span>
            <span>{folioDetails?.room_booking[0]?.room_title}</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Mkt Sgmt: </b>
            </span>
            <span></span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Sales Person: </b>
            </span>
            <span></span>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="w-[100%] flex-wrap flex justify-between">
          {/* <div className="w-[49.5%]"> */}
          {/* <p>
              <b>Arrivals#</b>
            </p> */}
          <br />
          <br />
          {/* <div className="flex w-[100%] py-[3px] flex-wrap">
              <span className="w-[40%]">
                <b>Arrival/Flight#: </b>
              </span>
              <span></span>
            </div> */}
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Arrival Time: </b>
            </span>
            <span> 12:00 PM</span>
          </div>
          <div className="flex w-[100%] py-[3px] flex-wrap">
            <span className="w-[40%]">
              <b>Departure Time: </b>
            </span>
            <span> 11:00 AM</span>
          </div>
          {/* <div className="flex w-[100%] py-[3px] flex-wrap">
              <span className="w-[40%]">
                <b>Send Email: </b>
              </span>
              <span></span>
            </div> */}
        </div>
      </div>
      <div className="w-[49.5%]">
        <p className="text-lg">
          <b>Booking Details</b>
        </p>
        <br />
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%]">Room Charges: </span>
          <span className=" w-[40%] text-right">
            {convertToRupee(folioDetails?.total_sale_amount)}
          </span>
        </div>
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%]">Discount: </span>
          <span className=" w-[40%] text-right">
            {convertToRupee(
              folioDetails?.discount_value +
                folioDetails?.charge_till_now?.discount_amount -
                folioDetails?.charge_till_now?.room_discount_till_now
            )}
          </span>
        </div>
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%]">Total Tax(es):</span>
          <span className=" w-[40%] text-right">
            {convertToRupee(
              folioDetails?.charge_till_now?.tax_amount +
                folioDetails?.tax_amount -
                folioDetails?.charge_till_now?.room_tax_till_now
            )}
          </span>
        </div>
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%]">Other Charges:</span>
          <span className=" w-[40%] text-right">
            {convertToRupee(folioDetails?.charge_till_now.other_charges)}
          </span>
        </div>
        <hr className="w-[80%]" />
        <div className="flex w-[100%] py-2 flex-wrap">
          <span className="w-[40%]">
            <b>Total: </b>
          </span>
          <span className=" w-[40%] text-right">
            {convertToRupee(
              folioDetails?.total_sale_amount -
                folioDetails?.discount_value +
                Math.round(
                  folioDetails?.charge_till_now?.room_tax_till_now +
                    folioDetails?.charge_till_now.other_charges
                )
            )}
          </span>
        </div>
        <hr className="w-[80%]" />
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%]">Amount Paid: </span>
          <span className=" w-[40%] text-right">
            {convertToRupee(folioDetails?.charge_till_now?.paid_amount)}
          </span>
        </div>
        <hr className="w-[80%]" />
        <div className="flex w-[100%] py-2 flex-wrap">
          <span className="w-[40%]">
            <b>Balance: </b>
          </span>
          <span className=" w-[40%] text-right">
            {/* {convertToRupee(
              folioDetails?.total_sale_amount -
                folioDetails?.discount_value +
                folioDetails?.charge_till_now?.tax_amount +
                folioDetails?.tax_amount +
                folioDetails?.charge_till_now?.room_tax_till_now +
                folioDetails?.charge_till_now.other_charges -
                folioDetails?.charge_till_now?.paid_amount +
                folioDetails?.charge_till_now?.refund
            )} */}
            {convertToRupee(folioDetails?.charge_till_now?.balance)}
          </span>
        </div>
        <hr className="w-[80%]" />

        <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1 my-4">
          <Button
            type="submit"
            name="paymentFolio"
            className="bg-sky-300 hover:bg-green-300"
            onClick={() => onPaymentFolioClick()}
          >
            Payment Folio
          </Button>
          {folioDetails.booking_status === "pending" && (
            <Button
              type="submit"
              name="checkin"
              className="bg-sky-300 hover:bg-green-300"
              onClick={() => handleCheckIn()}
            >
              Check In
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FolioDetailsRight;
