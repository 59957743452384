import { useState, useEffect } from "react";
import { getCurrentUser, getHotelDetails } from "../../api-config/config";
import CompanyDetailsForm from "../../components/ProfileDetailsForm/CompanyDetailsForm";

export default function CompanyDetails() {
  const [hotelDetails, setHotelDetails] = useState(undefined);
  const loggedUser = getCurrentUser();
  useEffect(() => {
    getHotelDetails(loggedUser?.hotel_code).then((response: any) => {
      setHotelDetails(response.data);
    });
  }, []);
  if (hotelDetails) {
    return <CompanyDetailsForm hotelDetails={hotelDetails} />;
  } else {
    return <></>;
  }
}
