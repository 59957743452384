import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  Stack,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  getCorporateData,
  insertCorporate,
  updateCorporate,
} from "../../api-config/corporate.api";
import { getCurrentUser } from "../../api-config/config";
import { useLocation } from "react-router-dom";
import DiscountPopup from "../../components/EditPosCommission";

const defaultValues = {
  corporate_name: "",
  main_office_address: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  first_name: "",
  office_phone: "",
  email_id: "",
  date_of_birth: "",
  hotel_code: "",
  mobile_number: "",
  file: "",
  market_segment: "",
  salutation: "",
  last_name: "",
  designation: "",
  extn: "",
  office_fax: "",
  gender: "",
  website: "",
  gst_number: "",
};
const defaultErrors = {
  corporate_name: "",
  gst_number: "",
  country: "",
  state: "",
  city: "",
  office_phone: "",
  zip_code: "",
  mobile_number: "",
  first_name: "",
  last_name: "",
  designation: "",
  email_id: "",
  date_of_birth: "",
  gender: "",
  website: "",
  main_office_address: "",
  // Add more fields here as needed
};
export default function AddTravelagent() {
  const [popup, setPopup] = useState(null);
  const [eligibleForDiscount, setEligibleForDiscount] = useState(false);
  const [addcreditcard, setAddcreditcard] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const [isOpenDiscountPopup, setDiscountPopupOpen] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(defaultValues);
  const [validationErrors, setValidationErrors] = useState(defaultErrors);
  const errorMessageRef = useRef(null);

  const location = useLocation();
  const loggedUser = getCurrentUser();
  const params = new URLSearchParams(window.location.search);
  const itemId = params.get("id");

  const openPopup = (e: any) => {
    setDiscountPopupOpen(true);
  };

  const closePopup = () => {
    setDiscountPopupOpen(false);
  };

  const handleSaveDiscount = (discountData: any) => {
    console.log("Discount data:", discountData);
    // Implement your logic to save the discount here
  };

  useEffect(() => {
    if (location?.state?.data > 0) {
      console.log(location.state.data, "my location ");
      setLoader(true);
      getCorporateData(loggedUser?.hotel_code, location?.state?.data).then(
        (res) => {
          setToggleBtn(true);
          setData(res.data[0]);
          setFormValues((prev: any) => ({ ...prev, ...res.data[0] }));
          setLoader(false);
        }
      );
    } else {
      setToggleBtn(false);
    }
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // const errors = { ...defaultErrors }; // Initialize with default errors
    const isValid = true;

    // Validate corporate_name
    // if (!formValues.corporate_name) {
    //   errors.corporate_name = "Corporate Name is required";
    //   isValid = false;
    // }
    // if (!formValues.gst_number) {
    //   errors.gst_number = "Gst number is required";
    //   isValid = false;
    // }

    // // Validate country
    // if (!formValues.country) {
    //   errors.country = "Country is required";
    //   isValid = false;
    // }

    // // Validate state
    // if (!formValues.state) {
    //   errors.state = "State is required";
    //   isValid = false;
    // }
    // if (!formValues.date_of_birth) {
    //   errors.state = "DOB is required";
    //   isValid = false;
    // }
    // if (!formValues.main_office_address) {
    //   errors.main_office_address = "Address is required";
    //   isValid = false;
    // }
    // if (!formValues.state) {
    //   errors.state = "State is required";
    //   isValid = false;
    // }
    // if (!formValues.city) {
    //   errors.city = "City is required";
    //   isValid = false;
    // }
    // if (!formValues.zip_code) {
    //   errors.zip_code = "Zip-code is required";
    //   isValid = false;
    // }
    // if (!formValues.first_name) {
    //   errors.first_name = "FirstName is required";
    //   isValid = false;
    // }
    // if (!formValues.last_name) {
    //   errors.last_name = "LastName is required";
    //   isValid = false;
    // }
    // if (!formValues.designation) {
    //   errors.designation = "Designation is required";
    //   isValid = false;
    // }
    // if (!formValues.office_phone) {
    //   errors.office_phone = "Office no is required";
    //   isValid = false;
    // }
    // if (!formValues.email_id) {
    //   errors.email_id = "Email id is required";
    //   isValid = false;
    // }
    // if (!formValues.mobile_number) {
    //   errors.mobile_number = "Mobile no is required";
    //   isValid = false;
    // }
    // if (!formValues.gender) {
    //   errors.gender = "Gender is required";
    //   isValid = false;
    // }
    // if (!formValues.date_of_birth) {
    //   errors.date_of_birth = "Date of Birth is required";
    //   isValid = false;
    // }

    // ... Repeat this for other fields

    if (isValid) {
      // Form submission logic here
      // If the form is valid, clear any previous validation errors
      // setValidationErrors(defaultErrors);

      const values = {
        ...defaultValues,
        ...data,
        ...formValues,
        hotel_code: loggedUser?.hotel_code,
      };
      if (toggleBtn) {
        console.log("running insert");
        updateCorporate({ ...values, id: location?.state?.data }).then(
          (res: any) => {
            console.log(res);
            if (res.status_message === "success") {
              setOpenSnackSuccess(true);
              setFormValues(defaultValues);
            } else {
              setOpenSnackFailure(true);
            }
          }
        );
      } else {
        console.log("running update");
        insertCorporate(values).then((res) => {
          console.log(res);
          if (res.status_message === "success") {
            setOpenSnackSuccess(true);
            setFormValues(defaultValues);
          } else {
            setOpenSnackFailure(true);
          }
        });
      }
    } else {
      // If the form is not valid, update the validation errors state
      // setValidationErrors(errors);

      // Scroll to the error message if it's present
      if (errorMessageRef.current) {
        (errorMessageRef.current as HTMLElement).scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Snackbar
          open={openSnackSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSnackSuccess(false)}
        >
          <Alert
            onClose={() => setOpenSnackSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully created.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackFailure}
          autoHideDuration={6000}
          onClose={() => setOpenSnackFailure(false)}
        >
          <Alert
            onClose={() => setOpenSnackFailure(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error while adding the corporate.
          </Alert>
        </Snackbar>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          Add/Edit Corporate
        </Typography>
        {/* <div
          className="bg-slate-200 py-4 "
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 18,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold">
            {" "}
            Main / List of Corporate / Add/Edit Corporate
          </span>
        </div> */}
        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">
              Main / List of Corporate / Add/Edit Corporate
            </span>
          </div>
        </div>
        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">
              Please enter the Corporate details below.
            </span>
          </div>
        </div>

        {/* form start cdetails */}
        <div className="p-8 bg-white rounded-b-lg shadow-md grid grid-cols-2 gap-4">
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Corporate Name *
            </label>
            <input
              type="text"
              id="corporate_name"
              name="corporate_name"
              value={formValues.corporate_name}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-green-500 rounded focus:outline-none focus:border-blue-500"
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.corporate_name}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              VAT/GST
            </label>
            <div className="flex items-center">
              <input
                type="text"
                id="gst_number"
                name="gst_number"
                className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
                value={formValues.gst_number}
                onChange={handleInputChange}
              />

              {/* <a href="#" className="text-blue-600 ml-2 underline">
                Tax Type
              </a> */}
            </div>
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.gst_number}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Upload Logo
            </label>
            <input
              type="file"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="file"
              name="file"
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Market Segment
            </label>
            <select
              id="market_segment"
              name="market_segment"
              value={formValues.market_segment}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
            >
              <option value="Business">Business</option>
              <option value="Corporates">Corporates</option>
              <option value="Events">Events</option>
              <option value="Government/PSU">Government/PSU</option>
              <option value="Leisure">Leisure</option>
              <option value="Tour Operators">Tour Operators</option>
              <option value="Wedding">Wedding</option>
            </select>
          </div>
        </div>

        {/* form end cdetails */}
        {/* corporate details end */}
        {/* main office address start */}

        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">Main Office Address :</span>
          </div>
        </div>
        <div className="p-8 bg-white rounded-b-lg shadow-md grid grid-cols-2 gap-4">
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address Line 1 *
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-green-500 rounded focus:outline-none focus:border-blue-500"
              id="main_office_address"
              name="main_office_address"
              value={formValues.main_office_address}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.main_office_address}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Address Line 2
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Country*
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formValues.country}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.country}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              State*
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={formValues.state}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.state}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              City*
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="city"
              name="city"
              value={formValues.city}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">{validationErrors.city}</p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Zip Code*
            </label>
            <input
              type="number"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="zip_code"
              name="zip_code"
              value={formValues.zip_code}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.zip_code}
            </p>
          </div>
        </div>

        {/* main office address end */}
        {/* contact person details start */}
        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">
              Contact Person Details :
            </span>
          </div>
        </div>

        <div className="p-8 bg-white rounded-b-lg shadow-md grid grid-cols-3 gap-4">
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Salutation
            </label>
            <Select
              labelId="salutation-label"
              id="salutation"
              name="salutation"
              value={formValues.salutation}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="Dr.">Dr.</MenuItem>
              <MenuItem value="Mr.">Mr.</MenuItem>
              <MenuItem value="Mrs.">Mrs.</MenuItem>
              <MenuItem value="Ms.">Ms.</MenuItem>
            </Select>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              First Name *
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="first_name"
              name="first_name"
              value={formValues.first_name}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.first_name}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Last Name *
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="last_name"
              name="last_name"
              value={formValues.last_name}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.last_name}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Designation *
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="designation"
              name="designation"
              value={formValues.designation}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.designation}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Office Phone *
            </label>
            <input
              type="number"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="office_phone"
              name="office_phone"
              value={formValues.office_phone}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.office_phone}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Extn#
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="extn"
              name="extn"
              value={formValues.extn}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Office Fax
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="office_fax"
              name="office_fax"
              value={formValues.office_fax}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email Id *
            </label>
            <input
              type="email"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="email_id"
              name="email_id"
              value={formValues.email_id}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.email_id}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Mobile
            </label>
            <input
              type="number"
              className="w-full h-12 px-3 py-2 border-2 border-green-500 rounded focus:outline-none focus:border-blue-500"
              id="mobile_number"
              name="mobile_number"
              value={formValues.mobile_number}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.mobile_number}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Gender
            </label>
            <Select
              labelId="gender-label"
              id="gender"
              name="gender"
              value={formValues.gender}
              onChange={handleInputChange}
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              style={{ minWidth: "100px" }}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.gender}
            </p>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Date Of Birth *
            </label>
            <input
              type="date"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="date_of_birth"
              name="date_of_birth"
              value={formValues.date_of_birth}
              onChange={handleInputChange}
            />
            <p className="text-sm text-red-500 mt-1">
              {validationErrors.date_of_birth}
            </p>
          </div>
          <div className="mb-2 ">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Website
            </label>
            <input
              type="text"
              className="w-full h-12 px-3 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              id="website"
              name="website"
              value={formValues.website}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* contact person details end*/}
        {/* credit card details start */}
        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">Credit Card Details :</span>
          </div>
        </div>
        <div className="text-center py-5 p-8 bg-white  shadow-md">
          <table className="border-collapse w-full rounded-lg shadow-md">
            <thead className="text-white bg-blue-500 ">
              <tr>
                <th className=" py-2 px-4">#</th>
                <th className=" py-2 px-4">Name</th>
                <th className=" py-2 px-4">Card Number</th>
                <th className=" py-2 px-4">Type</th>
                <th className=" py-2 px-4">Expiry</th>
                <th className=" py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-100">
                <td className=" py-2 px-4">1</td>
                <td className=" py-2 px-4">SHIV</td>
                <td className=" py-2 px-4">7894 5612 30XX XXXX</td>
                <td className=" py-2 px-4">PLATINUM</td>
                <td className=" py-2 px-4">2028</td>
                <td className=" py-2 px-4">
                  <button className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded">
                    DELETE
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-start p-8 grid grid-cols-3 gap-4">
            <div className="mb-2">
              <label className="block text-md font-medium text-gray-700 mb-2 ">
                Credit Allowed{" "}
              </label>
              <Select
                labelId="market-segment-label"
                id="market-segment"
                className="w-full h-10 px-2 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
                style={{ minWidth: "100px" }}
              >
                <MenuItem value="segment1">No</MenuItem>
                <MenuItem value="segment2">Yes</MenuItem>
                <MenuItem value="segment2">Unlimited</MenuItem>
              </Select>
            </div>
            <div className="mb-2">
              <label className="block text-md font-medium text-gray-700 mb-2">
                Credit Limit :{" "}
              </label>
              <input
                type="text"
                className="w-full h-10 px-2 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-2">
              <label className="block text-md font-medium text-gray-700 mb-2">
                Payment Terms :{" "}
              </label>
              <input
                type="text"
                className="w-full h-10 px-2 py-2 border-2 border-gray-400 rounded focus:outline-none focus:border-blue-500"
              />
              {/* Day(s) */}
            </div>
            <div className="mb-2">
              <label className=""> Eligible for Discount </label>
              <input
                type="checkbox"
                className="ml-4  mr-2 border 2px #666666"
                checked={eligibleForDiscount}
                onChange={() => setEligibleForDiscount(!eligibleForDiscount)}
              />
            </div>
            {eligibleForDiscount && (
              <div className="py-4">
                <div className="font-bold">
                  Discount Details:
                  <span className="ml-16">
                    <input
                      type="number"
                      className="  py-1 px-2 border 2px #666666"
                      placeholder="0.00"
                      style={{ width: "100px" }}
                    />
                    <input type="radio" className="ml-2 mr-2" />%
                  </span>
                </div>
                <div className="">
                  <em> Global Discount 0.00%</em>
                </div>
                <div className="py-2">
                  <div
                    className="bg-[#CCCCCC] py-2 my-4"
                    style={{
                      marginLeft: 20,
                      fontWeight: 500,
                      fontSize: 16,
                      border: "2px solid #ccc",
                    }}
                  >
                    <span className="m-8 my-6 font-bold ">
                      Validity Details
                    </span>
                  </div>
                  <div className="underline text-blue-600">
                    <a href="" onClick={openPopup}>
                      Add/Edit Other Discount
                    </a>
                    <DiscountPopup
                      open={isOpenDiscountPopup}
                      onClose={closePopup}
                      onSave={handleSaveDiscount}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="underline text-blue-600">
          <button onClick={toggleAddCreditCard}>
            <span className="underline">Add Credit Card Details</span>
          </button>
        </div> */}
        {/* {addcreditcard && (
          <div className="py-4">
            <div className="">
              <input
                type="text"
                placeholder="Name on Card"
                className="ml-4 px-2 border 2px #666666"
                style={{ width: "350px" }}
              />
            </div>
            <div className="py-2">
              <input
                type="text"
                placeholder="Credit Card No."
                className="ml-4 px-1 border 2px #666666"
                style={{ width: "350px" }}
              />
              <Select
                className="ml-2 border 2px #666666"
                style={{ minWidth: "120px" }}
                placeholder="Credit Card"
              >
                <MenuItem value="segment1">Credit Card</MenuItem>
                <MenuItem value="segment2">Visa</MenuItem>
                <MenuItem value="segment3">MatserCard</MenuItem>
                <MenuItem value="segment4">Online Payment Gateway</MenuItem>
              </Select>
              <span className="font-bold ml-2"> Expiry :</span>
              <input
                type="text"
                placeholder="MM"
                className="ml-4 px-1 border 2px #666666 mr-2"
                style={{ width: "50px" }}
              />{" "}
              /
              <input
                type="text"
                placeholder="YYYY"
                className="ml-2 px-1 border 2px #666666"
                style={{ width: "90px" }}
              />
              <span className="font-bold ml-2"> CVC :</span>
              <input
                type="text"
                className="ml-2 px-1 border 2px #666666"
                style={{ width: "130px" }}
              />
            </div>
            <div className="pt-4">
              <div className="font-bold ml-2"> Billing Address*</div>
              <div className="pt-2">
                <input
                  type="text"
                  placeholder="Address Line"
                  className="ml-4 px-1 border 2px #666666"
                  style={{ width: "380px" }}
                />
                <Select
                  className="ml-2 border 2px #666666"
                  style={{ minWidth: "300px" }}
                  placeholder="Choose Country"
                >
                  <MenuItem value="segment1">India</MenuItem>
                  <MenuItem value="segment2">Aus</MenuItem>
                  <MenuItem value="segment3">Brazil</MenuItem>
                </Select>
              </div>
              <div className="py-2">
                <Select
                  className="ml-2 border 2px #666666"
                  style={{ minWidth: "220px" }}
                >
                  <MenuItem disabled value="">
                    State
                  </MenuItem>
                  <MenuItem value="segment1">Rajasthan</MenuItem>
                  <MenuItem value="segment2">Bihar</MenuItem>
                  <MenuItem value="segment3">Delhi</MenuItem>
                </Select>
                <input
                  type="text"
                  placeholder="City"
                  className="ml-2 px-1 border 2px #666666"
                  style={{ width: "180px" }}
                />
                <input
                  type="text"
                  placeholder="Zip Code"
                  className="ml-2 px-1 border 2px #666666"
                  style={{ width: "180px" }}
                />
              </div>
            </div>
          </div>
        )} */}
        {/* <div className="mb-4 pt-16 ml-6">
          <label className="">Credit Allowed </label>
          <Select
            labelId="market-segment-label"
            id="market-segment"
            className="ml-4 py-1 border 2px #666666"
            style={{ minWidth: "100px" }}
          >
            <MenuItem value="segment1">No</MenuItem>
            <MenuItem value="segment2">Yes</MenuItem>
            <MenuItem value="segment2">Unlimited</MenuItem>
          </Select>
        </div>
        <div className="mb-4 ml-10">
          <label className="">Credit Limit : </label>
          <input type="text" className="ml-4 py-1 border 2px #666666" />
        </div>
        <div className="mb-4 ml-2">
          <label className="">Payment Terms : </label>
          <input type="text" className="ml-4 py-1 mr-2 border 2px #666666" />
          Day(s)
        </div>
        <div className="mb-4">
          <label className=""> Eligible for Discount </label>
          <input
            type="checkbox"
            className="ml-4  mr-2 border 2px #666666"
            checked={eligibleForDiscount}
            onChange={() => setEligibleForDiscount(!eligibleForDiscount)}
          />
        </div>
        {eligibleForDiscount && (
          <div className="py-4">
            <div className="font-bold">
              Discount Details:
              <span className="ml-16">
                <input
                  type="number"
                  className="  py-1 px-2 border 2px #666666"
                  placeholder="0.00"
                  style={{ width: "100px" }}
                />
                <input type="radio" className="ml-2 mr-2" />%
              </span>
            </div>
            <div className="">
              <em> Global Discount 0.00%</em>
            </div>
            <div className="py-2">
              <div
                className="bg-[#CCCCCC] py-2 my-4"
                style={{
                  marginLeft: 20,
                  fontWeight: 500,
                  fontSize: 16,
                  border: "2px solid #ccc",
                }}
              >
                <span className="m-8 my-6 font-bold ">Validity Details</span>
              </div>
              <div className="underline text-blue-600">
                <a href="" onClick={openPopup}>
                  Add/Edit Other Discount
                </a>
                <DiscountPopup
                  open={isOpenDiscountPopup}
                  onClose={closePopup}
                  onSave={handleSaveDiscount}
                />
              </div>
            </div>
          </div>
        )} */}
        {/* credit card details end */}
        {/* other discount start */}
        <div
          className="bg-slate-200 py-2  text-left rounded-t-lg p-2 mt-4"
          style={{
            boxShadow:
              " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <div className="font-bold flex justify-start items-center">
            <span className="m-2 my-2 font-bold ">Other Discount :</span>
          </div>
        </div>
        {/* <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold ">Other Discount :</span>
        </div> */}
        <div className="text-center py-5">
          <table className="border-collapse ">
            <thead className="text-white bg-black">
              <tr>
                <th className="border py-2 px-4"> POS</th>
                <th className="border py-2 px-4"> Discounts</th>
                <th className="border py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
        <div className="underline text-blue-600">Add/Edit Other Discounts</div>
        {/* other discount end */}
        <div className="ml-16 mt-8">
          Note: Fields marked with an * are compulsory
        </div>
        <div className="ml-16 mt-8">
          {toggleBtn ? (
            <Button
              className="bg-blue-500 text-white font-bold border-2 border-gray-300 py-2 px-4 rounded hover:bg-blue-600 hover:text-white"
              onClick={() => handleSubmit()}
            >
              Update
            </Button>
          ) : (
            <Button
              className="bg-blue-500 text-white font-bold border-2 border-gray-300 py-2 px-4 rounded hover:bg-blue-600 hover:text-white"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          )}
          <Button className="ml-4 bg-blue-500 text-white font-bold border-2 border-gray-300 py-2 px-4 rounded hover:bg-blue-600 hover:text-white">
            Cancel
          </Button>
        </div>
      </Container>
    </>
  );
}
