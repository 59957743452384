import { Button, FormGroup, Input } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import {
  getCurrentUser,
  getFolioOptionsPms,
  insertChargesPms,
  insertOtherPaymentData,
  insertPaymentData,
} from "../../api-config/config";
import FolioOverviewEditOtherCharges from "./FolioOverviewEditOtherCharges";

const FolioOverviewOtherCharges = (props: any) => {
  const { folioDetails, fetch, setOpenOtherCharges, allowance } = props;

  const [bookingId, setBookingId] = useState(0);
  const loggedUser = getCurrentUser();
  const handleChange = (event: any) => {
    console.log(event.target.checked);
    setPaymentDetails((prev: any) => ({
      ...prev,
      extra_allowance: event.target.checked,
    }));
  };

  const voucherView = (res: any) => {
    window.open(res.voucher);
  };
  const [paymentDetails, setPaymentDetails] = useState({
    tax_amount: 0,
    description: "",
    paid_by: "",
    date: new Date(),
    charge_to: "",
    mode: "cash",
    pos_point: "",
    price: null,
    wallet: 0,
    online: 0,
    card: 0,
    upi: 0,
    extra_allowance: false,
    cash: 0,
    quantity: 1,
    discount_value: 0,
    discount_amount: 0,
    total_amount: 0,
  } as any);
  const [otherChargesDropDown, setOtherChargesDropDown] = useState([]);
  useEffect(() => {
    if (allowance) {
      setPaymentDetails((prev: any) => ({
        ...prev,
        extra_allowance: true,
      }));
    }
  }, [allowance]);

  const handleSubmit = (formData: any) => {
    console.log(formData, paymentDetails);
    console.log(formData, loggedUser, folioDetails);
    console.log(folioDetails, "before payload");

    // setInitialChargesData((prevData: any) => ({
    //   ...prevData,
    //   charge_to: paymentDetails?.charges_to || prevData.charge_to,
    //   pos_point: paymentDetails?.pos_point || prevData.pos_point,
    //   description: paymentDetails?.description || prevData.description,
    //   price:
    //     parseInt(paymentDetails?.price) !== null
    //       ? formData.price
    //       : prevData.price,
    // }));

    const payload: any = {
      ...paymentDetails,
      booking_id: bookingId,
      description: paymentDetails?.description,
      mode: paymentDetails?.mode,
      online: parseInt(paymentDetails?.online),
      upi: parseInt(paymentDetails?.upi),
      card: parseInt(paymentDetails?.card),
      cash: parseInt(paymentDetails?.cash),
      tax_amount: paymentDetails?.tax_amount,
      total_amount:
        parseInt(paymentDetails?.online) +
        parseInt(paymentDetails?.upi) +
        parseInt(paymentDetails?.card) +
        parseInt(paymentDetails?.cash) +
        parseInt(paymentDetails?.wallet),
      paid_by: paymentDetails?.paid_by,
      date: paymentDetails?.date,

      charge_to: paymentDetails?.charges_to,
      pos_point: paymentDetails?.pos_point,
      price: parseInt(paymentDetails?.price),
      wallet: paymentDetails?.wallet,
      quantity: paymentDetails?.quantity,
      extra_allowance: paymentDetails?.extra_allowance ? "yes" : "no",
      discount_value: paymentDetails?.discount_value,
      discount_amount: paymentDetails?.discount_amount,
      hotel_code: loggedUser?.hotel_code,
    };

    insertChargesPms(payload).then((response: any) => {
      console.log(response);
      // if (response.status_message === "success") {
      setOpenOtherCharges(false);
      fetch();
      if (
        response.status_message === "success" &&
        paymentDetails?.extra_allowance
      ) {
        voucherView(response);
      }
      // onFeatureAdded();
      // handleClose();
    });
  };
  useEffect(() => {
    // getFolioOptionsPms
    const payload = {
      hotel_code: loggedUser?.hotel_code || 0,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      group_booking_id: folioDetails?.group_booking_id || 0,
    };
    setBookingId(folioDetails?.booking_id);
    getFolioOptionsPms(payload).then((res) =>
      setOtherChargesDropDown(res.data)
    );
  }, [folioDetails]);
  return (
    <div className=" w-[100%] py-4 bg-[#e8eff5] mt-5 flex justify-center text-sm flex-wrap">
      <div className="w-[95%] flex flex-wrap items-center justify-evenly font-bold">
        <FormGroup className="w-[18%]">
          <InputLabel htmlFor="mode" className="">
            Charges To
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentDetails?.charge_to || ""}
            label="Mode"
            className="p-4 w-[100%]"
            onChange={(e) => {
              const remainingData = otherChargesDropDown?.find(
                (ele: any) => ele.charge_to === e.target.value
              ) as any;
              setPaymentDetails((prev: any) => ({
                ...prev,
                charge_to: e.target.value,
                ...remainingData,
              }));
            }}
          >
            <MenuItem value={""} key="select">
              Select
            </MenuItem>
            {otherChargesDropDown?.map((room: any, i: number) => {
              return (
                <MenuItem value={room?.charge_to} key={i}>
                  {room?.charge_to}
                </MenuItem>
              );
            })}
          </Select>
        </FormGroup>
        <FormGroup className="w-[18%]">
          <InputLabel htmlFor="mode" className="">
            POS Point
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentDetails?.pos_point || ""}
            label="Mode"
            className="p-4 w-[100%]"
            onChange={(e) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                pos_point: e.target.value,
              }))
            }
          >
            <MenuItem value={""} key="select">
              Select
            </MenuItem>
            {/* <MenuItem value={"Bar"} key="Bar">
              Bar
            </MenuItem>
            <MenuItem value={"Restaurant"} key="Restaurant">
              Restaurant
            </MenuItem>
            <MenuItem value={"Room Service"} key="Room Service">
              Room Service
            </MenuItem>
            <MenuItem value={"Travel Desk"} key="Travel Desk">
              Travel Desk
            </MenuItem> */}
            <MenuItem value={"Room Service"} key="Room Service">
              Room Service
            </MenuItem>
            <MenuItem value={"Restaurant"} key="Restaurant">
              Restaurant
            </MenuItem>
            <MenuItem value={"Laundry Charge"} key="Room Service">
              Laundry
            </MenuItem>
            <MenuItem value={"Room Charge"} key="Room Service">
              Room Charge
            </MenuItem>
            <MenuItem value={"Travel Desk"} key="Travel Desk">
              Travel Desk
            </MenuItem>
            <MenuItem value={"Miscellaneous"} key="Miscellaneous">
              Miscellaneous
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup className="w-[18%]">
          <InputLabel htmlFor="description">Description</InputLabel>
          <OutlinedInput
            type="text"
            value={paymentDetails?.description || ""}
            name="description"
            id="description1"
            onChange={(e: any) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          />
        </FormGroup>

        <FormGroup className="w-[18%]">
          <InputLabel htmlFor="price">Price</InputLabel>
          <OutlinedInput
            type="number"
            value={paymentDetails?.price || null}
            name="price"
            id="price2"
            onChange={(e: any) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                price: e.target.value,
                [paymentDetails.mode]: e.target.value,
              }))
            }
          />
        </FormGroup>
        <FormGroup>
          <InputLabel htmlFor="demo-simple-select-label">Tax</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentDetails?.tax_amount}
            label="Mode"
            className="p-4 w-[100%]"
            onChange={(e) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                tax_amount: e.target.value,
              }))
            }
          >
            <MenuItem value={0} key="0">
              0
            </MenuItem>
            <MenuItem value={5} key="0">
              5
            </MenuItem>
            <MenuItem value={12} key="12">
              12
            </MenuItem>
            <MenuItem value={18} key={18}>
              18
            </MenuItem>
          </Select>
        </FormGroup>
        {/* {
          allowance ?
            <FormGroup className="">
              <InputLabel htmlFor="extra_allowance">Allowance</InputLabel>
              <Checkbox
                className="p-4"
                checked={paymentDetails?.extra_allowance || false}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </FormGroup>
          :
            null
        } */}
        {/* <FormGroup className="w-[18%]">
          <InputLabel htmlFor="quantity">Quantity</InputLabel>
          <OutlinedInput
            type="number"
            value={paymentDetails?.quantity || null}
            name="quantity"
            id="quantity"
            onChange={(e: any) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                quantity: e.target.value
              }))
            }
          />
        </FormGroup> */}
        <FormGroup className="w-[100%] flex items-center align-center justify-center">
          <Button
            type="button"
            className="flex w-[30%] mt-5 bg-sky-600 hover:bg-sky-400 text-[white]"
            // onClick={() => console.log(paymentDetails)}
            onClick={() => handleSubmit(paymentDetails)}
          >
            Save
          </Button>
        </FormGroup>

        {/* <FolioOverviewEditOtherCharges initialData={initialData} /> */}
      </div>
    </div>
  );
};

export default FolioOverviewOtherCharges;
