import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import { useNavigate } from "react-router-dom";

const FolioOverviewLeft = (props: any) => {
  const { folioDetails } = props;
  console.log(folioDetails.guest_detail_new[0], "Main Guest");

  const navigate = useNavigate();

  // const handleClose = () => {
  //   onHandleClose();
  // };
  function onFolioClick() {
    navigate("/main/folio", {
      state: {
        booking_id: folioDetails?.booking_id,
      },
    });
    // handleClose();
  }

  return (
    <div className=" text-sm ">
      <div className=" p-2">
        <div className="flex w-[100%] py-[3px] flex-wrap">
          <span
            onClick={onFolioClick}
            className="ml-auto mr-2 text-[blue]"
            style={{ cursor: "pointer" }}
          >
            <b>Guest Folio </b>
          </span>
        </div>
        <div className="mb-2 ">
          <span className="mr-2">
            <b>Guest Name: </b>
          </span>
          <span className="text-[blue] font-semibold text-base">
            {folioDetails?.guest_detail_new?.[0]?.first_name +
              " " +
              folioDetails?.guest_detail_new?.[0]?.last_name}
            (<span className="text-[red]">{folioDetails?.customer_id}</span>)
          </span>
        </div>

        <div className="">
          <span className="">
            <b>Address: </b>
          </span>
          <span>{folioDetails?.guest_detail_new?.[0]?.address}</span>
        </div>
      </div>

      <div className=" grid grid-cols-2 p-2 gap-2">
        <div className="">
          <span className="">
            <b>Mobile No: </b>
          </span>
          <span>{folioDetails?.guest_detail_new?.[0]?.mobile_number}</span>
        </div>
        <div className="">
          <span className="">
            <b>Email: </b>
          </span>
          <span>{folioDetails?.guest_detail_new?.[0]?.email}</span>
        </div>
        <div className="">
          <p className="font-bold">Created On</p>
          <p>{new Date(folioDetails?.created_datetime).toDateString()}</p>
        </div>
        <div className="">
          <p className="font-bold">Stay Details</p>
          <p>{folioDetails?.room_booking[0].room_title}</p>
          <p>
            {new Date(folioDetails?.from_date).toDateString()} -{" "}
            {new Date(folioDetails?.to_date).toDateString()}
          </p>
          <p className="">({folioDetails?.nights} Nights)</p>
        </div>
        <div className="">
          <p className="font-bold">Room(s)/Person(s)</p>
          <p>
            Room(s){folioDetails?.no_of_rooms} ({folioDetails?.no_of_adults}{" "}
            Adult(s))
          </p>
        </div>
        <div className="">
          <p className="font-bold">Amount</p>
          <p
            className={`${
              folioDetails?.total_sale_amount < -1 ? "text-red-500" : ""
            }`}
          >
            {convertToRupee(folioDetails?.total_sale_amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FolioOverviewLeft;
