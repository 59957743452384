import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { Adminregister } from "../../../api-config/config";

export default function AdminRegisterForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstname: Yup.string().required("First name required"),
    lastname: Yup.string().required("Last name required"),
    username: Yup.string().required("User name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    mobileno: Yup.string().required("Mobile number required"),
  });

  const defaultValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobileno: "",
    username: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (formValue: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    mobileno: string;
    username: string;
  }) => {
    const { firstname, lastname, email, password, username, mobileno } =
      formValue;
    Adminregister(
      firstname,
      lastname,
      email,
      password,
      username,
      mobileno
    ).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setAlert(true);
          setError(false);
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 500);
        } else {
          setAlert(false);
          setError(true);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setError(true);
        console.log("error", resMessage);
        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {alert && <Alert severity="success">Registration successful !</Alert>}

        <RHFTextField
          name="hotelName"
          label="Hotel Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"ri:hotel-line"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
        <RHFTextField
          name="firstname"
          label="First Name"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:user"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="lastname"
          label="Last Name"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:user"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="username"
          label="User Name"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"mdi:user"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="email"
          label="Email address"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"ic:outline-email"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    sx={{}}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />

        <RHFTextField
          name="mobileno"
          label="Mobile Number"
          other={{}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <Iconify icon={"material-symbols:phone-android"} sx={{}} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ background: "#0323f8" }}
        >
          Register
        </LoadingButton>
        {error && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            Something Went Wrong!
          </Alert>
        )}
      </Stack>
    </FormProvider>
  );
}
