import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import AdminDashboardLayout from "./layouts/admin-dashboard";

import Login from "./pages/Login";
import AdminLogin from "./pages/admin/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import AdminRegister from "./pages/admin/Register";
import DashboardApp from "./pages/DashboardApp";
import HouseKeeping from "./pages/HouseKeeping";

import GuestLookUp from "./pages/guestLookup";
import CheckInList from "./pages/CheckInList";
import CheckOutList from "./pages/CheckOutList";
import ReservationList from "./pages/ReservationList";
import TempRoomList from "./pages/TempRoomList";
import NoShowList from "./pages/NoShowList";
import AmenitiesList from "./pages/admin/AmenitiesList";
import BlockList from "./pages/admin/BlockList";
import FloorList from "./pages/admin/FloorList";
import RoomTypeList from "./pages/admin/RoomTypeList";
import RoomList from "./pages/admin/RoomList";
import RoomTypeListCreate from "./pages/admin/RoomTypeListCreate";
import ExpectedArrivalList from "./pages/ExpectedArrivalList";
import GuestInHouseList from "./pages/GuestInHouseList";
import Calendar from "./components/Calendar";
import CancelledReservationList from "./pages/CancelledReservationList";
import RevenueReport from "./pages/RevenueReport";
import PaymentReport from "./pages/PaymentReport";
import FeaturesList from "./pages/admin/FeaturesList";
import Folio from "./pages/Folio";
import CashCounter from "./pages/CashCounter";
import FolioDetails from "./pages/FolioDetails";
import HotelDetails from "./pages/admin/HotelDetails";
import CompanyDetails from "./pages/admin/CompanyDetails";
import NightAuditSummary from "./pages/NightAuditSummary";
import NightAudit from "./pages/NightAudit";
import TaxList from "./pages/admin/TaxList";
import FundsOperation from "./pages/FundsOperation";
import NightAuditReport from "./pages/NightAuditReport.jsx";
import RegisteredCorporates from "./pages/admin/RegisteredCorporates";
import AddCorporate from "./pages/admin/AddCorporates";
import RegisteredTravelagent from "./pages/admin/RegisteredTravelagent";
import AddTravelagent from "./pages/admin/AddTravelagent";
import CancelledBillList from "./pages/CancelledBillList";
import SettlementReport from "./pages/SettlementReport";
import ManagerFlashReport from "./pages/ManagerFlashReport";
import SegmentReport from "./pages/SegmentReport";
import UnSettledReport from "./pages/UnSettledReport";
import HousekeepingReport from "./pages/HousekeepingReport";
import DiscrepnacyReport from "./pages/DiscrepancyReport";
import Reports from "./pages/Reports";
import AdminReports from "./pages/admin/AdminReports";
import TaxReport from "./pages/TaxReport";
import OTA from "./pages/admin/ota";
import OccupancyForecastingReport from "./pages/Reports/Reservations Report/OccupancyForecastingReport";
import AllUsers from "./pages/Reports/Activity Log/AllUsers";
import PkgReport from "./pages/Reports/Activity Log/PkgReport";
import RatePkgReport from "./pages/Reports/Activity Log/RatePkgReport";
import RateVariations from "./pages/Reports/Activity Log/RateVariations";
// import AllowanceReport from "./pages/Reports/Discount Report/AllowanceReport";
import DiscountReport from "./pages/DiscountReports";
import AdvanceDepositReport from "./pages/Reports/Financial Report/AdvanceDepositReport";
import DailyRevenueReport from "./pages/Reports/Financial Report/DailyRevenueReport";
import FlashReport from "./pages/Reports/Financial Report/FlashReport";
// import JournalReport from "./pages/Reports/Financial Report/JournalReport";
import MonthlySummaryReport from "./pages/Reports/Financial Report/MonthlySummaryreport";
import PostingAuditReport from "./pages/Reports/Financial Report/PostingAuditReport";
import RevParRoomReportMonthwise from "./pages/Reports/Financial Report/RevParRoomReportMonthwise";
import AddWithdrawn from "./pages/Reports/Fund Operations/AddWithdrawn";
import GuestContactReport from "./pages/Reports/Guest Report/GuestContactReport";
import GuestHistoryReport from "./pages/Reports/Guest Report/GuestHistoryReport";
import GuestLedger from "./pages/Reports/Guest Report/GuestLedger";
import HotelTrialBalanceReport from "./pages/Reports/Night Audit Report/HotelTrialBalanceReport";
import MtdYtdReport from "./pages/Reports/Night Audit Report/MtdYtdReport";
import NightAuditCounterReport from "./pages/Reports/Night Audit Report/NightAuditCounterReport";
import NightAuditRevReport from "./pages/Reports/Night Audit Report/NightAuditRevReport";
import NightAuditRoomDates from "./pages/Reports/Night Audit Report/NightAuditRoomDates";
import NightAuditTaxReport from "./pages/Reports/Night Audit Report/NightAuditTaxReport";
import HistoryForecastReport from "./pages/Reports/Occupancy Report/HistoryForecastReport";
import HouseCountReport from "./pages/Reports/Occupancy Report/HouseCountReport";
import BusinessSourceReport from "./pages/Reports/Reservations Report/BusinessSourceReport";
import ComplimentaryRoomReport from "./pages/Reports/Reservations Report/ComplimentaryRoomReport";
import ExpectedArrivalReport from "./pages/Reports/Reservations Report/ExpectedArrivalReport";
import NoShowsReport from "./pages/Reports/Reservations Report/NoShowsReport";
import TempReservationReport from "./pages/Reports/Reservations Report/TempReservationReport";
import BillToCompany from "./pages/Reports/Tax Report/BillToCompany";
import DailyMtdYtd from "./pages/Reports/Tax Report/DailyMtdYtd";
import SourceReport from "./pages/Reports/Tax Report/SourceReport";
import CustomField from "./pages/admin/Reports/Console/CustomField";
import RegisteredCorporate from "./pages/admin/Reports/Console/RegisteredCorporate";
import RegisteredTravelAgent from "./pages/admin/Reports/Console/RegisteredTravelAgent";
import Counters from "./pages/admin/Reports/Generals/Counters";
import Departments from "./pages/admin/Reports/Generals/Departments";
import GuestType from "./pages/admin/Reports/Generals/GuestType";
import InvoiceNumberSetting from "./pages/admin/Reports/Generals/InvoiceNumberSetting";
import ListOfLanguage from "./pages/admin/Reports/Generals/ListOfLanguage";
import PayModes from "./pages/admin/Reports/Generals/PayModes";
import PayTypes from "./pages/admin/Reports/Generals/PayTypes";
import ReasonForTaxExempt from "./pages/admin/Reports/Generals/ReasonForTaxExempt";
import SourceOfBusiness from "./pages/admin/Reports/Generals/SourceOfBusiness";
import TaxCreations from "./pages/admin/Reports/Generals/TaxCreations";
import BlackList from "./pages/admin/Reports/Guest Manager/BlackList";
import GuestPreferences from "./pages/admin/Reports/Guest Manager/GuestPreferences";
import VipGuestList from "./pages/admin/Reports/Guest Manager/VipGuestList";
import Billings from "./pages/admin/Reports/My Account/Billings";
import BookingReservations from "./pages/admin/Reports/My Account/BookingReservations";
import HotelBudget from "./pages/admin/Reports/My Account/HotelBudget";
import PaymentHistory from "./pages/admin/Reports/My Account/PaymentHistory";
import CorporateRate from "./pages/admin/Reports/Price Manager/CorporateRate";
import PackageMaster from "./pages/admin/Reports/Price Manager/PackageMaster";
import TravelAgentRate from "./pages/admin/Reports/Price Manager/TravelAgentRate";
import RoomRevenueAccountCode from "./pages/admin/Reports/Room Manager/RoomRevenueAccountCode";
import LoginActivity from "./pages/admin/Reports/User Managers/LoginActivity";
import UserLevels from "./pages/admin/Reports/User Managers/UserLevels";
import Users from "./pages/admin/Reports/User Managers/Users";
import GuestList from "./pages/admin/Reports/Guest Manager/GuestList";
import ListOfCurrency from "./pages/admin/Reports/Generals/ListOFCurrency";
import ExpectedDepartureList from "./pages/ExpectedDeparture";
import NoShowReport from "./pages/NoShowReport";
import CreateUser from "./pages/admin/CreateUser";
import UsersList from "./pages/admin/UsersList";
import RoomsRatePlan from "./pages/admin/RoomsRatePlan";
import AccountStatement from "./pages/Reports/Fund Operations/AccountStatement";
import AllowanceReport from "./pages/AllowanceReport";
import AdvDepositReport from "./pages/AdvDepositReport";
import JournalReports from "./pages/JournalReports";
import ManagementDashboard from "./layouts/ManagementDashboard/ManagementDashboard";

export default function Router() {
  return useRoutes([
    {
      path: "/main",
      element: <DashboardLayout />,
      children: [
        { path: "home", element: <DashboardApp /> },
        { path: "management-dashboard", element: <ManagementDashboard /> },
        { path: "housekeeping", element: <HouseKeeping /> },
        // { path: "guestlookup", element: <GuestLookUp /> },
        { path: "checkinlist", element: <CheckInList /> },
        { path: "checkoutlist", element: <CheckOutList /> },
        { path: "reservationlist", element: <ReservationList /> },
        { path: "temproomlist", element: <TempRoomList /> },
        { path: "cashcounter", element: <CashCounter /> },
        { path: "fundsoperations", element: <FundsOperation /> },
        { path: "folio", element: <Folio /> },
        { path: "foliodetails", element: <FolioDetails /> },
        { path: "noshowlist", element: <NoShowList /> },
        { path: "nightauditsummary", element: <NightAuditSummary /> },
        { path: "nightauditreport", element: <NightAuditReport /> },
        { path: "nightaudit", element: <NightAudit /> },
        { path: "ExpectedArrivalList", element: <ExpectedArrivalList /> },
        { path: "ExpectedDepartureList", element: <ExpectedDepartureList /> },
        {
          path: "occupancyForecastingReport",
          element: <OccupancyForecastingReport />,
        },
        // { path: "revenuereport", element: <ExpectedDepartureList /> },
        {
          path: "cancelledreservationlist",
          element: <CancelledReservationList />,
        },
        {
          path: "cancelledbilllist",
          element: <CancelledBillList />,
        },
        { path: "historyforecastreport", element: <HistoryForecastReport /> },
        { path: "guestinhouselist", element: <GuestInHouseList /> },
        { path: "revenuereport", element: <RevenueReport /> },
        { path: "paymentreport", element: <PaymentReport /> },
        { path: "settlementreport", element: <SettlementReport /> },
        { path: "noshowreport", element: <NoShowReport /> },
        { path: "taxreport", element: <TaxReport /> },
        { path: "unsettledreport", element: <UnSettledReport /> },
        { path: "segmentreport", element: <SegmentReport /> },
        { path: "managerflashreport", element: <ManagerFlashReport /> },
        { path: "discountreport", element: <DiscountReport /> },
        { path: "allowancereport", element: <AllowanceReport /> },
        { path: "adv-deposit-report", element: <AdvDepositReport /> },
        // { path: "housekeepingreport", element: <HousekeepingReport /> },
        // { path: "discrepancyreport", element: <DiscrepnacyReport /> },
        // { path: "reports", element: <Reports /> },
        { path: "historyforecastreport", element: <HistoryForecastReport /> },
        { path: "accountstatement", element: <AccountStatement /> },
        { path: "journalreports", element: <JournalReports /> },

        // { path: "", element: < /> },
      ],
    },
    {
      path: "/reports",
      element: <DashboardLayout />,
      children: [
        // ---------------------------------------irfan ---------------------
        {
          path: "cancelledbilllist",
          element: <CancelledBillList />,
        },
        {
          path: "cancelledreservationlist",
          element: <CancelledReservationList />,
        },
        { path: "checkinlist", element: <CheckInList /> },
        { path: "checkoutlist", element: <CheckOutList /> },
        { path: "ExpectedArrivalList", element: <ExpectedArrivalList /> },
        { path: "ExpectedDepartureList", element: <ExpectedDepartureList /> },
        { path: "guestinhouselist", element: <GuestInHouseList /> },
        { path: "managerflashreport", element: <ManagerFlashReport /> },
        { path: "nightaudit", element: <NightAudit /> },
        { path: "noshowlist", element: <NoShowList /> },
        { path: "revenuereport", element: <RevenueReport /> },
        { path: "taxreport", element: <TaxReport /> },
        { path: "accountstatement", element: <AccountStatement /> },
        { path: "segmentreport", element: <SegmentReport /> },
        { path: "settlementreport", element: <SettlementReport /> },
        { path: "unsettledreport", element: <UnSettledReport /> },
        { path: "discountreport", element: <DiscountReport /> },
        { path: "allowancereport", element: <AllowanceReport /> },
        { path: "historyforecastreport", element: <HistoryForecastReport /> },
        { path: "housekeeping", element: <HouseKeeping /> },
        { path: "journalreports", element: <JournalReports /> },
        { path: "guestinhouselist", element: <GuestInHouseList /> },

        // { path: "reservationlist", element: <ReservationList /> },
        // { path: "temproomlist", element: <TempRoomList /> },
        // { path: "cashcounter", element: <CashCounter /> },
        // { path: "fundsoperations", element: <FundsOperation /> },
        // { path: "folio", element: <Folio /> },
        // { path: "foliodetails", element: <FolioDetails /> },
        // { path: "nightauditsummary", element: <NightAuditSummary /> },
        // { path: "nightauditreport", element: <NightAuditReport /> },

        // { path: "paymentreport", element: <PaymentReport /> },
        // { path: "noshowreport", element: <NoShowReport /> },
        // ------------------------------------------------------------

        // ---------------------------------------irfan ---------------------
        {
          path: "cancelledbilllist",
          element: <CancelledBillList />,
        },
        {
          path: "cancelledreservationlist",
          element: <CancelledReservationList />,
        },
        { path: "checkinlist", element: <CheckInList /> },
        { path: "checkoutlist", element: <CheckOutList /> },
        { path: "ExpectedArrivalList", element: <ExpectedArrivalList /> },
        { path: "ExpectedDepartureList", element: <ExpectedDepartureList /> },
        { path: "guestinhouselist", element: <GuestInHouseList /> },
        { path: "managerflashreport", element: <ManagerFlashReport /> },
        { path: "nightaudit", element: <NightAudit /> },
        { path: "noshowlist", element: <NoShowList /> },
        { path: "revenuereport", element: <RevenueReport /> },

        { path: "housekeeping", element: <HouseKeeping /> },
        // { path: "guestlookup", element: <GuestLookUp /> },

        { path: "reservationlist", element: <ReservationList /> },
        { path: "temproomlist", element: <TempRoomList /> },
        { path: "cashcounter", element: <CashCounter /> },
        { path: "fundsoperations", element: <FundsOperation /> },
        { path: "folio", element: <Folio /> },
        { path: "foliodetails", element: <FolioDetails /> },

        { path: "nightauditsummary", element: <NightAuditSummary /> },
        { path: "nightauditreport", element: <NightAuditReport /> },

        { path: "historyforecastreport", element: <HistoryForecastReport /> },

        { path: "paymentreport", element: <PaymentReport /> },
        { path: "settlementreport", element: <SettlementReport /> },
        { path: "noshowreport", element: <NoShowReport /> },
        { path: "taxreport", element: <TaxReport /> },
        { path: "unsettledreport", element: <UnSettledReport /> },
        { path: "segmentreport", element: <SegmentReport /> },

        { path: "discountreport", element: <DiscountReport /> },

        // ------------------------------------------------------------

        {
          path: "occupancyForecastingReport",

          element: <OccupancyForecastingReport />,
        },
        { path: "noShowsReport", element: <NoShowsReport /> },
        { path: "tempReservationReport", element: <TempReservationReport /> },
        { path: "expectedArrivalReport", element: <ExpectedArrivalReport /> },
        { path: "businessSourceReport", element: <BusinessSourceReport /> },
        {
          path: "complimentaryRoomReport",
          element: <ComplimentaryRoomReport />,
        },
        {
          path: "complimentaryRoomReport",
          element: <ComplimentaryRoomReport />,
        },
        { path: "monthlySummaryReport", element: <MonthlySummaryReport /> },
        {
          path: "revParRoomReportMonthwise",
          element: <RevParRoomReportMonthwise />,
        },
        { path: "advanceDepositReport", element: <AdvanceDepositReport /> },
        // { path: "journalReport", element: <JournalReport /> },
        { path: "dailyRevenueReport", element: <DailyRevenueReport /> },
        { path: "postingAuditReport", element: <PostingAuditReport /> },
        { path: "flashReport", element: <FlashReport /> },
        // { path: "discountReport", element: <DiscountReport /> },
        // { path: "allowanceReport", element: <AllowanceReport /> },
        { path: "guestLedger", element: <GuestLedger /> },
        { path: "guestContactReport", element: <GuestContactReport /> },
        { path: "guestHistoryReport", element: <GuestHistoryReport /> },
        { path: "houseCountReport", element: <HouseCountReport /> },
        { path: "history&forecastReport", element: <HistoryForecastReport /> },
        { path: "allUsers", element: <AllUsers /> },
        { path: "ratePkgReport", element: <RatePkgReport /> },
        { path: "pkgReport", element: <PkgReport /> },
        { path: "rateVariations", element: <RateVariations /> },
        { path: "dailyMtdYtd", element: <DailyMtdYtd /> },
        { path: "sourceReport", element: <SourceReport /> },
        { path: "billToCompany", element: <BillToCompany /> },
        { path: "addWithdrawn", element: <AddWithdrawn /> },
        { path: "housekeepingreport", element: <HousekeepingReport /> },
        { path: "discrepancyreport", element: <DiscrepnacyReport /> },
        { path: "nightAuditRoomDates", element: <NightAuditRoomDates /> },
        {
          path: "nightAuditCounterReport",
          element: <NightAuditCounterReport />,
        },
        { path: "nightAuditRevReport", element: <NightAuditRevReport /> },
        { path: "nightAuditTaxReport", element: <NightAuditTaxReport /> },
        { path: "mtdYtdReport", element: <MtdYtdReport /> },
        {
          path: "hotelTrialBalanceReport",
          element: <HotelTrialBalanceReport />,
        },

        // { path: "", element: < /> },
      ],
    },
    {
      path: "/admin",
      element: <AdminDashboardLayout />,
      children: [
        { path: "amenitieslist", element: <AmenitiesList /> },
        { path: "blocklist", element: <BlockList /> },
        { path: "floorlist", element: <FloorList /> },
        { path: "roomtypelist", element: <RoomTypeList /> },
        { path: "roomlist", element: <RoomList /> },
        { path: "taxlist", element: <TaxList /> },
        { path: "featureslist", element: <FeaturesList /> },
        { path: "createuser", element: <CreateUser /> },
        { path: "edituser", element: <CreateUser /> },
        { path: "userslist", element: <UsersList /> },
        { path: "roomtypelistcreate", element: <RoomTypeListCreate /> },
        { path: "hoteldetails", element: <HotelDetails /> },
        { path: "companydetails", element: <CompanyDetails /> },
        { path: "registeredcorporates", element: <RegisteredCorporates /> },
        { path: "addcorporate", element: <AddCorporate /> },
        { path: "editcorporate", element: <AddCorporate /> },
        { path: "registeredtravelagent", element: <RegisteredTravelagent /> },
        { path: "addtravelagent", element: <AddTravelagent /> },
        { path: "ota", element: <OTA /> },
        { path: "roomsrateplan", element: <RoomsRatePlan /> },
      ],
    },
    {
      path: "/adminReports",
      element: <AdminDashboardLayout />,
      children: [
        { path: "roomRevenueAccountCode", element: <RoomRevenueAccountCode /> },
        { path: "invoiceNumberSetting", element: <InvoiceNumberSetting /> },
        { path: "departments", element: <Departments /> },
        { path: "lisOfCurrency", element: <ListOfCurrency /> },
        { path: "listOfLanguage", element: <ListOfLanguage /> },
        { path: "reasonForTaxExempt", element: <ReasonForTaxExempt /> },
        { path: "payModes", element: <PayModes /> },
        { path: "payTypes", element: <PayTypes /> },
        { path: "counters", element: <Counters /> },
        { path: "guestType", element: <GuestType /> },
        { path: "sourceOfBusiness", element: <SourceOfBusiness /> },
        { path: "taxCreations", element: <TaxCreations /> },
        { path: "packageMaster", element: <PackageMaster /> },
        { path: "travelAgentRate", element: <TravelAgentRate /> },
        { path: "corporateRate", element: <CorporateRate /> },
        { path: "userLevels", element: <UserLevels /> },
        { path: "users", element: <Users /> },
        { path: "loginActivity", element: <LoginActivity /> },
        { path: "guestList", element: <GuestList /> },
        { path: "vipGuestList", element: <VipGuestList /> },
        { path: "blackList", element: <BlackList /> },
        { path: "guestPreferences", element: <GuestPreferences /> },
        { path: "registeredCorporate", element: <RegisteredCorporate /> },
        { path: "registeredTravelAgent", element: <RegisteredTravelAgent /> },
        { path: "customField", element: <CustomField /> },
        { path: "report", element: <Reports /> },
        { path: "bookingReservations", element: <BookingReservations /> },
        { path: "billings", element: <Billings /> },
        { path: "paymentHistory", element: <PaymentHistory /> },
        { path: "nightAuditReport", element: <NightAuditReport /> },
        { path: "hotelBudget", element: <HotelBudget /> },

        // { path: "", element: < /> },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "adminlogin",
      element: <AdminLogin />,
    },
    {
      path: "adminregister",
      element: <AdminRegister />,
    },
    {
      path: "register",
      element: <Register />,
      // element: <AdminRegister />,
    },
    {
      path: "calendar",
      element: <Calendar />,
      // element: <AdminRegister />,
    },
    { path: "admin-register", element: <AdminRegister /> },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/main/home" /> },
        { path: "404", element: <NotFound /> },
        // { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: "*",
    //   element: <Navigate to="/404" replace />,
    // },
  ]);
}
