import { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Button,
  FormGroup,
  OutlinedInput,
  Tooltip,
  Toolbar,
  Snackbar,
  Alert,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { Page } from "../components/Page";
import {
  getCurrentUser,
  getCashierData,
  insertCashierDaliyPaymentData,
} from "../api-config/config";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";

export default function CashCounter(props: any) {
  const { setOpenCounter, date } = props;

  const [cashierData, setCashierData] = useState([] as any);
  const [enteredData, setEnteredData] = useState([] as any);

  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const loggedUser = getCurrentUser();
  // let amountEntered: number = 0;
  // console.log(amountEntered);
  //   amountEntered = 5;
  const handleSubmit = (formData: any) => {
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      name: formData?.name,
      online: formData?.online,
      card: formData?.card,
      upi: formData?.upi,
      cash: formData?.cash,
      wallet: formData?.wallet || 0,
      pos: formData?.pos,
      total_payment: formData?.available_balance,
      date: moment().format("YYYY-MM-DD"),
      // "date": new Date().toISOString().slice(0, 10),
      next_day_opening_amount: formData?.next_day_opening_amount,
      reason_for_discrepancy: formData?.reason_for_discrepancy,
    };
    // insertCashierDaliyPaymentData(payload).then((response: any) => {
    //   console.log(response);
    //   if (response.status_message === "success") {
    //     setOpenSnackSuccess(true);
    //   } else {
    //     setOpenSnackFailure(true);
    //   }
    //   // onFeatureAdded();
    //   // handleClose();
    // });
  };

  const getcashier = (hotel_code: number) => {
    return getCashierData({
      hotel_code: loggedUser?.hotel_code,
      date: moment().format("YYYY-MM-DD"),
    }).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setCashierData(res.data);
          setEnteredData(res.data);
          // setCashierData([...rooms]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getcashier(loggedUser?.hotel_code);
  }, []);
  const capitalizeFirstChar = (value: any) => {
    if (typeof value !== "string" || value.length === 0) {
      return value;
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      disableExport: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "funds",
      headerName: "Funds Available",
      disableExport: false,
      headerClassName: "super-app-theme--header",
      width: 150,
      valueFormatter: ({ value }) => capitalizeFirstChar(value),
    },
    {
      field: "withdrawls",
      headerName: "Withdrawls",
      disableExport: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "collections",
      disableExport: false,
      headerName: "Collections",
      width: 150,
      headerClassName: "super-app-theme--header",
    },

    // {
    //   field: "withdrawals",
    //   headerName: "Withdrawals",
    //   width: 100,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "added",
    //   headerName: "Added",
    //   width: 100,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: 'date',
    //   headerName: 'Year',
    //   renderCell: (params: GridRenderCellParams<Date>) => (
    //     <strong>
    //       {params.value.getFullYear()}
    //       <Button
    //         variant="contained"
    //         size="small"
    //         style={{ marginLeft: 16 }}
    //         tabIndex={params.hasFocus ? 0 : -1}
    //       >
    //         Open
    //       </Button>
    //     </strong>
    //   ),
    // },
    {
      field: "available_balance",
      headerName: "Available Balance",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
      // valueSetter(params) {
      //   console.log(params);
      //   setAmountEnt(params.value);
      //   // setEnteredData((prev: any) => ({...prev, [params.row.funds.replace(" ", "_")]: parseInt(params.value)}))
      // },
      // valueParser(value, params: any) {
      //   // setAmountEnt(value);
      //   console.log("==> value", value);
      //   console.log("===> params", params);
      // },
      // renderCell: (params: GridRenderCellParams<number>) => (
      //   <OutlinedInput
      //     onChange={(e) => {
      //       console.log(params);
      //       setCashierData((prev: any) => ({
      //         ...prev,
      //         [params.row.funds.replace(" ", "_")]: parseInt(e.target.value),
      //       }));
      //     }}
      //     // value={params.value}
      //     value={cashierData[params.row.funds.replace(" ", "_")]}
      //   />
      // ),
    },
    {
      field: "discrepencies",
      headerName: "Discrepencies",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "added",
      headerName: "Added",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
  ];

  function NightAuditGrid(data: any) {
    const auditFields: any[] = [];
    console.log(data);
    // const obj: any = data?.row;
    // console.log("===> obj", Object.keys(obj));
    // const list: any[] = Object.keys(obj);
    // console.log("===> this is the list to be shown", list);
    // list
    //   ?.filter((el: any) => el !== "date" && el !== "name")
    //   ?.forEach((el: any, i: any) => {
    //     console.log(el, "poooooooooooooooj...................");

    //     auditFields.push({
    //       id: i + 1,
    //       funds: el.replace(/_/g, " "),
    //       // no_of_rooms: obj[el].rooms,
    //       // funds: "Online Payment Gateway",
    //       last_operating_balance: 0,
    //       collections: 0,
    //       withdrawals: 0,
    //       added: 0,
    //       total_payment: obj[el],
    //       discrepencies: 0,
    //       balance_on_hand: 0,
    //     });

    cashierData?.forEach((ele: any, i: any) => {
      let funds = "";
      if (ele?.name === "upi" || ele?.name === "pos") {
        funds = ele.name.toUpperCase();
      } else {
        funds = ele.name
          ?.split("_")
          .map(
            (word: any) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");
      }
      auditFields.push({
        id: i + 1,
        funds: funds,
        // no_of_rooms: obj[el].rooms,
        // funds: "Online Payment Gateway",
        // last_operating_balance: 0,
        collections: ele.collections,
        withdrawls: ele?.withdrawls,
        added: ele.added,
        available_balance: ele.available_balance,
        discrepencies: ele.discrepencies,
        // balance_on_hand: 0,
      });
    });

    return (
      <Box
        sx={{
          height: 450,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          <h3 style={{ marginTop: -2 }}>Cash Counter</h3>
        </Box>
        <CSVLink data={auditFields} filename={"cash-counter.csv"}>
          <Tooltip title="Download Excel">
            {/* <FileDownloadIcon></FileDownloadIcon> */}
            <Button
              type="button"
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              Download
            </Button>
          </Tooltip>
        </CSVLink>
        {/* <DataGrid {...data2} loading={loading} slots={{ toolbar: GridToolbar }} /> */}
        <DataGrid
          rows={auditFields}
          columns={columns}
          // components={{
          //   Toolbar: () => {
          //     return (
          //       <GridToolbarContainer>
          //         <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
          //       </GridToolbarContainer>
          //     );
          //   },
          // }}
          pageSize={8}
          rowsPerPageOptions={[8]}
          headerHeight={75}
          rowHeight={65}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Cash Counter
          </Typography>
          {
            <NightAuditGrid
              row={cashierData}
              // featureAdded={onFeatureAdded}
            />
          }
          {/* {
            cashierData?.total_payment > -1 &&
            <NightAuditGrid row={cashierData} 
            // featureAdded={onFeatureAdded}
            />
          } */}
          <div className="mt-12 w-[50%] m-auto">
            <FormGroup className="w-[100%]">
              <InputLabel className="text-center">
                Next day opening amount in cash:{" "}
              </InputLabel>
              <OutlinedInput
                type="number"
                // value={cashierData?.next_day_opening_amount}
                // onChange={(e) =>
                //   setCashierData((prev: any) => ({
                //     ...prev,
                //     next_day_opening_amount: parseInt(e.target.value),
                //   }))
                // }
              />
            </FormGroup>
            <FormGroup>
              <InputLabel className="text-center">
                Reason for discrepency:{" "}
              </InputLabel>
              <OutlinedInput
                className="h-[15rem]"
                value={cashierData?.reason_for_discrepancy}
                onChange={(e) =>
                  setCashierData((prev: any) => ({
                    ...prev,
                    reason_for_discrepancy: e.target.value,
                  }))
                }
              />
            </FormGroup>
            <div className="flex w-[100%] mt-4 justify-between m-auto">
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b] flex"
                onClick={() => handleSubmit(cashierData)}
              >
                Close & Withdraw
              </Button>
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b] flex"
                onClick={() => {
                  // console.log("cancel clicked");
                  setOpenCounter(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b] flex"
              >
                Print
              </Button>
            </div>
          </div>
        </Container>
      </Page>
      <Snackbar
        open={openSnackSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSnackSuccess(false)}
      >
        <Alert
          onClose={() => setOpenSnackSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully done.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackFailure}
        autoHideDuration={6000}
        onClose={() => setOpenSnackFailure(false)}
      >
        <Alert
          onClose={() => setOpenSnackFailure(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error while submitting.
        </Alert>
      </Snackbar>
    </>
  );
}
