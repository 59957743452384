import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  getCurrentUser,
  routeChargesTo,
  routePaymentTo,
} from "../../api-config/config";

const FoiloOverviewRouteTransactions = (props: any) => {
  const { invList, selectedChargeFolioList, setRouteCharges, fetch } = props;
  const [selectedFromInv, setSelectedFromInv] = useState(null);
  const [selectedToInv, setSelectedToInv] = useState(null);
  const [toAmount, setToAmount] = useState<number>(0);
  const [toPercentage, setToPercentage] = useState<number>(0);
  const [fromAmount, setFromAmount] = useState<number | null>(null);
  const [routeType, setRouteType] = useState("amount");
  const [toOptions, setToOptions] = useState([]);
  const loggedUser = getCurrentUser();
  const transactionType = selectedChargeFolioList[0]?.type;
  const isCharge = transactionType === "charge";

  const handleSubmit = (values: any) => {
    if (selectedFromInv && selectedToInv && toAmount && fromAmount) {
      const payload = {
        [isCharge ? "charges_id" : "payments_id"]:
          selectedChargeFolioList[0]?.id,
        init_prov_inv_no: selectedFromInv,
        route_prov_inv_no: selectedToInv,
        init_prov_inv_amount: fromAmount - toAmount,
        route_prov_inv_amount: toAmount,
        hotel_code: loggedUser?.hotel_code,
      };
      if (isCharge) {
        routeChargesTo(payload).then((res) => {
          if (res.status_message === "success") {
            fetch();
            setRouteCharges(false);
          }
        });
      } else {
        routePaymentTo(payload).then((res) => {
          if (res.status_message === "success") {
            fetch();
            setRouteCharges(false);
          }
        });
      }
    }
  };

  const fromOptions: any = [];
  selectedChargeFolioList[0]?.folio_data?.forEach((element: any) => {
    invList.forEach((ele: any) => {
      if (element.prov_inv_no === ele.prov_inv_no) {
        console.log(element);

        fromOptions.push({
          value: ele.prov_inv_no,
          label: ele.charge_to,
          amount: element.amount,
        });
      }
    });
  });
  useEffect(() => {
    setToOptions(
      invList
        ?.filter((item: any) => item.prov_inv_no !== selectedFromInv)
        .map((item: any) => ({
          value: item?.prov_inv_no,
          label: item?.charge_to,
        }))
    );
  }, [selectedFromInv]);
  console.log(toOptions, "tooptions");

  const handleFromInvChange = (selectedOption: any) => {
    setSelectedFromInv(selectedOption?.value || null);
    setSelectedToInv(null);
    setFromAmount(selectedOption?.amount || null);
    setToAmount(0);
    setToPercentage(0);
  };

  const handleToInvChange = (selectedOption: any) => {
    setSelectedToInv(selectedOption?.value || null);
  };

  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setRouteType(newAlignment);
  };

  const handleToAmountChange = (event: any) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value) && fromAmount !== null && value <= fromAmount) {
      setToAmount(value);
      setToPercentage((value / fromAmount) * 100);
    } else if (value === 0) {
      setToAmount(0);
      setToPercentage(0);
    }
  };

  const handleToPercentageChange = (event: any) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value) && value <= 100) {
      setToPercentage(value);
      setToAmount((value * fromAmount!) / 100);
    } else if (value === 0) {
      setToPercentage(0);
      setToAmount(0);
    }
  };

  const formValidationSchema = Yup.object().shape({
    from_total: Yup.number()
      .required("Total is required")
      .min(0, "Total must be a positive number"),
    to_amount: Yup.number().when("routeType", {
      is: "amount",
      then: Yup.number()
        .required("Amount to Route is required")
        .min(0, "Amount to Route must be a positive number")
        .max(Yup.ref("from_total"), "Amount to Route cannot exceed Total"),
      otherwise: Yup.number(),
    }),
    to_percentage: Yup.number().when("routeType", {
      is: "percentage",
      then: Yup.number()
        .required("Percentage amount to Route is required")
        .min(0, "Percentage must be at least 0%")
        .max(100, "Percentage cannot exceed 100%"),
      otherwise: Yup.number(),
    }),

    selectedFromInv: Yup.string().nullable().required("Route From is required"),

    selectedToInv: Yup.string().nullable().required("Route To is required"),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          from_total: fromAmount,
          to_amount: toAmount,
          to_percentage: toPercentage,
          selectedFromInv: selectedFromInv,
          selectedToInv: selectedToInv,
        }}
        onSubmit={handleSubmit}
        validationSchema={formValidationSchema}
      >
        {({ values }) => (
          <Form className="grid gap-4 lg:grid-cols-1 p-8">
            <div className="bg-sky-600 text-white font-semibold text-xl text-center">
              <p className="p-1 m-1">Route</p>
            </div>

            <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1">
              <div className="m-2">
                <FormGroup className="col-span-3">
                  <label htmlFor="" className="pb-2">
                    Route From
                  </label>
                  <div className="relative">
                    <div>
                      <Select
                        value={fromOptions?.find(
                          (option: any) => option.value === selectedFromInv
                        )}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: 2,
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow:
                              "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                          }),
                          option: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: 1,
                            border: "1px solid #e2e8f0",
                          }),
                        }}
                        onChange={handleFromInvChange}
                        options={fromOptions}
                        isClearable
                        placeholder="Select"
                        className="border rounded"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                      <p className="text-red-600">
                        <ErrorMessage name="selectedFromInv" />
                      </p>
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="m-2">
                <FormGroup className="col-span-3">
                  <label htmlFor="" className="pb-2">
                    Route To
                  </label>
                  <div className="relative">
                    <div>
                      <Select
                        value={toOptions?.find(
                          (option: any) => option?.value === selectedToInv
                        )}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: 2,
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow:
                              "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                          }),
                          option: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: 1,
                            border: "1px solid #e2e8f0",
                          }),
                        }}
                        onChange={handleToInvChange}
                        options={toOptions}
                        isClearable
                        placeholder="Select"
                        className="border rounded"
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                      <p className="text-red-600">
                        <ErrorMessage name="selectedToInv" />
                      </p>
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>

            <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1">
              <div className="m-2">
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={routeType}
                  aria-label="Platform"
                  onChange={handleTypeChange}
                >
                  <ToggleButton value="amount">Amount</ToggleButton>
                  <ToggleButton value="percentage">Percentage</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {routeType === "percentage" && (
                <div className="m-2">
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Percentage amount to Route
                    </label>
                    <Field
                      name="to_percentage"
                      className="con-field"
                      placeholder="Percentage amount to Route"
                      id="to_percentage"
                      type="number"
                      required
                      value={toPercentage}
                      onChange={handleToPercentageChange}
                    />
                    <p className="text-red-600">
                      <ErrorMessage name="to_percentage" />
                    </p>
                  </FormGroup>
                </div>
              )}
            </div>

            <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1">
              <div className="m-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Total
                  </label>
                  <Field
                    name="from_total"
                    className="con-field"
                    placeholder="Total"
                    id="from_total"
                    type="number"
                    required
                    disabled={true}
                    value={fromAmount || ""}
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="from_total" />
                  </p>
                </FormGroup>
              </div>
              <div className="m-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Amount to Route
                  </label>
                  <Field
                    name="to_amount"
                    className="con-field"
                    placeholder="Amount to Route"
                    id="to_amount"
                    type="number"
                    required
                    disabled={routeType !== "amount"}
                    value={toAmount || ""}
                    onChange={handleToAmountChange}
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="to_amount" />
                  </p>
                </FormGroup>
              </div>
            </div>

            <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1">
              <FormGroup className="p-2">
                <Button
                  type="submit"
                  name="newprice"
                  className="bg-sky-400 hover:bg-green-300"
                >
                  Route {isCharge ? "Charges" : "Payments"}
                </Button>
              </FormGroup>

              <FormGroup className="p-2">
                <Button
                  type="button"
                  name="cancel"
                  className="bg-sky-400 hover:bg-green-300"
                  onClick={() => setRouteCharges(false)}
                >
                  Cancel
                </Button>
              </FormGroup>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FoiloOverviewRouteTransactions;
