import {
  Button,
  Dialog,
  DialogContent,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { getCorporateData } from "../../api-config/user.api";
import { getBookingInfo, getCurrentUser } from "../../api-config/config";
import Select from "react-select";
import { forEach } from "lodash";
import {
  corporate_pms_v2,
  insertCorporate,
  updateCorporate,
} from "../../api-config/corporate.api";
import { getFolioDetails } from "../../api-config/folio.api";

const Corporate = (props: any) => {
  const {
    bookingInfo,
    handleCloseDialog,
    setOpenSnack,
    setSnackbarMessage,
    fetch,
  } = props;
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [data, setData] = useState([]);
  const loggedUser = getCurrentUser();
  const [corporateSelected, setCorporateSelected] = useState(false);
  const [isNewCorporate, setIsNewCorporate] = useState(true);
  const [editCorporate, setEditCorporate] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    corporate_name: "",
    gst_number: "",
    main_office_address: "",
  });

  const fetchSegmentData = async () => {
    try {
      const res = await getCorporateData(loggedUser?.hotel_code, 0);
      if (bookingInfo.corporate_id) {
        setData(res.data);
        const defaultValue = res.data.find(
          (ele: any) => ele.id === bookingInfo.corporate_id
        );
        if (defaultValue !== undefined) {
          setDefaultValues(defaultValue);
        }
        setSelectedCorporate(bookingInfo.corporate_id);
        setIsNewCorporate(false);
        setCorporateSelected(true);
      } else {
        await setData(res.data);
      }
    } catch (error) {
      console.error("Error fetching segment data:", error);
    }
  };
  const options = data.map((item: any) => ({
    value: item.id,
    label: item.corporate_name,
  }));
  useEffect(() => {
    fetchSegmentData();
    const options = data.map((item: any) => ({
      value: item.id,
      label: item.corporate_name,
    }));
  }, []);
  const handleCorporateChange = (event: any) => {
    setSelectedCorporate(event.value);
    setIsNewCorporate(false);

    const defaultValue = data.find((ele: any) => ele.id === event.value);
    if (defaultValue !== undefined) {
      setDefaultValues(defaultValue);
    }

    setCorporateSelected(true);
  };

  const handleSubmit = () => {
    setDefaultValues({
      corporate_name: "",
      gst_number: "",
      main_office_address: "",
    });
    setSelectedCorporate(null);
    setIsNewCorporate(true);
    setCorporateSelected(true);
  };
  const addCorporate = async (values: any) => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    formData.append("hotel_code", loggedUser.hotel_code);

    try {
      const res = await insertCorporate(formData);
      console.log(res);
      if (res.status_message === "success") {
        setOpenSnack(true);
        setSnackbarMessage(res);
        const res2 = await getCorporateData(loggedUser?.hotel_code, 0);
        await setData(res2.data);
        const defaultValue = res2.data.find(
          (ele: any) =>
            ele.corporate_name === values.corporate_name &&
            ele.main_office_address === values.main_office_address
        );
        if (defaultValue !== undefined) {
          setDefaultValues(defaultValue);
        }
        setIsNewCorporate(false);
        setCorporateSelected(true);
        setSelectedCorporate(
          defaultValue !== undefined ? (defaultValue as any).id : null
        );
      }
    } catch (error) {
      console.error("Error adding corporate:", error);
    }
  };

  const enableEditCorporate = (values: any) => {
    setEditCorporate(true);
  };
  const editCorporateData = (values: any) => {
    setEditCorporate(false);
    setDefaultValues({ ...defaultValues, ...values });
    updateCorporate({ ...defaultValues, ...values }).then((res: any) => {
      setOpenSnack(true);
      setSnackbarMessage(res);
    });
  };

  const addCorporateBooking = async () => {
    if (bookingInfo.corporate_id) {
      const payload = {
        booking_id: bookingInfo.booking_id,
        corporate_id: (defaultValues as any).id,
        shift_charges: 1,
      };
      const res = await corporate_pms_v2(payload);
      if (res.status_message == "success") {
        setOpenSnack(true);
        setSnackbarMessage(res);
        fetch();
        handleCloseDialog();
      }
    } else {
      const payload = {
        booking_id: bookingInfo.booking_id,
        corporate_id: (defaultValues as any).id,
        shift_charges: 2,
      };
      const res = await corporate_pms_v2(payload);
      if (res.status_message == "success") {
        setOpenSnack(true);
        setSnackbarMessage(res);
        fetch();
        handleCloseDialog();
      }
    }
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={(event) => handleSubmit()}
      >
        {({ values }) => (
          <Form className="grid gap-4 lg:grid-cols-1 p-8">
            <div className="grid gap-4 lg:grid-cols-4 sm:grid-cols-1">
              <FormGroup className="col-span-3">
                <label htmlFor="" className="pb-2">
                  Choose Corporate
                </label>
                <div className="relative">
                  <div>
                    <Select
                      value={options.find(
                        (option) => option.value === selectedCorporate
                      )}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 2,
                          backgroundColor: "white",
                          borderRadius: "4px",
                          boxShadow:
                            "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 1,
                          border: "1px solid #e2e8f0",
                        }),
                      }}
                      onChange={handleCorporateChange}
                      options={options}
                      isClearable
                      placeholder="Select"
                      className="border rounded"
                      menuPlacement="auto"
                      menuPosition="fixed"
                    />
                  </div>
                </div>
              </FormGroup>
              <div>
                {/* <div>
                  <label htmlFor="" className="pb-2 text-white">
                    Add Corporate
                  </label>
                </div> */}
                <div className="flex justify-center items-center">
                  <Tooltip title="Add New Corporate">
                    <IconButton className="border-2 border-black bg-gray-200">
                      <AddIcon type="newcorporate" onClick={handleSubmit} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            {corporateSelected && (
              <>
                <div className="relative">
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1 rounded-box shadow-lg p-6 border my-4">
                    <div className="m-2">
                      <FormGroup>
                        <label htmlFor="" className="pb-2">
                          Corporate Name
                        </label>
                        <Field
                          name="corporate_name"
                          className="con-field"
                          placeholder="Corporate Name"
                          id="corporate_name"
                          type="text"
                          required
                          disabled={!isNewCorporate && !editCorporate}
                          value={values.corporate_name}
                        />
                      </FormGroup>
                    </div>
                    <div className="m-2">
                      <FormGroup>
                        <label htmlFor="" className="pb-2">
                          GST Number
                        </label>
                        <Field
                          name="gst_number"
                          className="con-field"
                          placeholder="GST Number"
                          id="gst_number"
                          type="text"
                          disabled={!isNewCorporate && !editCorporate}
                          value={values.gst_number}
                        />
                      </FormGroup>
                    </div>
                    <div className="m-2">
                      <FormGroup>
                        <label htmlFor="" className="pb-2">
                          Address
                        </label>
                        <Field
                          name="main_office_address"
                          className="con-field"
                          placeholder="Corporate address"
                          id="main_office_address"
                          type="text"
                          disabled={!isNewCorporate && !editCorporate}
                          value={values.main_office_address}
                        />
                      </FormGroup>
                    </div>
                    <Tooltip
                      title={
                        isNewCorporate
                          ? "Save Corporate"
                          : editCorporate
                          ? "Save Corporate"
                          : "Edit Corporate"
                      }
                    >
                      <IconButton className="absolute top-2 right-2 text-blue-500 mt-2">
                        {isNewCorporate ? (
                          <SaveIcon onClick={() => addCorporate(values)} />
                        ) : editCorporate ? (
                          <SaveIcon onClick={() => editCorporateData(values)} />
                        ) : (
                          <EditIcon
                            onClick={() => enableEditCorporate(values)}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                    <div></div>
                    {!isNewCorporate && !editCorporate && (
                      <FormGroup className="p-2">
                        <Button
                          type="button"
                          name="newprice"
                          className="bg-sky-300 hover:bg-green-300"
                          onClick={addCorporateBooking}
                        >
                          Link Corporate
                        </Button>
                      </FormGroup>
                    )}
                    {!isNewCorporate && !editCorporate && (
                      <FormGroup className="p-2">
                        <Button
                          type="button"
                          name="newprice"
                          className="bg-sky-300 hover:bg-green-300"
                          onClick={handleCloseDialog}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    )}
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Corporate;
