import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { arrangeReportData } from "../../utils/formatFlashReport";

const orderedKeys = [
  "Total Rooms in Hotel",
  "Out Of Order Rooms",
  "Available Rooms To Sell",
  "Complimentary Rooms and House Use",
  "No of Lettable rooms",
  "Rooms Occupied Including Complimentary and In house",
  "Rooms Occupied minus Complimentary and In house",
  "Day Use Rooms",
  "In House Adults",
  "VIP Persons In House",
  "Company Rooms In House",
  "Travel Agent Rooms In House",
  "Rooms Occupied minus Out Of Order Percentage",
  "Rooms Occupied minus Complimentary and In House Percentage",
  "Checked in Rooms",
  "Checked in Persons",
  "Walk in Rooms",
  "Walk in Persons",
  "Checked out Rooms",
  "Checked out Persons",
  "No Show Rooms",
  "No Show Persons",
  "Reservation Cancellations made Today",
  "Reservation Cancellations made Today Including Future",
  "reservations made today including future",
  "Reservations Made Today",
  "Cancelled Reservations for Today",
  "Room Nights Reserved Today including future",
  "ADR IncLusive of Complimentary Rooms",
  "ADR minus Complimentary Rooms",
  "RevPAR",
  "Room Revenue",
  "Arrival Rooms for Tomorrow",
  "Departure Rooms for Tomorrow",
  "Rooms Occupied for the Next 7 Days Percentage",
  "Rooms Occupied for the Next 31 Days Percentage",
  "POS Revenue",
  "BAR",
  "Travel Desk",
  "Total POS Revenue",
  "Total Revenue",
];

export default function ManagerReport(props: any) {
  const { report } = props;

  const [dynamicRows, setDynamicRows] = React.useState([] as any);

  React.useEffect(() => {
    // arrangeReportData(report)
    createDynamicRows(report);
  }, [report]);

  function createDynamicRows(report: any) {
    const rows = [] as any;
    let i = 1;
    // console.log(report,"comming from function");
    
    // console.log("my dynamic row", report);

    for (let newKey = 0; newKey < orderedKeys.length; newKey++) {
      const cKey = orderedKeys[newKey].toLowerCase();

      // report?.forEach((element,i) => {
        
      // });
      for (const key in report.daily_report) {
        // console.log(key,"inside for");
        const fKey = key.split("_").join(" ").toLowerCase();

        if (cKey === fKey && key !== "pos_revenue") {
          const daily = report?.daily_report[key];
          const monthly = report?.monthly_report[key];
          const yearly = report?.yearly_report[key];
          // console.log(daily,monthly,yearly,"from data");
          console.log(key, "got key");

          rows.push(createData(i++, key, daily, monthly, yearly));
        } else {
          console.log(key, "not found");
        }
      }
    }
    console.log(rows, "all rows");

    setDynamicRows(rows);
  }

  function createData(
    SNo: number,
    Title: string,
    Daily: number,
    Monthly: number,
    Yearly: number
  ) {
    return { SNo, Title, Daily, Monthly, Yearly };
  }
 
  return (
    <>
    
      <div className="flex justify-end mx-4">
        <CSVLink data={dynamicRows} filename={"manager-flash-report.csv"}>
          <Tooltip title="Download Excel">
            <FileDownloadIcon></FileDownloadIcon>
          </Tooltip>
        </CSVLink>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>SNo</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="right">Daily</TableCell>
              <TableCell align="right">Monthly</TableCell>
              <TableCell align="right">Yearly</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dynamicRows.map((row: any) => (
              <TableRow
                key={row.SNo}
                className="transition-transform transform hover:shadow-xl hover:font-bold hover:text-lg"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.SNo}</TableCell>
                <TableCell>
                  {row.Title.split("_")
                    .map(
                      (word: string) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ")}
                </TableCell>
                <TableCell align="right">{row.Daily}</TableCell>
                <TableCell align="right">{row.Monthly}</TableCell>
                <TableCell align="right">{row.Yearly}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
