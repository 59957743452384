import { useState, useEffect } from "react";
import { getCurrentUser, getHotelDetails } from "../../api-config/config";
import HotelDetailsForm from "../../components/ProfileDetailsForm/HotelDetailsForm";
export default function HotelDetails() {
  const [hotelDetails, setHotelDetails] = useState(undefined);
  const loggedUser = getCurrentUser();
  useEffect(() => {
    getHotelDetails(loggedUser?.hotel_code).then((response: any) => {
      setHotelDetails(response.data);
    });
  }, []);
  if (hotelDetails) {
    return <HotelDetailsForm hotelDetails={hotelDetails} />;
  } else {
    return <></>;
  }
}
