import { Button, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  getCashierData,
  getCurrentUser,
  getFolioOptionsPms,
} from "../../api-config/config";
import { refundBill } from "../../api-config/bill.api";

const FolioOverviewRefund = (props: any) => {
  const { folioDetails, fetch, setOpenRefund } = props;
  const loggedUser = getCurrentUser();
  const [cashierData, setCashierData] = useState([]);
  const [selectedGuestForPayment, setSelectedGuestForPayment] = useState(
    {} as any
  );
  const [otherChargesDropDown, setOtherChargesDropDown] = useState([]);
  const [error, setError] = useState(false);

  // const loggedUser = getCurrentUser();
  // const handleChange = (event: any) => {
  //   console.log(event.target.checked);
  //   setPaymentDetails((prev: any) => ({
  //     ...prev,
  //     extra_allowance: event.target.checked,
  //   }));
  // };
  const [paymentDetails, setPaymentDetails] = useState({
    booking_id: 0,
    mode: "cash",
    cash: Math.abs(folioDetails?.charge_till_now?.balance) || 0,
    upi: 0,
    online: 0,
    total_amount: Math.abs(folioDetails?.charge_till_now?.balance) || 0,
    reason: "",
    reason_type: "",
  } as any);

  useEffect(() => {
    getCashierData({
      hotel_code: loggedUser?.hotel_code,
      date: new Date().toISOString().slice(0, 10),
    }).then((res: any) => {
      // console.log(res.data, "cashier data for poooooooooo");
      setCashierData(res.data);
    });
  }, []);
  useEffect(() => {
    // getFolioOptionsPms
    const payload = {
      hotel_code: loggedUser?.hotel_code || 0,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      group_booking_id: folioDetails?.group_booking_id || 0,
    };
    // setBookingId(folioDetails?.booking_id);
    getFolioOptionsPms(payload).then((res) =>
      setOtherChargesDropDown(res.data)
    );
  }, [folioDetails]);
  console.log(selectedGuestForPayment, "this is selcted guest for payload");

  const handleSubmit = (formData: any) => {
    if (
      !selectedGuestForPayment ||
      Object.keys(selectedGuestForPayment).length === 0
    ) {
      setError(true);
      return;
    }
    const total_payment: any = cashierData?.[cashierData.length - 1];
    console.log(
      cashierData?.[cashierData.length - 1],
      "comming from data payload"
    );

    const { cash, upi, online } = formData;
    const total_amount = paymentDetails?.total_amount;
    const reason = paymentDetails?.reason;
    const available_balance = total_payment.available_balance;
    refundBill(
      parseInt(cash),
      parseInt(upi),
      parseInt(online),
      parseInt(total_amount),
      parseInt(available_balance),

      // () => {
      //   if (paymentDetails?.mode === "cash") {
      //     return parseInt(cash) + parseInt(upi) + parseInt(online);
      //   } else {
      //     return parseInt(upi) + parseInt(online);
      //   }
      // },
      // total_amount,
      reason,
      loggedUser?.hotel_code,
      selectedGuestForPayment?.booking_id,
      selectedGuestForPayment?.charge_to,
      selectedGuestForPayment?.corporate_id,
      selectedGuestForPayment?.group_booking_id,
      selectedGuestForPayment?.guest_id,
      selectedGuestForPayment?.id,
      selectedGuestForPayment?.room_id,
      selectedGuestForPayment?.travel_agent_id,
      selectedGuestForPayment?.type
    ).then((response: any) => {
      console.log(response);
      if (response.status_message === "success") {
        setSelectedGuestForPayment({});
        setOpenRefund(false);
        fetch();
      }
      // onFeatureAdded();
      // handleClose();
    });
  };

  return (
    <div className=" w-[100%] py-4 bg-[#e8eff5] mt-5 flex justify-center text-sm flex-wrap">
      <div className="w-[95%] flex flex-wrap items-center justify-evenly font-bold">
        <FormGroup className="w-[30%]">
          <InputLabel htmlFor="mode" id="mode-label" className="">
            Mode
          </InputLabel>
          <Select
            labelId="mode-label"
            id="mode"
            value={paymentDetails?.mode || ""}
            className="p-4"
            onChange={(e) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                mode: e.target.value,
                cash:
                  e.target.value === "cash" ? paymentDetails.total_amount : 0,
                upi: e.target.value === "upi" ? paymentDetails.total_amount : 0,
                online:
                  e.target.value === "online" ? paymentDetails.total_amount : 0,
              }))
            }
          >
            <MenuItem value={""} key="select">
              Select
            </MenuItem>
            <MenuItem value={"cash"} key="cash">
              Cash
            </MenuItem>
            <MenuItem value={"upi"} key="upi">
              UPI
            </MenuItem>
            <MenuItem value={"online"} key="online">
              Online
            </MenuItem>
          </Select>
        </FormGroup>
        <FormGroup className="w-[55%]">
          <InputLabel htmlFor="mode" className="">
            Guest
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedGuestForPayment}
            label="Mode"
            // className="p-4"
            className={
              error ? "p-4 focus:ring-red-500 focus:border-red-500" : "p-4"
            }
            onChange={(e) => {
              setSelectedGuestForPayment(e.target.value);
              setError(false);
            }}
          >
            {otherChargesDropDown?.map((guest: any, i: any) => (
              <MenuItem value={guest} key={i + 1}>
                {guest?.charge_to}
              </MenuItem>
            ))}
            {/*  */}
          </Select>
          {error && <span className="text-red-500">Please select a guest</span>}
        </FormGroup>
        <FormGroup className="w-[30%] mt-4">
          <InputLabel htmlFor="price">Price</InputLabel>
          <OutlinedInput
            type="number"
            value={paymentDetails?.total_amount}
            name="price"
            id="price"
            onChange={(e: any) => {
              if (e.target.value < 0) {
                return;
              } else if (
                e.target.value <=
                Math.abs(folioDetails?.charge_till_now?.balance)
              ) {
                setPaymentDetails((prev: any) => ({
                  ...prev,
                  total_amount: e.target.value,
                  [paymentDetails.mode]: e.target.value,
                }));
              } else {
                alert(
                  "Amount cannot be greater than" +
                    Math.abs(folioDetails?.charge_till_now?.balance)
                );
              }
            }}
          />
        </FormGroup>
        {/* <FormGroup className="w-[30%]">
          <InputLabel htmlFor="reason">Reason</InputLabel>
          <OutlinedInput
            type="text"
            value={paymentDetails?.reason || ""}
            name="reason"
            id="reason"
            onChange={(e: any) =>
              setPaymentDetails((prev: any) => ({
                ...prev,
                reason: e.target.value,
              }))
            }
          />
        </FormGroup> */}
        <FormGroup className="w-[30%]">
          <InputLabel htmlFor="reason" id="reason-label" className="">
            Reason
          </InputLabel>
          <Select
            labelId="reason-label"
            id="reason"
            value={paymentDetails.reason_type || ""}
            className="p-4"
            onChange={(e) => {
              console.log();
              if (e.target.value === "others") {
                setPaymentDetails((prev: any) => ({
                  ...prev,
                  reason: "",
                  reason_type: "others",
                }));
              } else {
                setPaymentDetails((prev: any) => ({
                  ...prev,
                  reason: e.target.value,
                  reason_type: e.target.value,
                }));
              }
            }}
          >
            <MenuItem value={""} key="select">
              Select
            </MenuItem>
            <MenuItem value={"booking cancelled"} key="booking cancelled">
              Booking Cancelled
            </MenuItem>
            <MenuItem value={"partly refunded"} key="partly refunded">
              Partly Refunded
            </MenuItem>
            <MenuItem value={"fully refunded"} key="fully refunded">
              Fully Refunded
            </MenuItem>
            <MenuItem value={"others"} key="others">
              Others
            </MenuItem>
          </Select>
        </FormGroup>

        {paymentDetails.reason_type === "others" ? (
          <FormGroup className="w-[30%]">
            <InputLabel htmlFor="description">Description</InputLabel>
            <OutlinedInput
              type="text"
              value={paymentDetails?.reason}
              name="description"
              id="description"
              onChange={(e: any) => {
                setPaymentDetails((prev: any) => ({
                  ...prev,
                  reason: e.target.value,
                }));
              }}
            />
          </FormGroup>
        ) : null}
        <FormGroup className="w-[100%] flex items-center align-center justify-center">
          <Button
            type="button"
            className="flex w-[30%] mt-5 bg-sky-600 hover:bg-sky-400 text-[white]"
            // onClick={() => console.log(paymentDetails)}
            onClick={() => {
              if (paymentDetails.total_amount > 0) {
                handleSubmit(paymentDetails);
              } else {
                alert("Enter positive amount to refund");
              }
            }}
          >
            Save
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

export default FolioOverviewRefund;
