import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getCurrentUser, insertTaxList } from "../../api-config/config";

const TaxForm = (props: any) => {
  const { handleClose, onTaxAdded, taxData } = props;
  const loggedUser = getCurrentUser();
  console.log("roomTypeData", taxData);
  const defaultValues = {
    tax_type: "",
    name: "",
    value: 0,
    min_amount: 0,
    max_amount: 0,
  };

  const formValidation = Yup.object().shape({
    tax_type: Yup.string().required("Please enter tax type"),
    name: Yup.string().required("Please enter name"),
    value: Yup.number().required("Please enter tax value"),
    min_amount: Yup.number().required("Please enter min amount"),
    max_amount: Yup.number().required("Please enter max amount"),
  });

  const handleSubmit = (formData: any) => {
    formData.hotel_code = loggedUser?.hotel_code;
    insertTaxList(formData).then((response) => {
      console.log(response);
      onTaxAdded();
      handleClose();
    });
  };
  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={formValidation}
      onSubmit={(event) => handleSubmit(event)}
    >
      <Form className="grid gap-4 lg:grid-cols-1 p-8">
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Tax Type
          </label>
          <Field
            name="tax_type"
            className="con-field"
            placeholder="Enter room type*"
            id="tax_type"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="tax_type" />
          </p>
        </FormGroup>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Tax Name
          </label>
          <Field
            name="name"
            className="con-field"
            placeholder="Enter room type*"
            id="name"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="name" />
          </p>
        </FormGroup>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Value
          </label>
          <Field
            name="value"
            className="con-field"
            placeholder="Enter Base Price*"
            id="value"
            type="number"
          />
          <p className="text-red-600">
            <ErrorMessage name="value" />
          </p>
        </FormGroup>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Min Amount
            </label>
            <Field
              name="min_amount"
              className="con-field"
              placeholder="Enter Extra Bed Price"
              id="min_amount"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="min_amount" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Max Amount
            </label>
            <Field
              name="max_amount"
              className="con-field"
              placeholder="Enter Extra Bed Price"
              id="max_amount"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="max_amount" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <Button
              type="button"
              onClick={() => handleClose()}
              className="bg-sky-300 hover:bg-sky-400"
            >
              Cancel
            </Button>
          </FormGroup>
          <FormGroup>
            <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
              Save
            </Button>
          </FormGroup>
        </div>
      </Form>
    </Formik>
  );
};

export default TaxForm;
