import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  SNo: number,
  Payment_Type: string,
  Amount: number
) {
  return { SNo, Payment_Type, Amount };
}


export default function PaymentReportTable(props: any) {
    const {report} = props;
    console.log("payment report:", report);
    const [dynamicRows, setDynamicRows] = React.useState([] as any);
    React.useEffect(() => {
        createDynamicRow(report)
    }, [report])
    function createDynamicRow(report: any) {

        let i = 1;
        const rows = [] as any;
        const lastRows = [] as any;
        for (const property in report) {
            if(property === 'card') {
                rows.push(createData(i++, 'card', Number(report[property])));
            }else if(property === 'cash') {
                rows.push(createData(i++, 'cash', Number(report[property])));
            } else if(property === 'online') {
                rows.push(createData(i++, 'online', Number(report[property])));
            }else if(property === 'total_gross_amount') {
              lastRows.push(createData(i++, 'total gross amount', Number(report[property])));
            }else if(property === 'total_paid_amount') {
              lastRows.push(createData(i++, 'total paid amount', Number(report[property])));
            }else if(property === 'upi') {
                rows.push(createData(i++, 'upi', Number(report[property])));
            }
          }
          rows.push(...lastRows);
          setDynamicRows([...rows]);
    }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>SNo</TableCell>
            <TableCell align="left">Revenue Type</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dynamicRows.map((row: any) => (
            <TableRow
              key={row.SNo}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell>{row.SNo}</TableCell>
              <TableCell>
                {row.Payment_Type}
              </TableCell>
              <TableCell align="right">{row.Amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}