import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(SNo: number, Revenue_Type: string, Amount: number) {
  return { SNo, Revenue_Type, Amount };
}

export default function ReportTable(props: any) {
  const { report } = props;
  const [dynamicRows, setDynamicRows] = React.useState([] as any);
  React.useEffect(() => {
    createDynamicRow(report);
  }, [report]);
  function createDynamicRow(report: any) {
    let i = 1;
    const rows = [] as any;
    for (const property in report) {
      if (property === "cancelled_orders_revenue") {
        rows.push(createData(i++, "Cancelled Revenue", report[property]));
      } else if (property === "check_in_orders_revenue") {
        rows.push(createData(i++, "Checkin Revenue", report[property]));
      } else if (property === "check_out_orders_revenue") {
        rows.push(createData(i++, "Checkout Revenue", report[property]));
      } else if (property === "confirmed_orders_revenue") {
        rows.push(createData(i++, "Confirmed Revenue", report[property]));
      } else if (property === "pending_orders_revenue") {
        rows.push(createData(i++, "Pending Revenue", report[property]));
      } else if (property === "total_revenue") {
        rows.push(createData(i++, "Total", report[property]));
      }
    }
    setDynamicRows([...rows]);
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>SNo</TableCell>
            <TableCell align="left">Revenue Type</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dynamicRows.map((row: any) => (
            <TableRow
              key={row.SNo}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.SNo}</TableCell>
              <TableCell>{row.Revenue_Type}</TableCell>
              <TableCell align="right">{row.Amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
