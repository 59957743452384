import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

export interface Logoprop {
  disabledLink?: boolean;
  sx?: [];
}

const Logo: React.FC<Logoprop> = (props) => {
  const logo = (
    <Box
      component="img"
      src="/static/images/newlogo.jpg"
      sx={{ width: "180px" }}
    />
  );

  if (props.disabledLink) {
    return <>{logo}</>;
  }
  // return <RouterLink to="/">{logo}</RouterLink>;
  return <>{logo}</>;
};

export default Logo;
