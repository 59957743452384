import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Link, Grid } from "@mui/material";
import Iconify from "../../components/Iconify";
import AccountPopover from "../admin-dashboard/AccountPopover";
import NotificationsPopover from "../dashboard/NotificationsPopover";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SchoolIcon from "@mui/icons-material/School";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";

const DRAWER_WIDTH = 200;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

type DashboardNavbarProps = {
  onOpenSidebar: () => void;
};

export default function AdminDashboardNavbar(props: DashboardNavbarProps) {
  const [frontdesk, setFrontdesk] = React.useState("");

  const frontdeskChange = (event: SelectChangeEvent) => {
    setFrontdesk(event.target.value);
  };
  const [other, setOther] = React.useState("");

  const otherChange = (event: SelectChangeEvent) => {
    setOther(event.target.value);
  };

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const navigate = useNavigate();
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={props.onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" sx={{ width: 20, height: 20 }} />
        </IconButton>
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="left"
          sx={{ display: "contents" }}
        ><Grid item>
            <Stack direction="row" spacing={2} sx={{ m: 1 }}>
              <Button
                type="button"
                onClick={() => navigate("/admin/hoteldetails")}
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Admin
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50 mr-2"
                onClick={() => navigate("/adminReports")}
              >
                Reports
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
