import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, IconButton, InputAdornment, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/Iconify";
import {
  AdminFormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { login } from "../../../api-config/config";

export default function LoginForm() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("Email must be a valid email address")
    //   .required("Email is required"),
    email: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;
    login(email, password).then(
      (res: any) => {
        if (res.status_message === "success") {
          setAlert(true);
          setError(false);
          setTimeout(() => {
            navigate("/admin", { replace: true });
          }, 500);
        } else {
          // console.log(res.message);
          setAlert(false);
          setError(true);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
        // setLoading(false);
        // setMessage(resMessage);
      }
    );
  };

  return (
    <AdminFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {alert && <Alert severity="success">Login successful !</Alert>}

      <Stack spacing={3}>
        {/* <RHFTextField name="hotelcode" label="Hotel Code" other={{}} /> */}

        <RHFTextField name="email" label="User name" other={{}} />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    sx={{ width: 20, height: 20 }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          other={{}}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ color: "#0323f8", fontWeight: 700 }}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        sx={{ background: "#0323f8" }}
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          Please Check username and password!
        </Alert>
      )}
    </AdminFormProvider>
  );
}
