import axios from "axios";
import { baseUrl } from "./config";
import api from "./axiosInstance";

export const getCorporateData = async (hotel_code: number, id: number) => {
  return api
    .post(baseUrl + "/get_corporate_data", {
      hotel_code,
      id,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertCorporate = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/insert_corporate_data", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      // func(response.data);
      return response.data;
    });
};
export const updateCorporate = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/update_corporate_data", data)
    .then((response: any) => {
      // func(response.data);
      return response.data;
    });
};
export const corporate_pms_v2 = async (data: any, func?: any) => {
  return api.post(baseUrl + "/corporate_pms_v2", data).then((response: any) => {
    // func(response.data);
    return response.data;
  });
};
// https://api.ratebotai.com:8443/delete_corporate_data
export const deleteCorporate = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/delete_corporate_data", data)
    .then((response: any) => {
      // func(response.data);
      return response.data;
    });
};

export const getTravelAgentData = async (hotel_code: number, id: number) => {
  return api
    .post(baseUrl + "/get_travel_agent_data", {
      hotel_code,
      id,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertTravelAgent = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/insert_travel_agent_data", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      return response.data;
    });
};
