import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Tooltip,
  Modal,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../api-config/config";
import {
  deleteCorporate,
  getCorporateData,
} from "../../api-config/corporate.api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import AlertSnack from "../../components/CustomSnackBar/AlertSnack";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "25px",
  boxShadow: 24,
  p: 2,
};

export default function RegisteredCorporates() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowData, setRowData] = useState({ id: 0, corporate_name: "" });
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const loggedUser = getCurrentUser();

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    // setRoomTypeData({});
  };

  const handleDeleteCoorporate = () => {
    // console.log(row, "clicked yes comaing from bugged");
    // console.log(rowData, "clicked yes comaing from new");
    deleteCorporate({ id: rowData.id }).then((res: any) => {
      console.log(res);
      setOpen1(true);
      setSnackbarMessage(res);
      setDeleteOpen(false);
      getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
        setData(res.data)
      );
    });
  };

  useEffect(() => {
    getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
      setData(res.data)
    );
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          List of Registered Corporates
        </Typography>
        {/* <div
          className=""
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <div className="m-4 ">
            <span className="text-color" style={{ color: "#0323f8" }}>
              Main
            </span>
            /List of Registered Corporate
            <div className="flex justify-end">
              <input type="text" />{" "}
            </div>
          </div>
        </div> */}
        {/* table start */}
        <div className="bg-slate-200 py-2 my-4 text-right rounded-lg p-4 shadow-md">
          <div className="font-bold flex justify-end items-center">
            <Link
              to="/admin/addcorporate"
              className="mr-5 text-custom-blue font-semibold hover:text-blue-700"
            >
              Add a Corporate
            </Link>
            <Button className="font-semibold text-white bg-custom-blue hover:bg-blue-700">
              Export Corporate(s)
            </Button>
          </div>
        </div>

        <div
          className="text-center rounded-lg"
          style={{
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          }}
        >
          <div className="max-h-[450px] overflow-x-auto rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-200 font-semibold sticky top-0">
                <tr className="font-semibold">
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Sr.no
                  </th>
                  {/* <th className="px-8 py-2 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Code
                  </th> */}
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Corporate Name
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Country
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Phone
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Address
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Discount
                  </th>

                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Login Details
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Corporate User(s)
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Corporate Sales Person
                  </th>
                  <th className="px-8 py-2 text-left text-xs  text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((row: any, i: number) => (
                  <>
                    <tr key={row?.id}>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        {i + 1}
                      </td>
                      {/* <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                      {row?.code}
                    </td> */}
                      <td className="px-14 py-2 whitespace-nowrap text-sm text-left">
                        {row?.corporate_name}
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        {row?.country}
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        {row?.office_phone}
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        {row?.email_id}
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm max-w-xs">
                        <ul className="list-disc ml-4 text-left">
                          <li>
                            <div style={{ display: "flex" }}>
                              <span className="font-semibold">Address:</span>{" "}
                              <Tooltip title={row?.main_office_address} arrow>
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      maxWidth: "100px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {row?.main_office_address}
                                  </div>
                                  <InfoIcon
                                    fontSize="small"
                                    color="action"
                                    style={{ verticalAlign: "middle" }}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </li>
                          <li>
                            <span className="font-semibold">City:</span>{" "}
                            {row?.city}
                          </li>
                          <li>
                            <span className="font-semibold">State:</span>{" "}
                            {row?.state}
                          </li>
                          <li>
                            <span className="font-semibold">Country:</span>{" "}
                            India
                          </li>
                          <li>
                            <span className="font-semibold">ZipCode:</span>{" "}
                            {row?.zip_code}
                          </li>
                        </ul>
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        20%
                      </td>

                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        Status
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        <a href="#">Send E-mail</a>
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        <a href="#">User(s)</a>
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        <a href="#">Sales Person</a>
                      </td>
                      <td className="px-8 py-2 whitespace-nowrap text-sm text-left">
                        <div className="flex space-x-2">
                          <Tooltip title="Edit">
                            <span
                              onClick={() =>
                                navigate("/admin/editcorporate", {
                                  state: { data: row.id },
                                })
                              }
                            >
                              <EditIcon className="text-black-600 cursor-pointer" />
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <span
                              onClick={() => {
                                setDeleteOpen(true);
                                setRowData(row);
                              }}
                            >
                              <DeleteForeverIcon className="text-red-600 cursor-pointer" />
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* table end */}
        <Modal
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}
              style={{ margin: "2rem auto 4rem auto" }}
            >
              Are you sure you wanna delete?
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}
              style={{ margin: "4rem auto" }}
            >
              {"Registered Coorporate : "}
              {rowData?.corporate_name || ""}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "2rem",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3B71CA",
                  color: "white",
                }}
                onClick={() => handleDeleteCoorporate()}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={handleDeleteClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>
        {open1 && (
          <AlertSnack
            response={snackbarMessage}
            open={open1}
            setOpen={setOpen1}
          />
        )}
      </Container>
    </>
  );
}
