import Iconify from "../../components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 22, height: 22 }} />
);

const navConfig = [
  // {
  //   title: "Admin Dashboard",
  //   path: "/admin/amenitieslist",
  // }

  {
    title: "Management Dashboard",
    path: "/main/management-dashboard",
  },
  // {
  //   title: "Advance Deposit Report",
  //   path: "/main/adv-deposit-report",
  // },
  {
    title: "Allowance Report",
    path: "/main/allowancereport",
  },
  {
    title: "Cancelled Bill Report",
    path: "/main/cancelledbilllist",
  },
  {
    title: "Cancelled Reservations",
    path: "/main/cancelledreservationlist",
  },
  {
    title: "Cashier Report",
    path: "/main/accountstatement",
  },
  {
    title: "Check in Report",
    path: "/main/checkinlist",
    // icon: getIcon("bytesize:home"),
  },
  {
    title: "Check out Report",
    path: "/main/checkoutlist",
  },
  {
    title: "Discount Report",
    path: "/main/discountreport",
  },
  {
    title: "Expected Arrival Report",
    path: "/main/ExpectedArrivalList",
  },
  {
    title: "Expected Departure Report",
    path: "/main/ExpectedDepartureList",
  },
  {
    title: "History & Forecast Report",
    path: "/main/historyforecastreport",
  },

  {
    title: "Journal Reports",
    path: "/main/journalreports",
  },

  // {
  //   title: "guest Look up",
  //   path: "/main/guestlookup",
  //   // icon: getIcon("bytesize:home"),
  // },
  {
    title: "Manager Flash Report",
    path: "/main/managerflashreport",
  },
  {
    title: "Night Audit Report",
    path: "/main/nightaudit",
  },
  {
    title: "No Show Report",
    path: "/main/noshowlist",
  },
  {
    title: "Occupancy Report",
    path: "/main/guestinhouselist",
  },
  {
    title: "Occupancy Forecasting Report",
    path: "/main/occupancyForecastingReport",
  },
  {
    title: "Revenue Report",
    path: "/main/revenuereport",
  },

  // {
  //   title: "Payment Report",
  //   path: "/main/paymentreport",
  // },
  // {
  //   title: "Reservation Report",
  //   path: "/main/reservationlist",
  // },
  // {
  //   title: "Temp Room Report",
  //   path: "/main/temproomlist",
  // },
  // {
  //   title: "No Show Report",
  //   path: "/main/noshowlist",
  // },
  // {
  //   title: "Checkout Pendding Report",
  //   path: "/main/ExpectedArrivalList",
  // },
  // {
  //   title: "Folio(s)",
  //   path: "/main/folio",
  // },
  // {
  //   title: "Pendding Follo(s)",
  //   path: "/main/home",
  // },
  // {
  //   title: "Booking Deposits Tracker",
  //   path: "/main/home",
  // },
  // {
  //   title: "Payment Tracker",
  //   path: "/main/home",
  // },
  {
    title: "Settlement Report",
    path: "/main/settlementreport",
  },
  {
    title: "Source Report",
    path: "/main/segmentreport",
  },

  // {
  //   title: "No Show Report",
  //   path: "/main/noshowreport",
  // },
  {
    title: "Tax Report",
    path: "/main/taxreport",
  },
  {
    title: "Unsettled Bill Report",
    path: "/main/unsettledreport",
  },

  // {
  //   title: "3rd Party Reservation Report",
  //   path: "/main/home",
  // },
  // {
  //   title: "Alerts & Notifications",
  //   path: "/main/home",
  // },
  // {
  //   title: "DNR/House Use Report",
  //   path: "/main/home",
  // },
  // {
  //   title: "Rate Posting Report",
  //   path: "/main/home",
  // },
];

export default navConfig;
