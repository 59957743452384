import React from "react";
import "./managementDash.css";
interface Room {
  data: string;
  title: string;
}
interface RevenueStatusProps {
  ele: Room;
}

const RevenueStatus: React.FC<RevenueStatusProps> = ({ ele }) => {
  return (
    <>
      <div className="revenue-main-cont">
        <h3>
          {ele?.title === "OCP" ? "OCCUPANCY %" : ele?.title}
          {}
        </h3>

        <div className="revenue-second-cont">
          <h1>{ele?.data}</h1>
          {/* <p>last year</p> */}
          <h5>--</h5>
        </div>
      </div>
    </>
  );
};

export default RevenueStatus;
