import { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbarExport } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  OutlinedInput,
  Tooltip,
  Toolbar,
  Snackbar,
  Alert,
} from "@mui/material";
import { Page } from "../components/Page";
import {
  getCurrentUser,
  getCashierData,
  insertCashierDaliyPaymentData,
  addFundsToCashCounter,
  withdrawFundsFromCashCounter,
} from "../api-config/config";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { GridToolbarContainer } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

export default function FundsOperation(props: any) {
  const { setOpenCounter, date } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const action = location?.state?.action;
  const [cashierData, setCashierData] = useState([] as any);
  const [enteredData, setEnteredData] = useState([] as any);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const loggedUser = getCurrentUser();
  const validateForm = (card_total: any, values: any) => {
    let flag = false;
    for (let i = 0; i < cashierData.length; i++) {
      const element = cashierData[i];
      if (
        (element.name === "card" && card_total > element.available_balance) ||
        (element.name === "cash" && values.cash > element.available_balance) ||
        (element.name === "online" &&
          values.online > element.available_balance) ||
        (element.name === "pos" && values.pos > element.available_balance) ||
        (element.name === "upi" && values.upi > element.available_balance)
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Funds transferred By is required"),
    comments: Yup.string().required("Comments for funds added is required"),
  });

  const formik = useFormik({
    initialValues: {
      hotel_code: loggedUser?.hotel_code,
      name: "",
      comments: "",
      online: 0,
      card: 0,
      visa: 0,
      master_card: 0,
      upi: 0,
      pos: 0,
      cash: 0,
      wallet: 0 || 0,
      total_payment: 0,
      date: new Date().toISOString().slice(0, 10),
      // "date": new Date().toISOString().slice(0, 10),
      next_day_opening_amount: 0,
      reason_for_discrepancy: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      const total_payment = cashierData?.[cashierData.length - 1];

      if (action === "add") {
        addFundsToCashCounter({
          ...values,
          total_payment: total_payment.available_balance,
        }).then((res) => {
          console.log(res);
          if (res.status_message === "success") {
            setOpenSnackSuccess(true);
            navigate("/main/cashcounter");
          } else {
            setOpenSnackFailure(true);
          }
        });
      } else {
        const card_total = values.card + values.visa + values.master_card;

        if (validateForm(card_total, values)) {
          alert(
            "Available balance less than entered amount\n Please add some funds before withdrawing"
          );
        } else {
          withdrawFundsFromCashCounter({
            ...values,
            total_payment: total_payment.available_balance,
          }).then((res) => {
            console.log(res);
            if (res.status_message === "success") {
              setOpenSnackSuccess(true);
              navigate("/main/cashcounter");
            } else {
              setOpenSnackFailure(true);
            }
          });
        }
      }
    },
  });

  const handleSubmit = (formData: any) => {
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      name: formData?.name,
      online: formData?.online,
      card: formData?.card,
      upi: formData?.upi,
      cash: formData?.cash,
      wallet: formData?.wallet || 0,
      total_payment: formData?.total_payment,
      date: new Date().toISOString().slice(0, 10),
      next_day_opening_amount: formData?.next_day_opening_amount,
      reason_for_discrepancy: formData?.reason_for_discrepancy,
    };
    insertCashierDaliyPaymentData(payload).then((response: any) => {
      console.log(response);
    });
  };
  const getcashier = (hotel_code: number) => {
    return getCashierData({
      hotel_code: loggedUser?.hotel_code,
      date: moment().format("YYYY-MM-DD"),
    }).then(
      (res: any) => {
        if (res?.status_message === "success") {
          console.log(res);
          setCashierData(res.data);
          setEnteredData(res.data);
          // setCashierData([...rooms]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getcashier(loggedUser?.hotel_code);
  }, []);
  const capitalizeFirstChar = (value: any) => {
    if (typeof value !== "string" || value.length === 0) {
      return value;
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 80,
      disableExport: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "funds",
      headerName: "Funds Available",
      disableExport: false,
      headerClassName: "super-app-theme--header",
      width: 200,
      valueFormatter: ({ value }) => capitalizeFirstChar(value),
    },
    {
      field: "withdrawls",
      headerName: "Withdrawls",
      disableExport: false,
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "collections",
      disableExport: false,
      headerName: "Collections",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "available_balance",
      headerName: "Available Balance",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "discrepencies",
      headerName: "Discrepencies",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "added",
      headerName: "Added",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
  ];

  function NightAuditGrid(data: any) {
    const auditFields: any[] = [];

    cashierData?.forEach((ele: any, i: any) => {
      auditFields.push({
        id: i + 1,
        funds: ele?.name,
        // no_of_rooms: obj[el].rooms,
        // funds: "Online Payment Gateway",
        // last_operating_balance: 0,
        collections: ele.collections,
        withdrawls: ele.withdrawls,
        added: ele.added,
        available_balance: ele.available_balance,
        discrepencies: ele.discrepencies,
        // balance_on_hand: 0,
      });
    });

    return (
      <Box
        sx={{
          height: 450,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <Box
          sx={{
            color: "black",
            background: "#e7e7e7",
            padding: "12px",
            height: "40px",
            width: "100%",
            margin: "1px",
          }}
        >
          <h3 style={{ marginTop: -2, textAlign: "center" }}>
            Funds {action?.toUpperCase()}: Default Counter
          </h3>
        </Box>
        <CSVLink data={auditFields} filename={"cash-counter.csv"}>
          <Tooltip title="Download Excel">
            <FileDownloadIcon></FileDownloadIcon>
          </Tooltip>
        </CSVLink>
        {/* <DataGrid {...data2} loading={loading} slots={{ toolbar: GridToolbar }} /> */}
        <DataGrid
          rows={auditFields}
          columns={columns}
          components={{
            Toolbar: () => {
              return (
                <GridToolbarContainer>
                  <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
                </GridToolbarContainer>
              );
            },
          }}
          pageSize={8}
          rowsPerPageOptions={[8]}
          headerHeight={75}
          rowHeight={65}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }
  if (action === "add") {
    return (
      <>
        <Page title="Home">
          <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{
                color: "#0323f8",
                marginLeft: 20,
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Fund Operations
            </Typography>
            {
              <NightAuditGrid
                row={cashierData}
                // featureAdded={onFeatureAdded}
              />
            }
            <div className="mt-6">
              <p className="bg-[#01579B] text-[white] p-2">
                Funds {action?.toUpperCase()}
              </p>

              {action === "add" && (
                <>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Credit Card</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="card"
                        name="card"
                        value={formik.values.card}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Visa</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="visa"
                        name="visa"
                        value={formik.values.visa}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">MasterCard</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="master_card"
                        name="master_card"
                        value={formik.values.master_card}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Cash</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="cash"
                        name="cash"
                        value={formik.values.cash}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">UPI</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="upi"
                        name="upi"
                        value={formik.values.upi}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Online Payment Gateway</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="online"
                        name="online"
                        value={formik.values.online}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Payment Collected from POS</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="pos"
                        name="pos"
                        value={formik.values.pos}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Funds transferred By:</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="text"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="text-red-500">{formik.errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Comments for finds Added</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        id="comments"
                        name="comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        type="textarea"
                      />
                      {formik.touched.comments && formik.errors.comments && (
                        <div className="text-red-500">
                          {formik.errors.comments}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="flex items-center my-2">
                <div className="w-[20%]"></div>
                <div className="w-[80%]">
                  <button
                    onClick={() => formik.handleSubmit()}
                    type="button"
                    className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md"
                  >
                    {action?.toUpperCase()} Funds
                  </button>
                  <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
                    View Details
                  </button>
                  <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </Page>
        <Snackbar
          open={openSnackSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSnackSuccess(false)}
        >
          <Alert
            onClose={() => setOpenSnackSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully {action === "add" ? "added." : "withdrawn."}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackFailure}
          autoHideDuration={6000}
          onClose={() => setOpenSnackFailure(false)}
        >
          <Alert
            onClose={() => setOpenSnackFailure(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error while {action === "add" ? "adding." : "withdrawing."}.
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      <>
        <Page title="Home">
          <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{
                color: "#0323f8",
                marginLeft: 20,
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Fund Operations
            </Typography>
            {
              <NightAuditGrid
                row={cashierData}
                // featureAdded={onFeatureAdded}
              />
            }
            <div className="mt-6">
              <p className="bg-[#01579B] text-[white] p-2">
                Funds {action?.toUpperCase()}
              </p>

              <>
                {/* <div className="flex items-center my-2">
                    <div className="w-[20%]">Credit Card</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="card"
                        name="card"
                        value={formik.values.card}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Visa</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="visa"
                        name="visa"
                        value={formik.values.visa}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">MasterCard</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="master_card"
                        name="master_card"
                        value={formik.values.master_card}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div> */}
                <div className="flex items-center my-2">
                  <div className="w-[20%]">Cash</div>
                  <div className="w-[80%]">
                    <OutlinedInput
                      type="number"
                      id="cash"
                      name="cash"
                      value={formik.values.cash}
                      onChange={formik.handleChange}
                    />
                    &nbsp;&nbsp;₹
                  </div>
                </div>
                {/* <div className="flex items-center my-2">
                    <div className="w-[20%]">UPI</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="upi"
                        name="upi"
                        value={formik.values.upi}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Online Payment Gateway</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="online"
                        name="online"
                        value={formik.values.online}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Payment Collected from POS</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="pos"
                        name="pos"
                        value={formik.values.pos}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div> */}
                <div className="flex items-center my-2">
                  <div className="w-[20%]">Funds withdrawn By:</div>
                  <div className="w-[80%]">
                    <OutlinedInput
                      type="text"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-red-500">{formik.errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="flex items-center my-2">
                  <div className="w-[20%]">Comments for funds withdraw</div>
                  <div className="w-[80%]">
                    <OutlinedInput
                      id="comments"
                      name="comments"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                      type="textarea"
                    />
                    {formik.touched.comments && formik.errors.comments && (
                      <div className="text-red-500">
                        {formik.errors.comments}
                      </div>
                    )}
                  </div>
                </div>
              </>

              <div className="flex items-center my-2">
                <div className="w-[20%]"></div>
                <div className="w-[80%]">
                  <button
                    onClick={() => formik.handleSubmit()}
                    type="button"
                    className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md"
                  >
                    {action?.toUpperCase()} Funds
                  </button>
                  <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
                    View Details
                  </button>
                  <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </Page>
        <Snackbar
          open={openSnackSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSnackSuccess(false)}
        >
          <Alert
            onClose={() => setOpenSnackSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully {action === "add" ? "added." : "withdrawn."}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackFailure}
          autoHideDuration={6000}
          onClose={() => setOpenSnackFailure(false)}
        >
          <Alert
            onClose={() => setOpenSnackFailure(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error while {action === "add" ? "adding." : "withdrawing."}.
          </Alert>
        </Snackbar>
      </>
    );
    /* {action === "withdraw" && (
                <>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Cash</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        type="number"
                        id="cash"
                        name="cash"
                        value={formik.values.cash}
                        onChange={formik.handleChange}
                      />
                      &nbsp;&nbsp;₹
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="w-[20%]">Comments for funds withdraw</div>
                    <div className="w-[80%]">
                      <OutlinedInput
                        id="comments"
                        name="comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        type="textarea"
                      />
                      {formik.touched.comments && formik.errors.comments && (
                        <div className="text-red-500">
                          {formik.errors.comments}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )} */
  }

  // return (
  //   <>
  //     <Page title="Home">
  //       <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
  //         <Typography
  //           variant="h5"
  //           gutterBottom
  //           style={{
  //             color: "#0323f8",
  //             marginLeft: 20,
  //             fontWeight: 600,
  //             fontSize: 16,
  //           }}
  //         >
  //           Fund Operations
  //         </Typography>
  //         {
  //           <NightAuditGrid
  //             row={cashierData}
  //             // featureAdded={onFeatureAdded}
  //           />
  //         }
  //         <div className="mt-6">
  //           <p className="bg-[#01579B] text-[white] p-2">
  //             Funds {action?.toUpperCase()}
  //           </p>

  //           {action === "add" && (
  //             <>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Credit Card</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="card"
  //                     name="card"
  //                     value={formik.values.card}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Visa</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="visa"
  //                     name="visa"
  //                     value={formik.values.visa}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">MasterCard</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="master_card"
  //                     name="master_card"
  //                     value={formik.values.master_card}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Cash</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="cash"
  //                     name="cash"
  //                     value={formik.values.cash}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">UPI</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="upi"
  //                     name="upi"
  //                     value={formik.values.upi}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Online Payment Gateway</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="online"
  //                     name="online"
  //                     value={formik.values.online}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Payment Collected from POS</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="pos"
  //                     name="pos"
  //                     value={formik.values.pos}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Funds transferred By:</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="text"
  //                     id="name"
  //                     name="name"
  //                     value={formik.values.name}
  //                     onChange={formik.handleChange}
  //                   />
  //                   {formik.touched.name && formik.errors.name && (
  //                     <div className="text-red-500">{formik.errors.name}</div>
  //                   )}
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Comments for finds Added</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     id="comments"
  //                     name="comments"
  //                     value={formik.values.comments}
  //                     onChange={formik.handleChange}
  //                     type="textarea"
  //                   />
  //                   {formik.touched.comments && formik.errors.comments && (
  //                     <div className="text-red-500">
  //                       {formik.errors.comments}
  //                     </div>
  //                   )}
  //                 </div>
  //               </div>
  //             </>
  //           )}

  //           {action === "withdraw" && (
  //             <>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Cash</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     type="number"
  //                     id="cash"
  //                     name="cash"
  //                     value={formik.values.cash}
  //                     onChange={formik.handleChange}
  //                   />
  //                   &nbsp;&nbsp;₹
  //                 </div>
  //               </div>
  //               <div className="flex items-center my-2">
  //                 <div className="w-[20%]">Comments for funds withdraw</div>
  //                 <div className="w-[80%]">
  //                   <OutlinedInput
  //                     id="comments"
  //                     name="comments"
  //                     value={formik.values.comments}
  //                     onChange={formik.handleChange}
  //                     type="textarea"
  //                   />
  //                   {formik.touched.comments && formik.errors.comments && (
  //                     <div className="text-red-500">
  //                       {formik.errors.comments}
  //                     </div>
  //                   )}
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //           <div className="flex items-center my-2">
  //             <div className="w-[20%]"></div>
  //             <div className="w-[80%]">
  //               <button
  //                 onClick={() => formik.handleSubmit()}
  //                 type="button"
  //                 className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md"
  //               >
  //                 {action?.toUpperCase()} Funds
  //               </button>
  //               <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
  //                 View Details
  //               </button>
  //               <button className="bg-[#01579B] hover:bg-blue-200 hover:text-[#01579B] py-2 px-4 mr-4 text-sm text-white rounded-md">
  //                 Cancel
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </Container>
  //     </Page>
  //     <Snackbar
  //       open={openSnackSuccess}
  //       autoHideDuration={6000}
  //       onClose={() => setOpenSnackSuccess(false)}
  //     >
  //       <Alert
  //         onClose={() => setOpenSnackSuccess(false)}
  //         severity="success"
  //         sx={{ width: "100%" }}
  //       >
  //         Successfully {action === "add" ? "added." : "withdrawn."}
  //       </Alert>
  //     </Snackbar>
  //     <Snackbar
  //       open={openSnackFailure}
  //       autoHideDuration={6000}
  //       onClose={() => setOpenSnackFailure(false)}
  //     >
  //       <Alert
  //         onClose={() => setOpenSnackFailure(false)}
  //         severity="error"
  //         sx={{ width: "100%" }}
  //       >
  //         Error while {action === "add" ? "adding." : "withdrawing."}.
  //       </Alert>
  //     </Snackbar>
  //   </>
  // );
}
