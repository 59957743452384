import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";

type DataItem = {
  date: string;
  "Standard Room": number;
  "Deluxe Room": number;
  "Super Room": number;
  "Exp Room": number;
  "Superior Room": number;
  "Family Room": number;
};

interface Room {
  available_rooms: number;
  room_type_id: number;
  room_type_name: string;
  total_rooms: number;
  total_rooms_booked: number;
}

interface IndObjStructure {
  data: Room[];
  date: string;
}

interface RoomData {
  // Define the properties based on your actual structure
  [roomType: string]: number;

  // Add other properties if needed
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
interface OccupancyChartProps {
  occupancyChartData: IndObjStructure[];
}

const OccupancyChart: React.FC<OccupancyChartProps> = ({
  occupancyChartData,
}) => {
  const [data1, setData1] = useState<Record<string, number>[]>([]);
  const [key1, setKey1] = useState<string[]>([]);

  const formatData = () => {
    // {
    //   date: "Jan-02",
    //   "Standard Room": 8,
    //   "Deluxe Room": 6,
    //   "Super Room": 7,
    //   "Exp Room": 4,
    //   "Superior Room": 14,
    //   "Family Room": 9,
    // },

    // const formattedData: RoomData[] = [];

    const key: string[] = [];
    const formattedData = occupancyChartData?.map((ele: any) => {
      const obj: Record<string, any> = {};
      ele.data.forEach((element: any) => {
        obj[element.room_type_name] = element.total_rooms_booked;
        key.push(element.room_type_name);
      });
      const date = new Date(ele.date);
      const formattedDate = `${new Intl.DateTimeFormat("en", {
        month: "short",
      }).format(date)}-${date.getDate()}`;
      obj.date = formattedDate;
      // formattedData.push(obj)
      return obj;
    });

    const uniqueKey = Array.from(new Set(key));

    setData1(formattedData);
    setKey1(uniqueKey);
  };
  useEffect(() => {
    formatData();
  }, [occupancyChartData]);
  return (
    <div style={{ border: "1px solid #ccc" }}>
      <h2 className="occupancy-heading">Occupancy Report</h2>
      <div style={{ height: "400px" }}>
        <ResponsiveBar
          data={data1}
          keys={key1}
          indexBy="date"
          margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: "paired" }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "Date",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Occupancy",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "row",
              justify: false,
              translateX: 90,
              translateY: 46,
              itemsSpacing: 0,
              itemWidth: 110,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default OccupancyChart;
