import React, { useState } from "react";
import { ResponsiveBar, BarDatum } from "@nivo/bar";

interface DataPoint {
  id: string;
  value: number;
}

const OtaPerformance: React.FC = () => {
  const [activeButton, setActiveButton] = useState<string>("revenue");
  const [data, setData] = useState<DataPoint[]>([
    { id: "A", value: 10 },
    // { id: 'B', value: 15 },
    // { id: 'C', value: 20 },
    // Add more data points as needed...
  ]);

  const transformData = (dataPoints: DataPoint[]): BarDatum[] => {
    return dataPoints.map(({ id, value }) => ({ id, value }));
  };

  const handleButtonClick = (type: string): void => {
    let newData: DataPoint[] = [];
    switch (type) {
      case "revenue":
        newData = [
          { id: "A", value: 10000 },
          //   { id: 'B', value: 15000 },
          //   { id: 'C', value: 20000 },
          // Adjust data for revenue...
        ];
        break;
      case "roomNight":
        newData = [
          { id: "A", value: 50 },
          //   { id: 'B', value: 70 },
          //   { id: 'C', value: 90 },
          // Adjust data for room night...
        ];
        break;
      case "arr":
        newData = [
          { id: "A", value: 120 },
          //   { id: 'B', value: 90 },
          //   { id: 'C', value: 150 },
          // Adjust data for ARR...
        ];
        break;
      default:
        break;
    }
    setData(newData);
  };

  return (
    <div style={{ border: "1px solid #ccc" }}>
      <h3 className="occupancy-heading">Top 5 Ota Performance</h3>
      <div style={{ height: "280px" }}>
      <div className="source-main-btn-cont">
          <div>
            <button
              className={activeButton === "revenue" ? "active" : ""}
              onClick={() => {
                handleButtonClick("revenue");
                setActiveButton("revenue");
              }}
            >
              Revenue
            </button>
          </div>
          <div>
            <button
              className={activeButton === "roomNight" ? "active" : ""}
              onClick={() => {
                handleButtonClick("roomNight");
                setActiveButton("roomNight");
              }}
            >
              Room Night
            </button>
          </div>
          <div>
            <button
              className={activeButton === "arr" ? "active" : ""}
              onClick={() => {
                handleButtonClick("arr");
                setActiveButton("arr");
              }}
            >
              ARR
            </button>
          </div>
        </div>
        <ResponsiveBar
          data={transformData(data)}
          keys={["value"]}
          indexBy="id"
          margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: "nivo" }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
        />
      </div>
    </div>
  );
};

export default OtaPerformance;
