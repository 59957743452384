import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../api-config/config";
import { getTravelAgentData } from "../../api-config/corporate.api";
import { Link } from "react-router-dom";

export default function RegisteredTravelagent() {
  const [data, setData] = useState([]);
  const loggedUser = getCurrentUser();

  useEffect(() => {
    getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
      setData(res.data)
    );
  }, []);
  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          List of Registered Travel Agent
          <div className="flex justify-end ">
            <Link to={"/admin/addtravelagent"} className="mr-5">
              Add a Travel Agent
            </Link>
            <a href="" className="mr-5">
              Delete Selected Travel Agent(s)
            </a>
            <a href="" className="">
              Cancel
            </a>
          </div>
        </Typography>
        <div
          className=""
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <div className="m-4 ">
            <span className="text-color" style={{ color: "#0323f8" }}>
              Main
            </span>
            / List of Registered Travel Agent
            <div className="flex justify-end">
              <input type="text" />{" "}
            </div>
          </div>
        </div>
        {/* table start */}
        <div
          className="bg-slate-200 py-2 my-4 text-right"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <Button className=" font-bold">Export Agent(s)</Button>
        </div>
        <div className="text-center py-5">
          <table className="border-collapse w-full">
            <thead className="text-white bg-black">
              <tr>
                <th className="border  ">#</th>
                <th className="border  ">
                  <input type="checkbox" />
                </th>
                <th className="border "> Code</th>
                <th className="border py-2 px-4">Travel Agent Name</th>
                <th className="border py-2 px-4">Country</th>
                <th className="border py-2 px-4">Phone</th>
                <th className="border py-2 px-4">Email</th>
                <th className="border py-2 px-8">Address</th>
                <th className="border py-2 px-4"> Discount</th>

                <th className="border py-2 px-4"> Status</th>
                <th className="border py-2 px-4"> Login Details</th>
                <th className="border py-2 px-4"> Travel Agent User(s)</th>
                <th className="border py-2 px-4"> Travel Agent Person</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td className="border py-2 px-4">1</td>
                <td className="border py-2 px-4">
                  <input type="checkbox" />
                </td>
                <td className="border py-2 px-4">2028</td>
                <td className="border py-2 px-4">Corp Name</td>
                <td className="border py-2 px-4">India</td>

                <td className="border py-2 px-8">0612-454</td>
                <td className="border py-2 px-4">abc@gmail.com</td>
                <td className="border py-2 px-4 text-left">
                  {" "}
                  <ul className="list-disc ml-2">
                    <li>
                      <span className="font-bold mr-2">Address:</span>E-66
                      Sector-6
                    </li>
                    <li>
                      {" "}
                      <span className="font-bold mr-2">City:</span>Noida{" "}
                    </li>
                    <li>
                      {" "}
                      <span className="font-bold mr-2">State:</span>Uttar
                      Pradesh
                    </li>
                    <li>
                      {" "}
                      <span className="font-bold mr-2">Country:</span>India
                    </li>
                    <li>
                      {" "}
                      <span className="font-bold mr-2">ZipCode:</span>12345
                    </li>
                  </ul>
                </td>
                <td className="border py-2 px-4">20%</td>

                <td className="border py-2 px-4">
                  <a href="">Send E-mail</a>
                </td>
                <td className="border py-2 px-4">
                  <a href="">User()s</a>
                </td>
                <td className="border py-2 px-4">
                  <a href="">Sales Person</a>
                </td>
              </tr> */}
              {data?.map((row: any, i: number) => (
                <tr>
                  <td className="border py-2 px-4">1</td>
                  <td className="border py-2 px-4">
                    <input type="checkbox" />
                  </td>
                  <td className="border py-2 px-4">2028</td>
                  <td className="border py-2 px-4">
                    {row?.billing_company_name}
                  </td>
                  <td className="border py-2 px-4">
                    {row?.billing_company_country}
                  </td>

                  <td className="border py-2 px-8">
                    {row?.main_billing_contact_person_office_phone}
                  </td>
                  <td className="border py-2 px-4">
                    {row?.main_billing_contact_person_email_id}
                  </td>
                  <td className="border py-2 px-4 text-left">
                    <ul className="list-disc ml-2">
                      <li>
                        <span className="font-bold mr-2">Address:</span>
                        {row?.main_office_address}
                      </li>
                      <li>
                        {" "}
                        <span className="font-bold mr-2">City:</span>
                        {row?.city}{" "}
                      </li>
                      <li>
                        {" "}
                        <span className="font-bold mr-2">State:</span>
                        {row?.state}
                      </li>
                      <li>
                        {" "}
                        <span className="font-bold mr-2">Country:</span>India
                      </li>
                      <li>
                        {" "}
                        <span className="font-bold mr-2">ZipCode:</span>
                        {row?.zip_code}
                      </li>
                    </ul>
                  </td>
                  <td className="border py-2 px-4">20%</td>
                  <td className="border py-2 px-4">
                    <a href="">Send E-mail</a>
                  </td>
                  <td className="border py-2 px-4">
                    <a href="">User()s</a>
                  </td>
                  <td className="border py-2 px-4">
                    <a href="">Sales Person</a>
                  </td>
                  <td className="border py-2 px-4">
                    <a href="">{`${row.contact_person_first_name} ${row.contact_person_last_name}`}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* table end */}
      </Container>
    </>
  );
}
