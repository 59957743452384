import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";

const FolioOverviewBottomRight = (props: any) => {
  
  const { folioDetails } = props;

  return (
    <div className=" w-[100%] mt-5 flex justify-end text-sm flex-wrap">
      <div className="w-[70%] font-bold">
        {Math.abs(folioDetails?.charge_till_now?.room_charges) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Room Charges: </span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.room_charges)}</span>
        </div> : null}
        {Math.abs(folioDetails?.charge_till_now?.other_charges) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Other Charges:</span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.other_charges)}</span>
        </div>: null}
        {Math.abs(folioDetails?.charge_till_now?.service_amount) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Service Amount:</span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.service_amount)}</span>
        </div>: null}
        {Math.abs(folioDetails?.charge_till_now?.discount_amount) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Total Disc/Allow:</span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.discount_amount)}</span>
        </div>: null}
        {Math.abs(folioDetails?.charge_till_now?.tax_amount) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Total Tax:</span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.tax_amount)}</span>
        </div>: null}
        <hr className="w-[80%]" />
        {/* <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Service Charge:</span>
          <span className=" w-[40%] text-right">{folioDetails?.service_charge} %</span>
        </div> */}
        
        
        {/* <hr className="w-[80%]" /> */}
        <div className="flex w-[100%] py-2 flex-wrap">
          <span className="w-[40%] text-right">
            <b>Total Amount: </b>
          </span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.balance + folioDetails?.charge_till_now?.paid_amount - folioDetails?.charge_till_now?.refund)}</span>
        </div>
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">Total Paid: </span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.paid_amount)}</span>
        </div>
        {Math.abs(folioDetails?.charge_till_now?.refund) >0  ?
        <div className="flex w-[100%] flex-wrap">
          <span className="w-[40%] text-right">
            Refund Amount:
          </span>
          <span className=" w-[40%] text-right">{convertToRupee(folioDetails?.charge_till_now?.refund)}</span>
        </div>: null}
        <hr className="w-[80%]" />
        <div className="flex w-[100%] py-2 flex-wrap">
          <span className={`w-[40%] text-right ${folioDetails?.charge_till_now?.balance < -1 ? "text-red-500": ""}`}>
            <b>Balance: </b>
          </span>
          <span className={`w-[40%] text-right ${folioDetails?.charge_till_now?.balance < -1 ? "text-red-500": ""}`}>{convertToRupee(folioDetails?.charge_till_now?.balance)}</span>
        </div>
        <hr className="w-[80%]" />
      </div>
    </div>
  );
};

export default FolioOverviewBottomRight;
