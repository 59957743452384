import * as React from "react";
import { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../../components/Page";
import AmenitiesCreate from "./AmenitiesCreate";
import {
  deleteTaxList,
  getBlockData,
  getCurrentUser,
  getTaxList,
  insertBlockData,
} from "../../api-config/config";
import TaxForm from "../../components/TaxForm";
import DeleteIcon from "@mui/icons-material/Delete";

export default function TaxList() {
  const [taxList, setTaxList] = useState();
  const loggedUser = getCurrentUser();
  const getTaxListData = (hotel_code: number) => {
    return getTaxList(hotel_code).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setTaxList(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getTaxListData(loggedUser?.hotel_code);
  }, []);
  function onTaxAdded() {
    getTaxListData(loggedUser?.hotel_code);
  }

  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Tax List
          </Typography>
          <TaxListGrid
            row={taxList}
            loggedUser={loggedUser}
            onTaxAdded={onTaxAdded}
          />
        </Container>
      </Page>
    </>
  );
}

function TaxListGrid(data: any) {
  const [open, setOpen] = React.useState(false);
  const [blockName, setBlockName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { row, loggedUser, onTaxAdded } = data;
  const rows: any[] = [];
  if (row !== undefined) {
    row.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        taxId: item.id,
        taxName: item.name,
        taxType: item.tax_type,
        minAmount: item.min_amount,
        maxAmount: item.max_amount,
        value: item.value,
      });
    });
  }
  function handleSubmit() {
    insertBlockData(loggedUser?.hotel_code, blockName).then((response) => {
      onTaxAdded();
      handleClose();
      alert("Tax Added");
    });
  }
  function deleteRow(row: any) {
    deleteTaxList(row.taxId).then((response: any) => {
      alert(response.message);
      onTaxAdded();
    });
  }
  const columns: GridColDef[] = [
    {
      field: "id",
      flex: 1,
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "taxName",
      flex: 1,
      headerName: "Tax Name",
      headerClassName: "super-app-theme--header",
      width: 250,
      editable: true,
    },
    {
      flex: 1,
      field: "taxType",
      headerName: "Tax Type",
      width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      flex: 1,
      field: "minAmount",
      headerName: "Min Amount",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      flex: 1,
      field: "maxAmount",
      headerName: "Max Amount",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      flex: 1,
      field: "value",
      headerName: "Value",
      width: 10,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 100,
      flex: 1,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={(event) => {
              deleteRow(cellValues.row);
            }}
          >
            <Button
              variant="contained"
              className="bg-red-500 hover:bg-red-600 text-stone-50"
              color="error"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <Box
      sx={{
        height: 480,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          background: "#b3c9df",
          padding: "12px",
          height: "70px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="contained"
            sx={{ margin: "0px 2px" }}
            onClick={handleClickOpen}
          >
            Add a Tax
          </Button>
          {/* <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Delete Selected Tax(S)
          </Button> */}
          <Button variant="contained" sx={{ margin: "0px 2px" }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      ></Box>

      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "calc(100% - 200px)",
          margin: "auto",
        }}
      >
        <DialogTitle>Add A Tax</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h4 style={{ marginTop: -2 }}>Please Enter Tax Details below.</h4>
            <TaxForm handleClose={handleClose} onTaxAdded={onTaxAdded} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
