import React from "react";

const HotelStatus = () => {
  const data = [
    { totalroom: "Totalroom", totalcount: 40 },
    { totalroom: "Occupied", totalcount: 10 },
    { totalroom: "Expected Arrivals", totalcount: 0 },
    { totalroom: "Expected Departures", totalcount: 6 },
    { totalroom: "Complimentary", totalcount: 0 },
    { totalroom: "House Use", totalcount: 0 },
    { totalroom: "DNR", totalcount: 0 },
    { totalroom: "Rooms to Sell", totalcount: 30 },
    { totalroom: "EOD Projection", totalcount: 4 },
    { totalroom: "Total VIP ", totalcount: 0 },
  ];

  return (
    <>
      <div>
        <div className="hotelstatus-cont">HotelStatus</div>
        <div className=" hotelstatus-main-cont  px-2 ">
          {data.map((data, index) => (
            <div
              key={index}
              className="hotel-status-mapdata flex justify-between"
            >
              <p>{data.totalroom}</p>
              <p>{data.totalcount}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HotelStatus;
