import { Box, Button, FormGroup } from "@mui/material";
import * as React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { convertToRupee } from "../../utils/formatNumber";
import {
  baseUrl,
  getCurrentUser,
  getPaymentData,
  invoicePms,
  provInvToInv,
} from "../../api-config/config";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { getInvListFolioPms } from "../../api-config/folio.api";
import { logoutUser } from "../../api-config/user.api";
import { Lock, LockOpen } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 50,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cash",
    headerName: "Cash",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "card",
    headerName: "Card",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "paid_amount",
    headerName: "Paid Amount",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "gross_amount",
    headerName: "Gross Amount",
    width: 110,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

function FeaturesListGrid(data: any) {
  const { row, featureAdded } = data;
  const rows: any[] = [];
  // console.log("==> from inside the grid",data.row.payment_details);
  if (row.payment_details !== undefined) {
    row.payment_details.forEach((item: any, index: any) => {
      rows.push({
        id: index + 1,
        cash: item?.cash,
        card: item?.card,
        paid_amount: item?.paid_amount,
        gross_amount: item?.gross_amount,
      });
    });
  }

  return (
    <Box
      sx={{
        height: 200,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[6]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

const FolioOverviewRight = (props: any) => {
  const {
    folioDetails,
    setOpenGenFolio,
    handleClickOpenInd,
    selectedIndFolio,
  } = props;
  const [paymentDetails, setPaymentDetails] = useState({});
  const [folioList, setFolioList] = useState([]);
  const loggedUser = getCurrentUser();

  const getInfoDetails = async () => {
    const response = await getPaymentData({
      booking_id: folioDetails?.booking_id,
      customer_id: folioDetails?.customer_id,
      hotel_code: loggedUser?.hotel_code,
    });
    console.log(response);
    setPaymentDetails((prev: any) => ({
      ...prev,
      message: response.data.message,
      notes: response.data.notes,
      preferences: response.data.preferences,
      tasks: response.data.tasks,
      guest_preferences: response.data.guest_preferences,
    }));
  };
  console.log(folioDetails?.room_booking?.[0].room_id);

  useEffect(() => {
    const payload = {
      hotel_code: folioDetails?.hotel_code,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id,
      group_id: folioDetails?.group_id,
      travel_agent_id: folioDetails?.travel_agent_id,
      room_id: folioDetails?.room_booking?.[0].room_id,
    };
    getInvListFolioPms(payload).then((response: any) => {
      setFolioList(response?.data2);
    });
  }, [folioDetails]);

  const handleClickProvToInv = (row: any, ele: any) => {
    const payload = {
      prov_inv_no: ele?.prov_inv_no,
      hotel_code: loggedUser?.hotel_code,
    };
    // window.open(
    //   `${baseUrl}/provissional_invoice_pdf?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`
    // );
    console.log(ele, "this is selected inv");
    if (ele?.inv_to === "corporate") {
      const userResponse = window.prompt(
        "Do you want to Bill To Company? (Yes/No)"
      );
      if (userResponse !== null) {
        const normalizedResponse = userResponse.trim().toLowerCase();
        if (normalizedResponse === "yes") {
          const payload = {
            prov_inv_no: ele?.prov_inv_no,
            hotel_code: loggedUser?.hotel_code,
            btc: true,
          };
          provInvToInv(payload).then((response: any) => {
            if (response?.status_message === "success") {
              invoicePms({
                inv_no: response.inv_no,
                hotel_code: loggedUser?.hotel_code,
              }).then((response: any) => {
                window.location.reload();
                window.open(response.file);
              });
            } else {
              alert(response?.message);
            }
          });
        } else {
          return;
        }
      }
    } else {
      provInvToInv(payload).then((response: any) => {
        if (response?.status_message === "success") {
          invoicePms({
            inv_no: response.inv_no,
            hotel_code: loggedUser?.hotel_code,
          }).then((response: any) => {
            window.location.reload();
            window.open(response.file);
          });
        } else {
          alert(response?.message);
        }
      });
    }
  };

  const handleClickBillView = (row: any, ele: any) => {
    invoicePms({ inv_no: ele.inv_no, hotel_code: loggedUser?.hotel_code }).then(
      (response: any) => {
        // window.location.reload();
        window.open(response.file);
      }
    );
  };

  const openProvInv = (row: any, ele: any) => {
    console.log(row, ele);
    if (folioDetails.booking_status !== "check_out") {
      window.open(
        `${baseUrl}/provissional_invoice_pdf?prov_inv_no=${ele?.prov_inv_no}&hotel_code=${loggedUser?.hotel_code}`
      );
    } else {
      invoicePms({
        inv_no: ele.inv_no,
        hotel_code: loggedUser?.hotel_code,
      }).then((response: any) => {
        window.open(response.file);
      });
    }
  };

  return (
    <div className="flex justify-between text-sm flex-wrap">
      <div className="w-full">
        <div className="w-full">
          {/* <h1 className="font-semibold text-base bg-blue-500 text-white p-2 border-b border-black">
            Folio List
          </h1> */}
          {/* <p className="text-center">
            No Folio generated.{" "}
            <span
              className="text-[blue] cursor-pointer"
              onClick={() => setOpenGenFolio(true)}
            >
              Generate Now
            </span>
          </p> */}
          <div className="px-0.5">
            <div className="container mx-auto">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr className="bg-blue-500 text-white">
                    <th className="p-2 py-2.5 border-b text-center">Date</th>
                    <th className="p-2 border-b text-center">Invoice No</th>
                    <th className="p-2 border-b text-center">Charges</th>
                    <th className="p-2 border-b text-center">Payment</th>
                    <th className="p-2 border-b text-center">Balance</th>
                    <th className="p-2 border-b text-center">View</th>
                  </tr>
                </thead>
                <tbody>
                  {folioList?.map((row: any, rowIndex: number) => (
                    <React.Fragment key={rowIndex}>
                      <tr>
                        <td className="p-2 border-b bg-[#ADB5BD]" colSpan={7}>
                          {row?.charge_to}
                        </td>
                      </tr>
                      {row?.data?.map((ele: any, dataIndex: number) => (
                        <tr
                          key={dataIndex}
                          className="hover:bg-blue-100 transition-colors duration-300"
                        >
                          <td
                            className="p-2 border-b"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "8px" }}
                              // onClick={() => handleHeaderClick(ele)}
                            />
                            {moment(ele?.date).format("DD-MM-YYYY") || 0}
                          </td>
                          <td className="p-2 border-b text-center">
                            {ele?.pretext} {ele?.prov_inv_no || ele?.inv_no}
                          </td>
                          <td className="p-2 border-b text-center">{`₹${ele?.total_amount}`}</td>
                          <td className="p-2 border-b text-center text-[#4caf50]">
                            {`₹${ele?.paid_amount || 0}`}
                          </td>
                          <td className="p-2 border-b text-center text-red-500">
                            {ele?.balance}
                          </td>
                          <td className="p-2 border-b text-center">
                            <button
                              className="bg-blue-100 hover:bg-blue-400 hover:text-white transition-colors duration-300 text-blue-500 font-bold py-2 px-4 rounded"
                              onClick={() => openProvInv(row, ele)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolioOverviewRight;
