import { useState } from "react";
import { createContext, useContext } from "react";
import dayjs, { Dayjs } from "dayjs";
import { any } from "prop-types";
import moment from "moment";
interface Row {
  id: number;
  status: string;
  availability: string;
}

const DetailsContext = createContext({
  formatAndSetCalendarValue: (value: any) => {
    return;
  },
  calendarValue: dayjs(new Date()),
  setHotelDetailsObj: (value: any) => {
    return;
  },
  hotelDetails: {} as any,
  statusCounts: {
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  } as any,
  updateStatusCounts: (value: any) => {
    return;
  },
  availableCounts: {
    positive: 0,
    negative: 0,
    departure: 0,
  } as any,
  updateavailableCounts: (value: any) => {
    return;
  },
});

export const useDetails = () => useContext(DetailsContext);

export const DetailsProvider = (props: any) => {
  const [folioDetails, setFolioDetailsStatic] = useState({});
  const [ratePlan, setRatePlan] = useState<any>({});
  const [hotelDetails, setHotelDetailsObj] = useState<any>({});
  const [calendarValue, setCalendarValue] = useState<moment.Moment | null>(
    moment()
  );
  const [statusCounts, setStatusCounts] = useState({
    status1: 5,
    status2: 0,
    status3: 0,
    status4: 0,
  });

  const updateStatusCounts = (rows: Row[]) => {
    const counts = {
      status1: rows.filter(
        (row) => row.status === "Clean" || row.status === "clean"
      ).length,
      status2: rows.filter(
        (row) => row.status === "Dirty" || row.status === "dirty"
      ).length,
      status3: rows.filter(
        (row) => row.status === "Out of Order" || row.status === "out of order"
      ).length,
      status4: rows.filter(
        (row) =>
          row.status === "Complimentary Room" ||
          row.status === "complimentary room"
      ).length,
    };
    setStatusCounts(counts);
  };

  const [availableCounts, setavailableCounts] = useState({
    positive: 5,
    negative: 0,
    departure: 0,
  });
  const updateavailableCounts = (rows: Row[]) => {
    const counts = {
      positive: rows.filter(
        (row) =>
          row.availability === "Available" || row.availability === "available"
      ).length,
      negative: rows.filter(
        (row) =>
          row.availability === "Occupied" || row.availability === "occupied"
      ).length,
      departure: rows.filter(
        (row) =>
          row.availability === "Departure" || row.availability === "departure"
      ).length,
    };
    setavailableCounts(counts);
  };

  const setFolioDetails = (data: any) => {
    setFolioDetailsStatic(data);
    localStorage.setItem("folio-details", JSON.stringify(data));
  };
  const formatAndSetCalendarValue = (data: any) => {
    data = dayjs(data);
    setCalendarValue(data);
  };
  const setRatePlanFunc: any = (data: any) => {
    setRatePlan(+data);
  };
  const value: any = {
    folioDetails,
    setFolioDetails,
    formatAndSetCalendarValue,
    calendarValue,
    setHotelDetailsObj,
    hotelDetails,
    statusCounts,
    updateStatusCounts,
    availableCounts,
    updateavailableCounts,
    ratePlan,
    setRatePlanFunc,
  };

  return (
    <DetailsContext.Provider value={value}>
      {props.children}
    </DetailsContext.Provider>
  );
};
