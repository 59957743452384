import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";

const DiscountPopup = (props:any) => {
const { open, onClose, onSave} = props;
  const [foodDiscount, setFoodDiscount] = useState(0);
  const [laundryDiscount, setLaundryDiscount] = useState(0);
  const [barDiscount, setBarDiscount] = useState(0);

  const handleSave = () => {
    // You can perform validation here before saving
    onSave({
      foodDiscount,
      laundryDiscount,
      barDiscount,
    });
    onClose();
  };
  const labelStyle = {
    marginTop: "4px",
    marginLeft: "4px",
  };
  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>POS Discount</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} >
          <Grid item xs={6}>
            <TextField
              label="% Food Discount"
              type="number"
              fullWidth
              value={foodDiscount}
              onChange={(e:any) => setFoodDiscount(e.target.value)}
              InputLabelProps={{ style: labelStyle }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="% Laundry Discount"
              type="number"
              fullWidth
              value={laundryDiscount}
              onChange={(e:any) => setLaundryDiscount(e.target.value)}
              InputLabelProps={{ style: labelStyle }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="% Bar Discount"
              type="number"
              fullWidth
              value={barDiscount}
              onChange={(e:any) => setBarDiscount(e.target.value)}
              InputLabelProps={{ style: labelStyle }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountPopup;