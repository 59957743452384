import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Container, Typography, Box } from "@mui/material";

import { Page } from "../../components/Page";
import { getCurrentUser } from "../../api-config/config";
import DemoTable from "../demo/DemoTable";
import { getFolioAccountStatement } from "../../api-config/folio.api";
import moment from "moment";
import Button from "@mui/material/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const label = { inputProps: { "aria-label": "Checkbox" } };

const accountStatementDetails: any[] = [
  // {
  //   id: 1,
  //   date: "April 09 2023",
  //   description: "Honeymoon Package Room Rent Standard Room/STD-101",
  //   folio: null,
  //   discount_value: null,
  //   total_amount: 150.00,
  //   paid_by: 22.50,
  //   payment: null,
  // },
  // {
  //   id: 2,
  //   date: "April 10 2023",
  //   description: "Paid by Austin Holden with Cash",
  //   folio: null,
  //   discount_value: null,
  //   total_amount: null,
  //   paid_by: null,
  //   payment: 118.00,
  // },
  // {
  //   id: 3,
  //   date: "April 10 2023",
  //   description: "Honeymoon Package Room Rent Standard Room/STD-101",
  //   folio: null,
  //   discount_value: null,
  //   total_amount: 150.00,
  //   paid_by: 22.50,
  //   payment: null,
  // },
  // {
  //   id: 4,
  //   date: "",
  //   description: "Total",
  //   folio: null,
  //   discount_value: null,
  //   total_amount: 300.00,
  //   paid_by: 45.00,
  //   payment: 118.00,
  // },
];

export default function FolioAccountStatement(props: any) {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 70,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      width: 150,
      // editable: true,
    },
    {
      field: "description",
      headerName: "Description - References",
      width: 300,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "charges",
      headerName: "Charges",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "discount_value",
      headerName: "Disc/Allowance",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "tax_percentage",
    //   headerName: "Tax Percentage",
    //   width: 150,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "tax",
      headerName: "Tax",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "payment",
      headerName: "Payment",
      width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "refund",
      headerName: "Refund",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mode",
      headerName: "Mode#",
      width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 100,
    //   editable: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params: GridRenderCellParams<number>) => (
    //     <div className="flex justify-between w-[100%]">
    //       <Button
    //         className="w-[30%]"
    //         onClick={() => {
    //           console.log("split bill")
    //           // setOpenCounter(true)
    //         }}
    //       >
    //         Split Bill
    //       </Button>
    //     </div>
    //   ),
    // },
  ];
  const capitalizeFirstLetter = (str: string) => {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function FeaturesListGrid(data: any) {
    const { row, featureAdded } = data;
    // const [ localListInd, setLocalListInd ] = useState([] as any);
    const list: any = localStorage.getItem("pms.indList");
    const paymentIndList: number[] = JSON.parse(list) || [];
    const setListFunc = (list: any) => {
      // setLocalListInd(list);
      localStorage.setItem("pms.indList", JSON.stringify(list));
      // setPaymentListInd(list);
    };
    const rows: any[] = [];
    // console.log("==> from inside the grid",data.row.payment_list);
    if (row?.payment_list !== undefined) {
      row?.payment_list.forEach((item: any, index: any) => {
        if (new Date(item?.date) > new Date()) {
          return;
        }
        rows.push({
          id: index + 1,
          date: item?.date,
          description: capitalizeFirstLetter(item?.description as string),
          mode: item?.mode,
          discount_value:
            item.paid_from === "partial_pay"
              ? 0
              : item?.extra_allowance === "yes"
              ? -item?.price
              : item?.discount_value,
          // charges: item?.paid ? -item?.price : item?.price,
          charges:
            item?.extra_allowance === "yes"
              ? 0
              : item?.refund === true
              ? 0
              : item?.extra_allowance === "no"
              ? item?.paid
                ? -item?.price
                : item?.price
              : item?.price,
          tax: item?.tax_amount,
          refund: item?.refund === true ? item?.price : 0,
          total_amount:
            item?.extra_allowance === "yes"
              ? -item?.total_amount
              : item?.total_amount,
          payment: item.paid_from === "partial_pay" ? item?.price : 0,

          // roomtype: item.room_type_name,
        });
      });
    }

    return (
      <Box
        sx={{
          height: 450,
          width: "100%",
          marginTop: 4,
          fontWeight: 600,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          rowsPerPageOptions={[rows.length]}
          checkboxSelection
          onSelectionModelChange={(ids: any, dets: any) => {
            console.log("===> these are the dets", dets);
            console.log("===> these are the ids", ids);
            console.log("===> these are the indexes", paymentIndList);
            setListFunc(ids);
            //   // if(paymentIndList.includes(parseInt(ids[0]))) {
            //   //   return [...paymentIndList]
            //   // } else {
            //   //   return paymentIndList.filter((el: number) => el !== parseInt(ids[0]))
            //   // }
            //   setListFunc([...paymentIndList, ids[0]])
            // // setListFunc([])
            // // return
          }}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    );
  }

  const {
    initialChargesData,
    setInitialChargesData,
    folioDetails,
    setSelectedOtherCharges,
    setSelectedRow,
    selectedRow,
  } = props;
  const [featureList, setFeatureList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const [loading, setLoading] = useState(false);
  const dataRows = [
    {
      headerTitle: "STD 101 Sudeep",
      data: [{ id: 1, name: "John Doe", age: 25, city: "New York" }],
    },
    // Add more objects as needed
    {
      headerTitle: "STD 102 AnotherName",
      data: [{ id: 2, name: "Jane Smith", age: 30, city: "San Francisco" }],
    },
    {
      headerTitle: "STD 103 YetAnotherName",
      data: [{ id: 3, name: "Bob Johnson", age: 28, city: "Los Angeles" }],
    },
    // Add more rows as needed
  ];

  const [folioData, setFolioData] = useState([]);
  const [checkBoxValidate, setCheckBoxValidate] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const toggleHeader = () => {
    setTimeout(() => {
      setShowHeader(!showHeader);
    }, 300);
    // setShowHeader(!showHeader);
  };
  const handleHeaderClick = (index: any) => {
    // setSelectedRow(index === selectedRow ? null : index);
    if (index?.folio_data) {
      console.log(index, "indexxx");
      console.log(folioData, "folioData");
      const chargeID = index.id;
      for (const charge of folioData) {
        const dataItem = charge["data"];
        for (const idTocheck of dataItem) {
          const id = idTocheck["id"];
          if (id === chargeID) {
            const pos_point = index["pos_point"];
            const charge_to = charge["charge_to"];
            const description = index["description"];
            const price = index["price"];
            const guest_id = charge["guest_id"];
            const room_id = charge["room_id"];
            const travel_agent_id = charge["travel_agent_id"];
            const corporate_id = charge["corporate_id"];
            const group_booking_id = charge["group_booking_id"];
            const type = charge["type"];
            console.log(initialChargesData, "initialChargesData");

            setInitialChargesData((prevData: any) => ({
              ...prevData,
              ...index,
              charge_to: charge_to || prevData.charge_to,
              pos_point: pos_point || prevData.pos_point,
              description: description || prevData.description,
              guest_id: guest_id,
              room_id: room_id,
              type: type,
              travel_agent_id: travel_agent_id,
              corporate_id: corporate_id,
              group_booking_id: group_booking_id,
              price: parseInt(price) !== null ? price : prevData.price,
              charge_id:
                parseInt(chargeID) !== null ? chargeID : prevData.charge_id,
            }));
            

            break;
          }
        }
      }
    }

   


    const available = selectedRow.some((ele: any) => ele.id === index.id);
    if (available) {
      // If already selected, remove it

      setSelectedRow((prevSelectedRows: any) =>
        prevSelectedRows.filter((ele: any) => ele.id !== index.id)
      );
      // Empty selectedOtherCharges
      setSelectedOtherCharges({
        total_amount: 0,
        folio_data: [],
        id: 0,
        type: "",
      });
    } else {
      // If not selected, add it
      if (selectedRow.length < 1) {
        setSelectedRow((prevSelectedRows: any) => [...prevSelectedRows, index]);
        setSelectedOtherCharges((prev: any) => ({ ...prev, ...index }));
      } else {
        alert("Only one charges can be routed at a time");
      }
    }
  };
  // console.log(selectedRow, "this selected row");

  useEffect(() => {
    const payload = {
      hotel_code: loggedUser?.hotel_code || 0,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      group_booking_id: folioDetails?.group_booking_id || 0,
    };
    getFolioAccountStatement(payload).then((response: any) => {
      console.log(response);
      setFolioData(response.data);
    });
  }, [folioDetails]);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginTop: "1rem",
              textAlign: "center",
              fontWeight: 600,
              fontSize: 16,
              width: "100%",
            }}
          >
            Account Statement
          </Typography>
          {/* <FeaturesListGrid row={folioDetails} /> */}
          <div className="container mx-auto mt-4">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="p-2 border-b text-center">Date</th>
                  <th className="p-2 border-b text-center">
                    Description - References
                  </th>
                  <th className="p-2 border-b text-center">Mode</th>
                  <th className="p-2 border-b text-center">Charges</th>
                  <th className="p-2 border-b text-center">Tax</th>
                  <th className="p-2 border-b text-center">Payment</th>
                  <th className="p-2 border-b text-center">Disc/Allowance</th>
                  <th className="p-2 border-b text-center">Refund</th>
                  <th className="p-2 border-b text-center">Invoices</th>
                  <th className="p-2 border-b text-center">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {folioData?.map((row: any, rowIndex: number) => (
                  <React.Fragment key={rowIndex}>
                    <tr>
                      <td className="p-2 border-b bg-[#ADB5BD]" colSpan={10}>
                        <Button
                          onClick={toggleHeader}
                          startIcon={
                            showHeader ? <Visibility /> : <VisibilityOff />
                          }
                        >
                          {/* {showHeader ? "Hide Header" : "Show Header"} */}
                        </Button>
                        {row?.charge_to}
                      </td>
                      {/* <td>
                        
                      </td> */}
                    </tr>
                    {showHeader &&
                      row?.data?.map((ele: any, dataIndex: number) => {
                        if (ele?.type === "charge") {
                          return ele?.extra_allowance && !ele?.paid ? (
                            <tr
                              key={dataIndex}
                              className={`hover:bg-grey-800 ${
                                showHeader ? "fade-in" : "fade-out"
                              }`}
                            >
                              <td className="p-2 border-b text-center">
                                <input
                                  type="checkbox"
                                  style={{ marginRight: "8px" }}
                                  checked={
                                    selectedRow[0]?.id === ele.id ? true : false
                                  }
                                  onClick={() => handleHeaderClick(ele)}
                                />
                                {moment(ele?.date).format("DD-MM-YYYY")}
                              </td>
                              <td className="p-2 border-b text-center">
                                (Allowance) {ele?.description}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.mode}
                              </td>
                              <td className="p-2 border-b text-center">0</td>
                              <td className="p-2 border-b text-center">
                                {ele?.tax_amount}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.discount_amount}
                              </td>
                              <td className="p-2 border-b text-center">
                                {"₹ -" + ele?.price || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.refund || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.folio_data?.map((folio: any, i: any) => (
                                  <span style={{ color: "blue" }} key={folio}>
                                    {folio?.prov_inv_no}
                                    {ele?.folio_data?.length - 1 === i
                                      ? null
                                      : ", "}
                                  </span>
                                ))}
                              </td>
                              <td className="p-2 border-b text-center">
                                {"-" + ele?.total_amount}
                              </td>
                            </tr>
                          ) : (
                            <tr
                              key={dataIndex}
                              className={`hover:bg-grey-800 ${
                                showHeader ? "fade-in" : "fade-out"
                              }`}
                            >
                              <td className="p-2 border-b text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedRow[0]?.id === ele.id ? true : false
                                  }
                                  // style={{ padding: "4px" }}
                                  style={{ marginRight: "8px" }}
                                  onClick={() => handleHeaderClick(ele)}
                                />
                                {moment(ele?.date).format("DD-MM-YYYY")}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.description}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.mode}
                              </td>
                              <td className="p-2 border-b text-center">
                                {"₹" + ele?.price}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.tax_amount}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.discount_amount}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.payment || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.refund || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {
                                  <td className="p-2 border-b text-center">
                                    {ele?.folio_data?.map(
                                      (folio: any, i: any) => (
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                            padding: "2px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {folio?.prov_inv_no}
                                          {ele?.folio_data?.length - 1 === i
                                            ? null
                                            : ", "}
                                        </span>
                                      )
                                    )}
                                  </td>
                                }
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.total_amount}
                              </td>
                            </tr>
                          );
                        } else {
                          return ele?.refund ? (
                            <tr
                              key={dataIndex}
                              className={`hover:bg-grey-800 ${
                                showHeader ? "fade-in" : "fade-out"
                              }`}
                            >
                              <td className="p-2 border-b text-center">
                                <input
                                  type="checkbox"
                                  style={{ marginRight: "8px" }}
                                  checked={
                                    selectedRow[0]?.id === ele.id ? true : false
                                  }
                                  onClick={() => handleHeaderClick(ele)}
                                />
                                {moment(ele?.date).format("DD-MM-YYYY")}
                              </td>
                              <td className="p-2 border-b text-center">
                                (Refund) {ele?.description}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.mode}
                              </td>
                              <td className="p-2 border-b text-center">0</td>
                              <td className="p-2 border-b text-center">
                                {ele?.tax_amount}
                              </td>
                              <td className="p-2 border-b text-center">{0}</td>
                              <td className="p-2 border-b text-center">{0}</td>
                              <td className="p-2 border-b text-center">
                                {ele?.price || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.folio_data?.map((folio: any, i: any) => (
                                  <span style={{ color: "blue" }} key={folio}>
                                    {folio?.prov_inv_no}
                                    {ele?.folio_data?.length - 1 === i
                                      ? null
                                      : ", "}
                                  </span>
                                ))}
                              </td>
                              <td className="p-2 border-b text-center">
                                {"-" + ele?.total_amount}
                              </td>
                            </tr>
                          ) : (
                            <tr
                              key={dataIndex}
                              className={`hover:bg-grey-800 ${
                                showHeader ? "fade-in" : "fade-out"
                              }`}
                            >
                              <td className="p-2 border-b text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedRow[0]?.id === ele.id ? true : false
                                  }
                                  // style={{ padding: "4px" }}
                                  style={{ marginRight: "8px" }}
                                  onClick={() => handleHeaderClick(ele)}
                                />
                                {moment(ele?.date).format("DD-MM-YYYY")}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.description || ele?.mode}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.mode}
                              </td>
                              <td className="p-2 border-b text-center">{0}</td>
                              <td className="p-2 border-b text-center">
                                {ele?.tax_amount}
                              </td>
                              <td className="p-2 border-b text-center">
                                {ele?.price}
                              </td>
                              <td className="p-2 border-b text-center">{0}</td>

                              <td className="p-2 border-b text-center">
                                {ele?.refund || 0}
                              </td>
                              <td className="p-2 border-b text-center">
                                {
                                  <td className="p-2 border-b text-center">
                                    {ele?.folio_data?.map(
                                      (folio: any, i: any) => (
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                            padding: "2px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {folio?.prov_inv_no}
                                          {ele?.folio_data?.length - 1 === i
                                            ? null
                                            : ", "}
                                        </span>
                                      )
                                    )}
                                  </td>
                                }
                              </td>
                              <td className="p-2 border-b text-center">
                                {"-" + ele?.total_amount}
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
        {/* <DemoTable /> */}
      </Page>
    </>
  );
}
