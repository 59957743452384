import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  getCurrentUser,
  getTaxList,
  insertRoomType,
} from "../../api-config/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItems = ["Light", "AC", "TV", "Fan"];
const RoomType = (props: any) => {
  const {
    handleClickOpen,
    handleClose,
    onRoomTypeAdded,
    roomTypeData,
    roomTypes,
  } = props;
  const [selectedAmenities, setAmenities] = useState<string[]>(
    roomTypeData?.amenities_ids?.split(",") || []
  );
  const loggedUser = getCurrentUser();
  const [isInputSelectable, setInputSelectable] = useState(false);
  const [taxList, setTaxList] = useState([] as any);
  const [selectedTaxList, setSelectedTaxList] = useState<string[]>(
    roomTypeData?.tax_ids?.split(",").map((value: string) => Number(value)) ||
      []
  );
  const [fieldValue, setFieldValue] = useState([] as any);
  console.log("roomTypeData", roomTypeData);

  const getTaxListData = (hotel_code: number) => {
    return getTaxList(hotel_code).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setTaxList(res.data);
          setSelectedTaxList([...extractTaxName(selectedTaxList, res.data)]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getTaxListData(loggedUser?.hotel_code);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedAmenities>) => {
    const {
      target: { value },
    } = event;
    setAmenities(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeForTax = (
    event: SelectChangeEvent<typeof selectedAmenities>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedTaxList(typeof value === "string" ? value.split(",") : value);
  };

  const defaultValues = {
    room_type: roomTypeData?.roomtype,
    baseOccupancy: roomTypeData?.base_occupancy,
    higherOccupancy: roomTypeData?.occupancy,
    noOfRoom: roomTypeData?.stock,
    extraBed: roomTypeData?.extra,
    basePrice: roomTypeData?.price,
    extraBedPrice: roomTypeData?.extra_bed_price,
    aminities: roomTypeData?.amenities_ids?.split(",") || [],
    service_charge: roomTypeData?.service_charge,
  };

  const formValidation = Yup.object().shape({
    room_type: Yup.string().required("Please enter room type"),

    // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // image: Yup.string().required("Please enter City"),
    baseOccupancy: Yup.number().required("Please enter Base Occupancy"),
    higherOccupancy: Yup.string().required("Please select higher occupancy"),
    extraBed: Yup.string().required("Please enter extra bed number"),
    noOfRoom: Yup.string().required("Please enter number of room"),
    service_charge: Yup.number().max(99, "Value must be less than 100"),
  });
  function onAddressAdded(response: any) {
    console.log("address Update", response);
  }
  function onCancel() {
    console.log("close");
  }

  function extractIds(list: any[]) {
    const idArray = list
      ?.map((data) => {
        const obj = taxList.find((taxObj: any) => {
          return taxObj.name === data;
        });
        return obj ? obj.id : -1;
      })
      .filter((value: any) => value > 0);
    return idArray;
  }
  function extractTaxName(selectedIds: any[], taxList: any) {
    const nameArray = selectedIds
      ?.map((data) => {
        const obj = taxList.find((taxObj: any) => {
          return taxObj.id === data;
        });
        return obj ? obj.name : "";
      })
      .filter((value: any) => value !== "");
    return nameArray;
  }

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser, selectedAmenities);
    const {
      room_type,
      baseOccupancy,
      higherOccupancy,
      noOfRoom,
      extraBed,
      basePrice,
      extraBedPrice,
      service_charge,
    } = formData;
    const { hotel_code } = loggedUser;
    const created_by = 0;
    const selectTaxIdsList = extractIds(selectedTaxList);
    insertRoomType(
      room_type,
      Number(baseOccupancy),
      Number(higherOccupancy),
      Number(extraBed),
      Number(noOfRoom),
      basePrice,
      extraBedPrice,
      created_by,
      hotel_code,
      selectedAmenities,
      selectTaxIdsList,
      service_charge
    ).then((response) => {
      console.log(response);
      onRoomTypeAdded();
      handleClose();
    });
  };
  function onCheckBoxSelected() {
    const checkbox = document.getElementById("myCheckbox") as any;
    if (checkbox?.checked === true) {
      setInputSelectable(true);
    } else {
      setInputSelectable(false);
    }
  }

  const CustomInputComponent = (props: any) => (
    <input
      className="my-custom-input"
      type="checkbox"
      id="myCheckbox"
      {...props}
    />
  );

  const MultipleSelectCheckmarks = () => {
    return (
      <FormControl sx={{ m: 0, width: 220 }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Select Aminities
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          sx={{ height: "50px" }}
          multiple
          value={selectedAmenities}
          onChange={handleChange}
          input={<OutlinedInput label="Select Aminities" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {menuItems.map((name: string) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAmenities.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const MultipleSelectCheckmarksForTax = () => {
    return (
      <FormControl sx={{ m: 0, width: 220 }}>
        <InputLabel id="demo-multiple-checkbox-label">Select Tax</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          sx={{ height: "50px" }}
          multiple
          value={selectedTaxList}
          onChange={handleChangeForTax}
          input={<OutlinedInput label="Select Tax" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {taxList.map((listObj: any) => (
            <MenuItem key={listObj.id} value={listObj.name}>
              <Checkbox
                checked={
                  selectedTaxList.indexOf(listObj.name) > -1 ||
                  selectedTaxList.indexOf(listObj.id) > -1
                }
              />
              <ListItemText primary={listObj.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const CustomeMultipleSelectCheckmarks = (props: any) => (
    <MultipleSelectCheckmarks id="myCheckbox" {...props} />
  );
  const CustomeMultipleSelectCheckmarks2 = (props: any) => (
    <MultipleSelectCheckmarksForTax id="myCheckbox2" {...props} />
  );

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={formValidation}
      onSubmit={(event) => handleSubmit(event)}
    >
      <Form className="grid gap-4 lg:grid-cols-1 p-8">
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Room Type
          </label>
          <Field
            name="room_type"
            className="con-field"
            placeholder="Enter room type*"
            id="room_type"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="room_type" />
          </p>
        </FormGroup>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Upload Image
          </label>
          <Field name="image" className="con-field" id="image" type="file" />
          <p className="text-red-600">
            <ErrorMessage name="image" />
          </p>
        </FormGroup>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Base Occupancy
            </label>
            <Field as="select" type="number" name="baseOccupancy">
              <option value="">Select</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </Field>
            <p className="text-red-600">
              <ErrorMessage name="baseOccupancy" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Higher Occupancy
            </label>
            <Field as="select" name="higherOccupancy">
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Field>
            <p className="text-red-600">
              <ErrorMessage name="higherOccupancy" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <div className="flex gap-2 lg:grid-cols-2">
              <label htmlFor="" className="pb-2">
                Extra Bed
              </label>
              {/* <label>
                                <Field as={CustomInputComponent} name="toggle" />
                                {`Yes`}
                            </label> */}
            </div>
            <Field as="select" name="extraBed">
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Field>
            <p className="text-red-600">
              <ErrorMessage name="extraBed" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              No Of Room Of This Type
            </label>
            <Field
              name="noOfRoom"
              className="con-field"
              placeholder="Enter no of rooms"
              id="noOfRoom"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="noOfRoom" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Select Tax
            </label>
            <Field name="taxList" as={CustomeMultipleSelectCheckmarks2} />
            <p className="text-red-600">
              <ErrorMessage name="taxList" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Aminities
            </label>
            <Field name="aminities" as={CustomeMultipleSelectCheckmarks} />
            <p className="text-red-600">
              <ErrorMessage name="aminities" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Base Price
            </label>
            <Field
              name="basePrice"
              className="con-field"
              placeholder="Enter Base Price*"
              id="basePrice"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="basePrice" />
            </p>
          </FormGroup>
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Extra Bed Price
            </label>
            <Field
              name="extraBedPrice"
              className="con-field"
              placeholder="Enter Extra Bed Price"
              id="extraBedPrice"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="extraBedPrice" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <label htmlFor="" className="pb-2">
              Service Charge(%)
            </label>
            <Field
              name="service_charge"
              className="con-field"
              placeholder="Enter room service charge"
              id="service_charge"
              type="number"
            />
            <p className="text-red-600">
              <ErrorMessage name="service_charge" />
            </p>
          </FormGroup>
        </div>
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <Button
              type="button"
              onClick={() => handleClose()}
              className="bg-sky-300 hover:bg-sky-400"
            >
              Cancel
            </Button>
          </FormGroup>
          <FormGroup>
            <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
              Save
            </Button>
          </FormGroup>
        </div>
      </Form>
    </Formik>
  );
};

export default RoomType;
