import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  getSettleBills,
  getUnsettledBills,
  settleBillApi,
} from "../api-config/bill.api";
import { getCurrentUser } from "../api-config/config";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
} from "@mui/material";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  getCorporateData,
  getTravelAgentData,
} from "../api-config/corporate.api";
import moment from "moment";

const rows = [
  {
    id: 1,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
  {
    id: 2,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
  {
    id: 3,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
];

export default function UnSettledReport() {
  const columns = [
    { field: "id", headerName: "#", width: 70, sortable: true },
    { field: "date", headerName: "Date", width: 130 },
    { field: "room", headerName: "Room", width: 80 },
    {
      field: "guestname",
      headerName: "Guest Name",
      width: 130,
      renderCell: (params: any) => {
        console.log(params);
        return (
          <p>
            {params.row.first_name} {params.row.last_name}
          </p>
        );
      },
    },
    {
      field: "account_id",
      headerName: "Account Code",
      width: 120,
    },
    {
      field: "invoice_number",
      headerName: "Invoice Number",
      width: 120,
      type: "number",
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 160,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 90,
    },
    {
      field: "debit",
      headerName: "Debits",
      width: 90,
    },
    {
      field: "credits",
      headerName: "Credits",
      width: 90,
    },
    {
      field: "user_name",
      headerName: "Username",
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params: any) => {
        return (
          <>
            <div
              onClick={() => {
                setBookingId(params.row.booking_id);
                setIsSettleOpen(true);
              }}
              className="text-center w-full cursor-pointer text-[#0284c7]"
            >
              Settle
            </div>
            <div
              onClick={() => {
                window.open(params.row.files, "_blank");
                // setIsSettleOpen(true);
              }}
              className="text-center w-full cursor-pointer text-[#0284c7]"
            >
              View
            </div>
          </>
        );
      },
    },
  ];
  const loggedUser = getCurrentUser();
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const [data, setData] = useState([]);
  const [bookingId, setBookingId] = useState(0 as number);
  const [isSettleOpen, setIsSettleOpen] = useState(false);
  const [corporateList, setCorporateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [travelAgentList, setTravelAgentList] = useState([]);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [reason, setReason] = useState("");
  const [mode, setMode] = useState("");
  const [corporateId, setCorporateId] = useState("");
  const [description, setDescription] = useState("");
  const [travelAgentId, setTravelAgentId] = useState("");
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function getRows(data: any) {
    const allRows: any = [] as any;
    let id = 1;
    data.forEach((data: any, key: any) => {
      const obj = {
        id: id++,
        invoiceNumber: parseInt(data.invoice_number),
        guestname: `${data.first_name} ${data.last_name}`,
        account_code: data.account_id,
        description: data.description,
        currency: data.currency,
        debit: data.debit,
        credits: data.credits,
        user_name: data.user_name,
      };
      // console.log(obj, "this is the obj");

      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }

  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchUnsettledBills();
  }
  const settleBill = async () => {
    setLoading(true);
    console.log("Bill will be cancelled");
    const response = await settleBillApi(
      bookingId,
      new Date().toISOString().slice(0, 10),
      mode,
      description,
      corporateId,
      travelAgentId
    );
    if (response.status_message === "success") {
      fetchUnsettledBills();
      setIsSettleOpen(false);
      setOpenSnackSuccess(true);
    } else {
      setOpenSnackFailure(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
      setCorporateList(res.data)
    );
    getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
      setTravelAgentList(res.data)
    );
  }, []);
  const fetchUnsettledBills = () => {
    // const startDateString = `${range.startDate.getFullYear()}-${
    //   range.startDate.getMonth() + 1
    // }-${range.startDate.getDate()}`;
    const startDateString = moment(range.startDate).format("YYYY-MM-DD");
    const endDateString = moment(range.startDate).format("YYYY-MM-DD");
    // const endDateString = `${range.endDate.getFullYear()}-${
    //   range.endDate.getMonth() + 1
    // }-${range.endDate.getDate()}`;
    getUnsettledBills(loggedUser?.hotel_code).then((res) => setData(res.data));
  };
  useEffect(() => {
    fetchUnsettledBills();
  }, []);
  useEffect(() => {
    getRows(data);
  }, [data]);
  return (
    <>
      <div className="font-bold text-xl text-blue-800 ml-2.5 mb-16">
        UnSettled Report
      </div>
      <p className="ml-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink data={dynamicRows} filename={"settlement-report.csv"}>
            <Tooltip title="Download Excel">
              {/* <FileDownloadIcon></FileDownloadIcon> */}
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Download
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          checkboxSelection
          sortingOrder={["asc", "desc"]}
        />
      </div>
      <Dialog
        open={isSettleOpen}
        onClose={() => setIsSettleOpen(false)}
        sx={{
          width: "calc(100% - 200px)",
          minWidth: "590px",
          margin: "auto",
        }}
      >
        <DialogTitle>Bill Settlement</DialogTitle>
        <DialogContent sx={{ minWidth: "600px" }}>
          <FormGroup>
            <InputLabel htmlFor="mode" className="">
              Mode
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mode}
              label="Mode"
              className="p-4"
              onChange={(e) => setMode(e.target.value)}
            >
              <MenuItem value={""} key="select">
                Select
              </MenuItem>
              <MenuItem value={"card"} key="card">
                Card
              </MenuItem>
              <MenuItem value={"cash"} key="cash">
                Cash
              </MenuItem>
              <MenuItem value={"upi"} key="upi">
                UPI
              </MenuItem>
              <MenuItem value={"online"} key="online">
                Online
              </MenuItem>
              <MenuItem value={"corporate"} key="corporate">
                Corporate
              </MenuItem>
              <MenuItem value={"travel_agent"} key="travel_agent">
                Travel Agent
              </MenuItem>
            </Select>
            {mode === "corporate" ? (
              <>
                <InputLabel htmlFor="mode" className="">
                  Corporate
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={paymentDetails?.mode || "cash"}
                  label="Corporate"
                  className="p-4"
                  onChange={(e: any) => {
                    setCorporateId(e.target.value);
                  }}
                >
                  <MenuItem value={""} key="select">
                    Select
                  </MenuItem>
                  {corporateList.length > 0 &&
                    corporateList.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.corporate_name}
                      </MenuItem>
                    ))}
                </Select>
              </>
            ) : mode === "travel_agent" ? (
              <>
                <InputLabel htmlFor="mode" className="">
                  Travel Agent
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={paymentDetails?.mode || "cash"}
                  label="Travel Agent"
                  className="p-4"
                  onChange={(e: any) => {
                    setTravelAgentId(e.target.value);
                  }}
                >
                  <MenuItem value={""} key="select">
                    Select
                  </MenuItem>
                  {travelAgentList.length > 0 &&
                    travelAgentList.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.billing_company_name}
                      </MenuItem>
                    ))}
                </Select>
              </>
            ) : null}
            {mode !== "" && (
              <FormGroup>
                <InputLabel>Description</InputLabel>
                <OutlinedInput
                  // disabled={loading}
                  placeholder="Please enter the Description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            )}
            <Button sx={{ marginY: "1rem" }} onClick={settleBill}>
              Click here to settle the bill
            </Button>
          </FormGroup>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openSnackSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSnackSuccess(false)}
      >
        <Alert
          onClose={() => setOpenSnackSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully settled.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackFailure}
        autoHideDuration={6000}
        onClose={() => setOpenSnackFailure(false)}
      >
        <Alert
          onClose={() => setOpenSnackFailure(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error while cancelling the bill.
        </Alert>
      </Snackbar>
    </>
  );
}
