import * as React from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Container, Typography, Box, Button, Tooltip } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Page } from "../components/Page";
import {
  getCurrentUser,
  getCashierData,
  insertCashierDaliyPaymentData,
  getNightAuditSummary,
  performNightAudit,
  getLastNightAuditDate,
} from "../api-config/config";
import { FormProvider, RHFTextField } from "../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Iconify from "../components/Iconify";
import AddFeatureForm from "../components/Features/AddFeatureForm";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Snackbar from "../components/CustomSnackBar/AlertSnack";
import AlertSnack from "../components/CustomSnackBar/AlertSnack";

const label = { inputProps: { "aria-label": "Checkbox" } };

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "room_details",
    headerName: "Room Details",
    headerClassName: "super-app-theme--header",
    width: 600,
  },
  {
    field: "no_of_rooms",
    headerName: "No of Room(s)",
    headerClassName: "super-app-theme--header",
    width: 400,
  },
  {
    field: "no_of_guests",
    headerName: "No of Guest(s)",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
];

const accountDetailsColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "counter",
    headerName: "Counter",
    headerClassName: "super-app-theme--header",
    width: 150,
  },
  {
    field: "cash_received",
    headerName: "Cash Received",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "others_received",
    headerName: "Revenue received from other sources",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cash_withdrawn",
    headerName: "Revenue received by cash",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "others_withdrawn",
    headerName: "Revenue withdrawn from other sources",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "balance",
    headerName: "Balance",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
];

const housekeepingColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "room_details",
    headerName: "Room Details",
    headerClassName: "super-app-theme--header",
    width: 800,
  },
  {
    field: "no_of_rooms",
    headerName: "No of Room(s)",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
];

const revenueColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "revenue_stream",
    headerName: "Revenue Stream",
    headerClassName: "super-app-theme--header",
    width: 1000,
  },
  {
    field: "amount",
    headerName: "Amount",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
];

const bookingRevenueColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "revenue_stream",
    headerName: "Revenue Stream",
    headerClassName: "super-app-theme--header",
    width: 1000,
  },
  {
    field: "amount",
    headerName: "Amount",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
];

const taxColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "tax_name",
    headerName: "Tax Name",
    headerClassName: "super-app-theme--header",
    width: 800,
  },
  {
    field: "amount",
    headerName: "Amount",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
];

const roomInclusionColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "source",
    headerName: "Restaurant",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "total_price",
    headerName: "Total Price",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "total_tax",
    headerName: "Total Tax",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "amount",
    headerName: "Amount",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
];

const infoColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "third_party_connection",
    headerName: "Third Party Connection",
    headerClassName: "super-app-theme--header",
    width: 200,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
];

function NightAuditGrid(data: any) {
  const { row, featureAdded } = data;

  const auditFields: any[] = [];
  if (data?.room_details) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.room_details;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      room_details: el.replace(/_/g, " "),
      no_of_rooms: obj[el].rooms,
      no_of_guests: obj[el].guest,
    });
  });

  return (
    <Box
      sx={{
        height: 530,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "5px 1px",
        }}
      >
        <h3 style={{ marginTop: 2 }}>Night Audit</h3>
        <CSVLink data={auditFields} filename={"night-audit.csv"}>
          <Tooltip title="Download Excel">
            {/* <FileDownloadIcon></FileDownloadIcon> */}
            <Button
              type="button"
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
            >
              Download
            </Button>
          </Tooltip>
        </CSVLink>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={columns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function HouseKeepingGrid(data: any) {
  const { row, featureAdded } = data;

  const auditFields: any[] = [];
  if (data?.housekeeping_details) {
    console.log("hi");
  }
  const obj: any = data?.row?.housekeeping_details;
  const list: any[] = Object.keys(obj);
  console.log(data);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      room_details: el.replace(/_/g, " "),
      no_of_rooms: obj[el],
    });
  });

  return (
    <Box
      sx={{
        height: 300,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>House Keeping Detals</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={housekeepingColumns}
        pageSize={8}
        // components={{Toolbar: () => {
        //   return <GridToolbarContainer><GridToolbarExport sx={{justifyContent: "flex-end"}} /></GridToolbarContainer>
        // }}}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function AccountDetailsGrid(data: any) {
  const auditFields: any[] = [];
  if (data?.account_details) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.account_details;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      counter: el.replace(/_/g, " "),
      cash_received: obj[el].revenue_received,
      others_received: 0.0,
      cash_withdrawn: obj[el].withdrawals,
      others_withdrawn: 0.0,
      balance: obj[el].balance,
      status: obj[el].status,
    });
  });

  return (
    <Box
      sx={{
        height: 220,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Account Details</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={accountDetailsColumns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function RevenueGrid(data: any) {
  const auditFields: any[] = [];
  if (data?.revenue_list) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.revenue_list;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      revenue_stream: el.replace(/_/g, " "),
      amount: obj[el],
    });
  });

  return (
    <Box
      sx={{
        height: 480,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Revenue List</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={revenueColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function BookingRevenueGrid(data: any) {
  const auditFields: any[] = [];
  if (data?.booking_revenue) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.booking_revenue;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      revenue_stream: el.replace(/_/g, " "),
      amount: obj[el],
    });
  });

  return (
    <Box
      sx={{
        height: 480,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Booking Revenue</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={bookingRevenueColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function RoomInclusionGrid(data: any) {
  const auditFields: any[] = [];
  if (data?.room_inclusions) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.room_inclusions;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      source: el.replace(/_/g, " "),
      quantity: obj[el].quantity,
      total_price: obj[el].total_price,
      total_tax: obj[el].total_tax,
      amount: obj[el].amount,
    });
  });

  return (
    <Box
      sx={{
        height: 250,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Room Inclusion</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={roomInclusionColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function TaxGrid(data: any) {
  const auditFields: any[] = [];
  if (data?.room_tax) {
    console.log("hi");
  }
  console.log(data);
  const obj: any = data?.row?.room_tax;
  const list: any[] = Object.keys(obj);
  console.log("===> this is the list to be shown", list);
  list?.forEach((el: any, i: any) => {
    auditFields.push({
      id: i + 1,
      tax_name: el.replace(/_/g, " "),
      amount: obj[el],
    });
  });

  return (
    <Box
      sx={{
        height: 250,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>Room Tax(es)</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={taxColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function PosTaxGrid(data: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [base, setBase] = useState("");
  const baseChange = (event: SelectChangeEvent) => {
    setBase(event.target.value);
  };
  const [higher, setHigher] = useState("");
  const higherChange = (event: SelectChangeEvent) => {
    setHigher(event.target.value);
  };
  const [extra, setExtra] = useState("");
  const extraChange = (event: SelectChangeEvent) => {
    setExtra(event.target.value);
  };
  const { row, featureAdded } = data;

  const auditFields: any[] = [
    // {
    //   id: 1,
    //   tax_name: "Service Charge",
    //   amount: 147,
    // },
    // {
    //   id: 2,
    //   tax_name: "VAT",
    //   amount: 295,
    // },
    // {
    //   id: 3,
    //   tax_name: "Total",
    //   amount: 442,
    // }
  ];

  function onFeatureAdded() {
    featureAdded();
  }

  return (
    <Box
      sx={{
        height: 125,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        <h3 style={{ marginTop: -2 }}>3rd Party Connections</h3>
      </Box>
      <DataGrid
        rows={auditFields}
        columns={taxColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function InfoGrid(data: any) {
  const { row, featureAdded } = data;

  const auditFields: any[] = [
    // {
    //   id: 1,
    //   third_party_connection: "Default Counter",
    //   action: null
    // }
  ];

  return (
    <Box
      sx={{
        height: 200,
        width: "100%",
        marginTop: 4,
        fontWeight: 600,
      }}
    >
      <Box
        sx={{
          color: "black",
          background: "#e7e7e7",
          padding: "12px",
          height: "40px",
          width: "100%",
          margin: "1px",
        }}
      >
        {/* <h3 style={{ marginTop: -2 }}>Account Details</h3> */}
      </Box>
      <DataGrid
        rows={auditFields}
        columns={infoColumns}
        pageSize={8}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarExport sx={{ justifyContent: "flex-end" }} />
              </GridToolbarContainer>
            );
          },
        }}
        rowsPerPageOptions={[8]}
        // checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function NightAuditSummary() {
  const [featureList, setFeatureList] = useState([] as any);
  const loggedUser = getCurrentUser();
  const location = useLocation();
  const oldData: any = location.state.date;
  const [nightAuditDate, setNightAuditDate] = useState(
    new Date(oldData) as any
  );
  console.log(oldData);
  const navigate = useNavigate();
  const [summary, setSummary] = useState({} as any);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [open1, setOpen1] = useState(false);

  // const [ buttonEnable, setButtonEnable ] = useState(false);

  const midNightQuery = () => {
    // Get the current time
    const currentTime = new Date();

    // Set the target time to midnight
    console.log(nightAuditDate);
    const targetTime = nightAuditDate;
    // targetTime.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    targetTime.setHours(23, 59, 59, 0); // Set hours, minutes, seconds, and milliseconds to zero
    console.log(currentTime);
    console.log(targetTime);

    // Compare the current time with the target time
    if (currentTime > targetTime) {
      // Enable the button
      console.log("working");
      // setButtonEnable(true);
      return true;
    } else {
      console.log("not-working");
      return false;
    }
  };

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser);
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      name: formData?.name,
      online: formData?.online,
      card: formData?.card,
      upi: formData?.upi,
      cash: formData?.cash,
      wallet: formData?.wallet,
      total_payment: formData?.total_payment,
      date: formData?.date,
    };
    insertCashierDaliyPaymentData(payload).then((response: any) => {
      console.log(response);
      // onFeatureAdded();
      // handleClose();
    });
  };
  const getNightAudit = (hotel_code: number) => {
    return getCashierData({
      hotel_code,
      from_data: new Date(),
      to_date: new Date(Date.now() + 1000 * 3600 * 24),
    }).then(
      (res: any) => {
        if (res?.status_message === "success") {
          console.log(res);
          // setFeatureList([...rooms]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  const getNightAuditSummaryValues = (hotel_code: number) => {
    return getNightAuditSummary({
      hotel_code,
      data: new Date().toISOString().slice(0, 10),
    }).then(
      (res: any) => {
        if (res?.status_message === "success") {
          console.log(res.data);
          setSummary(res.data);
          // setFeatureList([...rooms]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getNightAudit(loggedUser?.hotel_code);
    getNightAuditSummaryValues(loggedUser?.hotel_code);
    // getLastNightAuditDate(loggedUser?.hotel_code).then(res => {
    //   setNightAuditDate(((new Date((new Date(res.data.last_night_audit_last_date)).getTime() + 86400000))));
    // });
  }, []);
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          {open1 && (
            <AlertSnack
              response={snackbarMessage}
              open={open1}
              setOpen={setOpen1}
            />
          )}
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              textAlign: "center",
              marginLeft: 20,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Night Audit Summary for {`${nightAuditDate.toDateString()}`}
          </Typography>
          {summary?.room_details && <NightAuditGrid row={summary} />}
          {summary?.housekeeping_details && <HouseKeepingGrid row={summary} />}
          {summary?.account_details && <AccountDetailsGrid row={summary} />}
          {summary?.revenue_list && <RevenueGrid row={summary} />}
          {/* <InfoGrid row={featureList}/> */}
          {summary?.room_inclusions && <RoomInclusionGrid row={summary} />}
          {summary?.room_tax && <TaxGrid row={summary} />}
          {/* <PosTaxGrid row={featureList}/> */}
          {summary?.booking_revenue && <BookingRevenueGrid row={summary} />}
          <div className="mt-12 w-[80%] m-auto">
            <div className="flex w-[75%] mt-4 justify-evenly m-auto">
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b]"
                onClick={() => {
                  localStorage.setItem(
                    "nightAuditDets",
                    JSON.stringify(summary)
                  );
                  localStorage.setItem("nightAuditDate", nightAuditDate);
                  navigate("/main/nightauditreport");
                }}
                // onClick={() => alert("Reports will be printed soon!")}
              >
                Continue and View Night Audit Reports
              </Button>
              <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b]"
                onClick={() => navigate("/main/home")}
              >
                Continue to work
              </Button>
              <Button
                variant="contained"
                disabled={!midNightQuery()}
                // color="info"
                className="bg-[#01579b]"
                onClick={async () => {
                  console.log(new Date().getMinutes());
                  const response = await performNightAudit({
                    hotel_code: loggedUser?.hotel_code,
                    audit_date: nightAuditDate.toISOString().slice(0, 10),
                  });
                  if (response?.status_message === "failed") {
                    // alert(response?.message)
                    setOpen1(true);
                    setSnackbarMessage(response);
                  } else {
                    setOpen1(true);
                    setSnackbarMessage(response);

                    setTimeout(() => {
                      navigate("/main/home");
                    }, 2000);
                    // navigate("/main/home")
                  }
                }}
              >
                Day-End
              </Button>
              {/* <Button
                variant="contained"
                // color="info"
                className="bg-[#01579b]"
              >
                Continue to Next Night Audit
              </Button> */}
            </div>
          </div>
        </Container>
      </Page>
    </>
  );
}
