import Iconify from "../../components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 22, height: 22 }} />
);
interface NavItem {
  title: string;
  path: string;
}

interface NavConfigItem {
  title: string;
  sublinks?: NavItem[];
}
const navConfigReport: NavConfigItem[] = [
  {
    title: "Activity Log",
    sublinks: [
      {
        title: "All Users",
        path: "/reports/allUsers",
      },
      {
        title: "PKG Report",
        path: "/reports/pkgReport",
      },
      {
        title: "Rate / PKG Report",
        path: "/reports/ratePkgReport",
      },

      {
        title: "Rate Variations",
        path: "/reports/rateVariations",
      },
    ],
  },
  {
    title: "Discount Report",
    sublinks: [
      {
        title: "Allowance Report",
        path: "/reports/allowancereport",
      },
      {
        title: "Discount Report",
        path: "/reports/discountreport",
      },
    ],
  },
  {
    title: "Financial Report",
    sublinks: [
      // {
      //   title: "Financial Report",
      //   path: "/reports/monthlySummaryReport",
      // },

      // {
      //   title: "Advance Deposit Report",
      //   path: "/reports/advanceDepositReport",
      // },
      {
        title: "Daily Revenue Report",
        path: "/reports/dailyRevenueReport",
      },
      {
        title: "Journal Report",
        path: "/reports/journalreports",
      },
      {
        title: "Manager Flash Report",
        path: "/reports/managerflashreport",
      },
      {
        title: "Rev Par Room Report Monthwise",
        path: "/reports/revParRoomReportMonthwise",
      },
    ],
  },
  {
    title: "Fund Operations",
    sublinks: [
      {
        title: "Cashier Report",
        path: "/reports/accountstatement",
      },
      {
        title: "Discrepancy Report",
        path: "/reports/discrepancyreport",
      },

      {
        title: "Housekeeping Report",
        path: "/reports/housekeeping",
      },

      {
        title: "Revenue Report",
        path: "/reports/revenuereport",
      },
    ],
  },

  {
    title: "Guest Report",
    sublinks: [
      {
        title: "Expected Arrival Report",
        path: "/reports/ExpectedArrivalList",
      },
      {
        title: "Expected Departure Report",
        path: "/reports/ExpectedDepartureList",
      },
      {
        title: "Guest Contact Report",
        path: "/reports/guestContactReport",
      },
      {
        title: "Guest History Report",
        path: "/reports/guestHistoryReport",
      },

      {
        title: "Guest Ledger",
        path: "/reports/guestLedger",
      },
      {
        title: "Occupancy Report",
        path: "/reports/guestinhouselist",
      },
    ],
  },
  {
    title: "Night Audit Report",
    sublinks: [
      {
        title: "Hotel Trial Balance Report",
        path: "/reports/hotelTrialBalanceReport",
      },
      {
        title: "MTD & YTD Report",
        path: "/reports/mtdYtdReport",
      },
      {
        title: "Night Audit Counter Report",
        path: "/reports/nightAuditCounterReport",
      },
      {
        title: "Night Audit Report",
        path: "/reports/nightaudit",
      },
      {
        title: "Night Audit Rev Report",
        path: "/reports/nightAuditRevReport",
      },
      {
        title: "Night Audit Room Dates",
        path: "/reports/nightAuditRoomDates",
      },

      {
        title: "Night Audit Tax Report",
        path: "/reports/nightAuditTaxReport",
      },
    ],
  },
  {
    title: "Occupancy Report",
    sublinks: [
      {
        title: "History & Forecast Report",
        path: "/reports/historyforecastreport",
      },
      {
        title: "Occupancy Report",
        path: "/reports/guestinhouselist",
      },
    ],
  },
  {
    title: "Reservations Report",
    sublinks: [
      {
        title: "Business Source Report",
        path: "/reports/businessSourceReport",
      },
      {
        title: "Cancelled Bill Report",
        path: "/reports/cancelledbilllist",
      },
      {
        title: "Cancelled Reservations",
        path: "/reports/cancelledreservationlist",
      },

      {
        title: "Check-In Report",
        path: "/reports/checkinlist",
      },
      {
        title: "Check-Out Report",
        path: "/reports/checkoutlist",
      },
      {
        title: "Complimentary Room Report",
        path: "/reports/complimentaryRoomReport",
      },
      {
        title: "Expected Arrival Report",
        path: "/reports/expectedArrivalReport",
      },
      {
        title: "No Show Report",
        path: "/reports/noshowlist",
      },
      {
        title: "Occupancy Forecasting Report",
        path: "/reports/occupancyForecastingReport",
      },

      {
        title: "Temp Reservation Report",
        path: "/reports/tempReservationReport",
      },
    ],
  },
  {
    title: "Tax Report",
    sublinks: [
      {
        title: "Bill To Company",
        path: "/reports/billToCompany",
      },
      {
        title: "GST Report",
        path: "/reports/taxreport",
      },

      {
        title: "Settlement Report",
        path: "/reports/settlementreport",
      },
      {
        title: "Source Report",
        path: "/reports/segmentreport",
      },

      {
        title: "Hotel Trial Balance Report",
        path: "/reports/hotelTrialBalanceReport",
      },
      {
        title: "Night Audit Report",
        path: "/reports/nightaudit",
      },
      {
        title: "No Show Report",
        path: "/reports/noshowlist",
      },
    ],
  },
];

export default navConfigReport;
