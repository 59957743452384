import { replace } from "lodash";
import numeral from "numeral";

type Props = {
  number: number;
  perNumber: number;
};
export function fCurrency(props: Props) {
  return numeral(props.number).format(
    Number.isInteger(props.number) ? "$0,0" : "$0,0.00"
  );
}

export function fPercent(props: Props) {
  return numeral(props.perNumber / 100).format("0.0%");
}

export function fNumber(props: Props) {
  return numeral(props.number).format();
}

export function fShortenNumber(props: Props) {
  return replace(numeral(props.number).format("0.00a"), ".00", "");
}

export function fData(props: Props) {
  return numeral(props.number).format("0.0 b");
}

export const convertToRupee = (amount?: number) =>
  amount?.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

export const calNoOfNights = (start: any, end: any) => {
  // Define the start_date and end_date as strings

  // const start_date_str: string = "2023-10-11";
  // const end_date_str: string = "2023-10-15";

  // Convert the date strings to Date objects
  const start_date: Date = new Date(start);
  const end_date: Date = new Date(end);

  // Calculate the time difference in milliseconds
  const time_difference: number = end_date.getTime() - start_date.getTime();

  // Calculate the number of nights (1 night = 24 hours)
  const number_of_nights: number = Math.ceil(
    time_difference / (1000 * 60 * 60 * 24)
  );

  return +number_of_nights;
};
