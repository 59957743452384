import axios from "axios";
import { baseUrl, logout } from "./config";
import api from "./axiosInstance";

export const getCorporateData = async (hotel_code: number, id: number) => {
  return api
    .post(baseUrl + "/get_corporate_data", {
      hotel_code,
      id,
    })
    .then((response: any) => {
      return response.data;
    });
};
export const getUsersList = async (hotel_code: number) => {
  return api
    .post(baseUrl + "/get_user_data_pms", {
      hotel_code,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertUser = async (data: any, func?: any) => {
  return api.post(baseUrl + "/insert_user_data", data).then((response: any) => {
    // func(response.data);
    return response.data;
  });
};

export const editUser = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/update_user_data_pms", data)
    .then((response: any) => {
      // func(response.data);
      return response.data;
    });
};

export const logoutUser = async (data: any) => {
  logout;
};

export const getTravelAgentData = async (hotel_code: number, id: number) => {
  return api
    .post(baseUrl + "/get_travel_agent_data", {
      hotel_code,
      id,
    })
    .then((response: any) => {
      return response.data;
    });
};

export const insertTravelAgent = async (data: any, func?: any) => {
  return api
    .post(baseUrl + "/insert_travel_agent_data", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      return response.data;
    });
};
