import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getCurrentUser,
  getHouseKeepingList,
  getRoomType,
} from "../api-config/config";
import Calendar from "../components/Calendar";
import HouseKeepingTable from "../components/HouseKeeping";
import { Page } from "../components/Page";
import { useDetails } from "../contexts/DetailsContext";

export default function DashboardApp() {
  const [houseKeepingList, setHouseKeepingList] = useState([]);
  const [filteredHouseKeepingList, setFilteredHouseKeepingList] = useState([]);
  const { calendarValue } = useDetails();

  const loggedUser = getCurrentUser();

  function filterHouseKeepingList() {
    // if (!calendarValue.toISOString().slice(0, 10)) {
    //   // If selectedDate is not set, return the original list
    //   return houseKeepingList;
    // }
    return houseKeepingList.filter((item: any) => {
      const date: any = calendarValue.toISOString().slice(0, 10);
      console.log(date);
      const itemDate = item.from_date; // Replace with the actual property name in your data
      return itemDate === date; // Check for equality with selectedDate
    });
  }

  // Move the fetchHouseKeepingList function before useEffect
  const fetchHouseKeepingList = (hotel_code: number) => {
    return getHouseKeepingList(hotel_code).then(
      (res) => {
        if (res?.status_message === "success") {
          setHouseKeepingList(res.data);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };

  function onHouseKeepingListUpdated() {
    fetchHouseKeepingList(loggedUser?.hotel_code);
  }

  useEffect(() => {
    fetchHouseKeepingList(loggedUser?.hotel_code);
  }, []); // Only fetch the list on initial component mount

  useEffect(() => {
    // When selectedDate changes, filter and update the list
    const filteredList = filterHouseKeepingList();
    setFilteredHouseKeepingList(filteredList);
  }, [calendarValue, houseKeepingList]); // Include houseKeepingList in dependencies

  console.log("houseKeepingList", houseKeepingList);
  console.log("filtered house asad", filteredHouseKeepingList);

  return (
    <Page title="Home">
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 600,
            fontSize: 16,
          }}
        >
          <HouseKeepingTable
            houseKeepingList={houseKeepingList}
            onHouseKeepingListUpdated={onHouseKeepingListUpdated}
          />
        </Typography>
      </Container>
    </Page>
  );
}
