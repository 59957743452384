import React from "react";
import { ResponsiveBar } from "@nivo/bar";

type DataItem = {
  "name": string;
  "This Year": number;
  "Last Year": number;
  "Budget": number;
  // 'Exp Room': number;
  // 'Superior Room': number;
  // 'Family Room': number;
};

const RevenueChart: React.FC = () => {
  const data: DataItem[] = [
    {
      "name": "Frontdesk",
      "This Year": 10,
      "Last Year": 5,
      "Budget": 8,
      // 'Exp Room': 3,
      // 'Superior Room': 12,
      // 'Family Room': 6,
    },
    {
      "name": "F&B",
      "This Year": 8,
      "Last Year": 6,
      "Budget": 7,
      // 'Exp Room': 4,
      // 'Superior Room': 14,
      // 'Family Room': 9,
    },
    {
      "name": "Others",
      "This Year": 10,
      "Last Year": 5,
      "Budget": 8,
      // 'Exp Room': 3,
      // 'Superior Room': 12,
      // 'Family Room': 6,
    },
    {
      "name": "Total",
      "This Year": 8,
      "Last Year": 6,
      "Budget": 7,
      // 'Exp Room': 4,
      // 'Superior Room': 14,
      // 'Family Room': 9,
    },

    // Add more data objects as needed...
  ];

  return (
    <div style={{ border: "1px solid #ccc" }}>
      <h2 className="occupancy-heading"> Revenue Analysis</h2>
      <div style={{ height: "280px" }}>
        <ResponsiveBar
          data={data}
          keys={[
            "This Year",
            "Last Year",
            "Budget",
            // "Exp Room",
            // "Superior Room",
            // "Family Room",
          ]}
          indexBy="name"
          margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: "nivo" }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "name",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Occupancy",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default RevenueChart;
