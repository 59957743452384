export {};
import React, { useEffect, useState } from "react";
import { getChargesAndPayment, getCurrentUser } from "../../api-config/config";
import moment from "moment";

const IndFolioStatement = (props: any) => {
  const { selectedIndFolio, folioDetails, selectedIndInv } = props;
  const [accountData, setAccountData] = useState([] as any);
  const loggedUser = getCurrentUser();

  useEffect(() => {
    console.log(
      selectedIndFolio,
      folioDetails,
      "this is selected folio----------------------------"
    );
    console.log(selectedIndInv, "this selected ind invoice");

    if (selectedIndInv?.inv_type === "inv") {
      const payload = {
        inv_type: selectedIndInv?.inv_type,
        inv_no: selectedIndInv?.inv_no,
        hotel_code: loggedUser?.hotel_code,
      };

      getChargesAndPayment(payload).then((respone: any) => {
        console.log("this is ", respone);

        if (respone.status_message === "success") {
          // setLoading(false)
          setAccountData(respone.data);
        } else {
          // setLoading(false)
        }
      });
    } else {
      const payload = {
        inv_type: selectedIndInv?.inv_type,
        prov_inv_no: selectedIndInv?.prov_inv_no,
        hotel_code: loggedUser?.hotel_code,
      };

      getChargesAndPayment(payload).then((respone: any) => {
        console.log("this is ", respone);

        if (respone.status_message === "success") {
          // setLoading(false)
          setAccountData(respone.data);
        } else {
          // setLoading(false)
        }
      });
    }

    // setLoading(true)
  }, [selectedIndFolio]);
  return (
    <div>
      <h1 className="mt-8 text-center">{selectedIndFolio?.charge_to}</h1>
      <div className="container mx-auto mt-8">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="p-2 border-b text-center">Date</th>
              <th className="p-2 border-b text-center">
                Description - References
              </th>
              <th className="p-2 border-b text-center">Mode</th>
              <th className="p-2 border-b text-center">Charges</th>
              <th className="p-2 border-b text-center">Tax</th>
              <th className="p-2 border-b text-center">Payment</th>
              <th className="p-2 border-b text-center">Disc/Allowance</th>
              <th className="p-2 border-b text-center">Refund</th>
              <th className="p-2 border-b text-center">Folio</th>
              <th className="p-2 border-b text-center">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-2 border-b bg-yellow-200" colSpan={10}>
                {/* {row?.charge_to} */} charge to
              </td>
            </tr>
            {accountData?.map((ele: any, rowIndex: number) => {
              if (ele?.type === "charge") {
                return ele?.extra_allowance && !ele?.paid ? (
                  <tr key={rowIndex + 1} className="hover:bg-grey-800">
                    <td className="p-2 border-b text-center">
                      {/* <input
                          type="checkbox"
                          style={{ marginRight: "8px" }}
                          checked={
                            selectedRow[0]?.id === ele.id ? true : false
                          }
                          onClick={() => handleHeaderClick(ele)}
                        /> */}
                      {moment(ele?.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="p-2 border-b text-center">
                      (Allowance) {ele?.description}
                    </td>
                    <td className="p-2 border-b text-center">{ele?.mode}</td>
                    <td className="p-2 border-b text-center">0</td>
                    <td className="p-2 border-b text-center">
                      {ele?.tax_amount}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.discount_amount}
                    </td>
                    <td className="p-2 border-b text-center">
                      {"₹ -" + ele?.price || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.refund || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.folio_data?.map((folio: any, i: any) => (
                        <span style={{ color: "blue" }} key={folio}>
                          {folio?.prov_inv_no}
                          {ele?.folio_data?.length - 1 === i ? null : ", "}
                        </span>
                      ))}
                    </td>
                    <td className="p-2 border-b text-center">
                      {"-" + ele?.total_amount}
                    </td>
                  </tr>
                ) : (
                  <tr key={rowIndex + 1} className="hover:bg-grey-800">
                    <td className="p-2 border-b text-center">
                      {/* <input
                          type="checkbox"
                          checked={
                            selectedRow[0]?.id === ele.id ? true : false
                          }
                          style={{ padding: "4px" }}
                          onClick={() => handleHeaderClick(ele)}
                        /> */}
                      {moment(ele?.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.description}
                    </td>
                    <td className="p-2 border-b text-center">{ele?.mode}</td>
                    <td className="p-2 border-b text-center">
                      {"₹" + ele?.price}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.tax_amount}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.discount_amount}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.payment || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.refund || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {
                        <td className="p-2 border-b text-center">
                          {ele?.folio_data?.map((folio: any, i: any) => (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                padding: "2px",
                                textAlign: "center",
                              }}
                            >
                              {folio?.prov_inv_no}
                              {ele?.folio_data?.length - 1 === i ? null : ", "}
                            </span>
                          ))}
                        </td>
                      }
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.total_amount}
                    </td>
                  </tr>
                );
              } else {
                return ele?.refund ? (
                  <tr key={rowIndex + 1} className="hover:bg-grey-800">
                    <td className="p-2 border-b text-center">
                      {/* <input
                          type="checkbox"
                          style={{ marginRight: "8px" }}
                          checked={
                            selectedRow[0]?.id === ele.id ? true : false
                          }
                          onClick={() => handleHeaderClick(ele)}
                        /> */}
                      {moment(ele?.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="p-2 border-b text-center">
                      (Refund) {ele?.description}
                    </td>
                    <td className="p-2 border-b text-center">{ele?.mode}</td>
                    <td className="p-2 border-b text-center">0</td>
                    <td className="p-2 border-b text-center">
                      {ele?.tax_amount}
                    </td>
                    <td className="p-2 border-b text-center">{0}</td>
                    <td className="p-2 border-b text-center">{0}</td>
                    <td className="p-2 border-b text-center">
                      {ele?.price || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.folio_data?.map((folio: any, i: any) => (
                        <span style={{ color: "blue" }} key={folio}>
                          {folio?.prov_inv_no}
                          {ele?.folio_data?.length - 1 === i ? null : ", "}
                        </span>
                      ))}
                    </td>
                    <td className="p-2 border-b text-center">
                      {"-" + ele?.total_amount}
                    </td>
                  </tr>
                ) : (
                  <tr key={rowIndex + 1} className="hover:bg-grey-800">
                    <td className="p-2 border-b text-center">
                      {/* <input
                          type="checkbox"
                          checked={
                            selectedRow[0]?.id === ele.id ? true : false
                          }
                          style={{ padding: "4px" }}
                          onClick={() => handleHeaderClick(ele)}
                        /> */}
                      {moment(ele?.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="p-2 border-b text-center">
                      {ele?.description || ele?.mode}
                    </td>
                    <td className="p-2 border-b text-center">{ele?.mode}</td>
                    <td className="p-2 border-b text-center">{0}</td>
                    <td className="p-2 border-b text-center">
                      {ele?.tax_amount}
                    </td>
                    <td className="p-2 border-b text-center">{ele?.price}</td>
                    <td className="p-2 border-b text-center">{0}</td>

                    <td className="p-2 border-b text-center">
                      {ele?.refund || 0}
                    </td>
                    <td className="p-2 border-b text-center">
                      {
                        <td className="p-2 border-b text-center">
                          {ele?.folio_data?.map((folio: any, i: any) => (
                            <span
                              style={{
                                color: "blue",
                                cursor: "pointer",
                                padding: "2px",
                                textAlign: "center",
                              }}
                            >
                              {folio?.prov_inv_no}
                              {ele?.folio_data?.length - 1 === i ? null : ", "}
                            </span>
                          ))}
                        </td>
                      }
                    </td>
                    <td className="p-2 border-b text-center">
                      {"-" + ele?.total_amount}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IndFolioStatement;
