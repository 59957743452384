import { Button, FormGroup } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  getCurrentUser,
  getRoomType,
  insertFeatureData,
  insertRoomType,
} from "../../api-config/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const menuItems = ["Light", "AC", "TV", "Fan"];
const AddFeatureForm = (props: any) => {
  const { handleClickOpen, handleClose, onFeatureAdded } = props;
  const [roomTypeList, setRoomTypeList] = useState([] as any);
  const [isInputSelectable, setInputSelectable] = useState(false);
  const loggedUser = getCurrentUser();

  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          const roomTypeArray = [] as any;
          res.data?.forEach((roomType: any) => {
            const obj = {
              id: roomType.id,
              room_type_name: roomType.room_type_name,
            };
            roomTypeArray.push(obj);
          });
          setRoomTypeList([...roomTypeArray]);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);

  const defaultValues = {
    name: "",
    room_type_id: "",
  };

  const formValidation = Yup.object().shape({
    room_type_id: Yup.string().required("Please selecte Room type"),
    name: Yup.string().required("Please enter Feature name"),
  });
  function onCancel() {
    console.log("close");
  }

  const handleSubmit = (formData: any) => {
    console.log(formData, loggedUser);
    const payload = {
      name: formData.name,
      room_type_id: formData.room_type_id,
    };
    insertFeatureData(payload).then((response: any) => {
      console.log(response);
      onFeatureAdded();
      handleClose();
    });
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={formValidation}
      onSubmit={(event) => handleSubmit(event)}
    >
      <Form className="grid gap-4 lg:grid-cols-1 p-8">
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Room Type
          </label>
          <Field as="select" type="number" name="room_type_id">
            <option value="">Select</option>
            {roomTypeList.map((list: any, key: number) => {
              return (
                <option value={list.id} key={key}>
                  {list.room_type_name}
                </option>
              );
            })}
          </Field>
          <p className="text-red-600">
            <ErrorMessage name="room_type_id" />
          </p>
        </FormGroup>
        <FormGroup>
          <label htmlFor="" className="pb-2">
            Feature:
          </label>
          <Field
            name="name"
            className="con-field"
            placeholder="Enter Feature name"
            id="name"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="name" />
          </p>
        </FormGroup>
        {/* <FormGroup>
                    <label htmlFor="" className="pb-2">
                        Short Name: 
                    </label>
                    <Field
                        name="short_name"
                        className="con-field"
                        placeholder="Enter Short name"
                        id="short_name"
                        type="text"
                    />
                    <p className="text-red-600">
                        <ErrorMessage name="short_name" />
                    </p>
                </FormGroup> */}
        {/* <FormGroup>
                    <label htmlFor="" className="pb-2">
                        Upload Images: 
                    </label>
                    <Field
                        name="upload_images"
                        className="con-field"
                        placeholder="Enter Upload Images"
                        id="upload_images"
                        type="file"
                    />
                    <p className="text-red-600">
                        <ErrorMessage name="upload_images" />
                    </p>
                </FormGroup> */}
        {/* <FormGroup>
                    <label htmlFor="" className="pb-2">
                        Desctiption
                    </label>
                    <Field
                        name="description"
                        className="con-field"
                        placeholder="Enter Description"
                        id="description"
                        type="textarea"
                    />
                    <p className="text-red-600">
                        <ErrorMessage name="description" />
                    </p>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="" className="pb-2">
                        Description
                    </label>
                    <Field
                        name="description"
                        className="con-field"
                        placeholder="Enter Description"
                        id="description"
                        type="text"
                    />
                    <p className="text-red-600">
                        <ErrorMessage name="description" />
                    </p>
                </FormGroup> */}
        <div className="grid gap-4 lg:grid-cols-2">
          <FormGroup>
            <Button
              type="button"
              onClick={() => handleClose()}
              className="bg-sky-300 hover:bg-sky-400"
            >
              Cancel
            </Button>
          </FormGroup>
          <FormGroup>
            <Button type="submit" className="bg-sky-300 hover:bg-sky-400">
              Save
            </Button>
          </FormGroup>
        </div>
      </Form>
    </Formik>
  );
};

export default AddFeatureForm;
