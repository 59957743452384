import React, { useEffect, useState } from "react";
import {
  getCurrentUser,
  getEmptyRoomNumber,
  getRoomRatePlan,
  getRoomType,
} from "../../api-config/config";
import LoaderDotted from "../LoaderDotted/LoaderDotted";
import "../LoaderDotted/loaderdotted.css";

interface indObjStructure {
  // Define the structure of your object here
  // For example, assuming there are properties 'name' and 'age'
  room_type_id: number;
  adults_per_room: number;
  rate_plan_id: number;
  no_rooms: number;
  id: number;
}

interface GroupReservationRoomProps {
  range: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
  handleIncreaseRoomTypeRender: (value: any, id: any) => void;
  roomTypeRenderId: number;
  configureWholeData: (indObj: any, type: any) => void;
  wholeData: indObjStructure[];
}

const GroupReservationRoom: React.FC<GroupReservationRoomProps> = ({
  range,
  handleIncreaseRoomTypeRender,
  roomTypeRenderId,
  configureWholeData,
  wholeData,
}) => {
  const defaultValues = {
    room_type_id: 0,
    adults_per_room: 0,
    rate_plan_id: 0,
    no_rooms: 0,
    id: roomTypeRenderId,
  };
  const [selectedRoomData, SetSelectedRoomData] = useState(defaultValues);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [roomRatePlan, setRoomRatePlan] = useState([]);
  const [emptyRooms, setEmptyRoom] = useState([]);
  const [loader, setLoader] = useState(false);
  const loggedUser = getCurrentUser();
  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setRoomTypeList(res.data);
          // console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  const handleSelectedInputs = (e: any) => {
    const { name, value } = e.target;
    SetSelectedRoomData({ ...selectedRoomData, [name]: +value });
  };
  useEffect(() => {
    configureWholeData(selectedRoomData, { id: 0 });
  }, [selectedRoomData]);
  useEffect(() => {
    if (selectedRoomData?.room_type_id) {
      getRoomRatePlan({
        hotel_code: loggedUser?.hotel_code,
        room_type_id: +selectedRoomData?.room_type_id,
      }).then((response) => setRoomRatePlan(response?.data));
      const body = {
        from_date: range.startDate,
        to_date: range.endDate,
        pms_hotel_code: loggedUser?.hotel_code,
        room_type_id: +selectedRoomData?.room_type_id,
      };
      setLoader(true);
      getEmptyRoomNumber(body)
        .then((response: any) => {
          setLoader(false);
          setEmptyRoom(response.data);
          console.log(response.data, "coming empty rooms");
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedRoomData?.room_type_id, range]);
  useEffect(() => {
    if (loggedUser) getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);
  return (
    <>
      {loader && <LoaderDotted />}
      {loader && (
        <p className="mt-6 text-gray-600 text-center">
          Fetching number of rooms available...
        </p>
      )}
      <div
        className="relative mt-4"
        style={{
          //   border: "1px solid red",
          //   boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          borderRadius: "4px",
        }}
      >
        <div className={`pb-2  ${loader ? "blur-overlay" : ""}`}>
          {!loader && (
            <div className="bg-blue-400 p-2">
              <p className="text-white font-bold">
                Rooms available :{" "}
                {selectedRoomData.room_type_id > 0
                  ? emptyRooms.length > 0
                    ? emptyRooms.length
                    : "All rooms are sold out"
                  : "please select the room first"}
              </p>
            </div>
          )}{" "}
          <div className="grid gap-4 grid-cols-1 m-4 sm:grid-cols-2 lg:grid-cols-3">
            <div className="form-group">
              <label htmlFor="rooms" className="pb-2 font-semibold">
                Select Rooms Type
              </label>
              <div className="relative">
                <select
                  name="room_type_id"
                  className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none"
                  value={selectedRoomData.room_type_id}
                  onChange={handleSelectedInputs}
                >
                  {/* {Array.from({ length: noOfRoomsAvl }, (_, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))} */}
                  <option key={0} value={0}>
                    Select
                  </option>
                  {roomTypeList?.map((room: any, i: any) => (
                    <option
                      key={i + 1}
                      value={+room?.id}
                      //   disabled={wholeData.some(
                      //     (ele: any) => +room?.id === +ele?.room_type_id
                      //   )}
                    >
                      {room?.room_type_name}
                    </option>
                  ))}
                </select>
                <p className="text-red-600">
                  {/* <ErrorMessage name="rooms" /> */}
                </p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="rooms" className="pb-2 font-semibold">
                Rate Plan
              </label>
              <div className="relative">
                <select
                  name="rate_plan_id"
                  className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none"
                  value={selectedRoomData?.rate_plan_id}
                  onChange={handleSelectedInputs}
                >
                  {/* {Array.from({ length: noOfRoomsAvl }, (_, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))} */}
                  <option key={0} value={0}>
                    Select
                  </option>
                  {roomRatePlan?.map((plan: any, i: any) => (
                    <option
                      key={i + 1}
                      value={plan?.id}
                    >{`${plan?.name} : ${plan?.included}`}</option>
                  ))}
                </select>
                <p className="text-red-600">
                  {/* <ErrorMessage name="rooms" /> */}
                </p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="rooms" className="pb-2 font-semibold">
                No Of Rooms
              </label>
              <div className="relative">
                <select
                  name="no_rooms"
                  className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none"
                  value={selectedRoomData?.no_rooms}
                  onChange={(e) => handleSelectedInputs(e)}
                >
                  {/* {Array.from({ length: noOfRoomsAvl }, (_, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))} */}
                  <option key={0} value={0}>
                    Select
                  </option>
                  {emptyRooms?.map((ele: any, i: any) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                <p className="text-red-600">
                  {/* <ErrorMessage name="rooms" /> */}
                </p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="adult" className="pb-2 font-semibold">
                Adult Per Room
              </label>
              <div className="relative">
                <select
                  name="adults_per_room"
                  onChange={(e) => handleSelectedInputs(e)}
                  value={selectedRoomData?.adults_per_room}
                  className="w-full border rounded-md p-1 focus:outline-none focus:ring-1 focus:border-sky-200 appearance-none transition-transform transform-gpu hover:scale-105"
                >
                  <option value="">Select</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
                <p className="text-red-600">
                  {/* <ErrorMessage name="adult" /> */}
                </p>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-700 transition-transform transform-gpu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* <div className="form-group"> */}
            {roomTypeRenderId !== 1 && (
              <div
                style={{
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="bg-red-500 px-4 py-1 mt-6 rounded-md text-white hover:bg-red-400"
                  onClick={() =>
                    handleIncreaseRoomTypeRender("remove", roomTypeRenderId)
                  }
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupReservationRoom;
