import axios from "axios";
import { baseUrl } from "./config";
import api from "./axiosInstance";

export const insertPaymentData = async (data: any) => {
  return api
    .post(baseUrl + "/insert_payment_data", data)
    .then((response: any) => {
      return response.data;
    });
};
export const getAccountStatement = async (data: any) => {
  return api
    .post(baseUrl + "/pms_account_statement_details", data)
    .then((response: any) => {
      return response.data;
    });
};

export const changeBookingStatus = async (data: any) => {
  return api
    .post(baseUrl + "/change_booking_status", data)
    .then((response: any) => {
      return response.data;
    });
};
