import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Container, Typography, Box } from "@mui/material";
import { Page } from "../components/Page";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "# Group ID",
    width: 120,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "resId",
    headerName: "Res ID",
    headerClassName: "super-app-theme--header",
    width: 120,
    editable: true,
  },
  {
    field: "guestName",
    headerName: "Guest Name",
    width: 160,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "stayDuration",
    headerName: "Stay Duration",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "roomType",
    headerName: "Room#/Type",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "pax",
    headerName: "Pax",
    width: 120,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 120,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "preferences",
    headerName: "Preferences/Notes",
    width: 150,
    editable: true,
    headerClassName: "super-app-theme--header",
  },
];

const rows = [
  {
    id: 1,
    resId: "0824346",
    guestName: "Shanon Waire",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 2,
    resId: "0823342",
    guestName: "Salman Khan",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 3,
    resId: "1023474",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 4,
    resId: "1025475",
    guestName: "Boman Irani",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 5,
    resId: "1025476",
    guestName: "Boman Irani",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 6,
    resId: "1025477",
    guestName: "Priya Oberoi",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 7,
    resId: "1025478",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 8,
    resId: "1025479",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 9,
    resId: "1025480",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 10,
    resId: "1025481",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
  {
    id: 11,
    resId: "1025482",
    guestName: "Deccan Barois",
    stayDuration: "06 Sep-19 Sep(13)",
    roomType: "104/Deluxe Suit",
    pax: "3(A)0(C)",
    status: "Checked In",
    amount: "$27,908.00",
    preferences: "",
  },
];

function ReservationListGrid() {
  return (
    <Box
      sx={{
        height: 423,
        width: "100%",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function ReservationList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Reservation Report
          </Typography>
          <ReservationListGrid />
        </Container>
      </Page>
    </>
  );
}
