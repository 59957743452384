import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Key, ReactElement, ReactFragment, ReactPortal } from "react";

export interface RHFCheckboxProp {
  name: string;
  label: string;
}
export const RHFCheckbox: React.FC<RHFCheckboxProp> = (props) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          name={props.name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      label=""
    />
  );
};

// ----------------------------------------------------------------------

type RHFMultiCheckBoxOption = {
  value: Key | null | undefined;
  label:
    | string
    | number
    | boolean
    | ReactElement
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
};

export interface RHFMultiCheckboxProp {
  name: string;
  options: RHFMultiCheckBoxOption[];
}

const RHFMultiCheckbox: React.FC<RHFMultiCheckboxProp> = (props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) => {
          return field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];
        };

        return (
          <FormGroup>
            {props.options.map((option: RHFMultiCheckBoxOption) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() =>
                      field.onChange(
                        // TODO why use void return value?
                        onSelected(option.value ? option.value.toString() : "")
                      )
                    }
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
};

export default { RHFCheckbox, RHFMultiCheckbox };
