import React, { createContext, useState, useContext } from "react";

import { StatusCounts, Row } from "../layouts/Sidebar";

// ... (Previous imports and code)

type RoomContextType = {
  statusCounts: StatusCounts;
  updateStatusCounts: (rows: Row[]) => void;
};

const RoomContext = createContext<RoomContextType | null>(null);

export function useRoomContext() {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error("useRoomContext must be used within a RoomContextProvider");
  }
  return context;
}

export function RoomContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [statusCounts, setStatusCounts] = useState<StatusCounts>({
    status1: 0,
    status2: 0,
    status3: 0,
    status4: 0,
  });

  const updateStatusCounts = (rows: Row[]) => {
    const counts = {
      status1: rows.filter(
        (row) => row.status === "Clean" || row.status === "clean"
      ).length,
      status2: rows.filter(
        (row) => row.status === "Dirty" || row.status === "dirty"
      ).length,
      status3: rows.filter(
        (row) => row.status === "Out of Order" || row.status === "out of order"
      ).length,
      status4: rows.filter(
        (row) =>
          row.status === "Complimentary Room" ||
          row.status === "complimentary room"
      ).length,
    };
    setStatusCounts(counts);
  };

  return (
    <RoomContext.Provider value={{ statusCounts, updateStatusCounts }}>
      {children}
    </RoomContext.Provider>
  );
}
