import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getCurrentUser, getRoomType, hotelLogin } from "../api-config/config";
import Calendar from "../components/Calendar";
import { Page } from "../components/Page";

export default function DashboardApp() {
  const [roomTypeList, setRoomTypeList] = useState([]);
  const navigate = useNavigate();

  const loggedUser = getCurrentUser();
  const checkUserToken = () => {
    if (loggedUser === null) {
      navigate("/login", { replace: true });
    }
  };
  useEffect(() => {
    checkUserToken();
  }, []);

  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          setRoomTypeList(res.data);
          // console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    if (loggedUser) getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);

  console.log("roomTypeList", roomTypeList);
  return (
    <>
      <Page title="Home">
        <div className="w-[100%] m-2">
          <Calendar roomTypeList={roomTypeList} />
        </div>
      </Page>
    </>
  );
}
