import React from "react";
import "./loaderdotted.css";

const LoaderDotted = () => {
  return (
    <>
      <div className={`flex justify-center items-center h-full`}>
        <div className="loader-container">
          <div className="loader-dot loader-dot1 animate-bounce"></div>
          <div className="loader-dot loader-dot2 animate-bounce"></div>
          <div className="loader-dot loader-dot3 animate-bounce"></div>
        </div>
      </div>
    </>
  );
};

export default LoaderDotted;
