import axios from "axios";
import { baseUrl } from "./config";
import api from "./axiosInstance";

export const getFolioDetails = async (booking_id: number, func: any) => {
  return api
    .post(baseUrl + "/get_booking_data_pms", {
      booking_id,
    })
    .then((response: any) => {
      func(response.data);
    });
};
export const getFolioAccountStatement = async (data: any) => {
  return api
    .post(baseUrl + "/get_account_statement", data)
    .then((response: any) => {
      return response.data;
    });
};

export const genrateFolioPms = async (data: any) => {
  return api.post(baseUrl + "/generate_folio", data).then((response: any) => {
    return response.data;
  });
};
export const getInvListFolioPms = async (data: any) => {
  return api.post(baseUrl + "/get_inv_list_pms", data).then((response: any) => {
    return response.data;
  });
};

export const getAadhaarOtpPms = async (data: any) => {
  return api
    .post(baseUrl + "/get_aadhaar_otp_pms", data)
    .then((response: any) => {
      return response.data;
    });
};

export const submitAadhaarOtpPms = async (data: any) => {
  return api
    .post(baseUrl + "/submit_aadhaar_otp_pms", data)
    .then((response: any) => {
      return response.data;
    });
};
