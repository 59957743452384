import { Button } from "@mui/material";
import {
  baseUrl,
  changeCheckingInfo,
  getBookingInfo,
} from "../../api-config/config";
import { sendEmail } from "../../utils/emailjs-utils";
import axios from "axios";
import moment from "moment";
import { genrateFolioPms } from "../../api-config/folio.api";
import AlertSnack from "../CustomSnackBar/AlertSnack";
import { useState } from "react";
import api from "../../api-config/axiosInstance";

const FolioOverviewBottomLeft = (props: any) => {
  const {
    folioDetails,
    setOpenOtherCharges,
    setOpenRefund,
    fetch,
    setAllowance,
    setIsCancelOpen,
    setIsSettleOpen,
    setIsSplitOpen,
    setAction,
    setGenrateInvoice,
    setRouteCharges,
    selectedOtherCharges,
    selectedEditOtherCharges,
    setOpenEditOtherCharges,
  } = props;
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const billView = () => {
    let url = `${baseUrl}/invoice_pms?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`;
    if (folioDetails?.corporate?.corporate_name) {
      url = url + "&invoice_for=corporate";
    } else if (folioDetails?.travel_agent?.billing_company_name) {
      url = url + "&invoice_for=travel_agent";
    }
    api.get(url).then((res: any) => {
      window.open(res.data.file);
    });
  };
  const provisionalBillView = () => {
    window.open(
      `${baseUrl}/provissional_invoice_pdf?booking_id=${folioDetails?.booking_id}&customer_id=${folioDetails?.customer_id}`
    );
  };
  console.log(selectedOtherCharges, "this is other charges");

  const settleBill = () => {
    console.log("Bill will be settled");
  };
  const genrateFolio = () => {
    const payload = {
      hotel_code: folioDetails?.hotel_id,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      group_id: folioDetails?.group_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      room_id: folioDetails?.room_booking[0]?.room_id,
    };
    genrateFolioPms(payload).then((response: any) => {
      console.log(response, "this is res after folio generation");
      if (response?.status_message === "failed") {
        // alert(response?.message)
        setOpen1(true);
        setSnackbarMessage(response);
      } else {
        setOpen1(true);
        setSnackbarMessage(response);

        fetch();
        // navigate("/main/home")
      }
    });
  };
  function onCheckout() {
    getBookingInfo(folioDetails.booking_id).then((bookingInfo: any) => {
      if (folioDetails?.charge_till_now?.balance < -1) {
        alert(
          `Please refund the balance amount of ${folioDetails?.charge_till_now?.balance} before checking out.`
        );
        return;
      }

      const userResponse = window.prompt("Do you want to Check Out? (Yes/No)");
      if (userResponse !== null) {
        const normalizedResponse = userResponse.trim().toLowerCase();
        if (normalizedResponse === "yes") {
          bookingInfo.room_type_id = folioDetails.room_booking[0].room_type_id;
          bookingInfo.check_out_time = moment().format("YYYY-MM-DD HH:mm:ss");
          bookingInfo.booking_status = "check_out";
          bookingInfo.refund_amount = 0;
          bookingInfo.rate_plan_id = bookingInfo?.room_booking[0]?.rate_plan_id;
          bookingInfo.rate_plan_amount =
            bookingInfo?.room_booking[0]?.rate_plan_amount;
          const receiptLink = `${baseUrl}/invoice_pms?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`;
          changeCheckingInfo(bookingInfo).then((response: any) => {
            if (response.status_message === "failed") {
              alert(response.message);
              return;
            }
            const payload = {
              first_name: response.data?.first_name,
              checkinDate: response.data?.from_date,
              checkoutDate: response.data?.to_date,
              roomType: response.data?.room_booking[0]?.room_title,
              roomNo: response.data?.room_booking[0]?.room_id,
              totalPrice: response.data?.gross_amount,
              receiptLink: receiptLink,
              from_name: response.data?.hotel_name,
              reply_to: "booking@ratebotai.com",
              to_email: response.data?.email,
            };
            sendEmail(payload);
            alert("checkout Success");

            billView();
            fetch();
          });
        } else if (normalizedResponse === "no") {
          return;
        } else {
          return;
        }
      }
    });
  }
  if (folioDetails?.booking_status === "pending") {
    return (
      <>
        <div className=" w-[100%] mt-5 flex justify-between text-sm flex-wrap">
          <div className="w-[100%] mb-3">
            <Button
              variant="contained"
              className=" mr-2 hover:text-[black] text-[white] bg-[#2c96c4] "
              onClick={() => {
                if (folioDetails?.charge_till_now?.balance < 0) {
                  setOpenRefund(true);
                  setAllowance(false);
                } else {
                  alert("Balance is positive cannot be refunded");
                }
              }}
            >
              Refund
            </Button>
            <Button
              variant="contained"
              className="mr-2 hover:text-[black] bg-[#2c96c4] mr-2"
              onClick={() => provisionalBillView()}
            >
              Account Statement
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className=" w-[100%] mt-5 flex justify-between text-sm flex-wrap">
        {open1 && (
          <AlertSnack
            response={snackbarMessage}
            open={open1}
            setOpen={setOpen1}
          />
        )}
        <div className="w-[100%] mb-3">
          {folioDetails?.booking_status === "check_out" ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                className="bg-[#2c96c4] mr-2"
                onClick={() => genrateFolio()}
              >
                Generate Folio
              </Button>
              <Button
                variant="contained"
                className="bg-[#2c96c4] mr-2"
                onClick={() => {
                  if (selectedOtherCharges?.id === 0) {
                    alert("Please select a transaction first");
                    return;
                  }
                  if (selectedOtherCharges?.folio_data?.length < 1) {
                    alert("Please generate the Folio first");
                  } else {
                    setRouteCharges(true);
                  }
                }}
              >
                Route Transaction
              </Button>
              <Button
                variant="contained"
                className="bg-[#2c96c4] mr-2"
                onClick={() => {
                  setGenrateInvoice(true);
                }}
              >
                Genrate Invoice
              </Button>
              <Button
                variant="contained"
                className="bg-[#2c96c4] mr-2"
                onClick={() => onCheckout()}
              >
                Checkout
              </Button>
            </>
          )}
        </div>
        {folioDetails?.booking_status !== "check_out" ? (
          <div className="w-[100%]  p-4">
            <Button
              variant="contained"
              className=" mr-2 hover:text-[white] text-[black] "
              onClick={() => {
                setOpenOtherCharges(true);
                setAllowance(false);
              }}
            >
              Other Charges
            </Button>
            <Button
              variant="contained"
              className=" mr-2 hover:text-[white] text-[black] hover:bg-[red]"
              onClick={() => {
                setOpenOtherCharges(true);
                setAllowance(true);
              }}
            >
              Allowance
            </Button>
            <Button
              variant="contained"
              className=" mr-2 hover:text-[white] text-[black] "
              onClick={() => {
                if (folioDetails?.charge_till_now?.balance < 0) {
                  setOpenRefund(true);
                  setAllowance(false);
                } else {
                  alert("Refund not possible as Balance is greater than zero");
                }
              }}
            >
              Refund
            </Button>
            <Button
              variant="contained"
              className=" mr-2 hover:text-[white] text-[black] "
              onClick={() => {
                console.log("this is selected calling");

                if (
                  (selectedOtherCharges?.folio_data?.length < 1 &&
                    selectedOtherCharges?.type === "payment") ||
                  selectedOtherCharges?.type === ""
                ) {
                  alert("Please select a charge");
                } else {
                  setOpenEditOtherCharges(true);
                  setAllowance(false);
                }
              }}
            >
              Edit Charges
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
};

export default FolioOverviewBottomLeft;
