import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getSegmentBills, getSettleBills } from "../api-config/bill.api";
import { getCurrentUser } from "../api-config/config";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const columns = [
  { field: "id", headerName: "#", width: 70 },
  { field: "date", headerName: "Date", width: 130 },
  { field: "room", headerName: "Room", width: 80 },
  {
    field: "guestname",
    headerName: "Guest Name",
    width: 130,
    renderCell: (params: any) => {
      console.log(params);
      return (
        <p>
          {params.row.first_name} {params.row.last_name}
        </p>
      );
    },
  },
  {
    field: "account_id",
    headerName: "Account Code",
    width: 120,
  },
  {
    field: "invoice_number",
    headerName: "Invoice Number",
    width: 120,
  },
  {
    field: "payment_mode",
    headerName: "Payment Mode",
    width: 120,
  },
  {
    field: "corporate_name",
    headerName: "Corporate Name",
    width: 120,
  },
  {
    field: "travel_agent_name",
    headerName: "Travel Agent Name",
    width: 120,
  },
  {
    field: "description",
    headerName: "Description",
    width: 160,
  },
  {
    field: "currency",
    headerName: "Currency",
    width: 90,
  },
  {
    field: "debit",
    headerName: "Debits",
    width: 90,
  },
  {
    field: "credits",
    headerName: "Credits",
    width: 90,
  },
  {
    field: "user_name",
    headerName: "Username",
    width: 160,
  },
];

const rows = [
  {
    id: 1,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
  {
    id: 2,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
  {
    id: 3,
    date: "Snow",
    room: "Jon",
    guestname: "Jon",
    accountcode: "Jon",
    description: "Jon",
    currency: "Jon",
    debits: "Jon",
    credits: "Jon",
    username: "Jon",
  },
];

export default function SegmentReport() {
  const loggedUser = getCurrentUser();
  const [data, setData] = useState([]);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const [reportFor, setReportFor] = useState("corporate");
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }

  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    getSegmentBills(
      loggedUser?.hotel_code,
      startDateString,
      endDateString,
      reportFor
    ).then((res) => setData(res.data));
  }
  function getRows(data: any) {
    const allRows: any = [] as any;
    let id = 1;
    data.forEach((data: any, key: any) => {
      const obj = {
        id: id++,
        invoiceNumber: data.invoice_number,
        guestname: `${data.first_name} ${data.last_name}`,
        account_code: data.account_id,
        description: data.description,
        currency: data.currency,
        debit: data.debit,
        credits: data.credits,
        user_name: data.user_name,
      };
      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }
  useEffect(() => {
    getSegmentBills(
      loggedUser?.hotel_code,
      range.startDate.toISOString().slice(0, 10),
      range.endDate.toISOString().slice(0, 10),
      reportFor
    ).then((res) => setData(res.data));
  }, [reportFor]);
  useEffect(() => {
    getRows(data);
  }, [data]);
  return (
    <>
      <div className="font-bold text-xl text-blue-800 ml-2.5 mb-16">Source Report</div>
      <p className="m-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Report For</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportFor}
                label="Report For"
                sx={{
                  padding: "0.5rem",
                }}
                onChange={(e: any) => setReportFor(e.target.value)}
              >
                <MenuItem value={""}>-- SELECT --</MenuItem>
                <MenuItem value={"corporate"}>Corporate</MenuItem>
                <MenuItem value={"travel_agent"}>Travel Agent</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink data={dynamicRows} filename={"segment-report.csv"}>
            <Tooltip title="Download Excel">
              {/* <FileDownloadIcon></FileDownloadIcon> */}
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Download
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={data} columns={columns} checkboxSelection />
      </div>
    </>
  );
}
