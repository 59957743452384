import * as React from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import clsx from "clsx";
import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { updateHouseKeepingData } from "../../api-config/config";
import { useDetails } from "../../contexts/DetailsContext";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DateRangeComponent from "../DateRangePicker";
import RoomsBlock from "../RoomsBlock/RoomsBlock";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "room", headerName: "Room", width: 130 },
  { field: "roomType", headerName: "Room Type", width: 130 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    cellClassName: (params: GridCellParams<any, any>) => {
      if (params.value == null) {
        return "";
      }

      return clsx("super-app", {
        status1: params.value === "Clean" || params.value === "clean",
        status2: params.value === "dirty" || params.value === "Dirty",
        status3:
          params.value === "OOO" || params.value === "Out of Order",
        status4:
          params.value === "Management Block"
      });
    },
  },
  {
    field: "availability",
    headerName: "Availability",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    cellClassName: (params: GridCellParams<any, any>) => {
      if (params.value == null) {
        return "";
      }

      return clsx("super-app", {
        positive: params.value === "Available",
        negative: params.value === "Occupied",
        departure: params.value === "Departure",
        OOO: params.value === "OOO",
        ManagementBlock: params.value === "Management Block"
      });
    },  
  },

  { field: "name", headerName: "Name", width: 130 },
  { field: "remarks", headerName: "Remarks", width: 130, editable: true },
  { field: "service", headerName: "Service", width: 130, editable: true },
  {
    field: "discrepancy",
    headerName: "Discrepancy",
    width: 130,
    renderCell: (params) => {
      return (
        <div style={{ width: "100%" }} className=" grid justify-items-end">
          <AddCircleIcon />
        </div>
      );
    },
  },
];

export default function HouseKeepingTable(props: any) {
  const { houseKeepingList, onHouseKeepingListUpdated } = props;
  const { statusCounts, updateStatusCounts } = useDetails();
  const { availableCounts, updateavailableCounts } = useDetails();

  const [status, setStatus] = React.useState("");
  const [dynamicRows, setDynamicRows] = React.useState([] as any);
  const [selectedEmploye, setSelectedEmploye] = React.useState("");
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [selectedRoom, setSelectedRoom] = React.useState("");
  const [manageRoom, setManageRoom] = React.useState("");
  const [isRowSelected, setIsRowSelected] = React.useState(false);
  const [selectionModel, setRowSelectionModel] = React.useState([] as any);
  const [manageRoomsDialog, setManageRoomsDialog] = React.useState(false);
  const dataGridRef = React.useRef(null);


  const handleCloseDialog = () => {
    setManageRoomsDialog(false);
  };
 
  const getDynamicRows = (houseKeepingList: any) => {
    const rowList = [] as any;
    houseKeepingList?.forEach((obj: any, index: number) => {
      const len = obj.room_name.split(" ");
      const rowStatus = obj.status === "out_of_order" ? "OOO"  : obj.status === "management_block"  ? "Management Block" : obj.status
      const rowAvailability = obj.Availability === "out_of_order" ? "OOO"  : obj.Availability === "management_block"  ? "Management Block" : obj.Availability
      const row = {
        id: index + 1,
        roomType: obj.room_type_name,
        room: len[len.length - 1],
        status: rowStatus,
        availability: rowAvailability,
        remarks: obj.remarks,
        discrepancy: "",
        name: obj.name || "Not Assigned",
        room_type_id: obj.room_type_id,
        room_id: obj.room_id,
      };
      rowList.push(row);
    });

    setDynamicRows([...rowList]);
    updateStatusCounts([...rowList]);
    updateavailableCounts([...rowList]);
  };

  React.useEffect(() => {
    getDynamicRows(houseKeepingList);
  }, [houseKeepingList]);

  // React.useEffect(() => {

  // }, [selectionModel])
  const getSelectedRow = (selectedRows: any) => {
    setRowSelectionModel(selectedRows);
    if (selectedRows.length) {
      setIsRowSelected(true);
    } else {
      setIsRowSelected(false);
    }
  };

  const handleChange = (value: string) => {
    setSelectedEmploye(value);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const filterDataByStatus = (statusToFilter: string) => {
    const filteredData = houseKeepingList?.filter((obj: any) =>
      statusToFilter ? obj.status.includes(statusToFilter) : true
    );
    getDynamicRows(filteredData);
    return filteredData;
  };

  const handleSelectedStatus = (event: SelectChangeEvent) => {
    const newSelectedStatus = event.target.value as string;
    setSelectedStatus(newSelectedStatus);
    const filteredData = filterDataByStatus(newSelectedStatus);
  };



  const handleManagedRoom = (event: SelectChangeEvent) => {
    const newManagedRoom = event.target.value as string;
    setManageRoom(newManagedRoom);
    const filteredData = filterDataByStatus(newManagedRoom);
  };

  function updateHouseKeepingRows() {
    let count = 0;
    let updateCount = 0;
    setDynamicRows((prvRows: any) => {
      prvRows.forEach((row: any) => {
        if (selectionModel.find((value: number) => value === row.id)) {
          if (status === "") {
            row.status = "Unknown";
          } else {
            row.status = status;
          }
          if (selectedEmploye === "") {
            row.name = "Not Assigned";
          } else {
            row.name = selectedEmploye;
          }
          const paylod = {
            room_id: row.room_id,
            house_keeping_ids: row.id,
            name: selectedEmploye,
            status: status,
            remarks: row.remarks,
          };
          count++;
          updateHouseKeepingData(paylod).then((response: any) => {
            updateCount++;
            if (updateCount === count) {
              onHouseKeepingListUpdated();
              updateStatusCounts(prvRows);
              updateavailableCounts(prvRows);
              handleStatusChange(prvRows);

              alert(`${count} rows updated succesfully`);
            }
          });
        }
      });
      return [...prvRows];
    });
  }

  const MyBox = styled("Box" as any)({
    height: `400px`,
    width: "100%",
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      backgroundColor: "#9bc53d",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgb(255, 45, 45, 1)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.OOO": {
      backgroundColor: "rgba(168, 119, 17, 1)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.ManagementBlock": {
      backgroundColor: "rgba(149, 109, 149, 1)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.status1": {
      backgroundColor: "#9bc53d",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.status2": {
      backgroundColor: "#fee402",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.status3": {
      backgroundColor: "rgba(168, 119, 17, 1)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.status4": {
      backgroundColor: "rgba(149, 109, 149, 1)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.departure": {
      backgroundColor: "#ffa630",
      color: "#1a3e72",
      fontWeight: "600",
    },
  });
  return (
    <Box
      sx={{
        height: "600px",
        width: "100%",
        marginTop: 4,
        fontWeight: 400,
      }}
    >
      <Grid
        sx={{
          color: "#ffffff",
          fontWeight: 600,
          fontSize: 16,
          padding: "5px",
          height: "100px",
          width: "100%",
          margin: "1px",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <FormControl sx={{ minWidth: 250 }} disabled={!isRowSelected}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Set Housekeeping Status
            </InputLabel>
            <Select
              sx={{ m: 1, height: 50 }}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={status}
              onChange={handleStatusChange}
              autoWidth
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Clean"} sx={{ minWidth: 200 }}>
                Clean
              </MenuItem>
              <MenuItem value={"Dirty"}>Dirty</MenuItem>
              <MenuItem value={"Out of Order"}>Out of Order</MenuItem>
              <MenuItem value={"Complimentary Room"}>
                Complimentary Room
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 250, height: 50 }}>
            <TextField
              id="standard-basic"
              label="Enter Employee Name"
              variant="standard"
              disabled={!isRowSelected}
              value={selectedEmploye}
              onChange={(e) => handleChange(e.target.value)}
            />
          </FormControl>
          <Stack direction="row" spacing={2} sx={{ m: 1 }}>
            <Button
              type="button"
              onClick={() => updateHouseKeepingRows()}
              className="bg-sky-600 hover:bg-sky-400 text-stone-50 h-12"
              disabled={!isRowSelected}
            >
              Update
            </Button>
          </Stack>

          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              View All
            </InputLabel>
            <Select
              sx={{ m: 1, height: 50 }}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              autoWidth
              label="Filter Status"
              value={selectedStatus}
              onChange={handleSelectedStatus}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Clean"} sx={{ minWidth: 200 }}>
                Clean
              </MenuItem>
              <MenuItem value={"Dirty"}>Dirty</MenuItem>
              <MenuItem value={"Out of Order"}>Out of Order</MenuItem>
              <MenuItem value={"Complimentary Room"}>
                Complimentary Room
              </MenuItem>
            </Select>
          </FormControl>

          <Stack direction="row" spacing={2} sx={{ m: 1 }}>
            <Button
              type="button"
              onClick={() => setManageRoomsDialog(true)}
              className="bg-sky-600 hover:bg-sky-400 text-stone-50 h-12"
              // disabled={!isRowSelected}
            >
              Block Rooms
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Dialog
        open={manageRoomsDialog}
        onClose={handleCloseDialog}
        sx={{
          width: "calc(100% - 200px)",
          minWidth: "590px",
          margin: "auto",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>
          <div
            className="bg-sky-600 px-4 py-2"
            style={{ color: "white", fontWeight: "600" }}
          >
            <p>Manage Rooms</p>
          </div>
        </DialogTitle>

        <DialogContent sx={{ minWidth: "600px", height: "500px" }}>
          <RoomsBlock handleCloseDialog = {handleCloseDialog}/>
        </DialogContent>
      </Dialog>
      <div className="flex justify-end mx-4">
        <CSVLink
          data={dynamicRows}
          filename={"housekeeping-discrepancy-list.csv"}
        >
          <Tooltip title="Download Excel">
            <FileDownloadIcon></FileDownloadIcon>
          </Tooltip>
        </CSVLink>
      </div>

      <MyBox>
        <DataGrid
          ref={dataGridRef}
          rows={dynamicRows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[10, 20]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          onSelectionModelChange={(newSelectionModel) => {
            getSelectedRow(newSelectionModel);
          }}
          selectionModel={selectionModel}
          autoPageSize={true}
          headerHeight={45}
          rowHeight={35}
          hideFooter={true}
        />
      </MyBox>
    </Box>
  );
}
