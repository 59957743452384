import React, { useEffect, useState } from "react";
import FolioOverviewRight from "../components/Folio/FolioOverviewRight";
import { getFolioDetails, getInvListFolioPms } from "../api-config/folio.api";
import FolioOverviewLeft from "../components/Folio/FolioOverviewLeft";
import FolioAccountStatement from "../components/Folio/FolioAccountStatement";
import FolioOverviewBottomRight from "../components/Folio/FolioOverviewBottomRight";
import FolioOverviewBottomLeft from "../components/Folio/FolioOverviewBottomLeft";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button/Button";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import FolioOverviewPayments from "../components/Folio/FolioOverviewPayments";
import FolioOverviewOtherCharges from "../components/Folio/FolioOverviewOtherCharges";
import FolioOverviewEditOtherCharges from "../components/Folio/FolioOverviewEditOtherCharges";

import {
  cancelBillApi,
  settleBillApi,
  splitBill,
} from "../api-config/bill.api";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  AppBar,
  Divider,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  Toolbar,
} from "@mui/material";
import {
  genrateProvInvoice,
  getCurrentUser,
  getFolioOptionsPms,
  routeChargesTo,
  routePaymentTo,
} from "../api-config/config";
import {
  getCorporateData,
  getTravelAgentData,
} from "../api-config/corporate.api";
import FolioOverviewRefund from "../components/Folio/FolioOverviewRefund";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

// import Slide from '@mui/material/Slide';
import { TransitionProps } from "@mui/material/transitions";
import IndFolioStatement from "../components/IndFolioStatement/IndFolioStatement";
import IndFolioOverviewBottomLeft from "../components/IndFolioStatement/IndFolioOverviewBottomLeft";
import AlertSnack from "../components/CustomSnackBar/AlertSnack";
import FoiloOverviewRouteTransactions from "../components/Folio/FoiloOverviewRouteTransactions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface DataRow {
  id: number;
  name: string;
  age: number;
  city: string;
  booking_id: number;
  card: number;
  cash: 0.0;
  charge_to: string;
  date: string;
  description: string;
  discount_amount: number;
  discount_value: number;
  mode: string;
  online: number;
  paid: boolean;
  paid_by: string;
  pos_point: null;
  price: number;
  quantity: number;
  tax_amount: number;
  total_amount: number;
  upi: number;
  wallet: number;
  // Add other properties as needed
}
const FolioDetails = () => {
  const location = useLocation();
  const [folioDetails, setFolioDetails] = useState<any>();
  const [paymentListInd, setPaymentListInd] = useState([] as any);
  const [corporateList, setCorporateList] = useState([]);
  const [travelAgentList, setTravelAgentList] = useState([]);
  const [openGenFolio, setOpenGenFolio] = useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const [isSplitOpen, setIsSplitOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [allowance, setAllowance] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isSettleOpen, setIsSettleOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [reason, setReason] = useState("");
  const [action, setAction] = useState("");
  const [mode, setMode] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [corporateId, setCorporateId] = useState("");
  const [description, setDescription] = useState("");
  const [travelAgentId, setTravelAgentId] = useState("");
  const loggedUser = getCurrentUser();
  const [genrateInvoice, setGenrateInvoice] = useState(false);
  const [routeCharges, setRouteCharges] = useState(false);
  const [otherChargesDropDown, setOtherChargesDropDown] = useState([]);
  const [selectedDrop, setSelectedDrop] = useState({
    charge_to: { prov_inv_no: 0 },
    corporate_id: 0,
    group_booking_id: 0,
    guest_id: 0,
    id: 0,
    room_id: 0,
    travel_agent_id: 0,
    type: "",
  });
  const [fromRouteChargesDrop, setFromRouteChargesDrop] = useState({
    amount: 0,
    charge_id: 0,
    hotel_code: 0,
    id: 0,
    inv_no: null,
    inv_type: "prov_inv",
    prov_inv_no: 0,
  });
  const [selectedOtherCharges, setSelectedOtherCharges] = useState({
    total_amount: 0,
    folio_data: [],
    id: 0,
    type: "",
  });
  const [selectedEditOtherCharges, setSelectedEditOtherCharges] = useState({
    total_amount: 0,
    folio_data: [],
    id: 0,
    type: "",
  });
  console.log(selectedOtherCharges, "this is selected other charges");

  const [otherChargesRouteamount, setOtherChargesRouteamount] = useState("");
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const [initialChargesData, setInitialChargesData] = useState({
    charge_to: "",
    pos_point: "",
    description: "",
    price: null,
    charge_id: null,
  });
  const [selectedRow, setSelectedRow] = useState<DataRow[]>([]);

  // const handleinitialChargesData = (data: any) => {
  //   setinitialChargesData(data);
  // };

  const [openIndFolio, setOpenIndFolio] = useState(false);
  const [selectedIndFolio, setSelectedIndFolio] = useState({} as any);
  const [generateInvoiceDropDown, setGenerateInvoiceDropDown] = useState(
    [] as any
  );

  useEffect(() => {
    getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
      setCorporateList(res.data)
    );
    getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
      setTravelAgentList(res.data)
    );
  }, []);

  const [openOtherCharges, setOpenOtherCharges] = useState(false);
  const [openEditOtherCharges, setOpenEditOtherCharges] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndInv, setSelectedIndInv] = useState({} as any);
  const fetch = async () => {
    await getFolioDetails(
      parseInt(location?.state?.booking_id),
      setFolioDetails
    );
  };
  const [selectedGenInvoice, setSelectedGenInvoice] = useState({
    charge_to: { type: "" },
  });

  const handleFromRoute = (e: any) => {
    console.log(e.target.value, "this is handle change from route");

    setFromRouteChargesDrop(e.target.value);
  };
  const handleGenerateInvoice = (e: any) => {
    console.log(
      e.target.value,
      "this is handle change from handleGenerateInvoice"
    );
    const { charge_to } = selectedGenInvoice;
    const payload = {
      ...charge_to,
      hotel_code: loggedUser?.hotel_code,
      inv_to: charge_to.type,
    };
    console.log(payload, "this is payload for priv invoice");
    genrateProvInvoice(payload).then((response: any) => {
      console.log(response, "this is res from prov");
      if (response?.status_message === "failed") {
        // alert(response?.message)
        setOpen1(true);
        setSnackbarMessage(response);
      } else {
        setOpen1(true);
        setSnackbarMessage(response);
        setGenrateInvoice(false);
        fetch();
        // navigate("/main/home")
      }
    });
    // setSelectedGenInvoice(e.target.value);
  };
  const cancelBill = async () => {
    setLoading(true);

    const response = await cancelBillApi(
      folioDetails?.booking_id,
      invoiceId,
      reason,
      fetch
    );
    if (response.status_message === "success") {
      setIsCancelOpen(false);
      setOpenSnackSuccess(true);
    } else {
      setSnackMessage(response.message);
      setOpenSnackFailure(true);
    }
    setLoading(false);
  };
  const settleBill = async () => {
    setLoading(true);

    const response = await settleBillApi(
      folioDetails?.booking_id,
      date,
      mode,
      description,
      corporateId,
      travelAgentId,
      fetch
    );
    if (response.status_message === "success") {
      setIsSettleOpen(false);
      setOpenSnackSuccess(true);
    } else {
      setOpenSnackFailure(true);
    }
    setLoading(false);
  };
  const splitBillFunc = () => {
    console.log("");
    const list: any = localStorage.getItem("pms.indList");
    const paymentIndList: number[] = JSON.parse(list);
    console.log(paymentIndList);
    const payment_list: any[] = [];
    paymentIndList.forEach((element: number) => {
      payment_list.push(folioDetails.payment_list[element - 1]);
    });
    console.log(payment_list);
    // const payment_list = folioDetails.payment_list.filter((el) => paymentIndList.includes(el.))

    splitBill(
      folioDetails.booking_id,
      folioDetails.customer_id,
      payment_list
    ).then((res) => window.open(res.file, "_blank"));
  };
  const handleClickOpenInd = (selectedAccount: any, InvNo: any) => {
    setSelectedIndFolio(selectedAccount);
    setSelectedIndInv(InvNo);
    setOpenIndFolio(true);
  };
  const handleCloseInd = () => {
    setOpenIndFolio(false);
  };
  const handleRouteCharges = () => {
    if (selectedOtherCharges?.type === "payment") {
      const payload = {
        payments_id: selectedOtherCharges?.id,
        init_prov_inv_no: fromRouteChargesDrop?.prov_inv_no,
        route_prov_inv_no: selectedDrop?.charge_to?.prov_inv_no,
        init_prov_inv_amount:
          fromRouteChargesDrop?.amount - +otherChargesRouteamount,
        route_prov_inv_amount: +otherChargesRouteamount,
        hotel_code: loggedUser?.hotel_code,
        payment_type: selectedOtherCharges?.type,
      };
      console.log(payload, "this is paYLOAD for the route payments");
      routePaymentTo(payload).then((res) => {
        if (res.status_message === "success") {
          console.log(res);
          // window.location.reload();
          fetch();
          setRouteCharges(false);
        }
      });
    } else {
      const payload = {
        charges_id: selectedOtherCharges?.id,
        init_prov_inv_no: fromRouteChargesDrop?.prov_inv_no,
        route_prov_inv_no: selectedDrop?.charge_to?.prov_inv_no,
        init_prov_inv_amount:
          fromRouteChargesDrop?.amount - +otherChargesRouteamount,
        route_prov_inv_amount: +otherChargesRouteamount,
        hotel_code: loggedUser?.hotel_code,
        payment_type: selectedOtherCharges?.type,
      };
      console.log(payload, "this is paYLOAD for the route charges");
      routeChargesTo(payload).then((res) => {
        if (res.status_message === "success") {
          console.log(res);
          fetch();
          setRouteCharges(false);
          // window.location.reload();
        }
      });
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    // getFolioOptionsPms

    const payload = {
      hotel_code: loggedUser?.hotel_code || 0,
      booking_id: folioDetails?.booking_id,
      corporate_id: folioDetails?.corporate_id || 0,
      travel_agent_id: folioDetails?.travel_agent_id || 0,
      group_booking_id: folioDetails?.group_booking_id || 0,
    };
    getInvListFolioPms(payload).then((res) => {
      setOtherChargesDropDown(res.data);
    });
    getFolioOptionsPms(payload).then((res) => {
      setGenerateInvoiceDropDown(res.data);
    });
  }, [folioDetails]);
  console.log(selectedOtherCharges, "this is selected charges======");

  return (
    <>
      {open1 && (
        <AlertSnack
          response={snackbarMessage}
          open={open1}
          setOpen={setOpen1}
        />
      )}
      {folioDetails?.booking_id ? (
        <div className="flex w-[100%] justify-between flex-wrap">
          <div className="grid grid-cols-2 gap-2  w-[100%]">
            <div className=" border-2  shadow rounded bg-[#E9ECEF]">
              <h1 className="font-semibold bg-blue-500 text-white p-2 border-b border-black">
                Booking Owner Details
              </h1>
              <FolioOverviewLeft folioDetails={folioDetails} />
            </div>

            <div className="shadow border-2 rounded bg-white-50 h-[350px] overflow-y-auto">
              <FolioOverviewRight
                setOpenGenFolio={setOpenGenFolio}
                folioDetails={folioDetails}
                handleClickOpenInd={handleClickOpenInd}
                selectedIndFolio={selectedIndFolio}
              />
            </div>
          </div>
          <div className="w-[100%]">
            {/* here */}
            <FolioAccountStatement
              folioDetails={folioDetails}
              paymentListInd={paymentListInd}
              setPaymentListInd={setPaymentListInd}
              setSelectedOtherCharges={setSelectedOtherCharges}
              setSelectedEditOtherCharges={selectedEditOtherCharges}
              setInitialChargesData={setInitialChargesData}
              initialChargesData={initialChargesData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          </div>
          <div className="w-[65%]">
            {/* here */}
            <FolioOverviewBottomLeft
              fetch={fetch}
              folioDetails={folioDetails}
              setAllowance={setAllowance}
              setIsCancelOpen={setIsCancelOpen}
              setIsSettleOpen={setIsSettleOpen}
              setIsSplitOpen={setIsSplitOpen}
              setAction={setAction}
              setOpenOtherCharges={setOpenOtherCharges}
              setOpenEditOtherCharges={setOpenEditOtherCharges}
              setOpenRefund={setOpenRefund}
              setGenrateInvoice={setGenrateInvoice}
              setRouteCharges={setRouteCharges}
              selectedOtherCharges={selectedOtherCharges}
              selectedEditOtherCharges={selectedEditOtherCharges}
            />
          </div>
          <div className="w-[34%] ml-auto">
            <FolioOverviewBottomRight folioDetails={folioDetails} />
          </div>
          {/* {folioDetails?.booking_status === "check_out" ||
          Math.abs(folioDetails?.charge_till_now?.balance) < 1 ? null : (
            <div className="w-[100%]">
              <FolioOverviewPayments
                fetch={fetch}
                folioDetails={folioDetails}
              />
            </div>
          )} */}
          {folioDetails?.booking_status ===
          "check_out" ? null : folioDetails?.booking_status === "pending" ? (
            <div className="w-[100%]">
              <FolioOverviewPayments
                fetch={fetch}
                folioDetails={folioDetails}
                otherChargesDropDown={otherChargesDropDown}
              />
            </div>
          ) : Math.abs(folioDetails?.charge_till_now?.balance) < 1 ? null : (
            <div className="w-[100%]">
              <FolioOverviewPayments
                fetch={fetch}
                folioDetails={folioDetails}
                otherChargesDropDown={otherChargesDropDown}
              />
            </div>
          )}
          <Dialog
            open={openGenFolio}
            onClose={() => setOpenGenFolio(false)}
            className="w-[90%] m-auto"
            // sx={{
            //     width: 'calc(100% - 200px)',
            //     margin: 'auto',
            // }}
          >
            {/* <DialogTitle>Guest Info</DialogTitle> */}
            <DialogContent sx={{ minWidth: "650px" }}>
              Enter Folio Details:
              <OutlinedInput type="text" />
            </DialogContent>
          </Dialog>
          <Dialog
            open={openOtherCharges}
            onClose={() => setOpenOtherCharges(false)}
            sx={{
              width: "90vw",
              margin: "auto",
            }}
          >
            {/* <DialogTitle>Guest Info</DialogTitle> */}
            <DialogContent sx={{ width: "100%", textAlign: "center" }}>
              Enter Charge Details:
              <FolioOverviewOtherCharges
                allowance={allowance}
                fetch={fetch}
                folioDetails={folioDetails}
                setOpenOtherCharges={setOpenOtherCharges}
                setInitialChargesData={setInitialChargesData}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={openEditOtherCharges}
            onClose={() => setOpenEditOtherCharges(false)}
            sx={{
              width: "90vw",
              margin: "auto",
            }}
          >
            {/* <DialogTitle>Guest Info</DialogTitle> */}
            <DialogContent sx={{ width: "100%", textAlign: "center" }}>
              Enter Charge Details:
              <FolioOverviewEditOtherCharges
                allowance={allowance}
                fetch={fetch}
                folioDetails={folioDetails}
                setOpenEditOtherCharges={setOpenEditOtherCharges}
                initialChargesData={initialChargesData}
                setInitialChargesData={setInitialChargesData}
                selectedOtherCharges={selectedOtherCharges}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setSelectedOtherCharges={setSelectedOtherCharges}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            open={routeCharges}
            onClose={() => setRouteCharges(false)}
            sx={{
              width: "calc(100% - 200px)",
              margin: "auto",
            }}
          >
            <DialogContent sx={{ minWidth: "590px" }}>
              <FoiloOverviewRouteTransactions
                invList={otherChargesDropDown}
                selectedChargeFolioList={selectedRow}
                setRouteCharges={setRouteCharges}
                fetch={fetch}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={genrateInvoice}
            onClose={() => setGenrateInvoice(false)}
            sx={{
              width: "90vw",
              margin: "auto",
            }}
          >
            {/* <DialogTitle>Guest Info</DialogTitle> */}
            <DialogContent sx={{ width: "100%", textAlign: "center" }}>
              Generate Invoice
              {/* <FolioOverviewOtherCharges
                allowance={allowance}
                fetch={fetch}
                folioDetails={folioDetails}
                setOpenOtherCharges={setOpenOtherCharges}
              /> */}
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                For
              </p>
              <div
                style={{
                  display: "flex",
                  width: "400px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // selectedGenInvoice
                  value={selectedGenInvoice?.charge_to || ""}
                  label="Mode"
                  className="p-4 w-[60%]"
                  // onChange={handleGenerateInvoice}
                  onChange={(e: any) =>
                    setSelectedGenInvoice((prev: any) => ({
                      ...prev,
                      charge_to: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={""} key="select">
                    Select
                  </MenuItem>

                  {generateInvoiceDropDown?.map((room: any, i: number) => {
                    return (
                      <MenuItem value={room} key={i + 2}>
                        {room?.charge_to}
                      </MenuItem>
                    );
                  })}
                </Select>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleGenerateInvoice}
                >
                  Generate
                </button>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openRefund}
            onClose={() => setOpenRefund(false)}
            sx={{
              width: "90vw",
              margin: "auto",
            }}
          >
            {/* <DialogTitle>Guest Info</DialogTitle> */}
            <DialogContent sx={{ width: "100%", textAlign: "center" }}>
              Enter Refund Details:
              <FolioOverviewRefund
                allowance={allowance}
                fetch={fetch}
                folioDetails={folioDetails}
                setOpenRefund={setOpenRefund}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={isCancelOpen}
            onClose={() => setIsCancelOpen(false)}
            sx={{
              width: "calc(100% - 200px)",
              minWidth: "590px",
              margin: "auto",
            }}
          >
            <DialogTitle>Bill Cancellation</DialogTitle>
            <DialogContent sx={{ minWidth: "600px" }}>
              <FormGroup>
                <OutlinedInput
                  disabled={loading}
                  placeholder="Please enter the Invoice ID"
                  type="text"
                  value={invoiceId}
                  onChange={(e) => setInvoiceId(e.target.value)}
                />

                <OutlinedInput
                  disabled={loading}
                  placeholder="Please enter the Reason"
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <Button onClick={cancelBill}>
                  Click here to cancel the bill
                </Button>
              </FormGroup>
            </DialogContent>
          </Dialog>
          <Dialog
            open={isSettleOpen}
            onClose={() => setIsSettleOpen(false)}
            sx={{
              width: "calc(100% - 200px)",
              minWidth: "590px",
              margin: "auto",
            }}
          >
            <DialogTitle>Bill Settlement</DialogTitle>
            <DialogContent sx={{ minWidth: "600px" }}>
              <FormGroup>
                <InputLabel htmlFor="mode" className="">
                  Mode
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mode}
                  label="Mode"
                  className="p-4"
                  onChange={(e) => setMode(e.target.value)}
                >
                  <MenuItem value={""} key="select">
                    Select
                  </MenuItem>
                  <MenuItem value={"card"} key="card">
                    Card
                  </MenuItem>
                  <MenuItem value={"cash"} key="cash">
                    Cash
                  </MenuItem>
                  <MenuItem value={"upi"} key="upi">
                    UPI
                  </MenuItem>
                  <MenuItem value={"online"} key="online">
                    Online
                  </MenuItem>
                  <MenuItem value={"corporate"} key="corporate">
                    Corporate
                  </MenuItem>
                  <MenuItem value={"travel_agent"} key="travel_agent">
                    Travel Agent
                  </MenuItem>
                </Select>
                {mode === "corporate" ? (
                  <>
                    <InputLabel htmlFor="mode" className="">
                      Corporate
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={paymentDetails?.mode || "cash"}
                      label="Corporate"
                      className="p-4"
                      onChange={(e: any) => {
                        setCorporateId(e.target.value);
                      }}
                    >
                      <MenuItem value={""} key="select">
                        Select
                      </MenuItem>
                      {corporateList.length > 0 &&
                        corporateList.map((item: any) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.corporate_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : mode === "travel_agent" ? (
                  <>
                    <InputLabel htmlFor="mode" className="">
                      Travel Agent
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={paymentDetails?.mode || "cash"}
                      label="Travel Agent"
                      className="p-4"
                      onChange={(e: any) => {
                        setTravelAgentId(e.target.value);
                      }}
                    >
                      <MenuItem value={""} key="select">
                        Select
                      </MenuItem>
                      {travelAgentList.length > 0 &&
                        travelAgentList.map((item: any) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.billing_company_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : null}
                {mode !== "" && (
                  <FormGroup>
                    <InputLabel>Description</InputLabel>
                    <OutlinedInput
                      // disabled={loading}
                      placeholder="Please enter the Description"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>
                )}
                <Button sx={{ marginY: "1rem" }} onClick={settleBill}>
                  Click here to settle the bill
                </Button>
              </FormGroup>
            </DialogContent>
          </Dialog>
          <Dialog
            open={isSplitOpen}
            onClose={() => setIsSplitOpen(false)}
            sx={{
              width: "calc(100% - 200px)",
              minWidth: "590px",
              margin: "auto",
            }}
          >
            <DialogTitle>Bill Splitting</DialogTitle>
            <DialogContent sx={{ minWidth: "600px" }}>
              <FormGroup>
                <Button onClick={splitBillFunc}>
                  Click here to split the bills
                </Button>
              </FormGroup>
            </DialogContent>
          </Dialog>
          <Snackbar
            open={openSnackSuccess}
            autoHideDuration={6000}
            onClose={() => setOpenSnackSuccess(false)}
          >
            <Alert
              onClose={() => setOpenSnackSuccess(false)}
              severity="success"
              sx={{ width: "100%" }}
            >
              Successfully {action === "cancel" ? "cancelled" : "settled"}.
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackFailure}
            autoHideDuration={6000}
            onClose={() => setOpenSnackFailure(false)}
          >
            <Alert
              onClose={() => setOpenSnackFailure(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {action === "cancel"
                ? "Error while cancelling the bill."
                : snackMessage}
            </Alert>
          </Snackbar>
          <Dialog
            fullScreen
            open={openIndFolio}
            onClose={handleCloseInd}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseInd}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Account Statement
                </Typography>
                <Button autoFocus color="inherit" onClick={handleCloseInd}>
                  close
                </Button>
              </Toolbar>
            </AppBar>
            {/* <List>
              <ListItemButton>
                <ListItemText primary="Phone ringtone" secondary="Titania" />
              </ListItemButton>
              <Divider />
              <ListItemButton>
                <ListItemText
                  primary="Default notification ringtone"
                  secondary="Tethys"
                />
              </ListItemButton>
            </List> */}
            <IndFolioStatement
              selectedIndFolio={selectedIndFolio}
              folioDetails={folioDetails}
              selectedIndInv={selectedIndInv}
            />
            {/* <FolioOverviewBottomLeft
              fetch={fetch}
              folioDetails={folioDetails}
              setAllowance={setAllowance}
              setIsCancelOpen={setIsCancelOpen}
              setIsSettleOpen={setIsSettleOpen}
              setIsSplitOpen={setIsSplitOpen}
              setAction={setAction}
              setOpenOtherCharges={setOpenOtherCharges}
              setOpenRefund={setOpenRefund}
              setGenrateInvoice={setGenrateInvoice}
              setRouteCharges={setRouteCharges}
              selectedOtherCharges={selectedOtherCharges}
            /> */}
            <IndFolioOverviewBottomLeft
              fetch={fetch}
              folioDetails={folioDetails}
              selectedIndFolio={selectedIndFolio}
              selectedIndInv={selectedIndInv}
            />
          </Dialog>
        </div>
      ) : (
        <p>Loading ...</p>
      )}
    </>
  );
};

export default FolioDetails;
