import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import { Page } from "../components/Page";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCancelledBill, getCurrentUser } from "../api-config/config";
import DateRangeComponent from "../components/DateRangePicker";
// import Button from "../theme/overrides/Button";
import { CSVLink } from "react-csv";
import FileDownload from "@mui/icons-material/FileDownload";
import { getNoShowBills } from "../api-config/bill.api";
import moment from "moment";

function NoShowListGrid() {
  const [noShowlist, setNoShowList] = useState([] as any);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const navigate = useNavigate();
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchCheckinOrder(startDateString, endDateString);
    // fetchCheckinOrder(
    //   new Date(range.startDate).toISOString().slice(0, 10),
    //   new Date(range.endDate).toISOString().slice(0, 10)
    // );
  }
  function fetchCheckinOrder(start: any, end: any) {
    getNoShowBills(start, end, loggedUser?.hotel_code).then((response: any) => {
      setNoShowList(response.data);
      getRows(response.data);
      console.log(response.data);
    });
  }

  useEffect(() => {
    const getObj = getDateRange(new Date(), new Date());
    const start = new Date(getObj.start);
    const end = new Date(getObj.end);
    const startDateString = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endDateString = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    fetchCheckinOrder(
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
  }, []);
  const loggedUser = getCurrentUser();
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth - 1),
      end: eDate.setMonth(currentMonth + 1),
    };
  }
  function getRows(data: any) {
    const allRows = [] as any;
    let id = 1;
    data.forEach((listData: any, key: any) => {
      // if (listData.booking_status === 'cancelled') {
      const date1 = new Date(listData.from_date);
      const month1 = date1.toLocaleString("default", { month: "short" });
      const date2 = new Date(listData.to_date);
      const month2 = date2.toLocaleString("default", { month: "short" });
      const diffDays = date2.getDate() - date1.getDate();
      const obj = {
        id: id++,
        resId: listData.booking_id,
        guestName: listData.guest_first_name + listData.last_name,
        guestEmail: listData.email,
        guestMobile: listData.mobile_number,
        stayDuration: `${date1.getDate()} ${month1} - ${date2.getDate()} ${month2}(${diffDays})`,
        roomType: listData.room_booking_info.room_title,
        pax: `${listData?.no_of_adults}(A)0(C)`,
        status: listData.booking_status,
        amount: listData.gross_amount,
        guest_id: listData?.guest_id,
      };
      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "# Group ID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "resId",
      headerName: "Booking ID",
      headerClassName: "super-app-theme--header",
      width: 100,
      editable: true,
    },
    {
      field: "guestName",
      headerName: "Guest Name",
      width: 140,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stayDuration",
      headerName: "Stay Duration",
      width: 150,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "roomType",
      headerName: "Room#/Type",
      width: 140,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "pax",
      headerName: "Pax",
      width: 120,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 110,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "charges",
      headerName: "Charges",
      width: 136,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "preferences",
      headerName: "Preferences/Notes",
      width: 140,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     resId: "0824346",
  //     guestName: "Shanon Waire",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 2,
  //     resId: "0823342",
  //     guestName: "Salman Khan",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 3,
  //     resId: "1023474",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 4,
  //     resId: "1025475",
  //     guestName: "Boman Irani",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 5,
  //     resId: "1025476",
  //     guestName: "Boman Irani",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 6,
  //     resId: "1025477",
  //     guestName: "Priya Oberoi",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 7,
  //     resId: "1025478",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 8,
  //     resId: "1025479",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 9,
  //     resId: "1025480",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 10,
  //     resId: "1025481",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  //   {
  //     id: 11,
  //     resId: "1025482",
  //     guestName: "Deccan Barois",
  //     stayDuration: "06 Sep-19 Sep(13)",
  //     roomType: "104/Deluxe Suit",
  //     pax: "3(A)0(C)",
  //     status: "No Show",
  //     amount: "$27,908.00",
  //     charges: "Rs4,522.00(Balance RS4,522.00)",
  //     preferences: "",
  //   },
  // ];

  return (
    <Box
      sx={{
        height: 423,
        width: "100%",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <p>Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink
            data={dynamicRows}
            filename={"calcelled-reservation-list.csv"}
          >
            <Tooltip title="Download Excel">
              <FileDownload></FileDownload>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <DataGrid
        rows={dynamicRows}
        columns={columns}
        pageSize={6}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function NoShowList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            No Show Report
          </Typography>
          <NoShowListGrid />
        </Container>
      </Page>
    </>
  );
}
