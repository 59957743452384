import { Container, Typography, Grid, Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "@mui/material";
import * as Yup from "yup";
import {updateHotelDetails } from "../../api-config/config";
import './form.css'

export default function HotelDetailsForm(props: any) {
    const { hotelDetails } = props;
    const [billingAddress, setBillingAddress] = useState(hotelDetails.billing_address);
    const [billingCountry, setBillingCountry] = useState(hotelDetails.billing_country);
    const [billingState, setBillingState] = useState(hotelDetails.billing_state);
    const [billingCity, setBillingCity] = useState(hotelDetails.billing_city);
    const [billingZipCode, setBillingZipCode] = useState(hotelDetails.billing_zip_code);
    const [billingFaxNumber, setBillingFaxNumber] = useState(hotelDetails.billing_fax_number);
    const [billingMobileNumber, setBillingMobileNumber] = useState(hotelDetails.billing_mobile);
    const defaultValues = {
        address: hotelDetails?.address || '',
        billing_address: hotelDetails?.billing_address || '',
        billing_country: hotelDetails?.billing_country || '',
        billing_city: hotelDetails?.billing_city || '',
        billing_state: hotelDetails?.billing_state || '',
        billing_zip_code: hotelDetails?.billing_zip_code || '',
        billing_mobile: hotelDetails?.billing_mobile || '',
        billing_email: hotelDetails?.billing_email || '',
        billing_at_mobile: hotelDetails?.billing_at_mobile || '',
        billing_designation: hotelDetails?.billing_designation || '',
        billing_fax_number: hotelDetails?.billing_fax_number || '',
        billing_fax_number_contact: hotelDetails?.billing_fax_number_contact || '',
        billing_first_name_contact: hotelDetails?.billing_first_name_contact || '',
        billing_last_name_contact: hotelDetails?.billing_last_name_contact || '',
        billing_mobile_contact: hotelDetails?.billing_mobile_contact || '',
        city: hotelDetails?.city || '',
        country: hotelDetails?.country || '',
        description: hotelDetails?.description || '',
        email_id: hotelDetails?.email_id || '',
        first_name: hotelDetails?.first_name || '',
        gst_no: hotelDetails.gst_no || '',
        hotel_code: hotelDetails?.hotel_code || '',
        hotel_name: hotelDetails?.hotel_name || '',
        last_name: hotelDetails?.last_name || '',
        mobile_number: hotelDetails.mobile_number || '',
        no_of_rooms: hotelDetails?.no_of_rooms || '',
        postal_zip_code: hotelDetails?.postal_zip_code || '',
        service_offered: hotelDetails?.service_offered || '',
        state: hotelDetails?.state || '',
        website_link: hotelDetails?.website_link || '',
        toggle: false,
        company_name: hotelDetails?.company_name || ''
    };
    function onCheckBoxCheck(value: any) {
        if(!value.toggle) {
            setBillingAddress(value.address);
            setBillingCountry(value.country);
            setBillingState(value.state);
            setBillingCity(value.city);
            setBillingZipCode(value.postal_zip_code);
            setBillingFaxNumber(value.billing_fax_number);
            setBillingMobileNumber(value.mobile_number);
        } else {
            setBillingAddress("");
            setBillingCountry("");
            setBillingState("");
            setBillingCity("");
            setBillingZipCode("");
            setBillingFaxNumber("");
            setBillingMobileNumber("")
        }
    }
    function onInputChange(inputValue: string, inputType: string) {
        console.log(inputValue);
        if(inputType === 'billing_address') {
            setBillingAddress(inputValue);
        } else if(inputType === 'billing_country') {
            setBillingCountry(inputValue);
        } else if(inputType === 'billing_state') {
            setBillingState(inputValue);
        } else if(inputType === 'billing_city') {
            setBillingCity(inputValue);
        } else if(inputType === 'billing_zip_code') {
            setBillingZipCode(inputValue);
        } else if(inputType === 'billing_mobile') {
            setBillingMobileNumber(inputValue);
        } else if(inputType === 'billing_fax_number') {
            setBillingFaxNumber(inputValue);
        }
    }

    const formValidation = Yup.object().shape({
    });

    const handleSubmit = (formData: any) => {        
        formData.billing_address = billingAddress;
        formData.billing_country = billingCountry;
        formData.billing_city = billingCity;
        formData.billing_state = billingState;
        formData.billing_zip_code = billingZipCode;
        formData.billing_mobile = billingMobileNumber;
        formData.billing_fax_number = billingFaxNumber;
        updateHotelDetails(formData).then((response) => {
            alert(response.message);
        })
        console.log(formData, hotelDetails);
    };
    return (
        <>
            <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
                <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                        color: "#ffffff",
                        fontWeight: 600,
                        fontSize: 16,
                        background: "#1976d2",
                        padding: "12px",
                    }}
                >
                    {`Hotel Information:`}
                </Typography>
                <>
                    <div style={{ borderWidth: "thin", padding: "5px" }}>
                        <div style={{ flex: 1 }}>
                            <p>Please Enters the Hotel Details Below:</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={defaultValues}
                        validationSchema={formValidation}
                        onSubmit={(event) => handleSubmit(event)}
                    >
                        {({ values }) => (
                            <Form className="grid gap-4 lg:grid-cols-1 p-8">
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Name of Hotel:</label>
                                        <Field
                                            name="hotel_name"
                                            style={{ width: '50%' }}
                                            className="con-field"
                                            placeholder="Enter Name of the Hotel Property"
                                            id="hotel_name"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="hotel_name" />
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Hotel Code:</label>
                                        <Field
                                        disabled
                                            name="hotel_code"
                                            style={{ width: '50%', color: "red" }}
                                            className="con-field"
                                            id="hotel_name"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="hotel_name" />
                                    </p>
                                </FormGroup>
                                </div>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Address:</label>
                                        <Field
                                            name="address"
                                            style={{ width: '60%' }}
                                            className="con-field"
                                            placeholder="Enter Address*"
                                            id="address"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="mobile_number" />
                                    </p>
                                </FormGroup>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Country:</label>
                                            <Field
                                                name="country"
                                                className="con-field"
                                                placeholder="Enter Country"
                                                id="country"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="country" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">State:</label>
                                            <Field
                                                name="state"
                                                className="con-field"
                                                placeholder="Enter state*"
                                                id="state"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="state" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">City:</label>
                                            <Field
                                                name="city"
                                                className="con-field"
                                                placeholder="Enter City"
                                                id="city"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="city" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Zip Code:</label>
                                            <Field
                                                name="postal_zip_code"
                                                className="con-field"
                                                placeholder="Enter Zip Code*"
                                                id="postal_zip_code"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="postal_zip_code" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Email:</label>
                                            <Field
                                                name="email_id"
                                                className="con-field"
                                                placeholder="Enter Email id"
                                                id="email_id"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="email_id" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Mobile:</label>
                                            <Field
                                                name="mobile_number"
                                                className="con-field"
                                                placeholder="Enter Mobile no*"
                                                id="mobile_number"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="mobile_number" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Website Address:</label>
                                            <Field
                                                name="website_link"
                                                className="con-field"
                                                placeholder="Enter your website address"
                                                id="website_link"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="website_link" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Fax:</label>
                                            <Field
                                                name="billing_fax_number"
                                                className="con-field"
                                                placeholder="Enter Fax no*"
                                                id="billing_fax_number"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="fax" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Brief Description of your Hotel:</label>
                                        <Field
                                            component="textarea" rows="4"
                                            name="description"
                                            style={{ width: "50%", border: "solid gray", borderStyle: "dotted", borderWidth: "2px" }}
                                            className="con-field"
                                            placeholder="Enter Description of your Hotel"
                                            id="description"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="description" />
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">No of Rooms:</label>
                                        <Field
                                            name="no_of_rooms"
                                            className="con-field"
                                            placeholder="Enter Number of Rooms"
                                            id="no_of_rooms"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="no_of_rooms" />
                                    </p>
                                </FormGroup>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Service Offered:</label>
                                        <Field
                                            component="textarea" rows="4"
                                            name="service_offered"
                                            style={{ width: "50%", border: "solid gray", borderStyle: "dotted", borderWidth: "2px" }}
                                            className="con-field"
                                            placeholder="Enter Description of your Hotel"
                                            id="service_offered"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="service_offered" />
                                    </p>
                                </FormGroup>
                                <hr />
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    style={{
                                        color: "black",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        background: "LightGray",
                                        padding: "8px",
                                    }}
                                >
                                    Main Billing address:
                                </Typography>
                                <FormGroup>
                                    <div className="flex gap-2 lg:grid-cols-2">
                                        <label htmlFor="" className="pb-4">
                                            Same as Hotel Address
                                        </label>
                                        <label>
                                            <Field type="checkbox" name="toggle" onClick={() => onCheckBoxCheck(values)} />
                                        </label>
                                    </div>
                                    <div className="flex">
                                        <label className="mr-4">Address:</label>
                                        <Field
                                            name="billing_address"
                                            style={{ width: '60%' }}
                                            className="con-field"
                                            placeholder="Enter Address*"
                                            id="billing_address"
                                            type="text"
                                            value={billingAddress}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_address')}
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="billing_address" />
                                    </p>
                                </FormGroup>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Country:</label>
                                            <Field
                                                name="billing_country"
                                                className="con-field"
                                                placeholder="Enter Country"
                                                id="billing_country"
                                                type="text"
                                                value={billingCountry}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_country')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_country" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">State:</label>
                                            <Field
                                                name="billing_state"
                                                className="con-field"
                                                placeholder="Enter state*"
                                                id="billing_state"
                                                type="text"
                                                value={billingState}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_state')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_state" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">City:</label>
                                            <Field
                                                name="billing_city"
                                                className="con-field"
                                                placeholder="Enter City"
                                                id="billing_city"
                                                type="text"
                                                value={billingCity}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_city')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_city" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Zip Code:</label>
                                            <Field
                                                name="billing_zip_code"
                                                className="con-field"
                                                placeholder="Enter Zip Code*"
                                                id="billing_zip_code"
                                                type="text"
                                                value={billingZipCode}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_zip_code')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_zip_code" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Mobile:</label>
                                            <Field
                                                name="billing_mobile"
                                                className="con-field"
                                                placeholder="Enter your Mobile number"
                                                id="billing_mobile"
                                                type="text"
                                                value={billingMobileNumber}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_mobile')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_mobile" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Fax:</label>
                                            <Field
                                                name="billing_fax_number"
                                                className="con-field"
                                                placeholder="Enter Fax no*"
                                                id="billing_fax_number"
                                                type="text"
                                                value={billingFaxNumber}
                                            onChange={(e: any) => onInputChange(e.target.value, 'billing_fax_number')}
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_fax_number" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <hr />
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    style={{
                                        color: "black",
                                        fontWeight: 600,
                                        fontSize: 16,
                                        background: "LightGray",
                                        padding: "8px",
                                    }}
                                >
                                    Main Billing Contact:
                                </Typography>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">First Name:</label>
                                            <Field
                                                name="billing_first_name_contact"
                                                className="con-field"
                                                placeholder="Enter Your First Name"
                                                id="billing_first_name_contact"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_first_name_contact" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Last Name:</label>
                                            <Field
                                                name="billing_last_name_contact"
                                                className="con-field"
                                                placeholder="Enter Your last Name"
                                                id="billing_last_name_contact"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_last_name_contact" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Designation:</label>
                                            <Field
                                                name="billing_designation"
                                                className="con-field"
                                                placeholder="Enter Designation"
                                                id="billing_designation"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_designation" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Email:</label>
                                            <Field
                                                name="billing_email"
                                                className="con-field"
                                                placeholder="Enter Your Email"
                                                id="billing_email"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_email" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Mobile:</label>
                                            <Field
                                                name="billing_mobile_contact"
                                                className="con-field"
                                                placeholder="Enter your Mobile number"
                                                id="billing_mobile_contact"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_mobile_contact" />
                                        </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="flex">
                                            <label className="mr-4">Alt Mobile:</label>
                                            <Field
                                                name="billing_at_mobile"
                                                className="con-field"
                                                placeholder="Enter Alternate Number*"
                                                id="billing_at_mobile"
                                                type="text"
                                            />
                                        </div>
                                        <p className="text-red-600">
                                            <ErrorMessage name="billing_at_mobile" />
                                        </p>
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <div className="flex">
                                        <label className="mr-4">Fax:</label>
                                        <Field
                                            name="billing_fax_number_contact"
                                            className="con-field"
                                            placeholder="Enter Fax no*"
                                            id="billing_fax_number_contact"
                                            type="text"
                                        />
                                    </div>
                                    <p className="text-red-600">
                                        <ErrorMessage name="billing_fax_number_contact" />
                                    </p>
                                </FormGroup>
                                <hr />

                                <div className="grid gap-4 lg:grid-cols-2">
                                    <FormGroup>
                                        <Button
                                            type="submit"
                                            name="cancel"
                                            className="bg-sky-300 hover:bg-sky-400"
                                        >
                                            Cancel
                                        </Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button
                                            type="submit"
                                            name="update"
                                            className="bg-sky-300 hover:bg-sky-400"
                                        >
                                            Save Hotel Info
                                        </Button>
                                    </FormGroup>
                                </div>
                            </Form>
                        )}
                    </Formik >
                </>
            </Container>
        </>
    );
}
