import { FormProvider as Form, UseFormReturn } from "react-hook-form";

export interface FormProviderProp {
  children: React.ReactNode;
  methods: UseFormReturn<{
    email: string;
    password: string;
    remember: boolean;
  }>;
  onSubmit: () => void;
}

const AdminFormProvider: React.FC<FormProviderProp> = (props) => {
  return (
    <Form {...props.methods}>
      <form onSubmit={props.onSubmit}>{props.children}</form>
    </Form>
  );
};

export default AdminFormProvider;
