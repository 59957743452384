import React, { useEffect, useState } from "react";
import FolioDetailsRight from "../components/Folio/FolioDetailsRight";
import { getFolioDetails } from "../api-config/folio.api";
import FolioDetailsLeft from "../components/Folio/FolioDetailsLeft";
import { useLocation } from "react-router-dom";
import AlertSnack from "../components/CustomSnackBar/AlertSnack";

const Folio = () => {
  const location = useLocation();
  const [folioDetails, setFolioDetails] = useState<any>();
  const [openSnack, setOpenSnack] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});
  const fetch = async () => {
    await getFolioDetails(
      parseInt(location?.state?.booking_id),
      setFolioDetails
    );
  };
  useEffect(() => {
    fetch();
  }, []),
    console.log("==> this is the incoming", folioDetails);
  return (
    <>
      {folioDetails?.booking_id ? (
        <div className="flex w-[100%] justify-between h-full">
          <div className="w-[39.5%]">
            <FolioDetailsLeft
              folioDetails={folioDetails}
              setOpenSnack={setOpenSnack}
              setSnackbarMessage={setSnackbarMessage}
              fetch={fetch}
            />
          </div>
          <div className="w-[59.5%]">
            <FolioDetailsRight folioDetails={folioDetails} />
          </div>
        </div>
      ) : (
        <p>Loading ...</p>
      )}
      {openSnack && (
        <AlertSnack
          response={snackbarMessage}
          open={openSnack}
          setOpen={setOpenSnack}
        />
      )}
    </>
  );
};

export default Folio;
