import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnack = ({ response, open, setOpen }) => {
  // setLoading(false)
  console.log(response, "comming from snack componenet");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={
          response?.status_message?.toLowerCase() === "failed"
            ? "error"
            : "success"
        }
        sx={{
          width: "100%",
          bgcolor:
            response?.status_message?.toLowerCase() === "failed"
              ? "#D32F2F" // Custom error color (e.g., red)
              : "#66BB6A", // Custom success color (e.g., green)
        }}
      >
        {response?.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnack;
