import {
  Container,
  Typography,
  Grid,
  Button,
  Stack,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "@mui/material";
import * as Yup from "yup";
import {
  getBookingInfo,
  getCurrentUser,
  insertGuestDataPms,
  updateGuestData,
  updateGuestDataPms,
} from "../../api-config/config";
import { uploadGuestId } from "../../api-config/file-upload.api";
import { getCorporateData } from "../../api-config/corporate.api";
import { event } from "jquery";
import moment from "moment";
import { divide, values } from "lodash";
import { getAadhaarOtpPms, getFolioDetails } from "../../api-config/folio.api";
import { submitAadhaarOtpPms } from "../../api-config/folio.api";
import LoaderDotted from "../LoaderDotted/LoaderDotted";

export default function GuestInfo(props: any) {
  const {
    bookingInfo,
    handleCloseDialog,
    onGuestUpdated,
    selectedGuest,
    setSelectedGuest,
    folioDetails,
    fetch,
  } = props;
  const [selectedComp, setSelectedComp] = useState({
    id: undefined,
    corporate_name: "",
    gst_number: "",
    main_office_address: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
  });

  const [data, setData] = useState([]);
  const loggedUser = getCurrentUser();

  useEffect(() => {
    getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
      setData(res.data)
    );
  }, []);

  const [swap, setSwap] = useState(selectedGuest?.id_proof_path ? false : true);
  const [aadharno, setAadharno] = useState("");
  const [aadharOtpStatus, setAadharOtpStatus] = useState("");
  const [aadhaarSwap, setAadhaarSwap] = useState(false);
  const [aadhaarData, setAadhaarData] = useState({});
  const [defaultValues1, setDefaultValues1] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [compId, setCompId] = useState(1 as any);
  const [file, setFile] = useState("not" as any);
  const handleFileChange = (event: any) => {
    event.preventDefault();
    setFile(event.target?.files[0]);
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("guest_id", selectedGuest?.id);
    uploadGuestId(formData).then((response: any) => {
      alert("Id upload" + " " + response.status_message);
    });
  };

  const defaultValues = {
    firstName: selectedGuest?.first_name,
    lastName: selectedGuest?.last_name,
    email: selectedGuest?.email,
    mobNumber: selectedGuest?.mobile_number,
    gender: selectedGuest?.gender || "",
    state: selectedGuest?.state || "",
    address: selectedGuest?.address || "",
    country: selectedGuest?.country || "",
    city: selectedGuest?.city || "",
    zipcode: selectedGuest?.zipcode || "",
    id_number: selectedGuest?.id_proof_number || "",
    id_prof_type: selectedGuest?.id_proof_type || "",
    // maritalStatus: bookingInfo?.guest_data?.marital_status || "",
    date_of_birth: moment(selectedGuest?.dob).format("YYYY-MM-DD") || "",
    nationality: selectedGuest?.nationality || "",
    aadharno: "",
    // gstNumber: bookingInfo?.guest_data?.gst_number || "",
    // companyName: bookingInfo?.guest_data?.corporate_name || "",
    // conmanyId: bookingInfo?.corporate_id,
    // isCompany: bookingInfo?.guest_data?.corporate_name ? true : false,
  };

  const [isCompany, setIsCompany] = useState(
    bookingInfo?.guest_data?.corporate_name ? true : false
  );
  const [aadhaarOtp, setAadhaarOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");

  const formValidation = Yup.object().shape({
    // room_type: Yup.string().required("Please enter room type"),
    // // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // // image: Yup.string().required("Please enter City"),
    // adult: Yup.number().required("Please enter Base Occupancy"),
    // child: Yup.string().required("Please enter State"),
  });
  console.log(selectedGuest.id, "guest id");
  const handleSubmit = (formData: any) => {
    console.log(formData);

    formData.id_number = formData.id_number === "" ? 0 : +formData.id_number;
    formData.zipcode = formData.zipcode === "" ? 0 : +formData.zipcode;
    const formData1 = new FormData();

    const payload = {
      pms_guest_id: selectedGuest.id,
      first_name: formData.firstName,
      last_name: formData.lastName,
      mobile_number: formData.mobNumber,
      email: formData.email,
      dob: formData.date_of_birth,
      gender: formData.gender,
      // marital_status: formData.maritalStatus,
      nationality: formData.nationality,
      address: formData.address,
      id_proof_type: formData.id_prof_type,
      id_proof_number: formData.id_number === "" ? 0 : +formData.id_number,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      zipcode: formData.zipcode,
      // gstNumber: selectedComp.gst_number,
      // companyName: selectedComp.corporate_name,
      // companyId: selectedComp.id,
      booking_id: bookingInfo.booking_id,
      hotel_code: loggedUser?.hotel_code,
    };
    if (selectedGuest.id === 0) {
      formData1.append("file", file);
      formData1.append("first_name", formData.firstName);
      formData1.append("last_name", formData.lastName);
      formData1.append("booking_id", bookingInfo.booking_id);
      formData1.append("hotel_code", loggedUser?.hotel_code);
      formData1.append("mobile_number", formData.mobNumber);
      formData1.append("email", formData.email);
      formData1.append("dob", formData.date_of_birth);
      formData1.append("gender", formData.gender);
      formData1.append("nationality", formData.nationality);
      formData1.append("address", formData.address);
      formData1.append("id_proof_type", formData.id_prof_type);
      formData1.append("id_proof_number", formData.id_number);
      formData1.append("country", formData.country);
      formData1.append("state", formData.state);
      formData1.append("city", formData.city);
      formData1.append("zipcode", formData.zipcode);
      // formData1.append("pms_guest_id", selectedGuest.id);
      insertGuestDataPms(formData1).then((response: any) => {
        console.log(response);
        if (response?.status_message === "success") {
          onGuestUpdated();
        } else {
          alert(response?.message);
        }
      });
    } else {
      formData1.append("file", file);
      formData1.append("first_name", formData.firstName);
      formData1.append("last_name", formData.lastName);
      formData1.append("booking_id", bookingInfo.booking_id);
      formData1.append("hotel_code", loggedUser?.hotel_code);
      formData1.append("mobile_number", formData.mobNumber);
      formData1.append("email", formData.email);
      formData1.append("dob", formData.date_of_birth);
      formData1.append("gender", formData.gender);
      formData1.append("nationality", formData.nationality);
      formData1.append("address", formData.address);
      formData1.append("id_proof_type", formData.id_prof_type);
      formData1.append("id_proof_number", formData.id_number);
      formData1.append("country", formData.country);
      formData1.append("state", formData.state);
      formData1.append("city", formData.city);
      formData1.append("zipcode", formData.zipcode);
      formData1.append("pms_guest_id", selectedGuest.id);

      updateGuestDataPms(formData1).then((response: any) => {
        console.log(response);
        if (response?.status_message === "success") {
          onGuestUpdated();
        } else {
          alert(response?.message);
        }
      });
    }

    console.log(payload);
  };

  console.log(selectedGuest, "thiss is selected guest from pop///////////");

  const verifyAadhaar = () => {
    getAadhaarOtpPms({ aadhaar_number: aadharno }).then((response: any) => {
      setAadharOtpStatus(response.status);
      if (response.status === "success") {
        setAadhaarSwap(true);
      }
    });
  };
  const verifyAadhaarOtp = (mobile: any) => {
    setRefresh(true);
    submitAadhaarOtpPms({
      otp: aadhaarOtp,
      aadhaar_no: aadharno,
      mobile_number: mobile,
      pms_guest_id: selectedGuest?.id,
      booking_id: bookingInfo.booking_id,
    }).then((response: any) => {
      if (response.status === "success") {
        setRefresh(false);
        setOtpVerified(true);
        setOtpMessage(response.message);
        setSelectedGuest((prev: any) => ({ ...prev, ...response.data }));
        fetch();
        setDefaultValues1(!defaultValues1);

        // handleCloseDialog();
      }
    });
  };
  console.log(selectedGuest, "this is selected guesr==============");
  console.log(defaultValues, "this is def===========");

  return (
    <>
      {refresh && (
        <div style={{ textAlign: "center" }}>Getting guest details......</div>
      )}
      {refresh && <LoaderDotted />}
      {!refresh && (
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#ffffff",
              fontWeight: 600,
              fontSize: 16,
              background: "#1976d2",
              padding: "12px",
            }}
          >
            Guest Information:
          </Typography>
          <>
            <div
              style={{
                display: "flex",
                border: "solid black",
                borderWidth: "thin",
                padding: "5px",
              }}
            >
              <div style={{ flex: 1 }}>
                <p>Guest Details:</p>
              </div>
              <div style={{ flex: 1 }}>
                <p>
                  Guest Id:{" "}
                  <span style={{ color: "blue", fontWeight: 600 }}>
                    {selectedGuest?.id}
                  </span>
                </p>
              </div>
            </div>
            <Formik
              initialValues={defaultValues}
              validationSchema={formValidation}
              onSubmit={(event) => handleSubmit(event)}
            >
              {({ values }) => (
                <Form className="grid gap-4 lg:grid-cols-1 p-8">
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">First Name:</label>
                        <Field
                          name="firstName"
                          className="con-field"
                          placeholder="Enter First Name"
                          id="firstName"
                          type="text"
                          required
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="firstName" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Last Name:</label>
                        <Field
                          name="lastName"
                          className="con-field"
                          placeholder="Enter Last Name"
                          type="text"
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="lastName" />
                      </p>
                    </FormGroup>
                  </div>
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Gender:</label>
                        <Field as="select" type="string" name="gender" required>
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </Field>
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="firstName" />
                      </p>
                    </FormGroup>
                  </div>
                  <hr />
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Select Id:</label>
                        <Field as="select" type="string" name="id_prof_type">
                          <option value="">Select</option>
                          <option value={"Aadhaar"}>Aadhaar</option>
                          <option value={"Passport"}>Passport</option>
                          <option value={"Driving Licence"}>
                            Driving Licence
                          </option>
                        </Field>
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="adult" />
                      </p>
                    </FormGroup>
                    {/* <FormGroup>
                    <div className="flex">
                      <label className="mr-4">Enter Id/Visa#:</label>
                      <Field
                        name="id_number"
                        className="con-field"
                        placeholder="Enter Id Number"
                        id="id"
                        type="text"
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="id" />
                    </p>
                  </FormGroup> */}
                  </div>
                  {swap ? (
                    <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
                      <FormGroup>
                        <label htmlFor="" className="pb-2">
                          Upload Id
                        </label>
                        <Field
                          name="image"
                          className="con-field"
                          id="image"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <p className="text-red-600">
                          <ErrorMessage name="image" />
                        </p>
                      </FormGroup>
                    </div>
                  ) : (
                    <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 px-2">
                      <p
                        style={{
                          marginTop: "5px",
                          fontWeight: "600",
                          color: "green",
                        }}
                      >
                        ID Proof is Uploaded
                      </p>
                      <FormGroup>
                        <Button
                          className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                          onClick={() => setSwap(true)}
                        >
                          Edit
                        </Button>
                      </FormGroup>
                      {/* <p>Id Proof is Uploaded</p>
                    <p onClick={() => setSwap(true)}>Edit</p> */}
                    </div>
                  )}

                  <hr />
                  {values?.id_prof_type === "Aadhaar" &&
                    !selectedGuest?.verification_status &&
                    !aadhaarSwap && (
                      <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                        <FormGroup>
                          <div className="block">
                            <label className="mr-4">Enter Aadhaar No:</label>
                            <Field
                              name="aadharno"
                              className="con-field"
                              placeholder="Enter Aadhaar"
                              id="aadharno"
                              type="number"
                              value={aadharno}
                              onChange={(e: any) => setAadharno(e.target.value)}
                            />
                          </div>
                          <p className="text-red-600">
                            <ErrorMessage name="aadharno" />
                          </p>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                            onClick={verifyAadhaar}
                          >
                            Verify
                          </Button>
                        </FormGroup>
                      </div>
                    )}
                  {selectedGuest?.verification_status && (
                    <p
                      style={{
                        fontWeight: "600",
                        color: "green",
                        textAlign: "center",
                        padding: "4px",
                      }}
                    >
                      {" "}
                      Aadhaar is verified
                    </p>
                  )}
                  {aadhaarSwap ? (
                    <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                      <FormGroup>
                        <div className="block">
                          <label className="mr-4">Enter Otp:</label>
                          <Field
                            name="otp"
                            className="con-field"
                            placeholder="Enter Otp"
                            id="otp"
                            type="number"
                            value={aadhaarOtp}
                            onChange={(e: any) => setAadhaarOtp(e.target.value)}
                          />
                        </div>
                        <p className="text-red-600">
                          <ErrorMessage name="aadharno" />
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <Button
                          className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
                          onClick={() => verifyAadhaarOtp(values?.mobNumber)}
                        >
                          Validate
                        </Button>
                      </FormGroup>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div> {otpMessage}</div>
                    </>
                  )}
                  {otpVerified && <div> {otpMessage}</div>}
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="block">
                        <label className="mr-4">Nationality:</label>
                        <Field
                          name="nationality"
                          className="con-field"
                          placeholder="Enter Nationality"
                          id="nationality"
                          type="text"
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="nationality" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <div className="block">
                        <label className="mr-4">DOB:</label>
                        <Field
                          name="date_of_birth"
                          className="con-field"
                          placeholder="YYYY/MM/DD*"
                          id="date_of_birth"
                          type="date"
                          required
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="date_of_birth" />
                      </p>
                    </FormGroup>
                  </div>
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Email:</label>
                        <Field
                          name="email"
                          className="con-field"
                          placeholder="Enter Email id"
                          id="email"
                          type="text"
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="email" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Mobile:</label>
                        <Field
                          name="mobNumber"
                          className="con-field"
                          placeholder="Enter Mobile no*"
                          id="mobNumber"
                          type="text"
                          required
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="mobNumber" />
                      </p>
                    </FormGroup>
                  </div>
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Marital Status:</label>
                        <Field as="select" type="string" name="maritalStatus">
                          <option value={"Single"}>Single</option>
                          <option value={"Married"}>Married</option>
                        </Field>
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="maritalStatus" />
                      </p>
                    </FormGroup>
                  </div>

                  <hr />
                  {/* <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                  <FormGroup className="">
                    <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                      <InputLabel htmlFor="isCompany">Company</InputLabel>
                      <Checkbox
                        className="p-4"
                        checked={isCompany || false}
                        onChange={(event: any) => setIsCompany(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </FormGroup>{

                    isCompany && (
                      <Autocomplete
                        id="hotel-autocomplete"
                        options={data || []}
                        getOptionLabel={(comp) => comp.corporate_name}
                        value={compId.id}
                        onInputChange={(event, newInputValue) => {
                          // registeredSelect(newInputValue);
                        }}
                        onChange={(e, newValue) => {
                          console.log(newValue);
                          setSelectedComp(newValue);
                          setCompId(newValue ? newValue.id : "");
                          // checkDetailsEntered(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={(e) => console.log(e)}
                            label="Select Company"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // Disable browser autocomplete
                            }}
                          />
                        )}
                      />
                    )}

                </div> */}

                  <p>{`${isCompany ? "Company" : "Home"}  Address:`} </p>
                  {/* <OutlinedInput
                  fullWidth
                  placeholder="Please enter company name to search"
                /> */}

                  {isCompany && (
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Company Name:</label>
                        <Field
                          name="companyName"
                          className="con-field width-full"
                          placeholder="Enter Name"
                          type="text"
                          // value={selectedComp.corporate_name}
                          // onChange={(e: any) => {
                          //   console.log(e.target.value);
                          //   setSelectedComp((prev) => ({
                          //     ...prev,
                          //     corporate_name: e.target.value,
                          //   }));
                          // }}
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="companyName" />
                      </p>
                    </FormGroup>
                  )}

                  <FormGroup>
                    <div className="flex">
                      <label className="mr-4 ">Address:</label>
                      <Field
                        name="address"
                        className="con-field width-full"
                        placeholder="Enter Address*"
                        id="address"
                        type="text"
                        // value={selectedComp.main_office_address}
                        // onChange={(e: any) => {
                        //   console.log(e.target.value);
                        //   setSelectedComp((prev) => ({
                        //     ...prev,
                        //     main_office_address: e.target.value,
                        //   }));
                        // }}
                      />
                    </div>
                    <p className="text-red-600">
                      <ErrorMessage name="mobNumber" />
                    </p>
                  </FormGroup>
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Country:</label>
                        <Field
                          name="country"
                          className="con-field"
                          placeholder="Enter Country"
                          id="country"
                          type="text"
                          // value={selectedComp.country}
                          // onChange={(e: any) => {
                          //   console.log(e.target.value);
                          //   setSelectedComp((prev) => ({
                          //     ...prev,
                          //     country: e.target.value,
                          //   }));
                          // }}
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="country" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">State:</label>
                        <Field
                          name="state"
                          className="con-field"
                          placeholder="Enter state*"
                          id="state"
                          type="text"
                          // value={selectedComp.state}
                          // onChange={(e: any) => {
                          //   console.log(e.target.value);
                          //   setSelectedComp((prev) => ({
                          //     ...prev,
                          //     state: e.target.value,
                          //   }));
                          // }}
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="state" />
                      </p>
                    </FormGroup>
                  </div>
                  <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">City:</label>
                        <Field
                          name="city"
                          className="con-field"
                          placeholder="Enter City"
                          id="city"
                          type="text"
                          // value={selectedComp.city}
                          // onChange={(e: any) => {
                          //   console.log(e.target.value);
                          //   setSelectedComp((prev) => ({
                          //     ...prev,
                          //     city: e.target.value,
                          //   }));
                          // }}
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="city" />
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <div className="flex">
                        <label className="mr-4">Zip Code:</label>
                        <Field
                          name="zipcode"
                          className="con-field"
                          placeholder="Enter Zip Code*"
                          id="zipcode"
                          type="text"
                          // value={selectedComp.zip_code}
                          // onChange={(e: any) => {
                          //   console.log(e.target.value);
                          //   setSelectedComp((prev) => ({
                          //     ...prev,
                          //     zip_code: e.target.value,
                          //   }));
                          // }}
                        />
                      </div>
                      <p className="text-red-600">
                        <ErrorMessage name="zipcode" />
                      </p>
                    </FormGroup>
                  </div>
                  {/* <FormGroup>
                  <div className="flex">
                    <label className="mr-4">GST / VAT No:</label>
                    <Field
                      name="gstNumber"
                      className="con-field width-full"
                      placeholder="Enter GST number"
                      type="text"
                      value={selectedComp.gst_number}
                      onChange={(e: any) => {
                        console.log(e.target.value);
                        setSelectedComp((prev) => ({
                          ...prev,
                          gst: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <p className="text-red-600">
                    <ErrorMessage name="gstNumber" />
                  </p>
                </FormGroup> */}
                  <hr />
                  <div className="grid gap-4 lg:grid-cols-2">
                    <FormGroup>
                      <Button
                        type="button"
                        name="cancel"
                        className="bg-sky-300 hover:bg-sky-400"
                        onClick={() => handleCloseDialog()}
                      >
                        Cancel
                      </Button>
                    </FormGroup>
                    <FormGroup>
                      <Button
                        type="submit"
                        name="update"
                        className="bg-sky-300 hover:bg-sky-400"
                      >
                        Update
                      </Button>
                    </FormGroup>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Container>
      )}
    </>
  );
}
// new code ends here

// old code starts here
// import {
//   Container,
//   Typography,
//   Grid,
//   Button,
//   Stack,
//   OutlinedInput,
//   Autocomplete,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
// import Checkbox from "@mui/material/Checkbox";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import { FormGroup } from "@mui/material";
// import * as Yup from "yup";
// import {
//   getBookingInfo,
//   getCurrentUser,
//   updateGuestData,
// } from "../../api-config/config";
// import { uploadGuestId } from "../../api-config/file-upload.api";
// import { getCorporateData } from "../../api-config/corporate.api";
// import { event } from "jquery";

// export default function GuestInfo(props: any) {
//   const { bookingInfo, handleCloseDialog, onGuestUpdated } = props;
//   const [selectedComp, setSelectedComp] = useState({
//     id: undefined,
//     corporate_name: "",
//     gst_number: "",
//     main_office_address: "",
//     country: "",
//     state: "",
//     city: "",
//     zip_code: "",
//   });

//   const [data, setData] = useState([]);
//   const loggedUser = getCurrentUser();

//   useEffect(() => {
//     getCorporateData(loggedUser.hotel_code, 0).then((res) => setData(res.data));
//   }, []);

//   const [compId, setCompId] = useState(1 as any);
//   const [file, setFile] = useState("" as any);
//   const handleFileChange = (event: any) => {
//     event.preventDefault();
//     setFile(event.target?.files[0]);
//   };
//   const handleUpload = () => {
//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("guest_id", bookingInfo.customer_id);
//     uploadGuestId(formData).then((response: any) => {
//       alert("Id upload" + " " + response.status_message);
//     });
//   };

//   const defaultValues = {
//     firstName: bookingInfo?.guest_data?.first_name,
//     lastName: bookingInfo?.guest_data?.last_name,
//     email: bookingInfo?.guest_data?.email,
//     mobNumber: bookingInfo?.guest_data?.phone_number,
//     gender: "Male",
//     state: bookingInfo?.guest_data?.state || "",
//     address: bookingInfo?.guest_data?.address || "",
//     country: bookingInfo?.guest_data?.country || "",
//     city: bookingInfo?.guest_data?.city || "",
//     zipcode: bookingInfo?.guest_data?.postal_zip_code || "",
//     id_number: bookingInfo?.guest_data?.id_number || "",
//     id_prof_type: bookingInfo?.guest_data?.id_prof_type || "",
//     maritalStatus: bookingInfo?.guest_data?.marital_status || "",
//     date_of_birth: bookingInfo?.guest_data?.date_of_birth || "",
//     gstNumber: bookingInfo?.guest_data?.gst_number || "",
//     companyName: bookingInfo?.guest_data?.corporate_name || "",
//     conmanyId: bookingInfo?.corporate_id,
//     isCompany: bookingInfo?.guest_data?.corporate_name ? true : false,
//   };

//   const [isCompany, setIsCompany] = useState(
//     bookingInfo?.guest_data?.corporate_name ? true : false
//   );

//   const formValidation = Yup.object().shape({
//     // room_type: Yup.string().required("Please enter room type"),
//     // // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
//     // short_name: Yup.string().required("Please enter Short name"),
//     // // image: Yup.string().required("Please enter City"),
//     // adult: Yup.number().required("Please enter Base Occupancy"),
//     // child: Yup.string().required("Please enter State"),
//   });

//   const handleSubmit = (formData: any) => {
//     console.log(formData);
//     const payload = {
//       guest_id: bookingInfo.customer_id,
//       first_name: formData.firstName,
//       last_name: formData.lastName,
//       phone_number: formData.mobNumber,
//       email: formData.email,
//       date_of_birth: formData.date_of_birth,
//       gender: formData.gender,
//       marital_status: formData.maritalStatus,
//       address: formData.address,
//       id_prof_type: formData.id_prof_type,
//       id_number: formData.id_number,
//       country: formData.country,
//       state: formData.state,
//       city: formData.city,
//       postal_zip_code: formData.zipcode,
//       gstNumber: selectedComp.gst_number,
//       companyName: selectedComp.corporate_name,
//       companyId: selectedComp.id,
//       booking_id: bookingInfo.booking_id,
//     };
//     updateGuestData(payload).then((response: any) => {
//       console.log(response);
//       if (response?.status_message === "success") {
//         onGuestUpdated();
//       } else {
//         alert(response?.message);
//       }
//     });
//     console.log(payload);
//   };
//   return (
//     <>
//       <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
//         <Typography
//           variant="h5"
//           gutterBottom
//           style={{
//             color: "#ffffff",
//             fontWeight: 600,
//             fontSize: 16,
//             background: "#1976d2",
//             padding: "12px",
//           }}
//         >
//           Guest Information:
//         </Typography>
//         <>
//           <div
//             style={{
//               display: "flex",
//               border: "solid black",
//               borderWidth: "thin",
//               padding: "5px",
//             }}
//           >
//             <div style={{ flex: 1 }}>
//               <p>Guest Details:</p>
//             </div>
//             <div style={{ flex: 1 }}>
//               <p>
//                 Guest Id:{" "}
//                 <span style={{ color: "blue", fontWeight: 600 }}>
//                   {bookingInfo?.customer_id}
//                 </span>
//               </p>
//             </div>
//           </div>
//           <Formik
//             initialValues={defaultValues}
//             validationSchema={formValidation}
//             onSubmit={(event) => handleSubmit(event)}
//           >
//             {({ values }) => (
//               <Form className="grid gap-4 lg:grid-cols-1 p-8">
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">First Name:</label>
//                       <Field
//                         name="firstName"
//                         className="con-field"
//                         placeholder="Enter First Name"
//                         id="firstName"
//                         type="text"
//                         required
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="firstName" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Last Name:</label>
//                       <Field
//                         name="lastName"
//                         className="con-field"
//                         placeholder="Enter Last Name"
//                         type="text"
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="lastName" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Gender:</label>
//                       <Field as="select" type="string" name="gender" required>
//                         <option value={"Male"}>Male</option>
//                         <option value={"Female"}>Female</option>
//                       </Field>
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="firstName" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <hr />
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Select Id:</label>
//                       <Field as="select" type="string" name="id_prof_type">
//                         <option value="">Select</option>
//                         <option value={"Aadhar"}>Aadhar</option>
//                         <option value={"Passport"}>Passport</option>
//                         <option value={"Driving Licence"}>
//                           Driving Licence
//                         </option>
//                       </Field>
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="adult" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Enter Id/Visa#:</label>
//                       <Field
//                         name="id_number"
//                         className="con-field"
//                         placeholder="Enter Id Number"
//                         id="id"
//                         type="text"
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="id" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
//                   <FormGroup>
//                     <label htmlFor="" className="pb-2">
//                       Upload Id
//                     </label>
//                     <Field
//                       name="image"
//                       className="con-field"
//                       id="image"
//                       type="file"
//                       onChange={handleFileChange}
//                     />
//                     <p className="text-red-600">
//                       <ErrorMessage name="image" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <Button
//                       disabled={!file}
//                       name="cancel"
//                       className="bg-sky-300 hover:bg-sky-400 w-48 my-auto"
//                       onClick={() => handleUpload()}
//                     >
//                       Upload
//                     </Button>
//                   </FormGroup>
//                 </div>
//                 <hr />
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="block">
//                       <label className="mr-4">Nationality:</label>
//                       <Field
//                         name="nationality"
//                         className="con-field"
//                         placeholder="Enter Nationality"
//                         id="nationality"
//                         type="text"
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="nationality" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="block">
//                       <label className="mr-4">DOB:</label>
//                       <Field
//                         name="date_of_birth"
//                         className="con-field"
//                         placeholder="YYYY/MM/DD*"
//                         id="date_of_birth"
//                         type="date"
//                         required
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="date_of_birth" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Email:</label>
//                       <Field
//                         name="email"
//                         className="con-field"
//                         placeholder="Enter Email id"
//                         id="email"
//                         type="text"
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="email" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Mobile:</label>
//                       <Field
//                         name="mobNumber"
//                         className="con-field"
//                         placeholder="Enter Mobile no*"
//                         id="mobNumber"
//                         type="text"
//                         required
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="mobNumber" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Marital Status:</label>
//                       <Field as="select" type="string" name="maritalStatus">
//                         <option value={"Single"}>Single</option>
//                         <option value={"Married"}>Married</option>
//                       </Field>
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="maritalStatus" />
//                     </p>
//                   </FormGroup>
//                 </div>

//                 <hr />
//                 {/* <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup className="">
//                     <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                       <InputLabel htmlFor="isCompany">Company</InputLabel>
//                       <Checkbox
//                         className="p-4"
//                         checked={isCompany || false}
//                         onChange={(event: any) => setIsCompany(event.target.checked)}
//                         inputProps={{ 'aria-label': 'controlled' }}
//                       />
//                     </div>
//                   </FormGroup>{

//                     isCompany && (
//                       <Autocomplete
//                         id="hotel-autocomplete"
//                         options={data || []}
//                         getOptionLabel={(comp) => comp.corporate_name}
//                         value={compId.id}
//                         onInputChange={(event, newInputValue) => {
//                           // registeredSelect(newInputValue);
//                         }}
//                         onChange={(e, newValue) => {
//                           console.log(newValue);
//                           setSelectedComp(newValue);
//                           setCompId(newValue ? newValue.id : "");
//                           // checkDetailsEntered(e);
//                         }}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             onClick={(e) => console.log(e)}
//                             label="Select Company"
//                             inputProps={{
//                               ...params.inputProps,
//                               autoComplete: "new-password", // Disable browser autocomplete
//                             }}
//                           />
//                         )}
//                       />
//                     )}

//                 </div> */}

//                 <p>{`${isCompany ? "Company" : "Home"}  Address:`} </p>
//                 {/* <OutlinedInput
//                   fullWidth
//                   placeholder="Please enter company name to search"
//                 /> */}

//                 {isCompany && (
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Company Name:</label>
//                       <Field
//                         name="companyName"
//                         className="con-field width-full"
//                         placeholder="Enter Name"
//                         type="text"
//                         // value={selectedComp.corporate_name}
//                         // onChange={(e: any) => {
//                         //   console.log(e.target.value);
//                         //   setSelectedComp((prev) => ({
//                         //     ...prev,
//                         //     corporate_name: e.target.value,
//                         //   }));
//                         // }}
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="companyName" />
//                     </p>
//                   </FormGroup>
//                 )}

//                 <FormGroup>
//                   <div className="flex">
//                     <label className="mr-4">Address:</label>
//                     <Field
//                       name="address"
//                       className="con-field width-full"
//                       placeholder="Enter Address*"
//                       id="address"
//                       type="text"
//                       // value={selectedComp.main_office_address}
//                       // onChange={(e: any) => {
//                       //   console.log(e.target.value);
//                       //   setSelectedComp((prev) => ({
//                       //     ...prev,
//                       //     main_office_address: e.target.value,
//                       //   }));
//                       // }}
//                     />
//                   </div>
//                   <p className="text-red-600">
//                     <ErrorMessage name="mobNumber" />
//                   </p>
//                 </FormGroup>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Country:</label>
//                       <Field
//                         name="country"
//                         className="con-field"
//                         placeholder="Enter Country"
//                         id="country"
//                         type="text"
//                         // value={selectedComp.country}
//                         // onChange={(e: any) => {
//                         //   console.log(e.target.value);
//                         //   setSelectedComp((prev) => ({
//                         //     ...prev,
//                         //     country: e.target.value,
//                         //   }));
//                         // }}
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="country" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">State:</label>
//                       <Field
//                         name="state"
//                         className="con-field"
//                         placeholder="Enter state*"
//                         id="state"
//                         type="text"
//                         // value={selectedComp.state}
//                         // onChange={(e: any) => {
//                         //   console.log(e.target.value);
//                         //   setSelectedComp((prev) => ({
//                         //     ...prev,
//                         //     state: e.target.value,
//                         //   }));
//                         // }}
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="state" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2">
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">City:</label>
//                       <Field
//                         name="city"
//                         className="con-field"
//                         placeholder="Enter City"
//                         id="city"
//                         type="text"
//                         // value={selectedComp.city}
//                         // onChange={(e: any) => {
//                         //   console.log(e.target.value);
//                         //   setSelectedComp((prev) => ({
//                         //     ...prev,
//                         //     city: e.target.value,
//                         //   }));
//                         // }}
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="city" />
//                     </p>
//                   </FormGroup>
//                   <FormGroup>
//                     <div className="flex">
//                       <label className="mr-4">Zip Code:</label>
//                       <Field
//                         name="zipcode"
//                         className="con-field"
//                         placeholder="Enter Zip Code*"
//                         id="zipcode"
//                         type="text"
//                         // value={selectedComp.zip_code}
//                         // onChange={(e: any) => {
//                         //   console.log(e.target.value);
//                         //   setSelectedComp((prev) => ({
//                         //     ...prev,
//                         //     zip_code: e.target.value,
//                         //   }));
//                         // }}
//                       />
//                     </div>
//                     <p className="text-red-600">
//                       <ErrorMessage name="zipcode" />
//                     </p>
//                   </FormGroup>
//                 </div>
//                 {/* <FormGroup>
//                   <div className="flex">
//                     <label className="mr-4">GST / VAT No:</label>
//                     <Field
//                       name="gstNumber"
//                       className="con-field width-full"
//                       placeholder="Enter GST number"
//                       type="text"
//                       value={selectedComp.gst_number}
//                       onChange={(e: any) => {
//                         console.log(e.target.value);
//                         setSelectedComp((prev) => ({
//                           ...prev,
//                           gst: e.target.value,
//                         }));
//                       }}
//                     />
//                   </div>
//                   <p className="text-red-600">
//                     <ErrorMessage name="gstNumber" />
//                   </p>
//                 </FormGroup> */}
//                 <hr />
//                 <div className="grid gap-4 lg:grid-cols-2">
//                   <FormGroup>
//                     <Button
//                       type="button"
//                       name="cancel"
//                       className="bg-sky-300 hover:bg-sky-400"
//                       onClick={() => handleCloseDialog()}
//                     >
//                       Cancel
//                     </Button>
//                   </FormGroup>
//                   <FormGroup>
//                     <Button
//                       type="submit"
//                       name="update"
//                       className="bg-sky-300 hover:bg-sky-400"
//                     >
//                       Update
//                     </Button>
//                   </FormGroup>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </>
//       </Container>
//     </>
//   );
// }
