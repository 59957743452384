import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DateRangeComponent from "../DateRangePicker";
import { useEffect, useState } from "react";
import {
  getCurrentUser,
  insertBlockRooms,
  updateBlockRooms,
} from "../../api-config/config";
import { formateDateInApiSupport } from "../../utils/formatTime";
import moment from "moment";

const EditRoomsBlock = (props: any) => {
  const { handleCloseDialogEditBlock, selectedEventInfo, setRefresh } = props;
  const blockData = selectedEventInfo.event._def.extendedProps.blockData;
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedRoomStatus, setSelectedRoomStatus] = useState("");
  const [reason, setReason] = useState("");
  const loggedUser = getCurrentUser();

  function onSetRange(value: any) {
    setRange(value);
  }

  const handleSelectedRoomStatus = (event: any) => {
    setSelectedRoomStatus(event.target.value as string);
  };
  const handleUnblock = () => {
    updateBlockRooms({
      ...blockData,
      status: false,
      from_date: moment(blockData.from_date).format("YYYY-MM-DD"),
      to_date: moment(blockData.todate).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.status_message === "success") {
          //   setRefresh((prev: any) => !prev);
          window.location.reload();
          handleCloseDialogEditBlock();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setRefresh((prev: any) => !prev);
        handleCloseDialogEditBlock();
      });
  };
  const onClickSubmit = () => {
    // console.log(
    //   {
    //     ...blockData,
    //     hotel_code: loggedUser?.hotel_code,
    //     from_date: moment(range.startDate).format("YYYY-MM-DD"),
    //     to_date: moment(range.endDate).format("YYYY-MM-DD"),
    //   },
    //   "this is update"
    // );

    updateBlockRooms({
      ...blockData,
      hotel_code: loggedUser?.hotel_code,
      from_date: moment(range.startDate).format("YYYY-MM-DD"),
      to_date: moment(range.endDate).format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response.status_message === "success") {
          setRefresh((prev: any) => !prev);
          handleCloseDialogEditBlock();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (
      selectedEventInfo &&
      selectedEventInfo.event &&
      selectedEventInfo.event._def &&
      selectedEventInfo.event._def.extendedProps &&
      selectedEventInfo.event._def.extendedProps.blockData
    ) {
      const blockData = selectedEventInfo.event._def.extendedProps.blockData;

      const endDate = new Date(blockData.to_date);
      endDate.setDate(endDate.getDate() - 1); // Subtract one day from the startDate
      setRange({
        startDate: new Date(blockData.from_date),
        endDate: endDate,
        key: "selection",
      });
      setSelectedRoom(blockData.room_id);
      setSelectedRoomStatus(blockData.block_type);
      setReason(blockData.description);
    }
  }, [selectedEventInfo]);

  return (
    <>
      <div style={{ padding: "5px" }}>
        <div
          style={{ fontWeight: "600", marginBottom: "5px", color: "#2563EB" }}
        >
          <p>Select Date Range</p>
        </div>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
        </Stack>
        <div style={{ marginTop: "15px" }}></div>

        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Select a Room
          </InputLabel>
          <Select
            sx={{ m: 1, height: 50 }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Select a Room"
            value={selectedRoom}
            disabled
            onChange={(event) => setSelectedRoom(event.target.value)}
          >
            <MenuItem value={blockData.room_id}>{blockData.room_name}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Room Status
          </InputLabel>
          <Select
            sx={{ m: 1, height: 50 }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Room Status"
            value={selectedRoomStatus}
            disabled
            onChange={handleSelectedRoomStatus}
          >
            <MenuItem value="out_of_order">Out of Order</MenuItem>
            <MenuItem value="management_block">Management Block</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="mt-4">
        <label htmlFor="reason" className="block text-gray-600">
          Reason:
        </label>
        <input
          type="text"
          id="reason"
          name="reason"
          disabled
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="mt-2 p-3 border rounded w-full focus:outline-none"
        />
      </div>
      <div
        style={{ padding: "25px", display: "flex", justifyContent: "center" }}
      >
        <Button
          type="button"
          onClick={handleUnblock}
          className="bg-green-600 hover:bg-sky-400 text-stone-50 h-12 mx-2"
        >
          Unblock
        </Button>
        <Button
          type="button"
          onClick={onClickSubmit}
          className="bg-sky-600 hover:bg-sky-400 text-stone-50 h-12"
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default EditRoomsBlock;
