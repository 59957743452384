import React from "react";

const EventHover = (props: any) => {
  const { selectedEvent, onMouseLeave } = props;
  console.log(selectedEvent, "selectedEvent");
  const bookingDetails = selectedEvent?.event?._def?.extendedProps;
  // #0284c7
  console.log(bookingDetails, "this ius kfjkhdkjfhkdjbk");

  return (
    <div
      className=" border border-gray-200 rounded-lg shadow-lg px-2 py-2"
      // style={{ width: "300px", margin: "auto" }}
    >
      <h6 className="text-xl font-bold mb-2 text-[#000000] ml-2">
        Booking <span className="text-[#000000]">Details</span>
      </h6>
      <div className="grid grid-cols-2 gap-0 ml-2">
        <div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Reservation No:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Customer Name:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Room Name:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Check In Time:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Check Out Time:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Source:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Mobile Number:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Email:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">Booking Status:</p>
          </div>
          <div className="mb-2">
            <p className="text-[#1D4ED8] text-xs font-bold">
              Total Sale Amount
            </p>
          </div>
        </div>
        <div className="mx-2">
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.booking_id}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.fName +
                " " +
                bookingDetails?.guestBasicInfo?.lName}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.room_name}{" "}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.checkin_date_time
                ? bookingDetails?.guestBasicInfo?.checkin_date_time
                : ""}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.checkout_date_time
                ? bookingDetails?.guestBasicInfo?.checkout_date_time
                : ""}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.booking_from}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.mobile === ""
                ? "N/A"
                : bookingDetails?.guestBasicInfo?.mobile}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.email === ""
                ? "N/A"
                : bookingDetails?.guestBasicInfo?.email}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              {bookingDetails?.guestBasicInfo?.booking_status === "pending"
                ? "Reserved"
                : bookingDetails?.guestBasicInfo?.booking_status}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[#2C3E50] text-xs font-bold ml-2">
              ₹ {bookingDetails?.guestBasicInfo?.total_sale_amount}
            </p>
          </div>
        </div>
      </div>

      {/* Add more booking details as needed */}
    </div>
  );
};

export default EventHover;
