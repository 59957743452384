import Iconify from "../../components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} sx={{ width: 22, height: 22 }} />
);
interface NavItem {
  title: string;
  path: string;
}

interface NavConfigItem {
  title: string;
  sublinks?: NavItem[];
}
const navConfigReport:NavConfigItem[] = [

  {
    title: "Room Manager",
    sublinks: [
      {
        title: "Room Revenue Account Code",
        path: "/adminReports/roomRevenueAccountCode",
      }
    ],
  },
  {
    title: "Generals",
    sublinks: [
      {
        title: "Invoice Number Setting",
        path: "/adminReports/invoiceNumberSetting",
      },
      {
        title: "Departments",
        path: "/adminReports/departments",
      },
      {
        title: "List OF Currency",
        path: "/adminReports/listOfCurrency",
      },
      {
        title: "List OF Languages",
        path: "/adminReports/listOfLanguages",
      },
      {
        title: "Reason For Tax Exempt",
        path: "/adminReports/reasonForTaxExempt",
      },
      {
        title: "Pay Modes",
        path: "/adminReports/payModes",
      },
      {
        title: "Pay Types",
        path: "/adminReports/payTypes",
      },
      {
        title: "Counters",
        path: "/adminReports/counters",
      },
      {
        title: "Guest Type",
        path: "/adminReports/guestType",
      },
      {
        title: "Source OF Business",
        path: "/adminReports/sourceOfBusiness",
      },
      {
        title: "Tax Creations",
        path: "/adminReports/taxCreations",
      },
     
    ],
  },

  {
    title: "Price Manager",
    sublinks: [
      {
        title: "Package Master",
        path: "/adminReports/packageMaster",
      },
      {
        title: "Travel Agent Rate",
        path: "/adminReports/travelAgentRate",
      },
      {
        title: "Corporate Rate",
        path: "/adminReports/corporateRate",
      },
    ],
  },
  {
    title: "User Managers",
    sublinks: [
      {
        title: "User Levels",
        path: "/adminReports/userLevels",
      },
      {
        title: "Users",
        path: "/adminReports/users",
      },
      {
        title: "Login Activity",
        path: "/adminReports/loginActivity",
      },
    ],
  },
  {
    title: "Guest Manager",
    sublinks: [
      {
        title: "Guest List",
        path: "/adminReports/guestList",
      },
      {
        title: "Vip Guest List",
        path: "/adminReports/vipGuestList",
      },
      {
        title: "Black List",
        path: "/adminReports/blackList",
      },
      {
        title: "Guest Preferences",
        path: "/adminReports/guestPreferences",
      },
    ],
  },
  {
    title: "Console",
    sublinks: [
      {
        title: "Regiestered Corporate",
        path: "/adminReports/regiesteredCorporate",
      },
      {
        title: "Registered Travel Agent",
        path: "/adminReports/registeredTravelAgent",
      },
      {
        title: "Custom Field",
        path: "/adminReports/customField",
      },
      {
        title: "Report",
        path: "/adminReports/report",
      },
    ],
  },
  {
    title: "My Account",
    sublinks: [
      {
        title: "Booking & Reservations",
        path: "/adminReports/bookingReservations",
      },
      {
        title: "Billings",
        path: "/adminReports/billings",
      },
      {
        title: "Payment History",
        path: "/adminReports/paymentHistory",
      },
      {
        title: "Night Audit Report",
        path: "/adminReports/nightAuditReport",
      },
      {
        title: "Hotel Budget",
        path: "/adminReports/hotelBudget",
      },
    ],
  },
];

export default navConfigReport;
