import {
  Container,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  Stack,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import { insertCorporate } from "../../api-config/corporate.api";
import { getCurrentUser } from "../../api-config/config";
import { useState } from "react";
import DiscountPopup from "../../components/EditPosCommission";
import { editUser, insertUser } from "../../api-config/user.api";
import { useLocation } from "react-router-dom";

export default function CreateUser() {
  const [popup, setPopup] = useState(null);
  const location = useLocation();
  const [eligibleForDiscount, setEligibleForDiscount] = useState(false);
  const [addcreditcard, setAddcreditcard] = useState(false);
  const toggleAddCreditCard = () => {
    setAddcreditcard(!addcreditcard);
  };
  const userDetails = location?.state?.data;
  console.log("==> these are the incoming user details", userDetails);
  const loggedUser = getCurrentUser();
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [openSnackFailure, setOpenSnackFailure] = useState(false);
  const [isOpenDiscountPopup, setDiscountPopupOpen] = useState(false);
  const openPopup = (e: any) => {
    // e.preventDefault();
    setDiscountPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setDiscountPopupOpen(false);
  };

  // Function to handle saving the discount (you can customize this)
  const handleSaveDiscount = (discountData: any) => {
    console.log("Discount data:", discountData);
    // Implement your logic to save the discount here
  };
  const formik = useFormik({
    initialValues: {
      user_name: userDetails?.user_name || "",
      email: userDetails?.email || "",
      phone_number: userDetails?.phone_number || "",
      password: userDetails?.password || "",
      user_type: userDetails?.user_type || "",
      permission: userDetails?.user_type || [],
      hotel_code: loggedUser?.hotel_code.toString(),
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      if (userDetails) {
        editUser({
          ...values,
          id: userDetails.id,
          permission: userDetails.permission,
        }).then((res) => {
          console.log(res);
          if (res.status_message === "success") {
            setOpenSnackSuccess(true);
            formik.resetForm();
          } else {
            setOpenSnackFailure(true);
          }
        });
      } else {
        insertUser(values).then((res) => {
          console.log(res);
          if (res.status_message === "success") {
            setOpenSnackSuccess(true);
            formik.resetForm();
          } else {
            setOpenSnackFailure(true);
          }
        });
      }
      console.log(values);
    },
  });
  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Snackbar
          open={openSnackSuccess}
          autoHideDuration={6000}
          onClose={() => setOpenSnackSuccess(false)}
        >
          <Alert
            onClose={() => setOpenSnackSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully created.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackFailure}
          autoHideDuration={6000}
          onClose={() => setOpenSnackFailure(false)}
        >
          <Alert
            onClose={() => setOpenSnackFailure(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error while adding the user.
          </Alert>
        </Snackbar>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          Add/Edit User
        </Typography>
        {/* corporate details start */}
        <div
          className="bg-slate-200 py-2 my-4"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <span className="m-8 my-8 font-bold ">
            Please enter the User details below.
          </span>
        </div>
        {/* form start cdetails */}
        <div className="ml-16 py-5">
          <div className="mb-2">ID #</div>
          <div className="mb-2 flex">
            <label className="w-[10%] text-right">User Name *</label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              value={formik.values.user_name}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
            />
          </div>
          <div className="mb-2 flex ">
            <label className="w-[10%] text-right">E-mail *</label>
            <input
              type="email"
              className="ml-4 py-1 border 2px #666666"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mb-2 flex">
            <label className="w-[10%] text-right">Phone Number* </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
            />
          </div>
          <div className="mb-2 flex">
            <label className="w-[10%] text-right">Password* </label>
            <input
              type="text"
              id="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
            />
          </div>
          <div className="mb-2 flex">
            <label className="w-[10%] text-right">User Type *</label>
            <Select
              labelId="user_type-label"
              id="user_type"
              name="user_type"
              value={formik.values.user_type}
              onChange={formik.handleChange}
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="super_admin">Super Admin</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="front_office">Front Office</MenuItem>
            </Select>
          </div>
          <div className="mb-2 flex">
            <label className="w-[10%] text-right">Permission*</label>
            <Select
              labelId="permission-label"
              id="permission"
              name="permission"
              value={formik.values.permission}
              onChange={formik.handleChange}
              multiple
              className="ml-4 py-1 border 2px #666666"
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="front_desk">Front Desk</MenuItem>
              <MenuItem value="cashier">Cashier</MenuItem>
              <MenuItem value="house_keeping">House Keeping</MenuItem>
            </Select>
          </div>
        </div>
        <div className="ml-16 mt-8">
          <Button
            className="bg-slate-200 font-bold"
            style={{ border: "3px solid #ccc" }}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
          <Button
            className="ml-4 bg-slate-200 font-bold"
            style={{ border: "3px solid #ccc" }}
          >
            Cancel
          </Button>
        </div>
      </Container>
    </>
  );
}
