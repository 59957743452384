import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DateRangeComponent from "../DateRangePicker";
import {
  getCurrentUser,
  getEmptyRoomNumber,
  getRoomType,
  insertBlockRooms,
} from "../../api-config/config";
import { formateDateInApiSupport } from "../../utils/formatTime";
import AlertSnack from "../CustomSnackBar/AlertSnack";

const RoomsBlock = (props: any) => {
  const { handleCloseDialog } = props;
  const [range, setRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [selectedRoom, setSelectedRoom] = React.useState("");
  const [manageRoom, setManageRoom] = React.useState("");
  const [roomTypeList, setRoomTypeList] = useState([] as any);
  const [emptyRoom, setEmptyRoom] = useState([] as any);
  const [selectedRoomStatus, setSelectedRoomStatus] = React.useState("" as any);
  const [reason, setReason] = React.useState("" as any);
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({});

  const loggedUser = getCurrentUser();
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  const handleSelectedRoomStatus = (event: SelectChangeEvent) => {
    const newSelectedRoomStatus = event.target.value as string;
    setSelectedRoomStatus(newSelectedRoomStatus);
    // const filteredData = filterDataByStatus(newSelectedStatus);
  };
  const handleSelectedRoom = (event: SelectChangeEvent) => {
    const newSelectedRoom = event.target.value as string;

    emptyRoom;
    setSelectedRoom(newSelectedRoom);
  };
  const handleManagedRoom = (event: SelectChangeEvent) => {
    const newManagedRoom = event.target.value as string;
    setManageRoom(newManagedRoom);
    // const filteredData = filterDataByStatus(newManagedRoom);
  };

  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          const roomTypeArray = [] as any;
          res.data?.forEach((roomType: any) => {
            const obj = {
              id: roomType.id,
              room_type_name: roomType.room_type_name,
            };
            roomTypeArray.push(obj);
          });
          setRoomTypeList([...roomTypeArray]);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };
  useEffect(() => {
    const body = {
      from_date: formateDateInApiSupport(range.startDate),
      to_date: formateDateInApiSupport(range.endDate),
      pms_hotel_code: loggedUser?.hotel_code,
      room_type_id: selectedRoom,
    };

    getEmptyRoomNumber(body)
      .then((response) => {
        const massagedDataArray = response.data.map((ele: any) => ({
          room_name: ele.room_name,
          room_number: ele.room_number,
          room_id: ele.id,
        }));

        setEmptyRoom(massagedDataArray);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedRoom]);
  useEffect(() => {
    // getEmptyRoomNumber();
    getRoomTypeList(loggedUser?.hotel_code, 1);
  }, []);

  const onClickSubmit = () => {
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      room_id: +manageRoom,
      from_date: formateDateInApiSupport(range.startDate),
      to_date: formateDateInApiSupport(range.endDate),
      description: reason,
      block_type: selectedRoomStatus,
      status: true,
    };

    insertBlockRooms(payload)
      .then((response) => {
        if (response.status_message === "success") {
          setOpen1(true);
          setSnackbarMessage(response);
          handleCloseDialog();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    console.log(payload);
    //   handleCloseDialog()
  };
  return (
    <>
      <div style={{ padding: "5px" }}>
        <div
          style={{ fontWeight: "600", marginBottom: "5px", color: "#2563EB" }}
        >
          <p>Select Date Range</p>
        </div>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
        </Stack>
        <div style={{ marginTop: "15px" }}></div>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Select a Room Type
          </InputLabel>
          <Select
            sx={{ m: 1, height: 50 }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Select a room type"
            value={selectedRoom}
            onChange={handleSelectedRoom}
          >
            {roomTypeList?.map((ele: any, index: any) => (
              <MenuItem value={ele?.id} key={index}>
                <em>{ele?.room_type_name}</em>
              </MenuItem>
            ))}
            {/* <MenuItem value={"Clean"} sx={{ minWidth: 200 }}>
              Clean
            </MenuItem>
            <MenuItem value={"Dirty"}>Dirty</MenuItem>
            <MenuItem value={"Out of Order"}>Out of Order</MenuItem>
            <MenuItem value={"Complimentary Room"}>Complimentary Room</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Select a Room
          </InputLabel>
          <Select
            sx={{ m: 1, height: 50 }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Filter Status"
            value={manageRoom}
            onChange={handleManagedRoom}
          >
            {emptyRoom?.length === 0 && (
              <MenuItem value={""} sx={{ minWidth: 200 }}>
                No rooms available
              </MenuItem>
            )}
            {emptyRoom?.map((ele: any, index: any) => (
              <MenuItem value={ele?.room_id} key={index}>
                <em>{ele?.room_name}</em>
              </MenuItem>
            ))}
            {/* <MenuItem value={"Clean"} sx={{ minWidth: 200 }}>
              Clean
            </MenuItem>
            <MenuItem value={"Dirty"}>Dirty</MenuItem>
            <MenuItem value={"Out of Order"}>Out of Order</MenuItem>
            <MenuItem value={"Complimentary Room"}>Complimentary Room</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Room Status
          </InputLabel>
          <Select
            sx={{ m: 1, height: 50 }}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Filter Status"
            value={selectedRoomStatus}
            onChange={handleSelectedRoomStatus}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"out_of_order"} sx={{ minWidth: 200 }}>
              Out of Order
            </MenuItem>
            <MenuItem value={"management_block"}>Management Block</MenuItem>
            {/* <MenuItem value={"Out of Order"}>Out of Order</MenuItem>
              <MenuItem value={"Complimentary Room"}>
                Complimentary Room
              </MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className="mt-4">
        <label htmlFor="reason" className="block text-gray-600">
          Reason:
        </label>
        <input
          type="text"
          id="reason"
          name="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="mt-2 p-3 border rounded w-full focus:outline-none"
          // ${
          //   error ? "border-red-500" : "border-gray-300"
          // }

          // }
        />
        {/* {error && <p className="mt-2 text-red-500">{error}</p>} */}
      </div>
      <div
        style={{ padding: "25px", display: "flex", justifyContent: "center" }}
      >
        {/* <Button variant="contained">Search</Button> */}
        <Button
          type="button"
          onClick={onClickSubmit}
          className="bg-sky-600 hover:bg-sky-400 text-stone-50 h-12"
          // disabled={!isRowSelected}
        >
          Save
        </Button>
      </div>
      {open1 && (
        <AlertSnack
          response={snackbarMessage}
          open={open1}
          setOpen={setOpen1}
        />
      )}
    </>
  );
};

export default RoomsBlock;
