import React, { useState } from "react";
import DateRangeComponent from "../DateRangePicker";
import GroupReservationRoom from "../GroupReservationRoom/GroupReservationRoom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const defaultRoomTypeCount = [{ id: 1 }];
interface indObjStructure {
  // Define the structure of your object here
  // For example, assuming there are properties 'name' and 'age'
  room_type_id: number;
  adults_per_room: number;
  rate_plan_id: number;
  no_rooms: number;
  id: number;
}

const GroupReservationForm = (props: any) => {
  const {
    calendarInfo,
    selectedRoomInfo,
    handleClose,
    onFormSubmitted,
    roomTypeList1,
    groupIndBtn,
  } = props;
  const currentDate = new Date(); // Get the current date and time
  const nextDayDate = new Date(currentDate);
  nextDayDate.setDate(currentDate.getDate() + 1); // Add one day to get the next day's date

  const [range, setRange] = useState({
    startDate: new Date(calendarInfo.startStr),
    endDate: new Date(calendarInfo.endStr),
    key: "selection",
  });
  const [counter, setCounter] = useState(1);
  const [roomTypeRender, setRoomTypeRender] = useState(defaultRoomTypeCount);
  const [wholeData, setWholeData] = useState<indObjStructure[]>([]);

  const handleIncreaseRoomTypeRender = (value: any, id: any) => {
    if (value === "remove") {
      setRoomTypeRender((prev) => prev.filter((ele) => ele.id !== id));
      setCounter((prev) => prev - 1);
      configureWholeData({}, { operation: "remove", id: id });
    } else {
      const objRoomTypeRender = {
        id: counter + 1,
      };
      setRoomTypeRender((prev) => [...prev, objRoomTypeRender]);
      setCounter((prev) => prev + 1);
    }
  };

  const configureWholeData = (indObj: any, type: any) => {
    console.log(indObj, "this is indobj");
    if (type.operation === "remove" && type.id) {
      const newData = wholeData.filter((ele: any) => ele.id !== type.id);
      setWholeData(newData);
      return;
    }
    if (wholeData.length === 0) {
      wholeData.push(indObj);
      return;
    }
    const newData = wholeData.map((data: any, i: any) =>
      data.id === indObj.id ? { ...data, ...indObj } : data
    );
    if (!newData.some((data: any) => data.id === indObj.id)) {
      newData.push(indObj);
    }
    setWholeData(newData);
    console.log(
      newData,
      wholeData,
      "this data for the payload-------------------------------------"
    );
  };
  const handleDone = () => {
    console.log(wholeData, "tjis this is the data afyer selecting every thing");
  };
  function onSetRange(value: any) {
    setRange(value);
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center", // Align items vertically
          padding: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          fontFamily: "Arial, sans-serif", // Change the font family
          height: "80px", // Reduce the height of the container
          marginTop: "10px",
        }}
      >
        <div>
          <p>Check-in : Check-out</p>
          <DateRangeComponent
            range={range}
            onSetRange={onSetRange}
            isShowPastDate={false}
            startFalse={true}
          />
        </div>
      </div>

      {roomTypeRender?.map((ele: any) => (
        <GroupReservationRoom
          key={ele.id}
          range={range}
          handleIncreaseRoomTypeRender={handleIncreaseRoomTypeRender}
          roomTypeRenderId={ele.id}
          configureWholeData={configureWholeData}
          wholeData={wholeData}
        />
      ))}

      <div
        className="flex items-center justify-center mt-4"
        onClick={() => handleIncreaseRoomTypeRender("call", 0)}
      >
        <div className=" bg-green-500 hover:bg-green-400 hover:text-red-500 p-2 text-white rounded-full cursor-pointer">
          <AddCircleOutlineIcon />
        </div>
      </div>
      <div className="mt-4">
        <button onClick={handleDone}>Done</button>
      </div>
    </div>
  );
};

export default GroupReservationForm;
