import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import Logo from "../components/Logo";
import { Scrollbar } from "../components/Scrollbar";
import NavSection from "../components/NavSection";
import navConfig from "./dashboard/NavConfig";
import navConfigReport from "./dashboard/NavConfigReport";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDetails } from "../contexts/DetailsContext";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface StatusCounts {
  status1: number;
  status2: number;
  status3: number;
  status4: number;
}

export interface availableCounts {
  positive: number;
  negative: number;
  departure: number;
}
export interface Row {
  id: number;
  status: string;
  availability: string;
}

const DRAWER_WIDTH = 220;
const listDot = { display: "list-item" };
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

export default function DashboardSidebar(props: DashboardSidebarProps) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg", "xs", "lg");
  const [checkLocation, setCheckLocation] = React.useState("");
  const {
    calendarValue,
    formatAndSetCalendarValue,
    statusCounts,
    availableCounts,
  } = useDetails();

  const handleChange = (newValue: any) => {
    console.log(newValue);
    formatAndSetCalendarValue(newValue);
  };

  useEffect(() => {
    setCheckLocation(window.location.pathname);
  }, [window.location.pathname]);

  const val = true;
  useEffect(() => {
    if (props.isOpenSidebar) {
      props.onCloseSidebar();
    }
  }, [pathname]);
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const renderContent = (
    <Scrollbar
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo sx={[]} />
      </Box>
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} sx={{ margin: 1 }}>
          <DesktopDatePicker
            label=""
            inputFormat="YYYY/MM/DD"
            value={calendarValue}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>

      {!checkLocation.includes("houseKeeping") &&
        !checkLocation.includes("reports") && (
          <div style={val ? { display: "block" } : { display: "block" }}>
            <Box>
              <h4 style={{ marginLeft: 10, marginTop: 0 }}>Room Operations</h4>
              <NavSection navConfig={navConfig} />
            </Box>
          </div>
        )}
      {checkLocation.includes("reports") && (
        <div style={val ? { display: "block" } : { display: "none" }}>
          <Box>
            <h4 style={{ marginLeft: 30, marginTop: 0 }}>Reports</h4>
            <NavSection navConfig={navConfigReport} />
          </Box>
        </div>
      )}

      {/* <Box sx={{ flexGrow: 1 }} /> */}
      {checkLocation.includes("houseKeeping") && (
        <div style={val ? { display: "block" } : { display: "none" }}>
          <Box>
            {/* today's room status box start */}

            <Box>
              <h4
                style={{
                  marginLeft: 30,
                  marginTop: 20,
                  marginRight: 10,
                  borderTop: "2px solid #006EBF",
                  borderBottom: "2px solid #006EBF",
                  backgroundColor: "#F0F9FF",
                  fontWeight: "bold",
                }}
              >
                Today's Room Status
              </h4>
              <ul style={{ listStyle: "none", paddingLeft: 30 }}>
                <li style={{ fontSize: "14px", paddingTop: "10px" }}>
                  Today's Check-In
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    0
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Today's Check-Out
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    0
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Available
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {availableCounts.positive}
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Occupied
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {availableCounts.negative}
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Departure
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {availableCounts.departure}
                  </span>
                </li>
              </ul>
            </Box>
            {/* today's room status box end */}

            {/* total no. of rooms by status in housekeeping start */}
            <Box>
              <h4
                style={{
                  marginLeft: 30,
                  marginTop: 10,
                  marginRight: 10,
                  borderTop: "2px solid #006EBF",
                  borderBottom: "2px solid #006EBF",
                  backgroundColor: "#F0F9FF",
                  fontWeight: "bold",
                }}
              >
                Housekeeping
              </h4>
              <ul style={{ listStyle: "none", paddingLeft: 30 }}>
                <li style={{ fontSize: "14px", paddingTop: "10px" }}>
                  Clean
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {statusCounts.status1}
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Dirty
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {statusCounts.status2}
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Out of Order
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {statusCounts.status3}
                  </span>
                </li>
                <li style={{ fontSize: "14px" }}>
                  Complimentary Room
                  <span style={{ float: "right", paddingRight: "10px" }}>
                    {statusCounts.status4}
                  </span>
                </li>
              </ul>
            </Box>
            {/* total no. of rooms by status in housekeeping end */}
            {/* accordion room status legends start */}
            <Box>
              <h4
                style={{
                  marginLeft: 30,
                  marginTop: 20,
                  marginRight: 10,
                  borderTop: "2px solid #006EBF",
                  borderBottom: "2px solid #006EBF",
                  backgroundColor: "#F0F9FF",
                  fontWeight: "bold",
                }}
              >
                House Status
              </h4>
              <ul style={{ listStyle: "none", paddingLeft: 30 }}>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "#9bc53d ",
                    }}
                  ></span>
                  Clean
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "#fee402",
                    }}
                  ></span>
                  Dirty
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "rgba(168, 119, 17, 1)",
                    }}
                  ></span>
                  Out of Order
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "#d47486",
                    }}
                  ></span>
                  Complimentary
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "rgb(255, 45, 45, 1)",
                    }}
                  ></span>
                  Occupied
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "rgba(100, 170, 205, 1)",
                    }}
                  ></span>
                  Reservation
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "#ffa630",
                    }}
                  ></span>
                  Expected Departure
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "26px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "rgba(149, 109, 149, 1)",
                    }}
                  ></span>
                  Management Block
                </li>
              </ul>
            </Box>
            {/* <Box>
              <Accordion style={{ marginLeft: 10 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Room Status{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ marginLeft: 10 }}>
                  
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box> */}
            {/* accordion room status legends end */}
            {/* accordion House status legends start */}
            {/* <Box>
              <Accordion style={{ marginLeft: 10 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    House Status{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ marginLeft: 10 }}>
                    <ul style={{ fontSize: "14px" }}>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "26px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#2299547D",
                          }}
                        ></span>
                        Clean
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "26px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#d47486",
                          }}
                        ></span>
                        Dirty
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "26px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#d47486",
                          }}
                        ></span>
                        House Use
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "26px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#ff7400",
                          }}
                        ></span>
                        Touch Up
                      </li>
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <span
                          style={{
                            width: "26px",
                            height: "20px",
                            marginRight: "10px",
                            backgroundColor: "#d47486",
                          }}
                        ></span>
                        Out of Order
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box> */}
            {/* accordion house status legends end */}
          </Box>
        </div>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={props.isOpenSidebar}
          onClose={props.onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
