import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography, Box } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { Page } from "../components/Page";
import { LoginForm } from "../sections/auth/login";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 6),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(6, 0),
}));

export default function Login() {
  // TODO conf start + end
  // const smUp = useResponsive("up", "sm", "xs", "lg");

  const mdUp = useResponsive("up", "md", "xs", "lg");
  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <img
              src="/static/images/minh-pham-7pCFUybP_P8-unsplash.jpg"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              component="img"
              src="/static/images/logo.jpeg"
              sx={{ width: "100px", top: 0 }}
            />
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "blue", fontWeight: 700, fontSize: 35 }}
            >
              Welcome to RateBotAI
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "blue", fontWeight: 700, fontSize: 25 }}
            >
              Sign In
            </Typography>
            <LoginForm />

            {/* {!smUp && ( */}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{" "}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link><br></br>
            <Link variant="subtitle2" component={RouterLink} to="/adminlogin">
              admin login
            </Link>
          </Typography>
          {/* )} */}
        </ContentStyle>
      </Container>
    </RootStyle>
    </Page >
  );
}
