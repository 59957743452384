import { Container, Typography, Box, Grid, Stack, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../api-config/config";
import { getCorporateData } from "../../api-config/corporate.api";
import { getUsersList } from "../../api-config/user.api";
import Edit from "@mui/icons-material/Edit";

export default function UsersList() {
  const [data, setData] = useState([]);
  const loggedUser = getCurrentUser();
  const navigate = useNavigate();

  // useEffect(() => {
  //   getCorporateData(loggedUser?.hotel_code, 0).then(res => setData(res.data));
  // }, []);

  useEffect(() => {
    getUsersList(loggedUser?.hotel_code).then((res) => setData(res.data));
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            color: "#0323f8",
            marginLeft: 20,
            fontWeight: 700,
            fontSize: 20,
          }}
        >
          List of Users
          <div className="flex justify-end ">
            <Link to={"/admin/createuser"} className="mr-5">
              Add a User
            </Link>
            {/* <a href="" className="mr-5">
              Delete Selected Users(s)
            </a> */}
            <a href="" className="">
              Cancel
            </a>
          </div>
        </Typography>
        <div
          className=""
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <div className="m-4 ">
            <span className="text-color" style={{ color: "#0323f8" }}>
              Main
            </span>
            /List of Registered Corporate
            <div className="flex justify-end">
              <input type="text" />{" "}
            </div>
          </div>
        </div>
        {/* table start */}
        <div
          className="bg-slate-200 py-2 my-4 text-right"
          style={{
            marginLeft: 20,
            fontWeight: 500,
            fontSize: 16,
            border: "2px solid #ccc",
          }}
        >
          <Button className=" font-bold">Export Corporate(s)</Button>
        </div>
        <div className="text-center py-5">
          <table className="border-collapse w-full">
            <thead className="text-white bg-black">
              <tr>
                <th className="border  ">#</th>
                <th className="border  ">
                  <input type="checkbox" />
                </th>
                <th className="border py-2 px-8">User Name</th>
                <th className="border py-2 px-4">Phone No.</th>
                <th className="border py-2 px-4">Email</th>
                <th className="border py-2 px-4">User Type</th>
                <th className="border py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row: any, i: number) => (
                <tr>
                  <td className="border py-2 px-4">1</td>
                  <td className="border py-2 px-4">
                    <input type="checkbox" />
                  </td>
                  <td className="border py-2 px-4">{row?.user_name}</td>
                  <td className="border py-2 px-4">{row?.phone_number}</td>

                  <td className="border py-2 px-8">{row?.email}</td>
                  <td className="border py-2 px-8">{row?.user_type}</td>
                  <td className="border py-2 px-8">
                    <Edit
                      onClick={() =>
                        navigate("/admin/edituser", {
                          state: {
                            data: row,
                          },
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* table end */}
      </Container>
    </>
  );
}
