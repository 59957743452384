import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import { Page } from "../components/Page";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import {
  getCancelledBill,
  getCheckInOrders,
  getCurrentUser,
} from "../api-config/config";
import DateRangeComponent from "../components/DateRangePicker";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { voucherView } from "../utils/miscellaneous";
import { useNavigate } from "react-router-dom";

function CancelledBillGrid() {
  const [checkinlist, setCheckinlist] = useState([] as any);
  const [dynamicRows, setDynamicRows] = useState([] as any);
  const navigate = useNavigate();
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  function onSetRange(value: any) {
    console.log(value);
    setRange(value);
  }
  function searchWithDateRange() {
    const startDateString = `${range.startDate.getFullYear()}-${
      range.startDate.getMonth() + 1
    }-${range.startDate.getDate()}`;
    const endDateString = `${range.endDate.getFullYear()}-${
      range.endDate.getMonth() + 1
    }-${range.endDate.getDate()}`;
    fetchCheckinOrder(startDateString, endDateString);
  }
  function fetchCheckinOrder(startDateString: any, endDateString: any) {
    getCancelledBill(
      startDateString,
      endDateString,
      loggedUser?.hotel_code
    ).then((response: any) => {
      setCheckinlist(response.data);
      getRows(response.data);
      console.log(response.data);
    });
  }

  useEffect(() => {
    const getObj = getDateRange(new Date(), new Date());
    const start = new Date(getObj.start);
    const end = new Date(getObj.end);
    const startDateString = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`;
    const endDateString = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`;
    fetchCheckinOrder(startDateString, endDateString);
  }, []);
  const loggedUser = getCurrentUser();
  function getDateRange(sDate: any, eDate: any) {
    const currentMonth = sDate.getMonth();
    return {
      start: sDate.setMonth(currentMonth - 1),
      end: eDate.setMonth(currentMonth + 1),
    };
  }
  function getRows(data: any) {
    const allRows = [] as any;
    let id = 1;
    data.forEach((listData: any, key: any) => {
      // if (listData.booking_status === 'cancelled') {
      const date1 = new Date(listData.from_date);
      const month1 = date1.toLocaleString("default", { month: "short" });
      const date2 = new Date(listData.to_date);
      const month2 = date2.toLocaleString("default", { month: "short" });
      const diffDays = date2.getDate() - date1.getDate();
      const obj = {
        id: id++,
        resId: listData.booking_id,
        first_name: listData.first_name,
        last_name: listData.last_name,
        guestEmail: listData.email,
        guestMobile: listData.mobile_number,
        stayDuration: `${date1.getDate()} ${month1} - ${date2.getDate()} ${month2}(${diffDays})`,
        invoiceNumber: listData.invoice_number,
        reason: listData?.reason,
        files: listData?.files,
      };
      allRows.push(obj);
      // }
    });
    allRows.reverse();
    setDynamicRows([...allRows]);
    console.log("dynamicRows", setDynamicRows);
  }
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "# Group ID",
      width: 90,
      headerClassName: "super-app-theme--header",
      hide: true,
    },
    {
      field: "resId",
      flex: 1,
      headerName: "Booking ID",
      headerClassName: "super-app-theme--header",
      width: 110,
      editable: true,
    },

    {
      field: "first_name",
      flex: 1,
      headerName: "First Name",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "last_name",
      flex: 1.5,
      headerName: "Last Name",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestEmail",
      flex: 1.5,
      headerName: "Guest email",
      // width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "guestMobile",
      flex: 1,
      headerName: "Mobile",
      // width: 100,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "stayDuration",
      flex: 1,
      headerName: "Stay Duration",
      width: 200,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "invoiceNumber",
      flex: 1,
      headerName: "Invoice Number",
      width: 140,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "reason",
      flex: 1,
      headerName: "Reason",
      width: 90,
      editable: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Action",
      width: 100,

      renderCell: (cellValues) => {
        return (
          <span
            style={{ marginLeft: "20px", marginRight: "20px" }}
            onClick={() => {
              // voucherView(cellValues.row);
              window.open(cellValues.row.files, "_blank");
            }}
          >
            <Button
              type="button"
              className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              onClick={() => {
                console.log(cellValues.row.files);
                // navigate(cellValues.row.files)
              }}
            >
              View
            </Button>
          </span>
        );
      },
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <Box
      sx={{
        width: "inherit",
        marginTop: 4,
        "& .super-app-theme--header": {
          backgroundColor: "#1976d2",
          color: "white",
        },
      }}
    >
      <p className="m-2.5">Select Date Range</p>
      <div className="flex justify-between p-2">
        <Stack direction="row" spacing={1} sx={{ justifyContent: "start" }}>
          <div className="border">
            <DateRangeComponent
              range={range}
              onSetRange={onSetRange}
              isShowPastDate={true}
            />
          </div>
          <Button
            type="button"
            onClick={() => searchWithDateRange()}
            className="bg-sky-600 hover:bg-sky-400 text-stone-50"
          >
            Search
          </Button>
        </Stack>
        <div className="flex justify-end mx-4">
          <CSVLink
            data={dynamicRows}
            filename={"calcelled-reservation-list.csv"}
          >
            <Tooltip title="Download Excel">
              {/* <FileDownloadIcon></FileDownloadIcon> */}
              <Button
                type="button"
                className="bg-sky-600 hover:bg-sky-400 text-stone-50"
              >
                Download
              </Button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataGrid
        autoHeight
        rows={dynamicRows}
        columns={columns}
        rowsPerPageOptions={[10, 50, 100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

export default function CancelledBillList() {
  return (
    <>
      <Page title="Home">
        <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "#0323f8",
              marginLeft: 10,
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Cancelled Bill Report
          </Typography>
          <CancelledBillGrid />
        </Container>
      </Page>
    </>
  );
}
