import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useDetails } from "../../contexts/DetailsContext";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";

import {
  baseUrl,
  changeCheckingInfo,
  checkInFromHotel,
  getBookingInfo,
  getCurrentUser,
  getEmptyRoomNumber,
  getRoomInformationData,
  getRoomRatePlan,
  getRoomType,
} from "../../api-config/config";
import {
  formateDateInApiSupport,
  getDate,
  getMonth,
  isEqual,
  isEqualOrAfter,
} from "../../utils/formatTime";
import { sendEmail } from "../../utils/emailjs-utils";
import DateRangeComponent from "../DateRangePicker";
import moment from "moment";
import {
  changeBookingStatus,
  insertPaymentData,
} from "../../api-config/payment.api";
import LoaderDotted from "../LoaderDotted/LoaderDotted";
import { calNoOfNights } from "../../utils/formatNumber";
import { getRoomPrice } from "../../utils/utilFuncForEditReservation";
import { calcDiscountValue, findRoomInfoAndSetPrice } from "./util.func";
import {
  getCorporateData,
  getTravelAgentData,
} from "../../api-config/user.api";
import { useNavigate } from "react-router-dom";

const DemoEdit = (props: any) => {
  const {
    selectedEventInfo,
    handleCloseEditReservationDialog,
    selectedRoomInfo,
    resourceMap,
    onFormSubmitted,
    getRoomtypeId,
    bookedRooms,
    calendarInfo,
    roomTypeList1,
  } = props;

  const [roomInfo, setRoomInfo] = useState({} as any);
  const [pricePerRoom, setPricePerRoom] = useState(0);
  const [bookingId, setBookingId] = useState("");
  const [bookingInfo, setBookingInfo] = useState({} as any);
  const [submitButtonType, setSubmitButtonType] = useState("");
  const [range, setRange] = useState({
    startDate: selectedEventInfo.event.start,
    endDate: selectedEventInfo.event.end,
    key: "selection",
  });

  const [noOfAdult, setNoOfAdult] = useState(
    selectedEventInfo.event._def.extendedProps.guestBasicInfo.no_of_adults
  );
  const [noOfChild, setNoOfChild] = useState(
    selectedEventInfo.event._def.extendedProps.guestBasicInfo.no_of_children
  );

  const [roomTypeList, setRoomTypeList] = useState([] as any);
  const [roomList, setRoomList] = useState([] as any);
  const [emptyRoom, setEmptyRoom] = useState([] as any);
  const [selectedRoom, setSelectedRoom] = useState({
    id: selectedEventInfo.event._def.extendedProps.guestBasicInfo.room_id,
  } as any);
  const [selectedRoomType, setSelectedRoomType] = useState({
    id: selectedEventInfo.event._def.extendedProps.guestBasicInfo.room_type_id,
  } as any);

  const initBookingData =
    selectedEventInfo?.event?._def?.extendedProps?.guestBasicInfo;
  const [originalPrice, setOriginalPrice] = useState(
    initBookingData.total_sale_amount
  );

  const [calculatedPrice, setCalcualtedPrice] = useState({
    total_price_with_tax: initBookingData.gross_amount,
    total_sale_price: initBookingData.total_sale_amount,
    room_service_charge: initBookingData.total_service_amount,
    service_charge_in_percentage:
      (initBookingData.service_amount / initBookingData.total_sale_amount) *
      100,
    discountMethod: initBookingData.discount_type,
    partialPayment: initBookingData.paid_amount,
    discountValue: calcDiscountValue(selectedEventInfo),
    discountAmount: initBookingData.discount_value,
    total_without_service:
      initBookingData.total_sale_amount - initBookingData.discount_value,
    total_without_tax: initBookingData.total_without_tax,
    total_tax: initBookingData.gross_amount - initBookingData.total_without_tax,
  } as any);

  const [isUpdateDisable, setIsUpdateDisable] = useState(true);
  const [roomInfoBasedOnAdult, setRoomInfoBasedOnAdult] = useState({} as any);
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [rateType, setRateType] = useState("");
  const [selectedRoomPlan, setSelectedRoomPlan] = useState({} as any);
  const [roomRatePlan, setRoomRatePlan] = useState([]);
  const [marketSegment, setMarketSegment] = useState("");
  const [data, setData] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState(0);
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked, "this is checked");

    setChecked(event.target.checked);
  };
  const navigate = useNavigate();

  // const handleClose = () => {
  //   onHandleClose();
  // };
  function onFolioClick() {
    navigate("/main/folio", {
      state: {
        booking_id: selectedEventInfo.event._def.title.split("_")[0],
      },
    });
    handleCloseEditReservationDialog();
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setReason("");
    setError("");
  };

  const handleSubmitCashierData = async (cause: string) => {
    const payload = {
      booking_status: "no_show",
      booking_id: bookingInfo.booking_id,
      no_show_reason: cause,
    };

    await changeBookingStatus(payload).then((response: any) => {
      console.log(response);
    });
    window.location.reload();
  };

  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
    if (e.target.value.trim() === "") {
      setError("Reason is required.");
    } else {
      setError("");
    }
  };
  const fetchSegmentData = (e: any) => {
    setMarketSegment(e.target.value);
    switch (e.target.value) {
      case "Corporate":
        return getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
          setData(res.data)
        );
      case "Travel Agent":
        return getTravelAgentData(loggedUser?.hotel_code, 0).then((res) =>
          setData(res.data)
        );
      default:
        return;
    }
  };
  const handleConfirm = () => {
    if (reason.trim() === "") {
      setError("Reason is required.");
    } else {
      handleSubmitCashierData(reason);
      // console.log("Guest marked as No Show with reason: ", reason);
      closeModal();
    }
  };

  const loggedUser = getCurrentUser();
  function getAndSetBookingInfo(booking_id: any) {
    getBookingInfo(Number(booking_id)).then((response: any) => {
      setBookingInfo({ ...response });
    });
  }

  const defaultValues = {
    room_type: "",
    firstName: selectedEventInfo.event._def.extendedProps.guestBasicInfo.fName,
    lastName: selectedEventInfo.event._def.extendedProps.guestBasicInfo.lName,
    email: selectedEventInfo.event._def.extendedProps.guestBasicInfo.email,
    mobileNumber:
      selectedEventInfo.event._def.extendedProps.guestBasicInfo.mobile,
    price: calculatedPrice.total_without_service,
    discountMethod: calculatedPrice.discountMethod,
    discountValue: calculatedPrice.discountValue,
    partialPaymentMethod: bookingInfo?.payment_list?.[0]?.mode.toUpperCase(),
    partialPayment: calculatedPrice.partialPayment,
    firstName2:
      selectedEventInfo.event._def.extendedProps.guestBasicInfo
        .otherGuestInfo[0]?.first_name || "",
    firstName3:
      selectedEventInfo.event._def.extendedProps.guestBasicInfo
        .otherGuestInfo[1]?.first_name || "",
    lastName2:
      selectedEventInfo.event._def.extendedProps.guestBasicInfo
        .otherGuestInfo[0]?.last_name || "",
    lastName3:
      selectedEventInfo.event._def.extendedProps.guestBasicInfo
        .otherGuestInfo[1]?.last_name || "",
    rateType: bookingInfo?.rete_plan_name?.split(" ")?.[0],
    rate_plan_id: bookingInfo?.room_booking?.[0]?.rate_plan_id,
  };
  // console.log(
  //   bookingInfo?.payment_list?.[0]?.mode.toUpperCase(),
  //   "calling for mode i want mode "
  // );

  const handleSubmit = (formData: any) => {
    let receiptLink = "";
    console.log(marketSegment);
    console.log(formData, "this is form data before payload");

    if (submitButtonType === "cancelReservation") {
      const userResponse = window.prompt(
        "Do you want to continue for Cancellation? (Yes/No)"
      );
      if (userResponse !== null) {
        const normalizedResponse = userResponse.trim().toLowerCase();
        if (normalizedResponse === "yes") {
          bookingInfo.room_type_id = roomInfo.room_type_id;
          bookingInfo.booking_status = "cancelled";
          receiptLink = `${baseUrl}/booking_pdf_pms?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`;
        } else if (normalizedResponse === "no") {
          return;
        } else {
          return;
        }
      }
    } else if (
      submitButtonType === "update" ||
      submitButtonType === "checkin"
    ) {
      const other_members = [];
      if (noOfAdult >= 2) {
        if (formData.firstName2) {
          const obj1: any = {
            first_name: formData.firstName2,
            last_name: formData.lastName2,
          };
          if (bookingInfo.other_members?.length) {
            obj1.extra_guest_id = bookingInfo.other_members[0].extra_guest_id;
          }
          other_members.push(obj1);
        }
        if (formData.firstName3 && noOfAdult >= 3) {
          const obj2: any = {
            first_name: formData.firstName3,
            last_name: formData.lastName3,
          };
          if (bookingInfo.other_members?.length === 2) {
            obj2.extra_guest_id = bookingInfo.other_members[1].extra_guest_id;
          }
          other_members.push(obj2);
        } else if (formData.firstName3 && noOfAdult === 2) {
          const obj = {
            extra_guest_id: bookingInfo.other_members[1].extra_guest_id,
          };
          other_members.push(obj);
        }
      }
      if (noOfAdult === 1) {
        if (bookingInfo.other_members?.length) {
          bookingInfo.other_members.forEach((member: any) => {
            const obj = {
              extra_guest_id: member.extra_guest_id,
            };
            other_members.push(obj);
          });
        }
      }
      bookingInfo.other_members = other_members;
      bookingInfo.room_type_id = selectedRoomType.id;
      bookingInfo.first_name = formData.firstName;
      bookingInfo.last_name = formData.lastName;
      bookingInfo.email = formData.email;

      bookingInfo.mobile_number = formData.mobileNumber;
      bookingInfo.from_date = formateDateInApiSupport(range.startDate);
      bookingInfo.to_date = formateDateInApiSupport(range.endDate);
      bookingInfo.total_sale_amount = calculatedPrice.total_sale_price;
      (bookingInfo.total_services_amount = calculatedPrice.room_service_charge),
        (bookingInfo.service_charge =
          calculatedPrice.service_charge_in_percentage),
        (bookingInfo.total_without_tax = calculatedPrice.total_without_tax);
      bookingInfo.tax_amount = calculatedPrice.total_tax;
      bookingInfo.gross_amount = calculatedPrice.total_price_with_tax;
      bookingInfo.balance_amount =
        calculatedPrice.total_price_with_tax - formData.partialPayment;
      bookingInfo.paid_amount = formData.partialPayment;
      if (formData.discountMethod.length && calculatedPrice?.discountAmount) {
        bookingInfo.discount_value = formData.discountValue;
        bookingInfo.discount_type = formData.discountMethod;
      }
      bookingInfo.discount_amount = calculatedPrice?.discountAmount || 0;
      bookingInfo.rate_plan_id =
        selectedRoomPlan.id || bookingInfo?.room_booking?.[0]?.rate_plan_id;
      bookingInfo.rate_plan_amount =
        selectedRoomPlan.rate_amount ||
        bookingInfo?.room_booking?.[0]?.rate_plan_amount;
      bookingInfo.corporate_id = selectedCorporate || 0;
      const roomAssignedAdultInfo = [
        {
          no_of_adults: noOfAdult,
          no_of_child: noOfChild,
          no_of_rooms: 1,
          room_id: selectedRoom.id,
        },
      ];
      bookingInfo.room_assinged_adult_info = roomAssignedAdultInfo;

      const roomBooking = [
        {
          room_id: selectedRoom.id,
          no_of_pax: noOfAdult + noOfChild,
          no_of_adults: noOfAdult,
          no_of_children: 0,
          no_of_rooms: 1,
          total_sale_amount: calculatedPrice.total_sale_price,
          discount_amount: calculatedPrice?.discountAmount || 0,
          coupon_amount: 0,
          total_without_tax: calculatedPrice.total_sale_price,
          tax_amount: "0",
          gross_amount: calculatedPrice.total_price_with_tax,
          rate_plan_id:
            selectedRoomPlan.id || bookingInfo?.room_booking?.[0]?.rate_plan_id,
          rate_plan_amount:
            selectedRoomPlan.rate_amount ||
            bookingInfo?.room_booking?.[0]?.rate_plan_amount,
        },
      ];
      bookingInfo.room_booking = roomBooking;

      receiptLink = `${baseUrl}/booking_pdf_pms?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`;
      if (submitButtonType === "checkin") {
        bookingInfo.check_in_time = moment().format("YYYY-MM-DD HH:mm:ss");
        bookingInfo.room_type_id = selectedRoomType.id;
        bookingInfo.booking_status = "check_in";
        receiptLink = `${baseUrl}/booking_pdf_pms?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`;
      }
    }

    changeCheckingInfo(bookingInfo).then((response: any) => {
      if (formData.partialPayment && formData.partialPaymentMethod) {
        const body = {
          booking_id:
            "this implementation is wrong need to think something else",
          paid_amount: formData.partialPayment,
          gross_amount: calculatedPrice.total_price_with_tax,
        } as any;
        if (formData.partialPaymentMethod === "UPI") {
          body.upi = formData.partialPayment;
        } else if (formData.partialPaymentMethod === "CASH") {
          body.cash = formData.partialPayment;
        } else if (formData.partialPaymentMethod === "CARD") {
          body.card = formData.partialPayment;
        } else if (formData.partialPaymentMethod === "ONLINE") {
          body.online = formData.partialPayment;
        }
        insertPaymentData(body);
      }
      if (response?.data?.room_booking?.length > 0) {
        const payload = {
          first_name: response.data?.first_name,
          checkinDate: response.data?.from_date,
          checkoutDate: response.data?.to_date,
          roomType: response.data?.room_booking[0]?.room_title,
          roomNo: response.data?.room_booking[0]?.room_id,
          totalPrice: response.data?.gross_amount,
          receiptLink: receiptLink,
          from_name: response.data?.hotel_name,
          reply_to: "booking@ratebotai.com",
          to_email: response.data?.email,
        };
        sendEmail(payload);
      }
      onFormSubmitted(response);
    });
  };

  const handleOnchangePlan = (name: any) => {
    // console.log(id, "onchange handle function plan blah blajh");
    // const match: any = roomRatePlan?.find((plan: any) => plan?.name === id);
    // console.log(match, "comming frommactched");
    // setSelectedRoomPlan(match);
    // setOriginalPrice((prev: any) => prev + match.rate_amount);
    const match: any = roomRatePlan.find((plan: any) => plan.name === name);

    if (match) {
      setRateType(match?.name);
      setSelectedRoomPlan(match);
    }
  };
  function onNoOfAdultlChange(value: any, discountObj?: any, str: any = "") {
    let child = 0;
    let adult = 0;
    setRateType("");
    if (str === "child") {
      child = Number(value);
      setNoOfChild(child);
    } else {
      adult = Number(value);
      setNoOfAdult(value);
    }
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      start_date: formateDateInApiSupport(range.startDate),
      end_date: formateDateInApiSupport(range.endDate),
      room_info: [
        {
          number_of_adults: +adult,
          number_of_children: child,
          number_of_children_0_6: 0,
          number_of_children_6_12: 0,
        },
      ],
    };
    getRoomInformationData(payload).then((roomInformationData: any) => {
      setRoomInfoBasedOnAdult(roomInformationData.data);
      // findRoomInfoAndSetPrice(roomInformationData.data, discountObj);
    });
  }
  function onRoomTypeSelected(id: any) {
    console.log("calling on selected room type id from form", id);

    roomTypeList?.forEach((roomType: any) => {
      if (Number(id) === roomType.id) {
        setSelectedRoomType(roomType);
        setRoomList([...roomType.rooms]);

        const payload = {
          hotel_code: loggedUser?.hotel_code,
          start_date: formateDateInApiSupport(range.startDate),
          end_date: formateDateInApiSupport(range.endDate),
          room_info: [
            {
              number_of_adults: +noOfAdult,
              number_of_children: +noOfChild,
              number_of_children_0_6: 0,
              number_of_children_6_12: 0,
            },
          ],
        };
        getRoomInformationData(payload).then((roomInformationData: any) => {
          setRoomInfoBasedOnAdult(roomInformationData.data);
          // findRoomInfoAndSetPrice(roomInformationData.data, discountObj);
        });

        getRoomRatePlan({
          hotel_code: loggedUser?.hotel_code,
          room_type_id: Number(id),
        }).then((response) => {
          setRoomRatePlan(response?.data);
        });
      }
    });
  }
  function onRoomSelected(id: any) {
    emptyRoom?.forEach((room: any) => {
      if (room.id === Number(id)) {
        setSelectedRoom(room);
        setIsUpdateDisable(false);
      }
    });
  }
  const formValidation = Yup.object().shape({
    // room_type: Yup.string().required("Please enter room type"),
    // mobile_no: Yup.number().min(10).required("Please enter your mobile number"),
    // short_name: Yup.string().required("Please enter Short name"),
    // // image: Yup.string().required("Please enter City"),
    // adult: Yup.number().required("Please enter Base Occupancy"),
    // rateType: Yup.string().required("Please select rate plan"),
    //child: Yup.string().required("Please enter State"),
  });
  function onSetRange(value: any) {
    setRange(value);
  }
  const handleCorporateChange = (event: any) => {
    console.log(event.target.value, "this event on change co");
    setSelectedCorporate(event.target.value);
  };
  const getRoomTypeList = (hotel_code: number, floor_id: number) => {
    return getRoomType(hotel_code, floor_id).then(
      (res: any) => {
        if (res?.status_message === "success") {
          const roomTypeArray = [] as any;
          res.data?.forEach((roomType: any) => {
            const obj = {
              id: roomType.id,
              room_type_name: roomType.room_type_name,
              rooms: roomType.rooms,
              price: roomType.price,
            };
            roomTypeArray.push(obj);
          });
          setRoomTypeList([...roomTypeArray]);
          console.log(res);
        } else {
          console.log(res.message);
        }
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error", resMessage);
      }
    );
  };

  const handleCalculateNewPrice = () => {
    let basePrice = 0;
    const noOfNights = +calNoOfNights(range.startDate, range.endDate);
    const ratePlan =
      (selectedRoomPlan.rate_amount || 0) * noOfNights * Number(noOfAdult);

    roomInfoBasedOnAdult?.rooms?.forEach((singleRoomInfo: any) => {
      if (selectedRoomType.id === singleRoomInfo?.room_type_id) {
        basePrice = singleRoomInfo?.room_price?.total_price;
      }
    });
    console.log(basePrice, "from base price");

    setOriginalPrice(basePrice + ratePlan);
  };
  // useEffect lafda
  useEffect(() => {
    console.log("this is e lafda effect");

    getRoomRatePlan({
      hotel_code: loggedUser?.hotel_code,
      room_type_id:
        selectedEventInfo.event._def.extendedProps.guestBasicInfo?.room_type_id,
    }).then((response: any) => {
      setRoomRatePlan(response?.data);

      if (
        selectedEventInfo.event._def.extendedProps.guestBasicInfo?.rate_plan_id
      ) {
        const match: any = response.data.find(
          (plan: any) =>
            plan.id ===
            selectedEventInfo.event._def.extendedProps.guestBasicInfo
              ?.rate_plan_id
        );

        if (match) {
          setRateType(match?.name);
          setSelectedRoomPlan(match);
        }
      }
    });
    getRoomTypeList(loggedUser?.hotel_code, 1);
    const payload = {
      hotel_code: loggedUser?.hotel_code,
      start_date: formateDateInApiSupport(range.startDate),
      end_date: formateDateInApiSupport(range.endDate),
      room_info: [
        {
          number_of_adults: +noOfAdult,
          number_of_children: +noOfChild,
          number_of_children_0_6: 0,
          number_of_children_6_12: 0,
        },
      ],
    };
    getRoomInformationData(payload).then((roomInformationData: any) => {
      setRoomInfoBasedOnAdult(roomInformationData.data);
      // findRoomInfoAndSetPrice(roomInformationData.data, discountObj);
    });
    onRoomTypeSelected(selectedRoomType?.id);
  }, [selectedEventInfo.event._def.extendedProps.guestBasicInfo?.room_type_id]);

  useEffect(() => {
    const body = {
      from_date: formateDateInApiSupport(range.startDate),
      to_date: formateDateInApiSupport(range.endDate),
      pms_hotel_code: loggedUser?.hotel_code,
      room_type_id: selectedRoomType.id,
    };

    getEmptyRoomNumber(body)
      .then((response) => {
        setEmptyRoom(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedRoomType.id]);
  useEffect(() => {
    const priceObj = findRoomInfoAndSetPrice(
      roomInfoBasedOnAdult,
      {
        discountMethod: calculatedPrice.discountMethod,
        discountValue: calculatedPrice.discountValue,
      },
      initBookingData,
      originalPrice,
      calendarInfo
    );
    setCalcualtedPrice({
      ...calculatedPrice,
      ...priceObj,
    });
  }, [originalPrice, calculatedPrice.discountValue]);
  console.log(
    rateType,
    bookingInfo?.rete_plan_name?.split(" ")?.[0],
    "this is rate type-------------------------"
  );

  useEffect(() => {
    const roomInfo = resourceMap[selectedEventInfo.event._def.resourceIds[0]];
    const bookingId = selectedEventInfo.event.title.split("_")[0];
    setRoomInfo(roomInfo);
    setBookingId(bookingId);
    getAndSetBookingInfo(bookingId);
  }, [selectedEventInfo]);
  useEffect(() => {
    onRoomTypeSelected(selectedRoomType?.id);
  }, [range.startDate, range.endDate]);
  useEffect(() => {
    if (bookingInfo?.corporate_id) {
      console.log(bookingInfo?.corporate_id, "why this is always true");
      setMarketSegment("Corporate");
      setSelectedCorporate(bookingInfo?.corporate_id);
      getCorporateData(loggedUser?.hotel_code, 0).then((res) =>
        setData(res.data)
      );
    }
    setRateType(bookingInfo?.rete_plan_name?.split(" ")?.[0]);
    // const matchRate = roomRatePlan?.find(
    //   (plan: any) => bookingInfo?.rate_plan_id === plan?.id
    // );
    // console.log(matchRate, "this is match rate lklkjl-00-9-09098798");

    // setSelectedRoomPlan(matchRate);
  }, [bookingInfo]);

  const elements = [] as any;
  for (let i = 2; i <= noOfAdult; i++) {
    elements.push(
      <>
        <FormGroup>
          <Field
            name={`firstName${i}`}
            className="con-field"
            placeholder="Enter First Name(extra)"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="firstName" />
          </p>
        </FormGroup>
        <FormGroup>
          <Field
            name={`lastName${i}`}
            className="con-field"
            placeholder="Enter Last Name"
            type="text"
          />
          <p className="text-red-600">
            <ErrorMessage name="lastName" />
          </p>
        </FormGroup>
      </>
    );
  }

  if (selectedEventInfo) {
    return (
      <>
        <div>
          {/* <button
            onClick={openModal}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Open Modal
          </button> */}
          {/* {loader && <LoaderDotted />} */}

          {isOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto overflow-y-auto outline-none focus:outline-none bg-opacity-50 bg-gray-700">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="p-6">
                    <h3 className="text-2xl font-semibold">
                      Are you sure to mark the guest as No Show?
                    </h3>
                    <div className="mt-4">
                      <label htmlFor="reason" className="block text-gray-600">
                        Reason:
                      </label>
                      <input
                        type="text"
                        id="reason"
                        name="reason"
                        value={reason}
                        onChange={handleReasonChange}
                        className={`mt-2 p-3 border rounded w-full focus:outline-none ${
                          error ? "border-red-500" : "border-gray-300"
                        }`}
                      />
                      {error && <p className="mt-2 text-red-500">{error}</p>}
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                    <button
                      onClick={handleConfirm}
                      type="button"
                      className="bg-blue-500 text-white active:bg-blue-600 text-sm font-bold uppercase px-6 py-3 rounded outline-none focus:outline-none mr-4 hover:shadow-lg"
                    >
                      Yes
                    </button>
                    <button
                      onClick={closeModal}
                      type="button"
                      className="bg-gray-300 text-gray-600 active:bg-gray-400 text-sm font-bold uppercase px-6 py-3 rounded outline-none focus:outline-none hover:shadow-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            border: "solid black",
            borderWidth: "thin",
            padding: "5px",
          }}
        >
          <div style={{ flex: 1 }}>
            <p>Duration:</p>
            {/* {bookingInfo.booking_status === "check_in" ? (
              <p style={{ color: "blue", fontWeight: 600 }}>{`${getMonth(
                selectedEventInfo?.event?.start
              )} ${getDate(selectedEventInfo?.event?.start)} - ${getMonth(
                selectedEventInfo?.event?.end
              )} ${getDate(selectedEventInfo?.event?.end)}`}</p>
            ) : ( */}
            {bookingInfo.booking_status === "check_in" ? (
              <DateRangeComponent
                range={range}
                onSetRange={onSetRange}
                isShowPastDate={false}
                startFalse={true}
              />
            ) : (
              <DateRangeComponent
                range={range}
                onSetRange={onSetRange}
                isShowPastDate={false}
                startFalse={false}
              />
            )}

            {/* )} */}
          </div>
          <div style={{ flex: 1 }}>
            <p>
              Room Type:{" "}
              <span style={{ color: "blue", fontWeight: 400 }}>
                {roomInfo.room_type_name}
              </span>
            </p>
            <p>
              Room:{" "}
              <span style={{ color: "blue", fontWeight: 400 }}>
                {roomInfo.room_name}
              </span>
            </p>
          </div>
          <div style={{ flex: 1 }}>
            <p>
              Booking Id:{" "}
              <span style={{ color: "blue", fontWeight: 400 }}>
                {bookingId}
              </span>
            </p>
            <p>
              Voucher:{" "}
              <a
                href={`${baseUrl}/booking_pdf_pms?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`}
                target="_blank"
                style={{
                  color: "blue",
                  fontWeight: 300,
                  textDecoration: "underline",
                }}
              >
                Click Here
              </a>
            </p>
            <p>
              Source:{" "}
              <span style={{ color: "blue", fontWeight: "400" }}>
                {bookingInfo?.booking_from === "Booking"
                  ? "Booking.com"
                  : bookingInfo?.booking_from}
              </span>{" "}
            </p>
          </div>
        </div>
        <Formik
          initialValues={defaultValues}
          validationSchema={formValidation}
          onSubmit={(event) => handleSubmit(event)}
        >
          {({ values }) => (
            <Form className="grid gap-4 lg:grid-cols-1 p-8">
              <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-1">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Room type
                  </label>
                  <Field
                    as="select"
                    type="number"
                    name="roomType"
                    onChange={(e: any) => onRoomTypeSelected(e.target.value)}
                    value={selectedRoomType.id}
                    // disabled={bookingInfo.booking_status === "check_in"}
                  >
                    <option value="">select</option>
                    {roomTypeList.map((roomType: any, key: any) => {
                      return (
                        <option value={roomType.id} key={key}>
                          {roomType.room_type_name}
                        </option>
                      );
                    })}
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="roomType" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Room
                  </label>
                  <Field
                    as="select"
                    type="number"
                    name="room"
                    value={selectedRoom.id}
                    onChange={(e: any) => onRoomSelected(+e.target.value)}
                    // disabled={bookingInfo.booking_status === "check_in"}
                  >
                    <option value={""}>select</option>
                    {emptyRoom?.map((room: any, key: any) => {
                      return (
                        <option value={room.id} key={key}>
                          {room.room_name}
                        </option>
                      );
                    })}
                    {/* {roomList.map((room: any, key: any) => {
                      return (
                        <option value={room.id} key={key}>
                          {room.room_name}
                        </option>
                      );
                    })} */}
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="child" />
                  </p>
                </FormGroup>
              </div>
              <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-1">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Rate Type
                  </label>
                  <Field
                    as="select"
                    name="rateType"
                    onChange={(e: any) => {
                      handleOnchangePlan(e.target.value);
                      setRateType(e.target.value);
                    }}
                    value={rateType}
                  >
                    <option value={""}>Select</option>
                    {roomRatePlan?.map((plan: any) => (
                      <option value={plan?.name} key={plan?.id}>
                        {plan?.name}
                      </option>
                    ))}
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="rateType" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-1">
                    Adult
                  </label>
                  <Field
                    as="select"
                    type="number"
                    name="adult"
                    onChange={(e: any) => onNoOfAdultlChange(e.target.value)}
                    value={noOfAdult}
                  >
                    <option value="">Select</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="adult" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-1">
                    Child
                  </label>
                  <Field
                    as="select"
                    name="child"
                    type="number"
                    onChange={(e: any) =>
                      onNoOfAdultlChange(e.target.value, {}, "child")
                    }
                    value={noOfChild}
                  >
                    <option value="">0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="child" />
                  </p>
                </FormGroup>
              </div>
              <div className="grid gap-2 lg:grid-cols-9 sm:grid-cols-4 border items-center">
                <div className="col-span-4">
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      First Name
                    </label>
                    <Field
                      name="firstName"
                      className="con-field"
                      placeholder="Enter First Name"
                      type="text"
                      disabled
                    />
                    <p className="text-red-600">
                      <ErrorMessage name="firstName" />
                    </p>
                  </FormGroup>
                </div>
                <div className="col-span-4">
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Last Name
                    </label>
                    <Field
                      name="lastName"
                      className="con-field"
                      placeholder="Enter Last Name"
                      type="text"
                      disabled
                    />
                    <p className="text-red-600">
                      <ErrorMessage name="lastName" />
                    </p>
                  </FormGroup>
                </div>
                <div className="col-span-1 justify-center">
                  <Tooltip title="Edit">
                    <EditIcon onClick={onFolioClick} />
                  </Tooltip>
                  {/* <button onClick={onFolioClick}>Edit</button> */}
                </div>
              </div>
              {/* <div className="grid gap-4 lg:grid-cols-2 sm:grid-cols-2 border">
                {elements}
              </div> */}
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Market Segment
                  </label>
                  <Field
                    as="select"
                    name="market_segment"
                    value={marketSegment}
                    onChange={fetchSegmentData}
                  >
                    <option value="">Select</option>
                    <option value="Corporate">Corporate</option>
                    <option value={"Travel Agent"}>Travel Agent</option>
                    <option value={"Walk Inn"}>Walk Inn</option>
                    <option value={"Wedding"}>Wedding</option>
                    <option value={"Events"}>Events</option>
                    <option value={"Leisure"}>Leisure</option>
                  </Field>

                  <p className="text-red-600">
                    <ErrorMessage name="Type" />
                  </p>
                </FormGroup>
                {marketSegment === "Corporate" ? (
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Corporate
                    </label>
                    <Field
                      as="select"
                      name="corporate_id"
                      value={selectedCorporate}
                      onChange={handleCorporateChange}
                    >
                      <option value="">Select</option>
                      {data.length > 0 &&
                        data.map((item: any) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.corporate_name}
                          </option>
                        ))}
                    </Field>
                  </FormGroup>
                ) : marketSegment === "Travel Agent" ? (
                  <FormGroup>
                    <label htmlFor="" className="pb-2">
                      Travel Agent
                    </label>
                    <Field as="select" name="travel_agent_id">
                      <option value="">Select</option>
                      {data.length > 0 &&
                        data.map((item: any) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.billing_company_name}
                          </option>
                        ))}
                    </Field>
                  </FormGroup>
                ) : null}
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Email
                  </label>
                  <Field
                    name="email"
                    className="con-field"
                    placeholder="Enter Email id"
                    id="email"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="email" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Mobile
                  </label>
                  <Field
                    name="mobileNumber"
                    className="con-field"
                    placeholder="Enter Mobile no*"
                    id="mobileNumber"
                    type="text"
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="mobileNumber" />
                  </p>
                </FormGroup>
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Price{" "}
                    <span style={{ fontSize: "13px" }}>
                      (
                      {calNoOfNights(range.startDate, range.endDate) > 1
                        ? `${calNoOfNights(
                            range.startDate,
                            range.endDate
                          )} nights`
                        : `${calNoOfNights(
                            range.startDate,
                            range.endDate
                          )} night`}
                      )
                    </span>
                  </label>
                  <Field
                    name="price1"
                    className="con-field"
                    placeholder="Enter Base Price*"
                    id="price1"
                    onChange={(e: any) => {
                      setOriginalPrice(+e.target.value);
                    }}
                    value={originalPrice}
                    type="number"
                  />
                </FormGroup>

                <FormGroup className="p-2">
                  <Button
                    type="button"
                    name="newprice"
                    className="bg-sky-300 hover:bg-green-300"
                    onClick={() => handleCalculateNewPrice()}
                  >
                    Calculate Price
                  </Button>
                </FormGroup>
              </div>
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Discount Method
                  </label>
                  <Field as="select" name="discountMethod">
                    <option value="">Select</option>
                    <option value={"Percentage"}>Percentage</option>
                    <option value={"Amount"}>Amount</option>
                  </Field>
                  <p className="text-red-600">
                    <ErrorMessage name="adult" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Percentage/Amount
                  </label>
                  <Field
                    name="discountValue"
                    className="con-field"
                    placeholder="Enter Percentage/Amount if any"
                    id="partialPayment"
                    type="number"
                    onBlur={(e: any) =>
                      // findRoomInfoAndSetPrice(roomInfoBasedOnAdult, {
                      //   discountMethod: calculatedPrice.discountMethod,
                      //   discountValue: Number(e.target.value),
                      // })
                      {
                        setCalcualtedPrice((prev: any) => ({
                          ...prev,
                          discountValue: +e.target.value,
                          discountMethod: values.discountMethod,
                        }));
                      }
                    }
                  />
                  <p className="text-red-600">
                    <ErrorMessage name="partialPayment" />
                  </p>
                </FormGroup>
              </div>
              {/* <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Payment Method
                  </label>
                  <Field as="select" name="partialPaymentMethod">
                    <option value="">Select</option>
                    <option value={"UPI"}>UPI</option>
                    <option value={"CASH"}>CASH</option>
                    <option value={"CARD"}>CARD</option>
                  </Field>

                  <p className="text-red-600">
                    <ErrorMessage name="adult" />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Partial Payment
                  </label>
                  <Field
                    name="partialPayment"
                    className="con-field"
                    placeholder="Enter Partial payment if any"
                    id="partialPayment"
                    type="number"
                    disabled={
                      (calculatedPrice.partialPayment > 0 &&
                        bookingInfo.booking_status === "pending") ||
                      (calculatedPrice.partialPayment > 0 &&
                        bookingInfo.booking_status === "check_in")
                    }
                  />
                  {((calculatedPrice.partialPayment > 0 &&
                    bookingInfo.booking_status === "pending") ||
                    (calculatedPrice.partialPayment > 0 &&
                      bookingInfo.booking_status === "check_in")) && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                        padding: "5px",
                      }}
                    >
                      Add more payment in payment folio
                    </p>
                  )}
                  <p className="text-red-600">
                    <ErrorMessage name="partialPayment" />
                  </p>
                </FormGroup>
              </div> */}
              <div className="grid gap-4 lg:grid-cols-2">
                <FormGroup>
                  <label htmlFor="" className="pb-2">
                    Final Price{" "}
                    <span style={{ fontWeight: "normal", fontSize: "10px" }}>
                      (excl. tax & svc. charge)
                    </span>
                  </label>
                  <Field
                    name="price"
                    className="con-field"
                    placeholder="Enter Base Price*"
                    id="price"
                    // onChange={(e: any) => {
                    //   console.log(e.target.value);
                    //   setCalcualtedPrice((prev: any) => ({
                    //     ...prev,
                    //     total_price_with_tax: e.target.value,
                    //     total_sale_price: parseInt(e.target.value),
                    //   }));
                    // }}
                    value={
                      checked
                        ? calculatedPrice.total_price_with_tax
                        : calculatedPrice.total_without_service
                    }
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    sx={{ fontSize: 12, width: "fit-content" }}
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Price Inc Tax"
                  />
                </FormGroup>
              </div>

              <div className="grid gap-3 lg:grid-cols-2">
                <>
                  <FormGroup>
                    <Button
                      disabled={
                        !noOfAdult || bookingInfo.booking_status === "check_out"
                      }
                      type="submit"
                      name="update"
                      className="bg-sky-300 hover:bg-yellow-300"
                      onClick={() => setSubmitButtonType("update")}
                    >
                      Update
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="submit"
                      disabled={
                        bookingInfo.booking_status === "check_in" ||
                        bookingInfo.booking_status === "check_out"
                      }
                      name="cancelReservation"
                      className="bg-sky-300 hover:bg-red-300"
                      onClick={() => setSubmitButtonType("cancelReservation")}
                    >
                      Cancel Reservation
                    </Button>
                  </FormGroup>
                </>
              </div>
              {bookingInfo.booking_status === "pending" &&
              isEqualOrAfter(
                moment().startOf("day"),
                selectedEventInfo.event.start
              ) ? (
                <div className="grid gap-3 lg:grid-cols-2">
                  <FormGroup>
                    <Button
                      type="submit"
                      name="checkin"
                      className="bg-sky-300 hover:bg-green-300"
                      onClick={() => setSubmitButtonType("checkin")}
                    >
                      Check In
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      type="button"
                      name="noshow"
                      className="bg-red-500 text-white hover:bg-red-300"
                      // onClick={handleSubmitCashierData}
                      onClick={openModal}
                    >
                      No Show
                    </Button>
                  </FormGroup>
                </div>
              ) : (
                <> </>
              )}
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return <p>Sorry something wrong</p>;
  }
};

export default DemoEdit;
